import React, { useEffect, useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  Checkbox,
  VStack,
  Input,
  FormLabel,
  useToast,
  Textarea,
  CheckboxGroup,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';

function ViewSingleRequest() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await GET(`/admin/digital-program/application/${id}`);
        if (response.status === 200) {
          console.log(response.data);
          setData(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [id]);
  return (
    <Stack>
      <HStack justifyContent={'space-between'}>
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            View Requested Digital Library Services
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all services...Check Now!
          </Text>
        </Stack>
        <Stack alignItems={'start'}>
          <Heading fontSize={'22px'} fontWeight={'500'}>
            Reg No: #{data?.registrationNumber || '566ce1'}
          </Heading>
        </Stack>
      </HStack>

      <form className="applyForm">
        <Text fontWeight={'500'} fontSize={'20px'}>
          Hospital Particulars:
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              name="hospitalName"
              value={data?.hospitalName}
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Address</FormLabel>
            <Input
              required
              name="address"
              value={data?.address}
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              name="hospitalEmail"
              value={data?.hospitalEmail}
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Requesting Staff Particulars:
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Name of Requesting Staff</FormLabel>
            <Input
              required
              name="requestingStaffName"
              type="text"
              value={data?.requestingStaffName}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Mobile No.</FormLabel>
            <Input
              required
              name="requestingStaffMobileNo"
              type="text"
              value={data?.requestingStaffMobileNo}
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Requested Subscription:
        </Text>
        <Stack pos={'relative'}>
          <Stack
            pos={'absolute'}
            m="0 !important"
            flexDirection={'row'}
            width={'100%'}
            top={-6}
            justifyContent={'end'}
            alignItems={'end'}
          >
            <Checkbox colorScheme="teal">All the above</Checkbox>
          </Stack>
        </Stack>
        <Box name="requestedSubscription" w="100%" className="checkboxFamily">
          <Stack
            spacing={0}
            gap={4}
            direction={'row'}
            flexDir={{ base: 'column', xl: 'row' }}
            flexWrap={'wrap'}
            alignItems={'center'}
          >
            <Box w="32.33%">
              <Checkbox
                isChecked={data?.requestedSubscription?.medicalDatabase}
                colorScheme="teal"
                name="medicalDatabase"
              >
                Medical Databases
              </Checkbox>
            </Box>
            <Box w="32.33%">
              <Checkbox
                colorScheme="teal"
                isChecked={data?.requestedSubscription?.articles}
                name="articles"
              >
                Articles
              </Checkbox>
            </Box>
            <Box w="32.33%">
              <Checkbox
                colorScheme="teal"
                isChecked={data?.requestedSubscription?.researches}
                name="researches"
              >
                Researches
              </Checkbox>
            </Box>
            <Box w="32.33%">
              <Checkbox
                colorScheme="teal"
                isChecked={data?.requestedSubscription?.periodicals}
                name="periodicals"
              >
                Periodicals
              </Checkbox>
            </Box>
            <Box w="32.33%">
              <Checkbox
                colorScheme="teal"
                isChecked={data?.equestedSubscription?.digitalMedicalReferences}
                name="digitalMedicalReferences"
              >
                Digital Medical References
              </Checkbox>
            </Box>
          </Stack>
        </Box>
      </form>
    </Stack>
  );
}

export default ViewSingleRequest;
