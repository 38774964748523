import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { POST, PUT } from '../../../utilities/ApiProvider';
import { useLocation, useNavigate } from 'react-router-dom';

export default function AddServiceDetails() {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [editId, seteditId] = useState('');
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [bannerImageFile, setBannerImageFile] = useState(null);
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState('');

  const handleThumbnailChange = event => {
    const file = event.target.files[0];
    setThumbnailFile(file);
  };

  const handleBannerImageChange = event => {
    const file = event.target.files[0];
    setBannerImageFile(file);
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
  ];

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    if (!title || !value || !thumbnailFile || !bannerImageFile) {
      toast({
        title: 'Please fill in all the fields.',
        status: 'error',
        duration: 2000,
        position: 'top-right',
        isClosable: true,
      });
      setLoading(false);
      return;
    }
    try {
      const form = new FormData();
      form.append('title', title);
      form.append('description', value);
      form.append('category', category);
      form.append('icon', thumbnailFile);
      form.append('thumbnail', bannerImageFile);
      console.log(category);
      const response = await POST('/admin/service', form);
      setLoading(false);
      if (response.status === 200) {
        toast({
          title: 'Service Added Successfully.',
          status: 'success',
          duration: 2000,
          position: 'top-right',
          isClosable: true,
        });
        setTitle('');
        setValue('');
        setThumbnailFile(null);
        setBannerImageFile(null);
      } else {
        toast({
          title: response?.message,
          status: 'error',
          duration: 2000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error, 'While creating the Blogs');
    }
  };

  const handleEdit = async e => {
    e.preventDefault();
    setLoading(true);
    if (!title || !value) {
      toast({
        title: 'Please enter a title and description.',
        status: 'error',
        duration: 2000,
        position: 'top-right',
        isClosable: true,
      });
      setLoading(false);
      return;
    }
    try {
      const form = new FormData();
      form.append('title', title);
      form.append('description', value);
      form.append('category', category);
      form.append('icon', thumbnailFile);
      form.append('thumbnail', bannerImageFile);
      const response = await PUT(`/admin/update-service/${editId}`, form);
      setLoading(false);
      if (response.status === 200) {
        toast({
          title: 'Service Updated Successfully.',
          status: 'success',
          duration: 2000,
          position: 'top-right',
          isClosable: true,
        });
        setTitle('');
        setValue('');
        setThumbnailFile(null);
        setBannerImageFile(null);
        setCategory('');
        seteditId('');
        navigate(`/dashboard/hospital-service/${editId}`);
      } else {
        toast({
          title: response?.message,
          status: 'error',
          duration: 2000,
          position: 'top-right',
          isClosable: true,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error, 'While editing the Service');
    }
  };

  useEffect(() => {
    console.log('LOCATION', location.state);
    if (location.state !== null) {
      setTitle(location.state?.title);
      setValue(location.state?.description);
      setThumbnailFile(location.state?.icon);
      setBannerImageFile(location.state?.thumbnail);
      setCategory(location.state?.category);
      seteditId(location.state?._id);
    } else {
      return;
    }
  }, [location]);

  return (
    <>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Add New Service
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all service...Check Now!
        </Text>
      </Stack>
      <form onSubmit={editId !== '' ? handleEdit : handleSubmit}>
        <SimpleGrid columns={2} spacing={10}>
          <Stack className="applyForm" spacing="4">
            <FormControl id="headline">
              <FormLabel>Headline</FormLabel>
              <Input
                value={title}
                onChange={e => setTitle(e.target.value)}
                py={6}
                placeholder="Enter here"
              />
            </FormControl>
            <FormControl id="description">
              <FormLabel>Description</FormLabel>
              <ReactQuill
                style={{ height: '245px' }}
                theme="snow"
                value={value}
                onChange={setValue}
                formats={formats}
                modules={modules}
              />
            </FormControl>
            <Stack mt={6} pt={6}>
              <Button
                isLoading={loading}
                width={'max-content'}
                type="submit"
                colorScheme="teal"
              >
                Save Changes
              </Button>
            </Stack>
          </Stack>
          <Stack className="applyForm" spacing="4">
            <FormControl id="headline">
              <FormLabel>Category</FormLabel>
              <Select
                value={category}
                onChange={e => setCategory(e.target.value)}
              >
                <option value="">Select Category</option>
                <option value="Practitioners">Practitioners</option>
                <option value="patients">Patients</option>
                <option value="hospital">Hospital</option>
              </Select>
            </FormControl>
            <FormControl id="thumbnail">
              <FormLabel>Service icon</FormLabel>
              <Box
                borderWidth="1px"
                borderRadius="md"
                borderStyle="dashed"
                p="4"
                textAlign="center"
              >
                <Input
                  type="file"
                  display="none"
                  onChange={handleThumbnailChange}
                  id="thumbnail-input"
                />
                <Box as="label" cursor="pointer" htmlFor="thumbnail-input">
                  📎 Attach File
                </Box>
              </Box>
              <Box mt="2" fontSize="sm" color="gray.500">
                {thumbnailFile ? thumbnailFile.name : 'No file chosen.'}
              </Box>
            </FormControl>

            <FormControl id="banner-image">
              <FormLabel>Banner Image</FormLabel>
              <Box
                borderWidth="1px"
                borderRadius="md"
                borderStyle="dashed"
                p="4"
                textAlign="center"
              >
                <Input
                  type="file"
                  display="none"
                  onChange={handleBannerImageChange}
                  id="banner-image-input"
                />
                <Box as="label" cursor="pointer" htmlFor="banner-image-input">
                  📎 Attach File
                </Box>
              </Box>
              <Box mt="2" fontSize="sm" color="gray.500">
                {bannerImageFile ? bannerImageFile.name : 'No file chosen.'}
              </Box>
            </FormControl>
          </Stack>
        </SimpleGrid>
      </form>
    </>
  );
}
