import React, { useState, useEffect } from 'react';
import MainDashboard from '../../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Checkbox,
  Select,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../../components/Header/DashboardHeader';
import BookingCard from './BookingCard';
import Modal from '../../../../components/Dashboard/Modal';
import { GET, POST } from '../../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';

function AddNewBed({ onClose, fetchBedBooking }) {
  const [checkedItem, setCheckedItem] = useState('');
  const [typeOfBed, setBed] = useState('');
  const [payment, setPayment] = useState('');
  const [departments, setDepartments] = useState(null);
  const [selectedId, setSelectedId] = useState('');
  const [wardId, setWardId] = useState('');
  const [beds, setBeds] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [wards, setWards] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const user = useSelector(state => state.user.value);
  console.log(user);
  async function fetchDepartments() {
    try {
      const response = await GET('/hospital/department');
      console.log(response);
      if (response.status === 200) setDepartments(response.data);
    } catch (error) {
      console.log('Error while fetching departments', error);
    }
  }

  useEffect(() => {
    fetchDepartments();
    fetchDoctors();
  }, []);

  const handleCheckboxChange = event => {
    const { name } = event.target;
    setCheckedItem(name);
  };
  const handleChange = id => {
    setSelectedId(id);
    console.log(departments.find(dep => dep._id === id));
    const ward = departments.find(dep => dep._id === id)?.['ward'];
    if (ward) setWards(ward);
  };

  const handleBed = event => {
    const { name } = event.target;
    setBed(name);
  };
  const handlePayment = event => {
    const { name } = event.target;
    setPayment(name);
  };
  const fetchBeds = async () => {
    try {
      const response = await POST(`/web/patient/department`, {
        department: selectedId,
        ward: wardId,
      });
      console.log(response);
      if (response.status === 200) setBeds(response.data);
    } catch (error) {
      console.log('Error while fetching wards', error);
    }
  };
  const fetchDoctors = async () => {
    try {
      const response = await GET('/hospital/practitioners');
      console.log(response);
      if (response.status === 200) setDoctors(response.data);
    } catch (error) {
      console.log('Error while fetching doctors', error);
    }
  };
  useEffect(() => {
    if (selectedId && wardId) {
      fetchBeds();
    }
  }, [selectedId, wardId]);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      form.append('type', 'inpatinet_booking');
      form.append('hospital', user?.hospital);
      const formDataEntries = Array.from(form.entries());
      console.log(formDataEntries.map(([key, value]) => ({ [key]: value })));
      const response = await POST('/web/bedbooking/book-a-bed', form);
      console.log(response);
      if (response) setLoading(false);
      if (response.status === 200) {
        fetchBedBooking();
        toast({
          description: 'Bed Booked Successfully!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack>
      <form onSubmit={handleSubmit} className="applyForm">
        <Box>
          <FormLabel>Patient Name</FormLabel>
          <Input name="name" placeholder="Enter here" />
        </Box>
        <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="58%">
            <FormLabel>Age</FormLabel>
            <Input type="number" name="age" placeholder="Enter here" />
          </Box>
          <Box w="38%">
            <FormLabel>Sex</FormLabel>
            <Select name="gender" placeholder="Choose option">
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Select>
          </Box>
        </Stack>
        {/* <Text margin={'0 important'} fontSize={'22px'} fontWeight={'500'}>Patient Current Location</Text>
         */}
        <Heading
          fontSize={'18px'}
          color={'primaryBlack.100'}
          mb={'10px !important'}
        >
          Patient Current Location
        </Heading>
        <Box w="100%" className="checkboxFamily">
          <Stack
            spacing={0}
            gap="10px"
            direction={'row'}
            flexDir={{ base: 'column', xl: 'row' }}
            justifyContent={'space-between'}
          >
            <Box w="100%">
              <Checkbox
                name="location"
                colorScheme="teal"
                value="At Home"
                onChange={handleCheckboxChange}
              >
                At Home
              </Checkbox>
            </Box>
            <Box w="100%">
              <Checkbox
                name="location"
                colorScheme="teal"
                value="Hospitalized"
                onChange={handleCheckboxChange}
              >
                Hospitalized
              </Checkbox>
            </Box>
          </Stack>
        </Box>
        <Heading
          fontSize={'18px'}
          color={'primaryBlack.100'}
          mb={'10px !important'}
        >
          Desired Inpatient Department
        </Heading>
        <Box>
          <FormLabel>Choose an option</FormLabel>
          <Select
            onChange={e => handleChange(e.target.value)}
            name="department"
            placeholder="Choose an option"
          >
            {departments?.map(department => (
              <option value={department._id}>{department.name}</option>
            ))}
          </Select>
        </Box>
        {wards && selectedId && (
          <>
            <Heading
              fontSize={'18px'}
              color={'primaryBlack.100'}
              mb={'10px !important'}
            >
              Choose Ward
            </Heading>
            <Box>
              <FormLabel>Choose an option</FormLabel>
              <Select
                onChange={e => setWardId(e.target.value)}
                name="ward"
                placeholder="Choose an option"
              >
                {wards?.map(ward => (
                  <option value={ward.wardId}>{ward.wardName}</option>
                ))}
              </Select>
            </Box>
          </>
        )}
        {beds && selectedId && wardId && (
          <>
            <Heading
              fontSize={'18px'}
              color={'primaryBlack.100'}
              mb={'10px !important'}
            >
              Choose Bed
            </Heading>
            <Box>
              <FormLabel>Choose an option</FormLabel>
              <Select name="bed" placeholder="Choose an option">
                {beds?.map(bed => (
                  <option value={bed?._id}>{bed?.name}</option>
                ))}
              </Select>
            </Box>
          </>
        )}
        <Heading
          fontSize={'18px'}
          color={'primaryBlack.100'}
          mb={'10px !important'}
        >
          Types Of Bed
        </Heading>
        <Box w="100%" className="checkboxFamily">
          <Stack
            spacing={0}
            gap="10px"
            direction={'row'}
            flexDir={{ base: 'column', xl: 'row' }}
            justifyContent={'space-between'}
          >
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="typeOfBed"
                value="ICU"
                onChange={handleBed}
              >
                ICU
              </Checkbox>
            </Box>
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="typeOfBed"
                value="Ordinary"
                onChange={handleBed}
              >
                Ordinary
              </Checkbox>
            </Box>
          </Stack>
        </Box>
        <Heading
          fontSize={'18px'}
          color={'primaryBlack.100'}
          mb={'10px !important'}
        >
          Desired Hospitalist
        </Heading>
        <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
          {/* <Box w="58%">
            <FormLabel>Name</FormLabel>
            <Input name="name" placeholder="Enter here" />
          </Box> */}
          <Box w="100%">
            <FormLabel>Any Hospitalist</FormLabel>
            <Select name="doctor" placeholder="choose option">
              {/* {doctors?.map(doctor => (
                <option value={doctor._id}>{doctor.name}</option>
              ))} */}
              {doctors?.map(doctor => {
                return (
                  <option value={doctor.doctor._id}>
                    {doctor.doctor.name}
                  </option>
                );
              })}
            </Select>
          </Box>
        </Stack>
        <Heading
          fontSize={'18px'}
          color={'primaryBlack.100'}
          mb={'10px !important'}
        >
          Treatment Cost to Be Paid by
        </Heading>
        <Box w="100%" className="checkboxFamily">
          <Stack
            spacing={0}
            gap="10px"
            direction={'row'}
            flexDir={{ base: 'column', xl: 'row' }}
            justifyContent={'space-between'}
          >
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="costtobepaid"
                value="Insurance"
                onChange={handlePayment}
              >
                Insurance
              </Checkbox>
            </Box>
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="costtobepaid"
                value="Self"
                onChange={handlePayment}
              >
                Self
              </Checkbox>
            </Box>
          </Stack>
        </Box>
        <Button
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          w="100%"
          mb={4}
          isLoading={loading}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          type="submit"
        >
          Add
        </Button>
      </form>
    </Stack>
  );
}

function BedBooking() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedService, setSelectedService] = useState('bedBooking');
  const [data, setData] = useState(null);
  // const data = {
  //   bedBooking: [
  //     {
  //       id: '1',
  //       options: [
  //         {
  //           key: 'Patient Name',
  //           value: 'John Doe',
  //         },
  //         {
  //           key: 'Age',
  //           value: '27',
  //         },
  //         {
  //           key: 'Sex',
  //           value: 'Male',
  //         },
  //         {
  //           key: 'Patient Location:',
  //           value: 'At Home',
  //         },
  //         {
  //           key: 'Desired Inpatient Department:',
  //           value: '29/3/2024',
  //         },
  //         {
  //           key: 'Type of Bed:',
  //           value: 'ICU',
  //         },
  //         {
  //           key: 'Desired Hospitalist:',
  //           value: '8:00 AM to 10:00 AM',
  //         },

  //         {
  //           key: 'Treatment Cost to Be Paid by:',
  //           value: 'Insurance',
  //         },
  //       ],
  //     },
  //     {
  //       id: '2',
  //       options: [
  //         {
  //           key: 'Patient Name',
  //           value: 'John Doe',
  //         },
  //         {
  //           key: 'Age',
  //           value: '27',
  //         },
  //         {
  //           key: 'Sex',
  //           value: 'Male',
  //         },
  //         {
  //           key: 'Patient Location:',
  //           value: 'At Home',
  //         },
  //         {
  //           key: 'Desired Inpatient Department:',
  //           value: '29/3/2024',
  //         },
  //         {
  //           key: 'Type of Bed:',
  //           value: 'ICU',
  //         },
  //         {
  //           key: 'Desired Hospitalist:',
  //           value: '8:00 AM to 10:00 AM',
  //         },

  //         {
  //           key: 'Treatment Cost to Be Paid by:',
  //           value: 'Insurance',
  //         },
  //       ],
  //     },
  //   ],
  //   patientBooking: [
  //     {
  //       id: '3',
  //       options: [
  //         {
  //           key: 'Department Name:',
  //           value: '4/4/2024',
  //         },
  //         {
  //           key: 'Department Founding Date:',
  //           value: '27',
  //         },
  //         {
  //           key: 'No. of Consultants:',
  //           value: '11',
  //         },
  //         {
  //           key: 'No. of Nurses:',
  //           value: '5',
  //         },
  //         {
  //           key: 'Desired Inpatient Department:',
  //           value: '29/3/2024',
  //         },
  //         {
  //           key: 'Medical Subspecialties:',
  //           value: 'Cardiologist',
  //         },
  //         {
  //           key: 'Medical Treatment Services:',
  //           value: 'Cardiologist',
  //         },

  //         {
  //           key: 'Medical Achievements:',
  //           value: 'Antibiotics',
  //         },
  //       ],
  //       occupied: false,
  //     },
  //     {
  //       id: '4',
  //       options: [
  //         {
  //           key: 'Department Name:',
  //           value: '4/4/2024',
  //         },
  //         {
  //           key: 'Department Founding Date:',
  //           value: '27',
  //         },
  //         {
  //           key: 'No. of Consultants:',
  //           value: '11',
  //         },
  //         {
  //           key: 'No. of Nurses:',
  //           value: '5',
  //         },
  //         {
  //           key: 'Desired Inpatient Department:',
  //           value: '29/3/2024',
  //         },
  //         {
  //           key: 'Medical Subspecialties:',
  //           value: 'Cardiologist',
  //         },
  //         {
  //           key: 'Medical Treatment Services:',
  //           value: 'Cardiologist',
  //         },

  //         {
  //           key: 'Medical Achievements:',
  //           value: 'Antibiotics',
  //         },
  //       ],
  //       occupied: true,
  //       occupiedDays: '10',
  //     },
  //   ],
  //   inPatient: [
  //     {
  //       id: '3',
  //       options: [
  //         {
  //           key: 'Department Name:',
  //           value: '4/4/2024',
  //         },
  //         {
  //           key: 'Department Founding Date:',
  //           value: '27',
  //         },
  //         {
  //           key: 'No. of Consultants:',
  //           value: '11',
  //         },
  //         {
  //           key: 'No. of Nurses:',
  //           value: '5',
  //         },
  //         {
  //           key: 'Desired Inpatient Department:',
  //           value: '29/3/2024',
  //         },
  //         {
  //           key: 'Medical Subspecialties:',
  //           value: 'Cardiologist',
  //         },
  //         {
  //           key: 'Medical Treatment Services:',
  //           value: 'Cardiologist',
  //         },

  //         {
  //           key: 'Medical Achievements:',
  //           value: 'Antibiotics',
  //         },
  //       ],
  //       occupied: false,
  //       occupiedDays: '0',
  //     },
  //     {
  //       id: '4',
  //       options: [
  //         {
  //           key: 'Department Name:',
  //           value: '4/4/2024',
  //         },
  //         {
  //           key: 'Department Founding Date:',
  //           value: '27',
  //         },
  //         {
  //           key: 'No. of Consultants:',
  //           value: '11',
  //         },
  //         {
  //           key: 'No. of Nurses:',
  //           value: '5',
  //         },
  //         {
  //           key: 'Desired Inpatient Department:',
  //           value: '29/3/2024',
  //         },
  //         {
  //           key: 'Medical Subspecialties:',
  //           value: 'Cardiologist',
  //         },
  //         {
  //           key: 'Medical Treatment Services:',
  //           value: 'Cardiologist',
  //         },

  //         {
  //           key: 'Medical Achievements:',
  //           value: 'Antibiotics',
  //         },
  //       ],
  //       occupied: true,
  //       occupiedDays: '10',
  //     },
  //   ],
  // };
  const fetchBedBooking = async () => {
    try {
      const response = await GET('/web/bedbooking/list');
      console.log(response.data, selectedService);
      if (response.status === 200) setData(response.data);
    } catch (error) {
      console.log('Error while fetching bed booking', error);
    }
  };
  useEffect(() => {
    fetchBedBooking();
  }, []);
  return (
    <Stack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={'Add New Bed Booking Service'}
        subTitle={'Enter the details to add new bed service'}
        children={
          <AddNewBed onClose={onClose} fetchBedBooking={fetchBedBooking} />
        }
      />
      {/*Heading  */}
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Bed Booking Service
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all bed booking details...Check Now!
          </Text>
        </Stack>
        <Stack>
          {selectedService === 'inPatient' && (
            <Button
              bgGradient="linear(to-r, #295377, #208C74)"
              color="#E9E9E9"
              fontWeight={'500'}
              fontSize={'15px'}
              borderRadius={'21px'}
              onClick={onOpen}
              border={'2px solid transparent'}
              _hover={{
                bgGradient: 'none',
                borderColor: 'primaryGreen.200',
                color: 'primaryGreen.200',
              }}
            >
              Add New Bed Service
            </Button>
          )}
        </Stack>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} gap={'20px'}>
        <Button
          bgGradient={
            selectedService === 'bedBooking' && 'linear(to-r, #295377, #208C74)'
          }
          bg={'none'}
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight={500}
          color={selectedService === 'bedBooking' ? '#fff' : 'primaryBlack.100'}
          borderRadius={'25px'}
          h={{ base: '35px', xl: '45px' }}
          textTransform={'capitalize'}
          onClick={() => setSelectedService('bedBooking')}
          _hover={{
            bgGradient: 'linear(to-r, #295377, #208C74)',
            color: '#fff',
          }}
        >
          Bed Bookings
        </Button>

        <Button
          bgGradient={
            selectedService === 'inPatient' && 'linear(to-r, #295377, #208C74)'
          }
          bg={'none'}
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight={500}
          color={selectedService === 'inPatient' ? '#fff' : 'primaryBlack.100'}
          borderRadius={'25px'}
          h={{ base: '35px', xl: '45px' }}
          textTransform={'capitalize'}
          onClick={() => setSelectedService('inPatient')}
          _hover={{
            bgGradient: 'linear(to-r, #295377, #208C74)',
            color: '#fff',
          }}
        >
          Inpatient Bed Bookings
        </Button>
      </Stack>
      {!data ? (
        <Spinner />
      ) : (
        <BookingCard
          selectedService={selectedService}
          fetchBedBooking={fetchBedBooking}
          data={data?.[selectedService]}
        />
      )}
    </Stack>
  );
}

export default BedBooking;
