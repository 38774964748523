import React, { useState } from 'react';
import {
  Box,
  Button,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  Link,
  useToast,
} from '@chakra-ui/react';
import Email from '../../assets/images/website/Vector.png';
import Phone from '../../assets/images/website/phone.png';
import { FaArrowLeft, FaEnvelope, FaPhone, FaPhoneAlt } from 'react-icons/fa';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/website/logo.jpg';
import { POST } from '../../utilities/ApiProvider';

function Forget({ setState }) {
  const [channel, setChannel] = useState(null);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const value = channel === 'email' ? email : phone;
    setIsLoading(true);

    try {
      const response = await POST('/authentication/send-otp', {
        channel,
        value,
      });
      setIsLoading(false);
      const isSuccess = response.status === 200;
      if (isSuccess) {
        localStorage.setItem('emedieeForgetEmail', email);
        setState(true);
      }

      toast({
        description: response?.message,
        status: isSuccess ? 'success' : 'error',
        isClosable: true,
        position: 'top-right',
        duration: 3000,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      toast({
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top-right',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
      setChannel(null);
      setEmail('');
      setPhone('');
      e.target.reset();
    }
  };

  return (
    <Box
      maxW={{ base: '100%', lg: '490px', xl: '600px' }}
      p={{
        base: '120px 25px',
        lg: '60px 0 60px 40px',
        xl: '60px 0 60px 120px',
      }}
    >
      <Link as={ReactLink} to="/login">
        <Button
          bgColor={'transparent'}
          _hover={{ bgColor: 'transparent' }}
          _active={{ bgColor: 'transparent' }}
          mb="60px"
          fontWeight={'500'}
        >
          <Icon as={FaArrowLeft} mr="10px" /> Back
        </Button>
      </Link>
      <Image mb="40px" src={Logo} />
      <Heading
        fontWeight={'500'}
        color={'primaryBlack.100'}
        fontSize={'32px'}
        pb="20px"
      >
        Forgot Password?
      </Heading>
      <Text
        fontWeight={'500'}
        color={'primaryGray.100'}
        fontSize={'14px'}
        pb="20px"
      >
        Enter your email and we will send you a verification code
      </Text>

      <form className="applyForm" onSubmit={handleSubmit}>
        <Box mb="10px">
          <FormLabel>Email Address</FormLabel>
          <Box position={'relative'}>
            <Input
              onClick={() => setChannel('email')}
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
              name="email"
              type={'email'}
              placeholder="Enter here"
              isDisabled={channel === 'phone' ? true : false}
            />
            <Box
              position={'absolute'}
              right={'-15px'}
              top={'5px'}
              zIndex={1}
              w={'70px !important'}
            >
              <Button
                bgGradient={
                  channel === 'email'
                    ? 'linear(to-r, primaryGreen.100, primaryGreen.200)'
                    : 'linear(to-r, #E2E2E2, #E2E2E2)'
                }
                fontSize={'18px !important'}
                border={'none !important'}
                bgColor={'transparent'}
                _hover={{ bgColor: 'transparent' }}
                _active={{ bgColor: 'transparent' }}
              >
                <Icon
                  color={channel === 'email' ? 'white' : '#75767A'}
                  fontSize={'18px'}
                  as={FaEnvelope}
                />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <FormLabel>Phone No*</FormLabel>
          <Box position={'relative'}>
            <Input
              required
              name="phone"
              onClick={() => setChannel('phone')}
              value={phone}
              onChange={e => setPhone(e.target.value)}
              type={'number'}
              placeholder="Enter here"
              isDisabled={channel === 'email' ? true : false}
            />
            <Box
              position={'absolute'}
              right={'-15px'}
              top={'5px'}
              zIndex={1}
              w={'70px !important'}
            >
              <Button
                bgGradient={
                  channel === 'phone'
                    ? 'linear(to-r, primaryGreen.100, primaryGreen.200)'
                    : 'linear(to-r, #E2E2E2, #E2E2E2)'
                }
                fontSize={'18px !important'}
                border={'none !important'}
                bgColor={'transparent'}
                _hover={{ bgColor: 'transparent' }}
                _active={{ bgColor: 'transparent' }}
              >
                <Icon
                  color={channel === 'phone' ? 'white' : '#75767A'}
                  fontSize={'18px'}
                  as={FaPhone}
                />
              </Button>
            </Box>
          </Box>
        </Box>
        <Button
          isLoading={isLoading}
          my="10px"
          bgGradient={'linear(to-r, #295377, #208C74)'}
          fontSize={'14px'}
          fontWeight={500}
          color={'#fff'}
          borderRadius={'25px'}
          w="100%"
          h="45px"
          textTransform={'uppercase'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          type="submit"
        >
          Continue
        </Button>
      </form>
    </Box>
  );
}

export default Forget;
