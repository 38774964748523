import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import Blog from '../../../assets/images/website/blogs/blogdetails.jpg';
import { useParams, useNavigate } from 'react-router-dom';
import { DELETE, GET } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';

export default function BlogDetails() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await GET(`/admin/get-single-blog/${id}`);
        if (response.status === 200) {
          setBlog(response.data[0]);
          console.log(response.data);
        } else {
          handleFetchError();
        }
      } catch (error) {
        handleFetchError(error);
      }
    };
    fetchBlog();
  }, []);

  const handleFetchError = (error = null) => {
    if (error) {
      console.error('While Fetching Blogs', error);
    }
    toast({
      title: 'Error',
      description: 'Something went wrong while fetching the blog.',
      status: 'error',
      duration: 2000,
      position: 'top-right',
      isClosable: true,
    });
  };

  const handleDeleteBlog = async () => {
    if (window.confirm('Are you sure you want to delete this blog?')) {
      try {
        const response = await DELETE(`/admin/delete-blog/${id}`);
        if (response.status === 200) {
          toast({
            title: 'Success',
            description: 'Blog deleted successfully.',
            status: 'success',
            duration: 2000,
            position: 'top-right',
            isClosable: true,
          });
          navigate('/dashboard/blogs');
        } else {
          handleFetchError();
        }
      } catch (error) {
        handleFetchError(error);
      }
    }
  };

  return (
    <>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
      >
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Blog Details
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all blogs...Check Now!
          </Text>
        </Stack>
        <Stack direction={'flex'} gap={5}>
          <Button
            bg={
              'var(--Gradient-1, linear-gradient(109deg, #295377 -22.91%, #208C74 93.87%))'
            }
            color={'#fff'}
            px={8}
            py={6}
            borderRadius={25}
            onClick={() => navigate('/dashboard/addblog', { state: blog })}
          >
            Edit Blog
          </Button>
          <Button
            bg={
              'var(--Gradient-1, linear-gradient(109deg, #295377 -22.91%, #208C74 93.87%))'
            }
            color={'#fff'}
            px={8}
            py={6}
            borderRadius={25}
            onClick={handleDeleteBlog}
          >
            Delete Blog
          </Button>
        </Stack>
      </Stack>
      <Stack gap={3}>
        {/* <Image width={'100%'} h='420px' borderRadius={12} src={Blog}/> */}
        <Image
          width={'100%'}
          h="420px"
          borderRadius={12}
          src={`${imgUrl}/${blog?.cover}`}
        />
        <Heading color={'#1B1C1D'} fontSize={'25px'} fontWeight={600}>
          {blog?.title}
        </Heading>

        <Text
          fontSize={'16px'}
          color={'#75767A'}
          fontWeight={'400'}
          dangerouslySetInnerHTML={{ __html: blog?.description }}
        ></Text>
      </Stack>
    </>
  );
}
