import React, { useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  Checkbox,
  VStack,
  Input,
  FormLabel,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import { useParams } from 'react-router-dom';
import { POST } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';

function ElectronicHospitalRequest() {
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.user.value);
  const { id } = useParams();
  const [minStartDate, setMinStartDate] = useState(
    new Date().toISOString().slice(0, 16)
  );

  const toast = useToast();
  const [checkedValues, setCheckedValues] = useState({
    website: true,
    socialMedia: false,
    emails: false,
  });
  const data = JSON.parse(localStorage.getItem('emediiUser'));

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    console.log(name, checked);

    setCheckedValues(() => {
      return {
        ...checkedValues,
        [name]: checked,
      };
    });
    console.log(checkedValues);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      if (id !== 'service') {
        form.append('service', id);
        form.append('hospital', user.hospital);
      } else {
        form.append('type', id);
        form.append('hospital', user.hospital);
      }
      const formDataEntries = Array.from(form.entries());
      console.log(formDataEntries.map(([key, value]) => ({ [key]: value })));

      const response = await POST('/hospital/service', form);

      if (response.status === 200) {
        setLoading(false);
        toast({
          description: 'Form Submitted SucessFully!!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
      }
    } catch (error) {
      console.log('Erorr while submitting form ', error);
    } finally {
      setLoading(false);
      e.target.reset();
    }
  };

  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Electronic Advertisement Request for a Hospital
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all services...Check Now!
        </Text>
        {/* <Text fontSize={'20px'} fontWeight={'500'}>
              You've 5 services
            </Text> */}
      </Stack>
      {/*Heading  */}
      <form onSubmit={handleSubmit} className="applyForm">
        <Text fontWeight={'500'} fontSize={'20px'}>
          Hospital Particulars:
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Name</FormLabel>
            <Input
              value={data.fullName}
              required
              name="name"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Address</FormLabel>
            <Input
              required
              value={data.address}
              name="address"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              value={data.email}
              name="email"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Name of Requesting Staff</FormLabel>
            <Input
              required
              name="requestingStaffName"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Mobile No.</FormLabel>
            <Input
              required
              name="number"
              type="text"
              value={data?.number}
              placeholder="Enter Name"
            />
          </Box>
        </Stack>

        <Text fontWeight={'500'} fontSize={'20px'}>
          Advertisement Information:
        </Text>
        {/* <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Type of Advertisement</FormLabel>
            <Input
              required
              name="advertisementType"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Advertisement Duration</FormLabel>
            <Input
              required
              name="advertisementDuration"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Advertisement Display Time</FormLabel>
            <Input
              required
              name="advertisementDisplayTime"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack> */}
        <Box w="100%">
          <FormLabel>Text of Advertisement</FormLabel>
          {/* <Input
            required
            name="advertisementType"
            type="text"
            placeholder="Enter Name"
          /> */}
          <Textarea
            resize="none"
            name="advertisementText"
            placeholder="Enter Advertisement Text"
          />
        </Box>
        <Box w="100%">
          <FormLabel>Advertisement Keywords</FormLabel>
          <Input
            required
            name="advertisementKeywords"
            type="text"
            placeholder="Enter Name"
          />
        </Box>
        <Box w="100%">
          <FormLabel>Advertisement Start Display Time</FormLabel>
          <Input
            required
            name="advertisementDurationStart"
            type="datetime-local"
            min={minStartDate}
          />
        </Box>
        <Box w="100%">
          <FormLabel>Advertisement End Display Time</FormLabel>
          <Input
            required
            name="advertisementDurationEnd"
            type="datetime-local"
            min={minStartDate}
          />
        </Box>
        <Box
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <FormLabel>Advertisement Image </FormLabel>
          <Input
            py={2.5}
            required
            name="document"
            type="file"
            placeholder="Enter Name"
          />
        </Box>

        <Text fontWeight={'500'} fontSize={'20px'}>
          Advertisement Display Means:
        </Text>
        <Stack pos={'relative'}>
          <Stack
            pos={'absolute'}
            m="0 !important"
            flexDirection={'row'}
            width={'100%'}
            top={-6}
            justifyContent={'end'}
            alignItems={'end'}
          >
            <Checkbox colorScheme="teal">All the above</Checkbox>
          </Stack>
        </Stack>
        <Box
          name="advertisementdisplayMeans"
          w="100%"
          className="checkboxFamily"
        >
          <Stack
            spacing={0}
            gap={4}
            direction={'row'}
            flexDir={{ base: 'column', xl: 'row' }}
            justifyContent={'space-between'}
          >
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="website"
                isChecked={checkedValues.website}
                defaultChecked={checkedValues.website}
                onChange={handleCheckboxChange}
              >
                emedst.com website
              </Checkbox>
            </Box>
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="socialMedia"
                isChecked={checkedValues.socialMedia}
                defaultChecked={checkedValues.socialMedia}
                onChange={handleCheckboxChange}
              >
                emedst.com social media
              </Checkbox>
            </Box>
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="emails"
                isChecked={checkedValues.emails}
                defaultChecked={checkedValues.emails}
                onChange={handleCheckboxChange}
              >
                emails by emedst.com to clients
              </Checkbox>
            </Box>
          </Stack>
        </Box>

        <Button
          w="30.33%"
          mt={'25px'}
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          type="submit"
          isLoading={loading}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
        >
          <Stack
            w="100%"
            direction={'row'}
            alignItems={'center'}
            px={4}
            justifyContent={'space-between'}
          >
            <Text fontSize={'16px'} fontWeight={'500'}>
              $80.00
            </Text>
            <Text fontSize={'16px'} fontWeight={'500'}>
              Continue
            </Text>
          </Stack>
        </Button>
      </form>
    </Stack>
  );
}

export default ElectronicHospitalRequest;
