import React, { useEffect, useState } from 'react';
import {
  Heading,
  Stack,
  Text,
  Box,
  Grid,
  Icon,
  Button,
  useToast,
} from '@chakra-ui/react';
import { TbUser } from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { GET } from '../../../utilities/ApiProvider';

export default function SupportInquiries() {
  const toast = useToast();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchInquires = async () => {
      try {
        const response = await GET('/admin/ticket');
        console.log(response);
        if (response.status === 200) {
          setData(response.data);
        } else {
          handleFetchError();
        }
      } catch (error) {
        handleFetchError(error);
      }
    };

    fetchInquires();
  }, []);

  const handleFetchError = (error = null) => {
    if (error) {
      console.error('While Fetching Support Inquiries', error);
    }
    toast({
      title: 'Error',
      description: 'Something went wrong while fetching the blogs.',
      status: 'error',
      duration: 2000,
      position: 'top-right',
      isClosable: true,
    });
  };

  const handleClick = item => {
    if (item) {
      navigate(`/dashboard/inquiry/${item._id}`, { state: item });
    }
  };
  return (
    <Stack gap={4}>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
      >
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Help & Support Inquiries
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all support inquiries...Check Now!
          </Text>
        </Stack>
        <Button
          as={Link}
          to={'/dashboard/hospital-addservicesdetails'}
          bg={
            'var(--Gradient-1, linear-gradient(109deg, #295377 -22.91%, #208C74 93.87%))'
          }
          color={'#fff'}
          px={8}
          py={6}
          borderRadius={25}
        >
          Add New
        </Button>
      </Stack>
      <Stack>
        <Text fontSize={'20px'} fontWeight={'500'}>
          You’ve 5 inquiries
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          {data &&
            data.length > 0 &&
            data.map((item, index) => (
              <Box
                shadow={'0px 16.32px 38.75px 0px #0000000F'}
                borderRadius={'17px'}
                p={{ base: '15px 20px', xl: '25px 40px' }}
                display={'flex'}
                key={index}
                alignItems={'center'}
                role="group"
                transition={'0.3s'}
                _hover={{
                  borderColor: 'primaryGreen.200',
                }}
              >
                <Stack flex={2}>
                  <Stack
                    direction={'row'}
                    flexWrap={'wrap'}
                    alignItems={'center'}
                  >
                    <Box
                      w={'54px'}
                      h={'54px'}
                      bgColor={'#E2E2E2'}
                      display={'flex'}
                      alignItems={'center'}
                      transition={'0.3s'}
                      borderRadius={'12px'}
                      mb="5px"
                      justifyContent={'center'}
                      _groupHover={{
                        bgColor: 'primaryGreen.200',
                      }}
                    >
                      <Icon
                        _groupHover={{
                          color: '#fff',
                        }}
                        transition={'0.3s'}
                        fontSize={'30px'}
                        // fontSize={{ base: '26px', xl: '52px' }}
                        //   as={`${imgUrl}/${val.icon}`}
                        as={TbUser}
                      />
                    </Box>
                    <Box>
                      <Heading fontSize={'18px'} fontWeight={'500'}>
                        {item.name}
                      </Heading>
                      <Text fontSize={'14px'}>{item.email}</Text>
                    </Box>
                  </Stack>

                  <Heading w={'60%'} fontSize={'18px'} fontWeight={'500'}>
                    {moment(item.createdAt).format('M/D/YYYY - h A')}
                  </Heading>
                </Stack>
                <Button
                  as={Link}
                  to={'/dashboard/viewinquiries'}
                  bgGradient={'linear(to-r, #295377, #208C74)'}
                  fontSize={{ base: '12px', xl: '14px' }}
                  fontWeight={400}
                  color={'#fff'}
                  borderRadius={'25px'}
                  w="122px"
                  h="34px"
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                >
                  View Inquiry
                </Button>
              </Box>
            ))}
        </Grid>
      </Stack>
    </Stack>
  );
}
