import React from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  VStack,
  Input,
  FormLabel,
  useToast,
} from '@chakra-ui/react';

function RequestAdmissionMedicalDetails({ formValue }) {
  return (
    <Stack>
      <form className="applyForm">
        <Text fontWeight={'500'} fontSize={'20px'}>
          Hospital Information:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              value={formValue.name}
              name="name"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Address</FormLabel>
            <Input
              required
              name="address"
              type="text"
              value={formValue.address}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              value={formValue.email}
              name="email"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Requesting Staff:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              name="staffName"
              type="text"
              value={formValue.staffName}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Mobile No.</FormLabel>
            <Input
              required
              name="staffNumber"
              type="text"
              value={formValue.staffNumber}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              name="staffEmail"
              type="text"
              value={formValue.staffEmail}
              placeholder="Enter Name"
            />
          </Box>
        </Stack>

        <Text fontWeight={'500'} fontSize={'20px'}>
          Requested Program:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Program Name</FormLabel>
            <Input
              required
              name="programName"
              type="text"
              value={formValue.programName}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Duration</FormLabel>
            <Input
              required
              name="programDuration"
              type="text"
              value={formValue.programDuration}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Location</FormLabel>
            <Input
              required
              name="programLocation"
              type="text"
              value={formValue.programLocation}
              placeholder="Enter Name"
            />
          </Box>
        </Stack>

      
      </form>
    </Stack>
  );
}

export default RequestAdmissionMedicalDetails;
