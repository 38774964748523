import React, { useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Spinner,
  Select,
  useToast,
} from '@chakra-ui/react';
import Modal from '../../../components/Dashboard/Modal';
import { imgUrl } from '../../../utilities/config';
import moment from 'moment/moment';
import { PUT } from '../../../utilities/ApiProvider';
import { Link } from 'react-router-dom';
function RemoveService({
  idToUnblock,
  onClose,
  fetchBlockUsers,
  selectedService,
}) {
  const toast = useToast();
  const unblockUser = async () => {
    try {
      const response = await PUT(`/hospital/user/${idToUnblock}/unblock`, {
        status: 'active',
        selectedService,
      });
      if (response.status === 200) {
        fetchBlockUsers();
        toast({
          description: `User Unblocked successfully`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
      }
    } catch (error) {
      console.error('Error in Unblocking the user:', error);
    }
  };

  return (
    <Stack mb={3}>
      <Button
        bgGradient="linear(to-r, #295377, #208C74)"
        color="#E9E9E9"
        fontWeight={'500'}
        fontSize={'15px'}
        borderRadius={'21px'}
        onClick={unblockUser}
        border={'2px solid transparent'}
        _hover={{
          bgGradient: 'none',
          borderColor: 'primaryGreen.200',
          color: 'primaryGreen.200',
        }}
      >
        Yes
      </Button>
      <Button
        shadow={'0px 16px 38px 0px #0000000F'}
        border={'1px solid #75767A'}
        color="#75767A"
        fontWeight={'500'}
        fontSize={'15px'}
        borderRadius={'21px'}
      >
        No
      </Button>
    </Stack>
  );
}
function BlockUserCard({ type, data, selectedService, fetchBlockUsers }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [idToUnblock, setUnblockId] = useState('');

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={'Are you sure?'}
        subTitle={'You want to “Unblock” this user?'}
        children={
          <RemoveService
            idToUnblock={idToUnblock}
            onClose={onClose}
            fetchBlockUsers={fetchBlockUsers}
            selectedService={selectedService}
          />
        }
        size={'md'}
        isCentered={true}
      />
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {!data ? (
          <Spinner />
        ) : data?.length > 0 ? (
          data.map((item, ind) => (
            <Box
              shadow={'0px 16px 38px 0px #0000000F'}
              bg={'#fff'}
              borderRadius={'16px'}
              p={4}
              display={'flex'}
              flexDirection={'column'}
              gap={4}
            >
              <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                px={4}
              >
                <HStack alignItems={'center'} lineHeight={'0.3rem'} gap={2}>
                  <Avatar
                    size={'lg'}
                    name="Dan Abrahmov"
                    src={`${imgUrl}/${
                      selectedService === 'patient' ? item.picture : item.photo
                    }`}
                  />
                  <Stack>
                    <Heading fontSize={'22px'} fontWeight={'500'}>
                      {selectedService === 'patient'
                        ? item.fullName
                        : item.name}
                    </Heading>
                    <Text
                      fontSize={'15px'}
                      color={'#75767A'}
                      fontWeight={'400'}
                    >
                      #d44vdsQ
                    </Text>
                  </Stack>
                </HStack>
                <Text fontSize={'16px'} color={'#75767A'} fontWeight={'400'}>
                  Member since: {moment(item.createdAt).format('DD MMM, YYYY')}
                </Text>
              </Stack>
              <HStack my={'10px'}>
                <Button
                  as={Link}
                  to={
                    selectedService === 'doctor'
                      ? `/dashboard/doctor-profile/${item._id}`
                      : selectedService === 'hospital'
                      ? `/dashboard/hospital-profile/${item._id}`
                      : `/dashboard/patient-profile/${item._id}`
                  }
                  w="100%"
                  bgGradient="linear(to-r, #295377, #208C74)"
                  color="#E9E9E9"
                  fontWeight={'500'}
                  fontSize={'15px'}
                  borderRadius={'21px'}
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                >
                  View Profile
                </Button>
                <Button
                  w="100%"
                  shadow={'0px 16px 38px 0px #0000000F'}
                  border={'1px solid #75767A'}
                  color="#75767A"
                  fontWeight={'500'}
                  fontSize={'15px'}
                  borderRadius={'21px'}
                  onClick={() => {
                    // const id =
                    //   selectedService === 'doctor' ? item.user : item._id;
                    setUnblockId(item._id);
                    onOpen();
                  }}
                >
                  Unblock
                </Button>
              </HStack>
            </Box>
          ))
        ) : (
          <Heading>No Blocked Users</Heading>
        )}
      </Grid>
    </>
  );
}

export default BlockUserCard;
