import React, { useEffect, useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  VStack,
  Image,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { LuCalendarClock } from 'react-icons/lu';
import { BsCameraVideo } from 'react-icons/bs';
import { TbEmergencyBed, TbLibrary } from 'react-icons/tb';
import { LiaHospitalAltSolid } from 'react-icons/lia';
import { MdOutlineDocumentScanner } from 'react-icons/md';
import { GET } from '../../../../utilities/ApiProvider';
import { imgUrl } from '../../../../utilities/config';
import { MdFlightTakeoff } from 'react-icons/md';
import { LuCalendarCheck } from "react-icons/lu";
import { GrVirtualStorage } from "react-icons/gr";
import { IoBedOutline,IoLocationOutline  } from "react-icons/io5";
import { MdOutlineTravelExplore } from "react-icons/md";


function PatientServices() {
  //   const icons = [
  //     TbEmergencyBed,
  //     LuCalendarClock,
  //     LiaHospitalAltSolid,
  //     TbLibrary,
  //     BsCameraVideo,
  //   ];
  const data = [
    {
      title: 'Book a Clinic Appointment',
      icon: LuCalendarCheck ,
      url: '/dashboard/health-practitioner?type=inperson',
    },
    {
      title: 'Book a Virtual Clinic Appointment',
      icon: GrVirtualStorage,
      url: '/dashboard/health-practitioner?type=virtual',
    },
    {
      title: 'Inpatient Bed Booking',
      icon: IoBedOutline ,
      url: '/dashboard/bed-booking',
    },
    {
      title: 'Travel Agency',
      icon: MdFlightTakeoff,
      url: '',
      //   url: '/dashboard/services/patient/electronic-advertising',

    },
    {
      title: 'Nearest Doctor Search',
      icon: IoLocationOutline ,
      url: '/dashboard/health-practitioner?type=nearByDoctors',
    },
  ];

  //   const fetchPatientServices = async () => {
  //     try {
  //       const response = await GET('/web/services/patients');
  //       console.log(response);
  //       if (response.status === 200) {
  //         setData(response?.data?.service);
  //         console.log(response?.data?.service);
  //       } else {
  //         handleFetchError();
  //       }
  //     } catch (error) {
  //       handleFetchError(error);
  //     }
  //   };

  //   const handleFetchError = (error = null) => {
  //     if (error) {
  //       console.error('While Fetching Dashboard', error);
  //     }
  //     toast({
  //       title: 'Error',
  //       description: 'Something went wrong while fetching the Dashboard.',
  //       status: 'error',
  //       duration: 2000,
  //       position: 'top-right',
  //       isClosable: true,
  //     });
  //   };
  //   useEffect(() => {
  //     fetchPatientServices();
  //   }, []);
  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Services
        </Heading>
        {/* <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all services...Check Now!
        </Text> */}
      </Stack>
      {!data ? (
        <Stack>
          <Spinner />
        </Stack>
      ) : (
        <>
          <Text fontSize={'20px'} fontWeight={'500'}>
            You've {data?.length} services
          </Text>
          {/*Heading  */}
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            {data &&
              data.length > 0 &&
              data.map((item, index) => (
                <Box
                  // border={'2px solid #75767A'}
                  shadow={'0px 16.32px 38.75px 0px #0000000F'}
                  borderRadius={'17px'}
                  p={{ base: '15px 20px', xl: '25px 40px' }}
                  display={'flex'}
                  // flexDir={'column'}
                  key={index}
                  // alignItems={'center'}
                  // justifyContent={'center'}
                  role="group"
                  transition={'0.3s'}
                  _hover={{
                    borderColor: 'primaryGreen.200',
                  }}
                >
                  <Stack flex={2}>
                    <Box
                      w={'54px'}
                      h={'54px'}
                      bgColor={'#E2E2E2'}
                      display={'flex'}
                      alignItems={'center'}
                      color={'#000'}
                      transition={'0.3s'}
                      borderRadius={'12px'}
                      mb="5px"
                      justifyContent={'center'}
                      _groupHover={{
                        bgColor: 'primaryGreen.200',
                        color: '#fff',
                      }}
                    >
                      <Icon
                        // _hover={{ color: '#ffff' }}
                        as={item.icon || RiHospitalLine}
                        boxSize="30px"
                      />
                    </Box>
                    <Heading w={'60%'} fontSize={'18px'} fontWeight={'500'}>
                      {item.title}
                    </Heading>
                  </Stack>
                  <Stack flex={1} alignItems={'end'} justifyContent={'center'}>
                    <Button
                      as={Link}
                      to={item.url}
                      bgGradient={'linear(to-r, #295377, #208C74)'}
                      fontSize={{ base: '12px', xl: '14px' }}
                      fontWeight={400}
                      color={'#fff'}
                      borderRadius={'25px'}
                      w="122px"
                      h="34px"
                      border={'2px solid transparent'}
                      _hover={{
                        bgGradient: 'none',
                        borderColor: 'primaryGreen.200',
                        color: 'primaryGreen.200',
                      }}
                    >
                      View Service
                    </Button>
                  </Stack>
                </Box>
              ))}
          </Grid>
        </>
      )}
    </Stack>
  );
}

export default PatientServices;
