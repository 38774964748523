import React from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Select,
} from '@chakra-ui/react';
import Modal from '../../../../components/Dashboard/Modal';

function RemoveService() {
  return (
    <Stack mb={3}>
      <Button
        bgGradient="linear(to-r, #295377, #208C74)"
        color="#E9E9E9"
        fontWeight={'500'}
        fontSize={'15px'}
        borderRadius={'21px'}
      >
        Yes
      </Button>
      <Button
        shadow={'0px 16px 38px 0px #0000000F'}
        border={'1px solid #75767A'}
        color="#75767A"
        fontWeight={'500'}
        fontSize={'15px'}
        borderRadius={'21px'}
      >
        No
      </Button>
    </Stack>
  );
}
function ServiceCard({ data }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={'Are you sure?'}
        subTitle={'You want to “Remove” this service?'}
        children={<RemoveService />}
        size={'md'}
        isCentered={true}
      />
      {data && data.length > 0 ? (
        data.map((item, index) => (
          <Box
            shadow={'0px 16.32px 38.75px 0px #0000000F'}
            bg="#fff"
            borderRadius={'16px'}
            p={4}
            display={'flex'}
            flexDirection={'column'}
            gap={3}
          >
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Heading fontSize={'20px'} fontWeight={'500'}>
                {item.title}
              </Heading>
              <Heading fontSize={'20px'} color={'#208C74'} fontWeight={'500'}>
                {item.price}
              </Heading>
            </Stack>
            <Text color="#75767A" fontSize={'16px'} fontWeight={'400'}>
              {item.description}
            </Text>
            <Heading fontSize={'18px'} fontWeight={'500'}>
              Health Practitioner:
            </Heading>
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <HStack>
                <Avatar
                  size="md"
                  name="Prosper Otemuyiwa"
                  src="https://bit.ly/prosper-baba"
                />
                <Heading fontSize={'17px'} fontWeight={'500'}>
                  Dr. Mike Johns
                </Heading>
              </HStack>
              <Button
                bgGradient="linear(to-r, #295377, #208C74)"
                color="#E9E9E9"
                fontWeight={'500'}
                fontSize={'15px'}
                borderRadius={'21px'}
              >
                View Profile
              </Button>
            </Stack>
            <Heading fontSize={'18px'} fontWeight={'500'}>
              Availability:
            </Heading>

            <Stack flexDirection={'column'} lineHeight={'0.8rem'} mb={3}>
              {item.availability &&
                item.availability.map((val, ind) => (
                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      {val.day}
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {val.time}
                    </Text>
                  </Stack>
                ))}
            </Stack>
            <HStack mb={2}>
              <Button
                w="50%"
                bgGradient="linear(to-r, #295377, #208C74)"
                color="#E9E9E9"
                fontWeight={'500'}
                fontSize={'15px'}
                borderRadius={'21px'}
              >
                Edit Service
              </Button>
              <Button
                w="50%"
                shadow={'0px 16px 38px 0px #0000000F'}
                border={'1px solid #75767A'}
                color="#75767A"
                fontWeight={'500'}
                fontSize={'15px'}
                borderRadius={'21px'}
                onClick={onOpen}
              >
                Remove Service
              </Button>
            </HStack>
          </Box>
        ))
      ) : (
        <Heading fontSize={'18px'} fontWeight={'500'}>
          No Services Available
        </Heading>
      )}
    </Grid>
  );
}

export default ServiceCard;
