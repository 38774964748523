import React, { useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
  Select,
  Textarea,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import {
  AttachmentIcon,
  CalendarIcon,
  EditIcon,
  ViewIcon,
} from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';

import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { POST } from '../../../utilities/ApiProvider';
// import shapeb from '../../../assets/images/icon/black.png'
Chart.register(CategoryScale);
export default function HelpSupportDashboard() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleButtonClick = () => {
    document.getElementById('file-input').click();
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const form = new FormData(e.target);
      const response = await POST('/hospital/help', form);
      if (response.status === 200) {
        setLoading(false);
        toast({
          description: 'Help & Support Submitted SuccessFully!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        e.target.reset();
      } else {
        setLoading(false);
        toast({
          description: response.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
      }
    } catch (error) {
      console.log('Error while submitting Help & Support form: ', error);
      toast({
        description: 'Failed to submit form. Please try again later.',
        status: 'error',
        isClosable: true,
        position: 'top-right',
        duration: 3000,
      });
    } finally {
      setLoading(false);
      e.target.reset();
      setSelectedFile(null);
    }
  };

  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Help & Support
        </Heading>
        {/* <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          If an query you can send it to the platform admin...!
        </Text> */}
      </Stack>
      <form onSubmit={handleSubmit} className="applyForm" id="registerForm">
        <Stack
          direction={'row'}
          spacing={0}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <Box w={{ base: '100%', xl: '48%' }}>
            <FormLabel>Issue Type</FormLabel>
            <Select name="issueType">
              <option>Issue Type 1</option>
              <option>Issue Type 2</option>
              <option>Issue Type 3</option>
            </Select>
          </Box>
          <Box w={{ base: '100%', xl: '48%' }}>
            <FormLabel>Email Address</FormLabel>
            <Input required name="email" placeholder="Enter here" />
          </Box>
          <Box w={{ base: '100%', xl: '100%' }}>
            <FormLabel>Describe your issue in detail</FormLabel>
            <Textarea
              placeholder="Enter here"
              height={'600p'}
              name="description"
              required
            ></Textarea>
          </Box>
          <Box w="100%">
            <FormLabel>You can attach, JPG, PNG, PDF.</FormLabel>

            <Text fontSize="14px" color="primaryGray.100">
              <Input
                name="attachment"
                type="file"
                id="file-input"
                display="none"
                onChange={handleFileChange}
              />
              <Button
                cursor={'pointer'}
                as="span"
                border="1px solid #75767A"
                bgColor="transparent"
                fontSize="14px"
                color="primaryGray.100"
                transition=".3s"
                _hover={{
                  bgColor: 'primaryGreen.200',
                  color: '#fff',
                  borderColor: 'primaryGreen.200',
                }}
                mr="10px"
                onClick={handleButtonClick}
              >
                <AttachmentIcon mr="10px" /> Attach File
              </Button>
              {selectedFile ? selectedFile.name : 'No File chosen'}
            </Text>
          </Box>
        </Stack>
        <Button
          bgGradient={'linear(to-r, #295377, #208C74)'}
          fontSize={'14px'}
          fontWeight={500}
          isLoading={loading}
          color={'#fff'}
          borderRadius={'25px'}
          px="40px"
          h="45px"
          type="submit"
          textTransform={'uppercase'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
        >
          Send Inquiry
        </Button>
      </form>
    </Stack>
  );
}
