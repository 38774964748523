import Header from '../../components/Website/Header';
import Footer from '../../components/Website/Footer';
import About from '../../components/Website/about';
import Testimonials from '../../components/Website/testimonials';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import AboutVision from '../../components/Website/about/AboutVision.js';
import ServiceInnerData from '../../components/Website/services/ServiceInnerData.js';
import SingleBlogData from '../../components/Website/blog/SingleBlogData.js';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';

export default function SingleBlog() {
  const { id } = useParams();

  return (
    <>
      <Header />
      <MiniBanner
        subHeading="Our News"
        mainHeading="Single News"
        // content="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      />
      <SingleBlogData id={id} />
      <Footer />
    </>
  );
}
