import {
  Box,
  Button,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  Link,
  useToast,
} from '@chakra-ui/react';
import Logo from '../../assets/images/website/logo.jpg';
import authBg from '../../assets/images/website/authBg.png';
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { POST } from '../../utilities/ApiProvider';

export default function NewPassword() {
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const form = new FormData(e.target);
      let data = {
        password: form.get('password'),
        confirmPassword: form.get('confirmPassword'),
        value: localStorage.getItem('emedieeForgetEmail'),
      };
      let passwordsMatch = data.password === data.confirmPassword;
      if (!passwordsMatch) {
        toast({
          description: 'Passwords do not match',
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        return;
      }
      const response = await POST('/authentication/change-password', data);
      if (response.status === 200) {
        toast({
          description:
            response.status === 200
              ? 'Password Change successfully!'
              : response?.message,
          status: response.status === 200 ? 'success' : 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        localStorage.removeItem('emedieeForgetEmail');
        localStorage.removeItem('emedieeForgetOtp');
        navigate('/login');
      }
    } catch (err) {
      toast({
        description: err?.message,
        status: 'error',
        isClosable: true,
        position: 'top-right',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
      e.target.reset();
    }
  };

  return (
    <Stack position={'relative'} height={'100vh'} p="0">
      <Box
        maxW={{ base: '100%', lg: '490px', xl: '600px' }}
        p={{
          base: '120px 25px',
          lg: '60px 0 60px 40px',
          xl: '60px 0 60px 120px',
        }}
      >
        <Link as={ReactLink} to="/">
          <Button
            bgColor={'transparent'}
            _hover={{ bgColor: 'transparent' }}
            _active={{ bgColor: 'transparent' }}
            mb="60px"
            fontWeight={'500'}
          >
            <Icon as={FaArrowLeft} mr="10px" /> Back
          </Button>
        </Link>
        <Image mb="40px" src={Logo} />
        <Heading
          fontWeight={'500'}
          color={'primaryBlack.100'}
          fontSize={'32px'}
          pb="20px"
        >
          Set New Password
        </Heading>
        <Text
          fontWeight={'500'}
          color={'primaryGray.100'}
          fontSize={'14px'}
          pb="20px"
        >
          Enter your new password to login
        </Text>
        <form className="applyForm" onSubmit={handleSubmit}>
          <Box mb={'10px'}>
            <FormLabel>Password*</FormLabel>
            <Box position={'relative'}>
              <Input
                required
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter here"
              />
              <Button
                w={'40px !important'}
                fontSize={'18px !important'}
                border={'none !important'}
                zIndex={1}
                bgColor={'transparent'}
                _hover={{ bgColor: 'transparent' }}
                _active={{ bgColor: 'transparent' }}
                onClick={() => setShowPassword(!showPassword)}
                position={'absolute'}
                right={'0'}
              >
                {!showPassword ? (
                  <Icon as={FaRegEyeSlash} />
                ) : (
                  <Icon
                    fontSize={'18px'}
                    color={'primaryGray.100'}
                    as={FaRegEye}
                  />
                )}
              </Button>
            </Box>
          </Box>
          <Box>
            <FormLabel>Confirm Password*</FormLabel>
            <Box position={'relative'}>
              <Input
                required
                name="confirmPassword"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter here"
              />
              <Button
                w={'40px !important'}
                fontSize={'18px !important'}
                border={'none !important'}
                zIndex={1}
                bgColor={'transparent'}
                _hover={{ bgColor: 'transparent' }}
                _active={{ bgColor: 'transparent' }}
                onClick={() => setShowPassword(!showPassword)}
                position={'absolute'}
                right={'0'}
              >
                {!showPassword ? (
                  <Icon as={FaRegEyeSlash} />
                ) : (
                  <Icon
                    fontSize={'18px'}
                    color={'primaryGray.100'}
                    as={FaRegEye}
                  />
                )}
              </Button>
            </Box>
          </Box>

          <Button
            isLoading={isLoading}
            my="20px"
            bgGradient={'linear(to-r, #295377, #208C74)'}
            fontSize={'14px'}
            fontWeight={500}
            color={'#fff'}
            borderRadius={'25px'}
            w="100%"
            h="45px"
            textTransform={'uppercase'}
            border={'2px solid transparent'}
            _hover={{
              bgGradient: 'none',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
            type="submit"
          >
            Submit
          </Button>
        </form>
      </Box>
      <Image
        display={{ base: 'none', xl: 'block' }}
        src={authBg}
        w={{ lg: '505px', xl: '605px' }}
        h="100%"
        position={'absolute'}
        top="0"
        right="0"
        mt="0 !important"
      />
    </Stack>
  );
}
