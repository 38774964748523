import { Box, Button, Icon, Textarea } from '@chakra-ui/react';
import { useState } from 'react';
import Chatbot from '../components/Website/chatbot/Chatbot';
import { MdOutlineMessage } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { Outlet } from 'react-router-dom';

function WebLayout() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChatBox = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Outlet />
      <Button
        position="fixed"
        bottom="4"
        right="4"
        color={'#fff'}
        bgGradient={'linear(to-r, #295377, #208C74)'}
        border={'2px solid primaryGreen.200'}
        _hover={{
          bgGradient: 'none',
          border: '2px solid',
          borderColor: 'primaryGreen.200',
          color: 'primaryGreen.200',
        }}
        onClick={toggleChatBox}
        // width={'60px'}
        // h={'60px'}
        borderRadius={'60px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {isOpen ? 'Close Chat' : 'Contact Us..'}

        {/* {isOpen ? (
          <Icon
            as={RxCross2}
            color="#fff"
            _hover={{ color: 'primaryGreen.200' }}
            w="30px"
            h="30px"
          />
        ) : (
          <Icon
            _hover={{ color: 'primaryGreen.200' }}
            as={MdOutlineMessage}
            color="#fff"
            w="30px"
            h="30px"
          /> */}
        {/* )} */}
      </Button>
      {isOpen && (
        <Box
          position="fixed"
          bottom="80px"
          right="4"
          width={{ base: '320px', lg: '400px' }}
          height="500px"
          border={'1px solid #208C74'}
          bg="white"
          boxShadow="lg"
          borderRadius="14px"
          p="1"
          zIndex="1000"
        >
          {/* <Textarea placeholder="Type your message here..." />
          <Button mt="2" colorScheme="teal" width="100%">
            Send
          </Button> */}
          <Chatbot name="WebLayout" />
        </Box>
      )}
    </>
  );
}

export default WebLayout;
