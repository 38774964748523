import React, { useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Divider,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  Image,
  VStack,
  Icon,
  Spinner,
} from '@chakra-ui/react';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import Doctor from '../../../assets/images/dashboard/doctor.png';
import { FaStar } from 'react-icons/fa';

import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AppointmentBox from '../Appointments/AppointmentBox';
import { FaEnvelope, FaMapMarker, FaPhoneAlt, FaRegStar } from 'react-icons/fa';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { useEffect } from 'react';
import { imgUrl } from '../../../utilities/config';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
function DoctorProfile() {
  const user = useSelector(state => state.user.value);
  const { id } = useParams();
  const [reviews, setReviews] = useState([]);
  const [doctor, setDoctor] = useState(null);
  const [searchParams] = useSearchParams();
  const [type, setType] = useState(user?.type);
  const typeUrl = searchParams.get('type');

  useEffect(() => {
    setType(user?.type);
  }, [user]);

  async function DoctorDetails() {
    try {
      const response = await GET(`/hospital/practitioners/${id}`);
      console.log(response);
      if (response.status === 200) setDoctor(response.data);
    } catch (error) {
      console.error('Error fetching doctor detail data:', error);
    }
  }

  async function fetchReviews() {
    try {
      const response = await GET(`/web/patient/doctor/${id}/reviews`);
      console.log(response);
      if (response.status === 200) setReviews(response.data);
    } catch (error) {
      console.error('Error fetching doctor review data:', error);
    }
  }
  useEffect(() => {
    DoctorDetails();
    fetchReviews();
    // if (type === 'patient') fetchReviews();
  }, []);
  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'center'}
          fontSize={'36px'}
          fontWeight={'500'}
        >
          Doctor Details
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the details of doctor
        </Text>
      </Stack>
      {/*Heading  */}

      <Grid gridTemplateColumns={'2fr 1fr'} gap={'80px'}>
        {!doctor ? (
          <Spinner />
        ) : (
          <Box display={'flex'} flexDirection={'column'} gap={5}>
            <Stack>
              <Box w="151px" h="151px">
                {/* <Image
                      borderRadius={'50%'}
                      src={`${imgUrl}/${doctor?.photo}` || Doctor}
                    /> */}
                <Image
                  borderRadius="full"
                  boxSize="130px"
                  src={`${imgUrl}/${doctor?.photo}` || Doctor}
                  alt="Dan Abramov"
                />
              </Box>
              <Heading
                display={'flex'}
                alignItems={'center'}
                fontSize={'26px'}
                fontWeight={'500'}
              >
                Dr {doctor?.name || ' Dr. Alexa John'} /{' '}
                <Text
                  mt={'8px'}
                  ml={'2px'}
                  color={'#208C74'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                >
                  {doctor?.generalSpeciality + '  ' + 'Specialist' ||
                    'Cadiology Specialist'}
                </Text>
              </Heading>
              <Text fontSize={'20px'} color={'#75767A'} fontWeight={'400'}>
                #d44vdsQ
              </Text>
            </Stack>

            <Stack
              direction={'row'}
              alignItems={'center'}
              gap="30px"
              flexWrap={'wrap'}
            >
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Hospital Name
                </Heading>
                <Text
                  fontSize={'16px'}
                  fontWeight={'400'}
                  color={'primaryGray.100'}
                >
                  {doctor?.hospitalName}
                </Text>
              </Stack>
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Subspecialty
                </Heading>
                <Text
                  fontSize={'14px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.subspeciality}
                </Text>
              </Stack>
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Academic Qualification
                </Heading>
                <Text
                  fontSize={'14px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.academicQualification}
                </Text>
              </Stack>
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Treatment Services
                </Heading>
                <Text
                  fontSize={'14px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.medicalTreatmentService}
                </Text>
              </Stack>
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Examination Charges
                </Heading>
                <Text
                  fontSize={'14px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.charges}
                </Text>
              </Stack>
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Heading
                fontSize={'20px'}
                fontWeight={'500'}
                color={'primaryBlack.100'}
                my="10px"
              >
                About :
              </Heading>
              <Heading
                fontSize={'20px'}
                fontWeight={'500'}
                color={'primaryBlack.100'}
                my="10px"
              >
                {doctor?.yearsOfExperience}+ Years of Experience
              </Heading>
            </Stack>
            <Text
              fontSize={'14px'}
              fontWeight={'400'}
              color={'primaryGray.100'}
            >
              {doctor?.about ||
                `  Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.`}
            </Text>
            {type === 'patient' ? (
              <Stack direction={'row'} alignItems={'center'} gap="30px">
                {doctor?.opening_hours?.map((val, ind) => (
                  <Text key={ind}>
                    <Text>{val.day}</Text>
                    <Text>
                      {val.startTime} - {val.endTime}
                    </Text>
                  </Text>
                ))}
              </Stack>
            ) : (
              <Stack>
                <Stack direction={'row'} alignItems={'center'} gap="30px">
                  <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
                    <Icon mr="5px" as={FaMapMarker} />{' '}
                    {doctor?.employmentAddress || 'King Fahad Rd, Al Bustan'}
                  </Text>
                  <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
                    <Icon mr="5px" as={FaPhoneAlt} />{' '}
                    {doctor?.number || '+966 765 272 46'}
                  </Text>
                </Stack>
                <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
                  <Icon mr="5px" as={FaEnvelope} />{' '}
                  {doctor?.email || 'adam.knight@gmail.com'}
                </Text>
              </Stack>
            )}

            <Heading
              fontSize={'20px'}
              fontWeight={'500'}
              color={'primaryBlack.100'}
              my="10px"
            >
              Contact :
            </Heading>
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap="30px"
              flexWrap={'wrap'}
            >
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Address
                </Heading>
                <Text
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.address || 'King Fahd Rd, Al Bustan'}
                </Text>
              </Stack>
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Email
                </Heading>
                <Text
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.email || 'King Fahd Rd, Al Bustan'}
                </Text>
              </Stack>
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Phone No
                </Heading>
                <Text
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.number || '0310876351'}
                </Text>
              </Stack>
            </Stack>
            <iframe
              style={{ width: '100%', height: '200px' }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.144404792699!2d46.614954999999995!3d24.824734799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1b5ab2e599c1%3A0x58ab1ee4e94e9c89!2sKing%20Fahd%20Rd%2C%20Riyadh%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1714130640115!5m2!1sen!2s"
            />
            <Stack mt={'10px !important'}>
              <Button
                w={'217px'}
                bgGradient={'linear(to-r, #295377, #208C74)'}
                fontSize={{ base: '12px', xl: '14px' }}
                fontWeight={500}
                color={'#fff'}
                borderRadius={'25px'}
                h={{ base: '35px', xl: '45px' }}
                textTransform={'uppercase'}
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
                as={Link}
                to={`/dashboard/recruitment/send-offer/${id}`}
              >
                Send Offer
              </Button>
            </Stack>
          </Box>
        )}
      </Grid>
      <Divider mt={'25px !important'} color={'#75767A'} />
    </Stack>
  );
}

export default DoctorProfile;
