import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { PhoneInput } from 'react-international-phone';
import { FaRegTrashAlt } from 'react-icons/fa';
import { PUT, GET } from '../../../utilities/ApiProvider';
import ModalWrapper from '../../../components/Dashboard/Modal';

function AddSocialLink({ socialLinks, setSocialLinks, onClose }) {
  const handleSubmit = e => {
    e.preventDefault();
    const form = new FormData(e.target);
    let data = {};
    form.forEach((value, key) => {
      data[key] = value;
    });
    setSocialLinks([...socialLinks, data]);
    onClose();
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box mb={4}>
        <FormLabel>Name*</FormLabel>
        <Box position={'relative'}>
          <Input
            name="platform"
            // name={val?.name?.toLowerCase()}
            type="text"
            placeholder="Enter name"
          />
        </Box>
      </Box>
      <Box mb={4}>
        <FormLabel>Value*</FormLabel>
        <Box position={'relative'}>
          <Input
            name="url"
            // name={val?.name?.toLowerCase()}
            type="text"
            placeholder="Enter name"
          />
        </Box>
      </Box>
      <Button
        bgGradient={'linear(to-r, #295377, #208C74)'}
        fontSize={'16px'}
        fontWeight={500}
        color={'#fff'}
        w="100%"
        // isLoading={isLoading}
        borderRadius={'25px'}
        h="45px"
        px="30px"
        border={'2px solid transparent'}
        _hover={{
          bgGradient: 'none',
          borderColor: 'primaryGreen.200',
          color: 'primaryGreen.200',
        }}
        type="submit"
      >
        Add link
      </Button>
    </form>
  );
}

export default function EditProfile() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [openingHours, setOpeningHours] = useState([
    {
      on: true,
      day: 'Monday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Tuesday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Wednesday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Thursday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Friday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Saturday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Sunday',
      startTime: '09:00',
      endTime: '22:00',
    },
  ]);
  const toast = useToast();
  const [isLoading, setLoading] = useState(false);
  const [socialLinks, setSocialLinks] = useState([]);
  const [data, setData] = useState({});

  async function fetchHospitalDetails() {
    try {
      const response = await GET('/hospital/profile');
      console.log(response);
      if (response.status === 200) {
        setData(response.data);
        const checkOpeningHours = response.data.opening_hours;
        const daysOn = new Set(checkOpeningHours.map(entry => entry.day));
        const updatedFirstArray = openingHours.map(entry => ({
          ...entry,
          on: daysOn.has(entry.day),
        }));
        console.log(updatedFirstArray);
        setOpeningHours(updatedFirstArray);
        setSocialLinks(response.data.socialLinks);
      } else setData({});
    } catch (error) {
      console.error(
        'An error occurred while fetching hosptal profile data:',
        error
      );
    }
  }
  useEffect(() => {
    fetchHospitalDetails();
  }, []);
  function changeOnStatus(itemIndex, value) {
    let modifiedArray = openingHours.map((val, ind) => {
      if (ind === itemIndex) {
        return {
          ...val,
          on: value,
        };
      } else return val;
    });
    setOpeningHours(modifiedArray);
  }

  function changeStartingTime(itemIndex, value) {
    let modifiedArray = openingHours.map((val, ind) => {
      if (ind === itemIndex) {
        return {
          ...val,
          startTime: value,
        };
      } else return val;
    });
    setOpeningHours(modifiedArray);
  }

  function changeEndingTime(itemIndex, value) {
    let modifiedArray = openingHours.map((val, ind) => {
      if (ind === itemIndex) {
        return {
          ...val,
          endTime: value,
        };
      } else {
        return val;
      }
    });
    setOpeningHours(modifiedArray);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      form.append(
        'opening_hours',
        JSON.stringify(openingHours.filter(item => item.on))
      );
      form.append('socialLinks', JSON.stringify(socialLinks));
      const formDataEntries = Array.from(form.entries());
      console.log(formDataEntries.map(([key, value]) => ({ [key]: value })));

      const response = await PUT('/hospital/profile', form);
      console.log(response);
      if (response.status === 200) {
        toast({
          description: `Edit successfully`,
          status: response.status === 200 ? 'success' : 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
      }
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async id => {
    let modifiedArray = socialLinks.filter(item => item._id !== id);
    setSocialLinks(modifiedArray);
  };

  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Edit Hospital Info
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is your hospital info, once you change click to save
        </Text>
      </Stack>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={'Add Social Link'}
        subTitle={'Enter the details to add new service'}
        children={
          <AddSocialLink
            socialLinks={socialLinks}
            setSocialLinks={setSocialLinks}
            onClose={onClose}
          />
        }
      />
      <form onSubmit={handleSubmit} className="applyForm" id="registerForm">
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Box w="50%">
            <Heading
              fontSize={'20px'}
              fontWeight={'500'}
              color={'primaryBlack.100'}
              mb="20px"
            >
              General Info
            </Heading>
            <Box mb="10px">
              <FormLabel>Hospital Name*</FormLabel>
              <Input
                defaultValue={data?.name}
                required
                name="name"
                placeholder="Enter here"
              />
            </Box>
            <Box mb="10px">
              <FormLabel>Email Address*</FormLabel>
              <Input
                defaultValue={data?.email}
                required
                name="email"
                placeholder="Enter here"
              />
            </Box>
            <Box mb="10px">
              <FormLabel>Phone No.*</FormLabel>
              <PhoneInput
                name="number"
                value={data?.number}
                required
                containerStyle={{ background: 'none', border: 'none' }}
                inputStyle={{ background: 'none', border: 'none' }}
                dropdownStyle={{ background: 'none', border: 'none' }}
                id="phone-input"
                defaultCountry="sa"
                inputClass="custom-phone-input"
              />
            </Box>
            <Box mb="10px">
              <FormLabel>Website*</FormLabel>
              <Input
                defaultValue={data?.website}
                required
                name="website"
                placeholder="Enter here"
              />
            </Box>
            <Box mb="10px">
              <FormLabel>Location*</FormLabel>
              <Input
                defaultValue={data?.address}
                required
                name="address"
                placeholder="Enter here"
              />
            </Box>
            <Box mb="10px">
              <FormLabel>About Hospital</FormLabel>
              <Textarea
                required
                name="about"
                defaultValue={data?.about}
                resize={'none'}
                placeholder="Enter here"
              ></Textarea>
            </Box>
            <Box mt="20px">
              <Button
                bgGradient={'linear(to-r, #295377, #208C74)'}
                fontSize={'16px'}
                fontWeight={500}
                color={'#fff'}
                isLoading={isLoading}
                borderRadius={'25px'}
                h="45px"
                px="30px"
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
                type="submit"
              >
                Save & Continue
              </Button>
            </Box>
          </Box>
          <Box w={'36%'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Heading
                fontSize={'20px'}
                fontWeight={'500'}
                color={'primaryBlack.100'}
                mb="20px"
              >
                Add Social Link
              </Heading>
              <Button
                bgGradient={'linear(to-r, #295377, #208C74)'}
                fontSize={'14px'}
                fontWeight={500}
                color={'#fff'}
                borderRadius={'25px'}
                h="35px"
                px="20px"
                onClick={onOpen}
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
              >
                Add
              </Button>
            </Box>

            {socialLinks.map((val, ind) => (
              <Box mb={2}>
                <FormLabel>{val?.platform}*</FormLabel>
                <Box position={'relative'}>
                  <Input
                    type="text"
                    defaultValue={val?.url}
                    placeholder="Enter link"
                  />
                  <Button
                    w={'40px !important'}
                    fontSize={'18px !important'}
                    border={'none !important'}
                    zIndex={1}
                    bgColor={'transparent'}
                    _hover={{ bgColor: 'transparent' }}
                    _active={{ bgColor: 'transparent' }}
                    position={'absolute'}
                    right={'0'}
                    onClick={() => handleDelete(val?._id)}
                  >
                    <Icon
                      mt="10px"
                      fontSize={'18px'}
                      color={'primaryGray.100'}
                      as={FaRegTrashAlt}
                    />
                  </Button>
                </Box>
              </Box>
            ))}

            {/* <Box>
              <FormLabel>Instagram*</FormLabel>
              <Box position={'relative'}>
                <Input name="instagram" type="text" placeholder="Enter link" />
                <Button
                  w={'40px !important'}
                  fontSize={'18px !important'}
                  border={'none !important'}
                  zIndex={1}
                  bgColor={'transparent'}
                  _hover={{ bgColor: 'transparent' }}
                  _active={{ bgColor: 'transparent' }}
                  position={'absolute'}
                  right={'0'}
                >
                  <Icon
                    mt="10px"
                    fontSize={'18px'}
                    color={'primaryGray.100'}
                    as={FaRegTrashAlt}
                  />
                </Button>
              </Box>
            </Box> */}
            <Heading
              fontSize={'20px'}
              fontWeight={'500'}
              color={'primaryBlack.100'}
              my="20px"
            >
              Hospital Availability
            </Heading>
            {openingHours.map((val, ind) => (
              <Stack
                mb="5px !important"
                border={'2px solid'}
                borderRadius={'12px'}
                borderColor={!val.on ? '#75767A' : 'primaryGreen.200'}
                p="5px 5px 5px 15px"
                key={val.day}
                direction={'row'}
                alignItems={'center'}
              >
                <Text flex={'1'}>
                  <Checkbox
                    colorScheme="teal"
                    defaultChecked={val.on}
                    checked={val.on}
                    onChange={e => changeOnStatus(ind, e.target.checked)}
                    mr="5px"
                    mt="3px"
                    transform="translateY(2px)"
                  ></Checkbox>
                  {val.day}:
                </Text>
                <Text
                  bgColor={!val.on ? '#75767A' : 'primaryGreen.200'}
                  borderRadius={'12px'}
                  color={'#fff'}
                >
                  <Input
                    isDisabled={!val.on}
                    height={'42px !important'}
                    borderColor={'primaryGreen.200 !important'}
                    onChange={e => changeStartingTime(ind, e.target.value)}
                    type="time"
                    defaultValue={val.startTime}
                    value={val.startTime}
                  />
                </Text>
                <Text
                  bgColor={!val.on ? '#75767A' : 'primaryGreen.200'}
                  borderRadius={'12px'}
                  color={'#fff'}
                >
                  <Input
                    isDisabled={!val.on}
                    height={'42px !important'}
                    borderColor={'primaryGreen.200 !important'}
                    onChange={e => changeEndingTime(ind, e.target.value)}
                    type="time"
                    defaultValue={val.endTime}
                    value={val.endTime}
                  />
                </Text>
              </Stack>
            ))}
          </Box>
        </Stack>
      </form>
    </Stack>
  );
}
