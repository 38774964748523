import Header from '../../components/Website/Header';
import Footer from '../../components/Website/Footer';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import BlogList from '../../components/Website/blog/BlogList';
import { useEffect } from 'react';

export default function BlogsMain() {
  return (
    <>
      <Header />
      <MiniBanner mainHeading="Blogs" />
      <BlogList />
      <Footer />
    </>
  );
}
