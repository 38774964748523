import React, { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import AppRoute from './routes/Route';
import { loadUser } from './reducers/useReducers';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { setWebsiteData } from './reducers/slices/website.slice';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { GET } from './utilities/ApiProvider';

function App() {
  const theme = extendTheme({
    colors: {
      primaryGreen: {
        100: '#295377',
        200: '#208C74',
      },
      primaryBlack: {
        100: '#1B1C1D',
      },
      primaryGray: {
        100: '#788094',
      },
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      if (localStorage.getItem('emediiUser') !== null) {
        let user = JSON.parse(localStorage.getItem('emediiUser') ?? '{}');
        console.log(user);
        dispatch(loadUser(user));
      } else if (Cookies.get('emediiUser') !== undefined) {
        let user = JSON.parse(Cookies.get('emediiUser') ?? '{}');
        dispatch(loadUser(user));
      }
      try {
        const response = await GET('/web');
        console.log(response);
        if (response?.status === 200) {
          dispatch(setWebsiteData(response?.data));
        }
      } catch (error) {
        console.error('An error occurred while fetching website data:', error);
      }
    })();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      {/* <AppRoute /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<AppRoute />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
