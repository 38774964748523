import React, { useState, useEffect } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Select,
  Spinner,
} from '@chakra-ui/react';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { RiHospitalLine } from 'react-icons/ri';
import { LiaHospitalAltSolid } from 'react-icons/lia';

import { GET } from '../../../utilities/ApiProvider';
function AllDepartment() {
  const [departments, setDepartments] = useState(null);
  async function fetchDepartments() {
    try {
      const response = await GET('/hospital/department');
      console.log(response);
      if (response.status === 200) setDepartments(response.data);
    } catch (error) {
      console.log('Error while fetching departments', error);
    }
  }
  useEffect(() => {
    fetchDepartments();
  }, []);
  return (
    <Stack>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            All Departments
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            If an query you can send it to the platform admin...!
          </Text>
        </Stack>
        <Stack>
          <Button
            as={Link}
            to={'/dashboard/add-department'}
            w="100%"
            bgGradient="linear(to-r, #295377, #208C74)"
            color="#E9E9E9"
            fontWeight={'500'}
            fontSize={'15px'}
            borderRadius={'21px'}
            border={'2px solid transparent'}
            _hover={{
              bgGradient: 'none',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
          >
            Add New Department
          </Button>
        </Stack>
      </HStack>

      <Grid templateColumns="repeat(2, 1fr)" gap={8}>
        {!departments ? (
          <Spinner />
        ) : departments.length > 0 ? (
          departments?.map((item, ind) => (
            <Box
              // border={'2px solid #75767A'}
              shadow={'0px 16.32px 38.75px 0px #0000000F'}
              borderRadius={'17px'}
              p={{ base: '15px 20px', xl: '25px 40px' }}
              display={'flex'}
              // flexDir={'column'}
              key={ind}
              // alignItems={'center'}
              // justifyContent={'center'}
              role="group"
              transition={'0.3s'}
              _hover={{
                borderColor: 'primaryGreen.200',
              }}
            >
              <Stack flex={2}>
                <Box
                  w={'54px'}
                  h={'54px'}
                  bgColor={'#E2E2E2'}
                  display={'flex'}
                  alignItems={'center'}
                  transition={'0.3s'}
                  borderRadius={'12px'}
                  mb="5px"
                  justifyContent={'center'}
                  _groupHover={{
                    bgColor: 'primaryGreen.200',
                    color: '#fff',
                  }}
                >
                  <Icon
                    _groupHover={{
                      color: '#fff',
                    }}
                    transition={'0.3s'}
                    fontSize={'30px'}
                    // fontSize={{ base: '26px', xl: '52px' }}
                    //   as={`${imgUrl}/${val.icon}`}
                    as={LiaHospitalAltSolid}
                  />
                </Box>
                <Heading w={'60%'} fontSize={'18px'} fontWeight={'500'}>
                  {item.name}
                </Heading>
              </Stack>
              <Stack flex={1} alignItems={'end'} justifyContent={'center'}>
                <Button
                  as={Link}
                  to={`/dashboard/services/${item.name}/view-beds/${item._id}`}
                  bgGradient={'linear(to-r, #295377, #208C74)'}
                  fontSize={{ base: '12px', xl: '14px' }}
                  fontWeight={400}
                  color={'#fff'}
                  borderRadius={'25px'}
                  w="122px"
                  h="34px"
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                >
                  View Service
                </Button>
              </Stack>
            </Box>
          ))
        ) : (
          <Heading>No Department </Heading>
        )}
      </Grid>
    </Stack>
  );
}

export default AllDepartment;
