import Header from '../../components/Website/Header';
import Footer from '../../components/Website/Footer';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import BlogList from '../../components/Website/blog/BlogList';
import ApplyForm from '../../components/Website/expert/ApplyForm';
import {
  Box,
  Container,
  Heading,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import Policy from './Policy';

export default function Terms() {
  return (
    <>
      <Header />
      <MiniBanner mainHeading="Terms & Conditions / Privacy Policy" />
      <Container maxW={'6xl'}>
        <Box p={8} mx="auto">
          <Heading as="h2" size="xl" mb={6}>
            1. Introduction:
          </Heading>

          <Text mb={4} color="primaryGray.100">
            Thank you for choosing eMedst.com as an intermediary to book the
            treatment services you need that are provided by hospitals through
            us, as well as to introduce you to the health practitioners who list
            their professional information and experience for you to review and
            choose the best that suits your needs. These terms and conditions
            may be amended from time to time; they apply directly or indirectly
            (through distributors) to all our services available through our
            website, our platform, and any of our means. By completing the
            booking of any of our services, you decide and agree that you have
            read, understood, and agreed to the terms and conditions set forth
            below including the privacy policy, and that you are legally
            eligible to comply with the following:
          </Text>

          <Text mb={4} color="primaryGray.100">
            The above preamble forms an integral part of the terms and
            conditions and privacy statement.
          </Text>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            2. Definitions
          </Heading>
          <OrderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              <b color="primaryBlack.100 !important">eMedst.com: </b> A platform
              and brokerage website for marketing a group of services in the
              health sector, aiming to provide the service to three categories
              (patients - hospitals - health practitioners). It belongs to the
              company (...) and its address is (……)
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b> Patient, Visitor or User of the platform and website: </b>{' '}
              Everyone who has been able to register with the platform and
              website and use its services provided by the hospitals through
              which they provide their services.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Password and Username: </b> These mean, respectively, a unique
              password and name issued by (eMedst.com) to clients who use the
              platform and the site.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Customer Data: </b> This means all information collected about
              the customer, including his personal data.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Data Protection: </b> This means all laws, regulations, and any
              other legislation issued and in force to regulate the privacy,
              processing, and obtaining of personal information.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Treatment Services: </b> : These mean the medical care provided
              by hospitals specializing in the treatment of certain diseases
              that require advanced equipment and are provided by highly
              specialized doctors.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b> Data and Information Panel (Dashboard): </b> This means the
              data and information related to each hospital and health
              practitioner that offers its services on the platform.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Timetables: </b> A pre-prepared model for clinic appointments
              generated by eMedst.com. The hospital builds it with the
              appointments available to its doctors and according to which
              patients can book their appointments.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Hospital: </b> Every hospital authorized by the competent
              authorities at its location to provide therapeutic services to
              patients.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Health Practitioners: </b> These include doctors, pharmacists,
              nurses, health specialists and paramedics who provide treatment
              services or obtain eMedst.com services.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Fees: </b>These are the charges that eMedst.com collects from
              customers in exchange for the services it provides to them, the
              terms of which are explained, how they are collected and
              distributed, and the date of payment of which are explained in the
              relevant section of these terms and conditions
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Direct Cash Payment: </b> The patient pays the costs of his
              treatment from his account, by credit card, bank check or bank
              transfer.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Payment through the insurance company: </b> The patient pays
              the costs of his treatment from his account, by credit card, bank
              check or bank transfer.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Electronic Payment Gateway: </b> It is a service that allows
              and facilitates the electronic payment process in an immediate
              manner for the value of the services that the customer obtains
              through direct communication.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Working Days: </b> Mean the days (other than weekends and
              official holidays) on which the hospital usually operates at its
              location.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Force Majeure: </b> : This means any situation beyond the
              reasonable control of the relevant party, including the acts of
              God, action or inaction by a governmental entity or a competent
              body, the inability to obtain materials, embargo or refusal to
              grant licenses, theft, riots, wars, invasions, acts of foreign
              aggression, terrorist acts, hostilities in declared or undeclared
              wars, rebellions, revolutions, military mutinies, usurpation of
              power, seizures, or confiscation, control or other appropriation
              by a government body, fires, floods,tornadoes, earthquakes,
              volcanoes, storms, tsunamis, mega hurricanes, epidemic diseases or
              other natural disasters or explosions, acts of sabotage, civil
              unrest, disturbances, industrial disputes, unavailability of
              communications networks and energy sources, poor performance,
              attacks to disrupt or interrupt services, unauthorized access to
              systems, records, programs, devices, data or services, or the
              cessation of factories or machines
            </ListItem>
          </OrderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            3. Our Services
          </Heading>
          <Text mb={4} color="primaryGray.100">
            The eMedst.com services target the following categories:
          </Text>
          <UnorderedList spacing={3} ml={5}>
            <ListItem>
              <Heading as="h2" size="md" mb={2}>
                1<sup>st</sup> Category: Patients
              </Heading>
              <OrderedList>
                <ListItem color={'primaryGray.100'}>
                  Booking appointments highly specialized clinics in specialized
                  or referral hospitals around the world.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Booking appointments in virtual clinics to obtain a medical
                  opinion from a consultant doctor in a medical sub-specialty
                  around the world based on a medical report submitted by the
                  patient in advance.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Booking an inpatient bed for hospitalized patients who are
                  looking for better treatment or whose treatment is not
                  available in the hospitals where they are hospitalized outside
                  the hospital, or who wish to be hospitalized directly.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Providing patients with new treatment offers and medical
                  services provided by hospitals to patients around the world.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Offering health practitioner services to patients who look for
                  the expertise of these health practitioners on eMedst.com.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Providing patients with modern visual medical content provided
                  by health practitioners, hospitals and subscribers to
                  eMedst.com around the world about medical developments and the
                  latest medical research results.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Providing travel services for patients who choose a treatment
                  destination outside their place of residence through travel
                  and tourism agents at competitive prices, including flight,
                  visa, transportation, housing, and translation services.
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <Heading as="h2" size="md" mb={2}>
                2<sup>nd</sup> Category: Health Practitioners
              </Heading>
              <OrderedList>
                <ListItem color={'primaryGray.100'}>
                  Providing job opportunities for health practitioners in
                  coordination with hospitals that need their services.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Displaying health practitioners’ data and experiences on the
                  platform so that patients searching for places to provide
                  their services ?? can learn about them.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Providing health practitioners and health college students
                  with digital medical library services (researches, articles
                  and e-books) at competitive prices.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Displaying visual medical content to any interested health
                  practitioners on eMedst.com and its social network sites for
                  free.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Providing discounts and offers to health practitioners offered
                  by international stores to those who hold a membership in
                  medii.com.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Providing medical education and training opportunities for
                  health practitioners in coordination with specialized training
                  centers.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              <Heading as="h2" size="md" mb={2}>
                3<sup>rd</sup> Category: Hospitals
              </Heading>
              <OrderedList>
                <ListItem color={'primaryGray.100'}>
                  Displaying the treatment services provided by specialized
                  hospitals around the world; these include: booking a
                  specialized clinic appointment, booking a virtual clinic
                  appointment to obtain a second medical opinion and booking an
                  inpatient bed for patients who wish to obtain it.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Providing electronic advertising services to medical
                  facilities around the world about their treatment services and
                  the offers they provide to patients through eMedst.com, its
                  social network sites, and its customers’ e-mails.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Providing hospitals with digital medical library services.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Providing hospitals with the medical education and training
                  programs their employees need in coordination with specialized
                  centers.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  Providing hospitals with regular lists of health practitioners
                  wishing to work with them.
                </ListItem>
              </OrderedList>
            </ListItem>
          </UnorderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            4. Prices of Our Services
          </Heading>
          <Text color={'primaryGray.100'}>
            eMedst.com charges for services according to the following:
          </Text>
          <OrderedList mb={4}>
            <UnorderedList>
              <ListItem color={'primaryGray.100'}>
                Booking an in-person clinic appointment and a virtual clinic
                appointment by hospitals, not patients
              </ListItem>
              <ListItem color={'primaryGray.100'}>
                <b>Reserving a bed for hospitalization: </b> A fixed fee
                collected from the customer upon confirmation of the bed
                reservation.
              </ListItem>
              <ListItem color={'primaryGray.100'}>
                <b>Travel Services:</b> These are provided for the customer at
                competitive prices from an approved travel agent paid directly
                by the customer.
              </ListItem>
            </UnorderedList>
          </OrderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            5. Payment, Cancellation, No-show and Refund
          </Heading>
          <UnorderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              <b>Payment: </b> eMedst.com applies a payment in advance policy
              for some services.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>
                {' '}
                Service for booking an in-person clinic appointment and booking
                a virtual clinic appointment:
              </b>{' '}
              Payment is made directly to the service provider according to the
              policy applied by him.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Bed reservation service for hospitalization:</b> A fixed fee
              for the service allocated to eMedst.com is paid when the bed
              reservation is confirmed, provided that the customer pays the
              expected cost of the hospitalization service specified by the
              hospital in the manner and time specified in the reservation form.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Cancellation: </b> The service can be cancelled and the amount
              paid can be refunded according to the period allowed for
              cancellation and specified in the service data.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Recovery: </b> The value of the service can be refunded under
              specific conditions.
              <OrderedList>
                <ListItem color={'primaryGray.100'}>
                  The value of the service booked in (booking a clinic
                  appointment or booking a virtual clinic appointment) can be
                  refunded by (eMedst.com) paid by the customer to the hospital
                  upon notifying the hospital that the customer will not attend
                  the appointment.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  The amount of the estimated cost of hospitalization is
                  recovered from the patient and the service provider according
                  to the policies and procedures followed by the service
                  provider.
                </ListItem>
                <ListItem color={'primaryGray.100'}>
                  The refund process takes place within (14) working days of the
                  customer’s request, provided that the value of the service has
                  actually been deposited through the electronic payment gateway
                  into the service provider’s account or the eMedst.com account.
                </ListItem>
              </OrderedList>
            </ListItem>
          </UnorderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            6. eMedst.com’s Obligations towards Customers
          </Heading>
          <OrderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              eMedst.com guarantees the ease and appropriateness of the
              procedures undertaken by the customer to join it.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              eMedst.com guarantees to maintain the confidentiality of the
              customer data it requests, with the exception of cases of force
              majeure
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              eMedst.com is committed to ensuring that the customer obtains the
              service that he booked and paid for in advance, according to the
              description and amount specified in its offer.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              eMedst.com is committed to displaying the services it markets for
              with detailed data that allows the customer to choose the
              appropriate service for him.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              eMedst.com is committed to adhering to the payment, cancellation
              and refund policy stated in Clause (5) of these terms with
              customers.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              eMedst.com is committed to ensuring that all services it offers
              have pre-limited prices that the customer must pay to the service
              provider.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              EMedst.com is committed that the service of reserving a bed for
              hospitalization will have an estimated cost and an admission
              period specified by the service provider, which may change by
              increase or decrease according to the patient’s condition at the
              time of admission.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The services that eMedst.com offers to customers are under the
              responsibility of the service providers in terms of obtaining the
              necessary licenses from their competent authorities, noting that
              when booking any service, we provide the customer with all data
              related to the service providers to enable him to verify the
              regularity of the service.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The detailed data that eMedst.com displays of service providers is
              under their responsibility and they are the ones who enter this
              data on eMedst.com.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              eMedst.com is committed to displaying each customer’s evaluation
              of any service he obtains in a transparent manner, while excluding
              any subjective evaluation of the service.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              eMedst.com’s responsibility is limited to the services offered by
              the service providers through it in order to obtain them at the
              specified time and at the price that is displayed opposite to each
              service.
            </ListItem>
          </OrderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            7. Customer’s Obligations towards eMedst.com
          </Heading>
          <OrderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              The customer shall maintain the username and password given to him
              by eMedst.com.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The customer shall write down all the information required of him
              in a complete and correct manner.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The customer shall ensure that the customer’s contact information
              (mobile number - email) through which the service is provided to
              him is correct.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The customer shall not use eMedst.com in a way that leads to
              violating a law or regulation applied in any field.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The customer shall not display data that contains defamation, a
              violation of the laws, pornographic material, a violation of
              public morals, or illegal materials or information through
              eMedst.com.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The customer shall not upload files on eMedst.com that contain
              viruses or corrupted data.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The customer shall not use any means, software or interception
              procedure, or attempt to intercept the correct configuration of
              eMedst.com.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The customer shall not take any action that exposes an immovable
              or appropriately large work to the eMedst.com infrastructure.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The information that the customer publishes through eMedst.com
              will not have the right to ownership, or the right to guarantee
              its confidentiality, and any use or interactive use of eMedst.com
              on it does not guarantee the user any rights, licenses, or
              privileges of any kind.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              By registering the customer on eMedst.com, it means that he
              accepts that it will provide him via his e-mail or mobile number
              through SMS messages with all advertisements and bulletins about
              the services it provides or the services that others provide
              through it, and he does not have the right to refuse the same.
              Therefore, eMedst.com has the right terminate his membership and
              block him.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The customer has no right to file any claim against eMedst.com for
              any effects or complications resulting from a service he obtained
              from a service provider that is offered on eMedst.com and was
              booked through it, but he has the right to claim against the
              service provider with the competent authorities at the service
              provider’s headquarters.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The customer’s right to claim and litigate against eMedst.com is
              limited to the effects and complications resulting from an
              automated violation and service reservation procedures only.
            </ListItem>
          </OrderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            8. Limits of Liability
          </Heading>
          <UnorderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              <Text mb={4} color={'primaryGray.100'}>
                The electronic services provided by eMedst.com and the
                information contained therein regarding the customer are
                provided for his/her identification only with hospitals and
                health practitioners. Thus, the customer acknowledges his full
                knowledge that communications via the Internet may be subject to
                interference or interception by third parties and that eMedst.om
                does not replace the information available through the customer.
                Accordingly, resorting to eMedst.com remains under the
                customer’s own responsibility, and eMedst.com will in no way be
                responsible for any loss or damage of any kind that the customer
                may incur due to using, visiting eMedst.com, or relying on it.
                Any data or opinion on eMedst.com, or resulting in any delay in
                operation, inability to communicate, problems accessing Internet
                networks, equipment or software malfunctions, or any means or
                ideas of any person accessing eMedst.com. The customer hereby
                acknowledges and agrees that his sole and exclusive remedy for
                any damage or loss that may occur to him due to his access or
                use of eMedst.com is to refrain from using it, accessing it, or
                discontinuing it.
              </Text>
            </ListItem>
          </UnorderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            9. Termination of Use
          </Heading>
          <UnorderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              <Text mb={4} color={'primaryGray.100'}>
                eMedst.com may, in its absolute discretion, terminate, restrict,
                or suspend the customer’s right to access and use its website
                and platform without notice and for any reason, including
                violation of the terms and conditions of this agreement or any
                behavior that it may consider, in its absolute discretion, to be
                illegal or harmful to others. Once accepted, the customer will
                be authorized to access eMedst.com.
              </Text>
            </ListItem>
          </UnorderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            10. Compensation
          </Heading>
          <UnorderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              <Text mb={4} color={'primaryGray.100'}>
                The customer admits that he will not take any action against
                eMedst.com, or any of its departments, and to secure it from
                liability, as well as any entities or employees responsible for
                managing, maintaining, or updating eMedst.com from any and all
                obligations and responsibilities that may arise in connection
                with any claim. It arises from a breach on his part of the terms
                and conditions of the agreement, the privacy policy, or any of
                the applicable laws, whether in the Kingdom of Saudi Arabia, or
                the customer’s place of residence.
              </Text>
            </ListItem>
          </UnorderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            11. Ownership Rights
          </Heading>
          <UnorderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              <Text mb={4} color={'primaryGray.100'}>
                The materials available to the customer on eMedst.com are
                protected by copyright, trademarks, and other forms of
                proprietary rights. Except as otherwise indicated, the customer
                may not sell, license, rent, modify, copy, reproduce, reprint,
                upload, print, download, transmit, distribute, publicly display,
                edit or create derivative works from any materials or contents
                from eMedst.com to the public or for commercial purposes without
                obtaining the prior written consent of eMedst.com in Kingdom of
                Saudi Arabia It is strictly prohibited to make any modification
                to any of the contents of eMedst.com, and the graphics and
                images therein are protected by copyright and may not be
                reproduced or exploited in any way without obtaining prior
                approval from eMedst.com in the Kingdom of Saudi Arabia
              </Text>
            </ListItem>
          </UnorderedList>
          <Heading as="h2" size="lg" mt={6} mb={4}>
            12. Judicial Jurisdiction and Dispute Settlement
          </Heading>
          <UnorderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              <Text mb={4} color={'primaryGray.100'}>
                These terms and conditions are subject to and interpreted in
                accordance with the laws and regulations in force at eMedst.com
                headquarters. Any dispute that arises between the two parties,
                if it is not possible to resolve it amicably between them, shall
                be decided by the competent courts at eMedst.com headquarters.
              </Text>
            </ListItem>
          </UnorderedList>
        </Box>
      </Container>
      <Heading textAlign={'center'} as="h2" size="xl">
        Privacy Policy
      </Heading>
      <Policy />
      <Footer />
    </>
  );
}
