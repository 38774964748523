import Header from '../../components/Website/Header';
import Footer from '../../components/Website/Footer';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import BlogList from '../../components/Website/blog/BlogList';
import ApplyForm from '../../components/Website/expert/ApplyForm';
import { useParams } from 'react-router-dom';

export default function Apply() {
  const { id } = useParams();

  return (
    <>
      <Header />
      <MiniBanner subHeading="Careers" mainHeading="Apply" />
      <ApplyForm id={id} />
      <Footer />
    </>
  );
}
