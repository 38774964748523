import React, { useEffect, useState } from 'react';
import { GET, PUT } from '../../../utilities/ApiProvider';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Spinner,
  Select,
  useToast,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ModalWrapper from '../../../components/Dashboard/Modal';
import { imgUrl } from '../../../utilities/config';
function RemoveService({ idToUnblock, onClose, status, fetchExternalDoctors }) {
  const toast = useToast();
  const unblockUser = async () => {
    try {
      const response = await PUT(`/hospital/user/${idToUnblock}/unblock`, {
        status: status,
      });
      console.log(response);
      if (response.status === 200) {
        fetchExternalDoctors();
        toast({
          description: `User ${status} successfully`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
      }
    } catch (error) {
      console.error('Error in Unblocking the user:', error);
    }
  };

  return (
    <Stack mb={3}>
      <Button
        bgGradient="linear(to-r, #295377, #208C74)"
        color="#E9E9E9"
        fontWeight={'500'}
        fontSize={'15px'}
        borderRadius={'21px'}
        onClick={unblockUser}
        border={'2px solid transparent'}
        _hover={{
          bgGradient: 'none',
          borderColor: 'primaryGreen.200',
          color: 'primaryGreen.200',
        }}
      >
        Yes
      </Button>
      <Button
        shadow={'0px 16px 38px 0px #0000000F'}
        border={'1px solid #75767A'}
        color="#75767A"
        fontWeight={'500'}
        fontSize={'15px'}
        borderRadius={'21px'}
        onClick={() => onClose()}
      >
        No
      </Button>
    </Stack>
  );
}
function ExternalDoctors() {
  const [externalDoctors, setExternalDoctors] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [idToUnblock, setUnblockId] = useState('');
  const [status, setStatus] = useState('');
  const fetchExternalDoctors = async () => {
    try {
      const response = await GET('/admin/doctors?doctor_type=internal');
      if (response?.status === 200) {
        console.log(response.data);
        setExternalDoctors(response?.data);
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching external doctors:',
        error
      );
    }
  };
  useEffect(() => {
    fetchExternalDoctors();
  }, []);
  return (
    <Stack>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={'Are you sure?'}
        subTitle={`You want to “${status}” this user?`}
        children={
          <RemoveService
            idToUnblock={idToUnblock}
            onClose={onClose}
            status={status}
            fetchExternalDoctors={fetchExternalDoctors}
          />
        }
        size={'md'}
        isCentered={true}
      />
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          External Health Practitioners
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all services...Check Now!
        </Text>
      </Stack>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {!externalDoctors && (
          <Stack>
            <Spinner />
          </Stack>
        )}
        {externalDoctors?.doctor?.map((item, ind) => (
          <Box
            shadow={'0px 16px 38px 0px #0000000F'}
            bg={'#fff'}
            borderRadius={'16px'}
            p={4}
            display={'flex'}
            flexDirection={'column'}
            gap={4}
          >
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              px={4}
            >
              <HStack alignItems={'center'} lineHeight={'0.3rem'} gap={2}>
                <Avatar
                  size={'lg'}
                  name="Dan Abrahmov"
                  src={`${imgUrl}/${item?.doctor?.photo}`}
                />
                <Stack>
                  <Heading fontSize={'22px'} fontWeight={'500'}>
                    {item?.fullName}
                    {/* {selectedService === 'patient' ? item.fullName : item.name} */}
                  </Heading>
                  <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
                    #{item?._id?.slice(0, 6)}
                  </Text>
                </Stack>
              </HStack>
              <Text fontSize={'16px'} color={'#75767A'} fontWeight={'400'}>
                {/* Member since: {moment(item.createdAt).format('DD MMM, YYYY')} */}
              </Text>
            </Stack>
            <HStack my={'10px'}>
              <Button
                as={Link}
                to={`/dashboard/external-doctor/${item?._id}`}
                // to={`/dashboard/doctor-profile/${item?.doctor?._id}`}
                w="100%"
                bgGradient="linear(to-r, #295377, #208C74)"
                color="#E9E9E9"
                fontWeight={'500'}
                fontSize={'15px'}
                borderRadius={'21px'}
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
              >
                View Profile
              </Button>
              {item?.status === 'active' ? (
                <Button
                  w="100%"
                  shadow={'0px 16px 38px 0px #0000000F'}
                  border={'1px solid #75767A'}
                  color="#75767A"
                  fontWeight={'500'}
                  fontSize={'15px'}
                  borderRadius={'21px'}
                  onClick={() => {
                    setStatus('blocked');
                    setUnblockId(item._id);
                    onOpen();
                  }}
                >
                  Block
                </Button>
              ) : (
                <Button
                  w="100%"
                  shadow={'0px 16px 38px 0px #0000000F'}
                  border={'1px solid #75767A'}
                  color="#75767A"
                  fontWeight={'500'}
                  fontSize={'15px'}
                  onClick={() => {
                    setStatus('active');
                    setUnblockId(item._id);
                    onOpen();
                  }}
                  borderRadius={'21px'}
                >
                  Unblock
                </Button>
              )}
            </HStack>
          </Box>
        ))}
      </Grid>
    </Stack>
  );
}

export default ExternalDoctors;
