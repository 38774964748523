import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';

import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  Link,
  HStack,
  Avatar,
  useToast,
  Spinner,
  Grid,
} from '@chakra-ui/react';
import profileBg from '../../../assets/images/dashboard/profileBg.jpg';
import { BiCamera } from 'react-icons/bi';
import {
  FaFacebookF,
  FaInstagram,
  FaMapMarker,
  FaPhoneAlt,
  FaStar,
  FaRegStar,
} from 'react-icons/fa';
import reviewPic from '../../../assets/images/website/test1.svg';
import reviewPic2 from '../../../assets/images/website/test2.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { GET, PUT } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import { useSelector } from 'react-redux';
import Vector from '../../../assets/images/dashboard/Vector.png';
import { Link as ReactLink } from 'react-router-dom';
import moment from 'moment';
export default function HospitalProfile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector(state => state?.user?.value);
  const [type, setType] = useState(user?.type);
  const toast = useToast();
  const [selectedService, setSelectedService] = useState('about');
  const [timings, setTimings] = useState([
    {
      id: 1,
      day: 'Monday',
      time: '8:00 AM to 10:00 PM',
    },
    {
      id: 2,
      day: 'Tuesday',
      time: '8:00 AM to 10:00 PM',
    },
    {
      id: 3,
      day: 'Wednesday',
      time: '8:00 AM to 10:00 PM',
    },
    {
      id: 4,
      day: 'Thursday',
      time: '8:00 AM to 10:00 PM',
    },
    {
      id: 5,
      day: 'Friday',
      time: '8:00 AM to 10:00 PM',
    },
    {
      id: 6,
      day: 'Saturday',
      time: '8:00 AM to 10:00 PM',
    },
    {
      id: 7,
      day: 'Sunday',
      time: '8:00 AM to 10:00 PM',
    },
  ]);
  console.log(type);
  const [reviews, setReviews] = useState([
    {
      reviewBy: 'Patient Review',
      img: reviewPic,
      id: '#eas12fv',
      content:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      rate: '5.0',
      name: 'Alexa John',
    },
    {
      reviewBy: 'Patient Review',
      img: reviewPic,
      id: '#dg142rg',
      content:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      rate: '5.0',
      name: 'Alexa John',
    },
    {
      reviewBy: 'Practitioner Review',
      img: reviewPic2,
      id: '#125advba',
      content:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      rate: '5.0',
      name: 'Dr. Adam Knight',
    },
    {
      reviewBy: 'Practitioner Review',
      img: reviewPic2,
      id: '#912nlkf1',
      content:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      rate: '5.0',
      name: 'Dr. Adam Knight',
    },
  ]);
  const appointmentData = [
    {
      id: '1',
      name: '7 New | Pending Appointments',
      appoinment: '7',
      status: 'Pending',
    },
    {
      id: '2',
      name: '5 New | Ongoing Appointments',
      appoinment: '5',
      status: 'Ongoing',
    },
    {
      id: '3',
      name: '3 New | Completed Appointments',
      appoinment: '3',
      status: 'Completed',
    },
    {
      id: '4',
      name: '2 New | Cancel Appointments',
      appoinment: '2',
      status: 'Cancelled',
    },
  ];
  const inputFileRef = useRef(null);
  const inputFileRef2 = useRef(null);

  const handleFileInputChange = async (file, type) => {
    try {
      if (file) {
        console.log('inIF');
        const form = new FormData();
        form.append(`${type}`, file);
        const response = await PUT('/hospital/profile', form);
        console.log(response);
        if (response.status === 200) {
          setData(response.data);
          toast({
            description: `${type} uploaded successfully`,
            status: response.status === 200 ? 'success' : 'error',
            isClosable: true,
            position: 'top-right',
            duration: 3000,
          });
        } else {
          toast({
            description: 'Error while uploading Logo try after some time',
            status: 'error',
            isClosable: true,
            position: 'top-right',
            duration: 3000,
          });
        }
      }
    } catch (error) {
      console.log('Error while uploading the logo ' + error);
    }
  };
  const [data, setData] = useState(null);

  async function fetchHospitalDetails() {
    try {
      let url =
        type === 'superadmin'
          ? `/admin/hospitals/${id}`
          : type === 'patient'
          ? `/web/patient/hospital/${id}`
          : '/hospital/profile';
      const response = await GET(url);
      console.log('hospital profile', response.data);
      if (response.status === 200) setData(response.data);
      else setData({});
    } catch (error) {
      console.error(
        'An error occurred while fetching hosptal profile data:',
        error
      );
    }
  }
  useLayoutEffect(() => {
    fetchHospitalDetails();
  }, []);
  useEffect(() => {
    setType(user?.type);
    // setType('superadmin');
  }, [user]);

  return (
    <Stack w="100%" h="100vh">
      {!data ? (
        <Spinner />
      ) : (
        <Stack w="100%">
          <Box position={'relative'}>
            <Image
              src={data?.banner ? `${imgUrl}/${data?.banner}` : profileBg}
              w={'100%'}
              h="300px"
              objectFit={'cover'}
            />

            {/* {type !== 'practitioner' ||
              (type !== 'patient' && (
                <Button
                  _hover={{ bgColor: 'transparent', color: 'primaryBlack.100' }}
                  border={'1px solid'}
                  borderColor={'primaryBlack.100'}
                  transition={'.3s'}
                  position={'absolute'}
                  right={'10px'}
                  bottom={'10px'}
                  bgColor={'primaryBlack.100'}
                  color={'#fff'}
                  borderRadius={'25px'}
                  cursor={'pointer !important'}
                  fontWeight={'400'}
                  zIndex={999}
                  onClick={() => inputFileRef2.current.click()}
                >
                  <Icon fontSize={'20px'} as={BiCamera} mr="5px" /> Change Photo
                </Button>
              ))} */}
            {type === 'hospital' && (
              <Button
                _hover={{ bgColor: 'transparent', color: 'primaryBlack.100' }}
                border={'1px solid'}
                borderColor={'primaryBlack.100'}
                transition={'.3s'}
                position={'absolute'}
                right={'10px'}
                bottom={'10px'}
                bgColor={'primaryBlack.100'}
                color={'#fff'}
                borderRadius={'25px'}
                cursor={'pointer !important'}
                fontWeight={'400'}
                zIndex={999}
                onClick={() => inputFileRef2.current.click()}
              >
                <Icon fontSize={'20px'} as={BiCamera} mr="5px" /> Change Photo
              </Button>
            )}
            <input
              ref={inputFileRef2}
              type="file"
              style={{ display: 'none' }}
              onChange={e => handleFileInputChange(e.target.files[0], 'banner')}
            />
          </Box>
          <Stack px="20px">
            <Stack
              marginTop={'-100px'}
              position={'relative'}
              mb="40px"
              direction={'row'}
              alignItems={'flex-end'}
              justifyContent={'space-between'}
            >
              <Box></Box>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDir={'column'}
              >
                <Box position={'relative'}>
                  <Box
                    border={'6px solid #F2F2F2'}
                    borderRadius={'100%'}
                    overflow={'hidden'}
                    w="126px"
                    h="126px"
                  >
                    <Image
                      mx="0 auto"
                      w={'100%'}
                      h={'100%'}
                      src={
                        data?.logo ? `${imgUrl}/${data?.logo}` : HospitalProfile
                      }
                      objectFit={'cover'}
                    />
                  </Box>
                  {/* {type !== 'practitioner' ||
                    (type !== 'patient' && (
                      <Icon
                        cursor={'pointer'}
                        position={'absolute'}
                        borderRadius={'100%'}
                        w={'35px'}
                        h={'35px'}
                        p="5px"
                        as={BiCamera}
                        color={'#fff'}
                        bgGradient={'linear(to-r, #295377, #208C74)'}
                        right="0"
                        bottom="15px"
                        onClick={() => inputFileRef?.current.click()}
                      />
                    ))} */}
                  {type === 'hospital' && (
                    <Icon
                      cursor={'pointer'}
                      position={'absolute'}
                      borderRadius={'100%'}
                      w={'35px'}
                      h={'35px'}
                      p="5px"
                      as={BiCamera}
                      color={'#fff'}
                      bgGradient={'linear(to-r, #295377, #208C74)'}
                      right="0"
                      bottom="15px"
                      onClick={() => inputFileRef?.current.click()}
                    />
                  )}

                  <input
                    ref={inputFileRef}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={e =>
                      handleFileInputChange(e.target.files[0], 'logo')
                    }
                  />
                </Box>
                <Heading fontWeight={'500'} fontSize={'26px'} mt="15px">
                  {data?.name || 'Bascom Palmer Anne Bates'}
                </Heading>
              </Box>
              <Box>
                {type === 'hospital' && (
                  <Button
                    onClick={() => navigate('/dashboard/hospital-edit-profile')}
                    m="20px 0 !important"
                    bgGradient={'linear(to-r, #295377, #208C74)'}
                    fontSize={'14px'}
                    fontWeight={500}
                    color={'#fff'}
                    borderRadius={'25px'}
                    h="45px"
                    w="165px"
                    border={'2px solid transparent'}
                    _hover={{
                      bgGradient: 'none',
                      borderColor: 'primaryGreen.200',
                      color: 'primaryGreen.200',
                    }}
                  >
                    Edit Details
                  </Button>
                )}
                {/* {type !== 'practitioner' ||
                  (type !== 'patient' && (
                    <Button
                      onClick={() =>
                        navigate('/dashboard/hospital-edit-profile')
                      }
                      m="20px 0 !important"
                      bgGradient={'linear(to-r, #295377, #208C74)'}
                      fontSize={'14px'}
                      fontWeight={500}
                      color={'#fff'}
                      borderRadius={'25px'}
                      h="45px"
                      w="165px"
                      border={'2px solid transparent'}
                      _hover={{
                        bgGradient: 'none',
                        borderColor: 'primaryGreen.200',
                        color: 'primaryGreen.200',
                      }}
                    >
                      Edit Details
                    </Button>
                  ))} */}
              </Box>
            </Stack>
            <Stack
              mb="20px !important"
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={{ base: '5px', xl: '20px' }}
              >
                <Button
                  bgGradient={
                    selectedService === 'about'
                      ? 'linear(to-r, #295377, #208C74)'
                      : 'linear(to-b, #C2D4FF, #fff)'
                  }
                  fontSize={{ base: '12px', xl: '16px' }}
                  fontWeight={500}
                  color={
                    selectedService === 'about' ? '#fff' : 'primaryBlack.100'
                  }
                  borderRadius={'25px'}
                  h={{ base: '35px', xl: '35px' }}
                  onClick={() => setSelectedService('about')}
                  _hover={{
                    bgGradient: 'linear(to-r, #295377, #208C74)',
                    color: '#fff',
                  }}
                >
                  About
                </Button>
                <Button
                  bgGradient={
                    selectedService === 'reviews'
                      ? 'linear(to-r, #295377, #208C74)'
                      : 'linear(to-b, #C2D4FF, #fff)'
                  }
                  fontSize={{ base: '12px', xl: '16px' }}
                  fontWeight={500}
                  color={
                    selectedService === 'reviews' ? '#fff' : 'primaryBlack.100'
                  }
                  borderRadius={'25px'}
                  h={{ base: '35px', xl: '35px' }}
                  onClick={() => setSelectedService('reviews')}
                  _hover={{
                    bgGradient: 'linear(to-r, #295377, #208C74)',
                    color: '#fff',
                  }}
                >
                  Reviews
                </Button>
                {type === 'patient' && (
                  <Button
                    bgGradient={
                      selectedService === 'practitioner'
                        ? 'linear(to-r, #295377, #208C74)'
                        : 'linear(to-b, #C2D4FF, #fff)'
                    }
                    fontSize={{ base: '12px', xl: '16px' }}
                    fontWeight={500}
                    color={
                      selectedService === 'practitioner'
                        ? '#fff'
                        : 'primaryBlack.100'
                    }
                    borderRadius={'25px'}
                    h={{ base: '35px', xl: '35px' }}
                    onClick={() => setSelectedService('practitioner')}
                    _hover={{
                      bgGradient: 'linear(to-r, #295377, #208C74)',
                      color: '#fff',
                    }}
                  >
                    Health Practitioners
                  </Button>
                )}
                {type === 'superadmin' && (
                  <>
                    <Button
                      bgGradient={
                        selectedService === 'practitioner'
                          ? 'linear(to-r, #295377, #208C74)'
                          : 'linear(to-b, #C2D4FF, #fff)'
                      }
                      fontSize={{ base: '12px', xl: '16px' }}
                      fontWeight={500}
                      color={
                        selectedService === 'practitioner'
                          ? '#fff'
                          : 'primaryBlack.100'
                      }
                      borderRadius={'25px'}
                      h={{ base: '35px', xl: '35px' }}
                      onClick={() => setSelectedService('practitioner')}
                      _hover={{
                        bgGradient: 'linear(to-r, #295377, #208C74)',
                        color: '#fff',
                      }}
                    >
                      Health Practitioners
                    </Button>
                    <Button
                      bgGradient={
                        selectedService === 'appointments'
                          ? 'linear(to-r, #295377, #208C74)'
                          : 'linear(to-b, #C2D4FF, #fff)'
                      }
                      fontSize={{ base: '12px', xl: '16px' }}
                      fontWeight={500}
                      color={
                        selectedService === 'appointments'
                          ? '#fff'
                          : 'primaryBlack.100'
                      }
                      borderRadius={'25px'}
                      h={{ base: '35px', xl: '35px' }}
                      onClick={() => setSelectedService('appointments')}
                      _hover={{
                        bgGradient: 'linear(to-r, #295377, #208C74)',
                        color: '#fff',
                      }}
                    >
                      Appointments
                    </Button>
                  </>
                )}
              </Stack>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={{ base: '5px', xl: '10px' }}
              >
                <Text
                  fontWeight={'500'}
                  fontSize={'14px'}
                  color={'primaryGray.100'}
                >
                  Social Links:
                </Text>
                {data?.socialLinks &&
                  data?.socialLinks?.map(item => (
                    <Text>
                      <Link href={`${item.url}`} isExternal>
                        <Icon
                          as={
                            item.platform === 'facebook' || 'Facebook'
                              ? FaFacebookF
                              : item.platform === 'instagram' || 'Instagram'
                              ? FaInstagram
                              : FaInstagram
                          }
                        />
                      </Link>
                    </Text>
                  ))}
              </Stack>
            </Stack>
            {selectedService === 'about' ? (
              <Stack>
                <Text
                  maxW={'50%'}
                  fontSize={'14px'}
                  fontWeight={'400'}
                  color={'primaryGray.100'}
                >
                  {data?.about ||
                    `Duis aute irure dolor in reprehenderit in voluptate velit esse
               cillum dolore eu fugiat nulla pariatur. Excepteur sint
               occaecat cupidatat non proident, sunt in culpa qui officia
               deserunt mollit anim id est laborum.`}
                </Text>
                <Stack mt="20px !important" direction={'row'} gap="20px">
                  <Box flex={1}>
                    <Heading fontSize={'24px'} mb="20px" fontWeight={'500'}>
                      Hospital Availability
                    </Heading>
                    <Stack
                      direction={'row'}
                      spacing={0}
                      flexWrap={'wrap'}
                      justifyContent={'space-between'}
                    >
                      {data.opening_hours &&
                        data.opening_hours.map((val, index) => (
                          <Box
                            key={val.id}
                            w="48%"
                            mb="20px !important"
                            p="15px"
                            borderRadius={'12px'}
                            border={'1px solid'}
                            borderColor={'primaryGray.100'}
                          >
                            <Text color={'primaryGray.100'} fontSize={'14px'}>
                              {val.day}
                            </Text>
                            <Text
                              fontWeight={'500'}
                              color={'primaryBlack.100'}
                              fontSize={'14px'}
                            >
                              {`${val.startTime} to ${val.endTime}`}
                            </Text>
                          </Box>
                        ))}
                      {/* {timings.map(val => (
                     <Box
                       key={val.id}
                       w="48%"
                       mb="20px !important"
                       p="15px"
                       borderRadius={'12px'}
                       border={'1px solid'}
                       borderColor={'primaryGray.100'}
                     >
                       <Text color={'primaryGray.100'} fontSize={'14px'}>
                         {val.day}
                       </Text>
                       <Text
                         fontWeight={'500'}
                         color={'primaryBlack.100'}
                         fontSize={'14px'}
                       >
                         {val.time}
                       </Text>
                     </Box>
                   ))} */}
                    </Stack>
                  </Box>
                  <Box flex={1}>
                    <Heading fontSize={'24px'} mb="20px" fontWeight={'500'}>
                      Hospital Info
                    </Heading>
                    <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
                      <Icon mr="5px" as={FaMapMarker} />
                      {data?.address || ' King Fahad Rd, AlBustan'}
                    </Text>
                    <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
                      <Icon mr="5px" as={FaPhoneAlt} />{' '}
                      {data?.number || '+966 765 272 46'}
                    </Text>
                    <iframe
                      style={{ width: '100%', height: '200px' }}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.144404792699!2d46.614954999999995!3d24.824734799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1b5ab2e599c1%3A0x58ab1ee4e94e9c89!2sKing%20Fahd%20Rd%2C%20Riyadh%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1714130640115!5m2!1sen!2s"
                    />
                  </Box>
                </Stack>
              </Stack>
            ) : selectedService === 'reviews' ? (
              <Stack>
                <Text
                  maxW={'50%'}
                  fontSize={'14px'}
                  fontWeight={'400'}
                  color={'primaryGray.100'}
                >
                  Total Reviews: {data?.reviews?.length || 0}
                </Text>
                <Stack mt="20px !important" direction={'row'} gap="20px">
                  <Box flex={1}>
                    <Stack
                      direction={'row'}
                      spacing={0}
                      flexWrap={'wrap'}
                      justifyContent={'space-between'}
                    >
                      {data?.reviews?.length > 0 ? (
                        data?.reviews.map((item, ind) => (
                          <Box w="48%" key={item._id}>
                            <Box
                              bgColor={'#fff'}
                              w="100%"
                              mb="20px !important"
                              p="10px"
                              borderBottom={'1px solid'}
                              borderColor={'primaryGray.100'}
                            >
                              <Text
                                fontWeight={'500'}
                                fontSize={'14px'}
                                color={'primaryBlack.100'}
                                mb="15px"
                              >
                                Patient
                              </Text>
                              <Box
                                mb="15px"
                                display={'flex'}
                                alignItems={'center'}
                                gap="10px"
                              >
                                <Image
                                  src={`${imgUrl}/${item?.['patient']['picture']}`}
                                  borderRadius={'100%'}
                                  w="65px"
                                  h="65px"
                                />
                                <Text
                                  fontSize={'16px'}
                                  color="primaryBlack.100"
                                >
                                  {item['patient']['fullName']}
                                </Text>
                              </Box>
                              <Text
                                color={'primaryGray.100'}
                                fontSize={'14px'}
                                mb="15px"
                              >
                                {item.hospitalFeedback}
                              </Text>
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems={'center'}
                                gap="5px"
                              >
                                <Text fontSize={'14px'} color={'yellow.500'}>
                                  {item.hospitalRating}.0
                                </Text>
                                <HStack>
                                  {Array.from({ length: 5 }, (_, i) => (
                                    <Icon
                                      as={
                                        i < item?.hospitalRating
                                          ? FaStar
                                          : FaRegStar
                                      }
                                      color={'#FEB052'}
                                      key={i}
                                    />
                                  ))}
                                </HStack>
                              </Stack>
                            </Box>
                            <Box
                              bgColor={'#fff'}
                              key={item._id}
                              w="100%"
                              mb="20px !important"
                              p="10px"
                              borderBottom={'1px solid'}
                              borderColor={'primaryGray.100'}
                            >
                              <Text
                                fontWeight={'500'}
                                fontSize={'14px'}
                                color={'primaryBlack.100'}
                                mb="15px"
                              >
                                Health Practitioner
                              </Text>
                              <Box
                                mb="15px"
                                display={'flex'}
                                alignItems={'center'}
                                gap="10px"
                              >
                                <Image
                                  src={`${imgUrl}/${item?.['doctor']['picture']}`}
                                  borderRadius={'100%'}
                                  w="65px"
                                  h="65px"
                                />
                                <Text
                                  fontSize={'16px'}
                                  color="primaryBlack.100"
                                >
                                  {item['doctor']['fullName']}
                                </Text>
                              </Box>
                              <Text
                                color={'primaryGray.100'}
                                fontSize={'14px'}
                                mb="15px"
                              >
                                {item.doctorFeedback}
                              </Text>
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems={'center'}
                                gap="5px"
                              >
                                <Text fontSize={'14px'} color={'yellow.500'}>
                                  {item.doctorRating}
                                </Text>
                                <HStack>
                                  {Array.from({ length: 5 }, (_, i) => (
                                    <Icon
                                      as={
                                        i < item?.doctorRating
                                          ? FaStar
                                          : FaRegStar
                                      }
                                      color={'#FEB052'}
                                      key={i}
                                    />
                                  ))}
                                </HStack>
                              </Stack>
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Heading>No Reviews</Heading>
                      )}
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
            ) : null}
            {selectedService === 'practitioner' ? (
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                {data?.doctors?.map((item, ind) => (
                  <Box
                    shadow={'0px 16px 38px 0px #0000000F'}
                    bg={'#fff'}
                    borderRadius={'16px'}
                    p={4}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={4}
                  >
                    <Stack
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={4}
                    >
                      <HStack
                        alignItems={'center'}
                        lineHeight={'0.3rem'}
                        gap={2}
                      >
                        <Avatar
                          size={'lg'}
                          name="Dan Abrahmov"
                          // src={`${imgUrl}/${
                          //   selectedService === 'patient' ? item.picture : item.photo
                          // }`}
                          src={
                            `${imgUrl}/${item?.photo}` ||
                            'https://bit.ly/dan-abramov'
                          }
                        />
                        <Stack>
                          <Heading fontSize={'22px'} fontWeight={'500'}>
                            {item.name}
                            {/* {selectedService === 'patient'
                            ? item.fullName
                            : item.name} */}
                          </Heading>
                          <Text
                            fontSize={'15px'}
                            color={'#75767A'}
                            fontWeight={'400'}
                          >
                            #d44vdsQ
                          </Text>
                        </Stack>
                      </HStack>
                      <Text
                        fontSize={'16px'}
                        color={'#75767A'}
                        fontWeight={'400'}
                      >
                        Member since:{' '}
                        {moment(item.createdAt).format('DD MMM, YYYY')}
                        {/* Member since: {moment(item.createdAt).format('DD MMM, YYYY')} */}
                      </Text>
                    </Stack>
                    <Stack pt={4}>
                      <Button
                        as={ReactLink}
                        to={
                          '/dashboard/doctor-profile/664464bf132944f12e780716'
                        }
                        w="100%"
                        bgGradient="linear(to-r, #295377, #208C74)"
                        color="#E9E9E9"
                        fontWeight={'500'}
                        fontSize={'15px'}
                        borderRadius={'21px'}
                        border={'2px solid transparent'}
                        _hover={{
                          bgGradient: 'none',
                          borderColor: 'primaryGreen.200',
                          color: 'primaryGreen.200',
                        }}
                      >
                        View Profile
                      </Button>
                    </Stack>
                  </Box>
                ))}
              </Grid>
            ) : selectedService === 'appointments' ? (
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                {data?.appointments?.map((item, ind) => (
                  <Box
                    shadow={'0px 16px 38px 0px #0000000F'}
                    bg={'#fff'}
                    borderRadius={'16px'}
                    p={4}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={4}
                  >
                    <Text pl={4} fontSize={'16px'} fontWeight={'400'}>
                      {item.status} Appointments
                    </Text>
                    <Stack
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={4}
                    >
                      <HStack
                        alignItems={'center'}
                        lineHeight={'0.3rem'}
                        gap={2}
                      >
                        <Box
                          w="63px"
                          h="63px"
                          borderRadius={'full'}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          border={'1px solid #208C74'}
                        >
                          <Image src={Vector} w="23px" h="23px" />
                        </Box>
                        <Box>
                          <Text fontSize={'22px'} fontWeight={'500'}>
                            {`${item.total} New | ${item.name}`}
                          </Text>
                        </Box>
                      </HStack>
                    </Stack>
                    <Stack>
                      <Button
                        w="100%"
                        onClick={() =>
                          navigate(
                            `/dashboard/appointments/${item.status.toLowerCase()}`
                          )
                        }
                        bgGradient="linear(to-r, #295377, #208C74)"
                        color="#E9E9E9"
                        fontWeight={'500'}
                        fontSize={'15px'}
                        borderRadius={'21px'}
                        border={'2px solid transparent'}
                        _hover={{
                          bgGradient: 'none',
                          borderColor: 'primaryGreen.200',
                          color: 'primaryGreen.200',
                        }}
                      >
                        View All ({item.total})
                      </Button>
                    </Stack>
                  </Box>
                ))}
              </Grid>
            ) : null}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
