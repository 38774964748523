import React from 'react';
import {
  Box,
  Heading,
  Stack,
  Text,
  Image,
  SimpleGrid,
  Avatar,
  Button,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import TravelPic from '../../../assets/images/website/travel.jpg';

export default function Travel() {
  return (
    <Stack gap={6}>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Travel Services
        </Heading>
      </Stack>
      <Stack>
        <SimpleGrid columns={3} spacing={10}>
          <Box
            w={'full'}
            bg={'fff'}
            boxShadow={'2xl'}
            rounded={'md'}
            p={6}
            overflow={'hidden'}
          >
            <Box bg={'gray.100'} mt={-6} mx={-6} mb={6} pos={'relative'}>
              <Image src={TravelPic} width={'500px'} alt="Example" />
            </Box>
            <Stack>
              <Heading color={'gray.700'} fontSize={'30px'} fontFamily={'body'}>
              Capital Ambulance Services
              </Heading>
              <Text color={'gray.500'}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum.
              </Text>
            </Stack>
            <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
              <Button
                as={'a'}
                target='_blank'
                href={`https://www.capitalambulance.sa/`}
                w="45%"
                m="0 !important"
                bgGradient={'linear(to-r, #295377, #208C74)'}
                border={'1px solid #208C74'}
                fontSize={{ base: '12px', xl: '16px' }}
                fontWeight={500}
                color={'#fff'}
                borderRadius={'6px'}
                h={{ base: '64px', xl: '64px' }}
                // onClick={() => setSelectedService('travel')}
                _hover={{
                  bgGradient: 'linear(to-r, #295377, #208C74)',
                  color: '#fff',
                }}
              >
                View Services
              </Button>
            </Stack>
          </Box>
        </SimpleGrid>
      </Stack>
    </Stack>
  );
}
