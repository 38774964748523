import React, { useState, useEffect } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import {
  CalendarIcon,
  EditIcon,
  ViewIcon,
  CloseIcon,
  CheckIcon,
} from '@chakra-ui/icons';
import moment from 'moment/moment';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LuCalendarClock } from 'react-icons/lu';
import { LiaHospitalAltSolid } from 'react-icons/lia';
import { MdOutlineAttachMoney } from 'react-icons/md';

import { GET } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import { TbBuildingHospital } from 'react-icons/tb';
// import shapeb from '../../../assets/images/icon/black.png'
Chart.register(CategoryScale);
export default function DashboardHome() {
  const user = useSelector(state => state.user.value);
  const [type, setType] = useState(user?.type);
  const [data, setData] = useState(null);
  const [selectedService, setSelectedService] = useState('onGoing');
  const toast = useToast();
  useEffect(() => {
    if (user) {
      setType(user.type);
      // setType('patient');
    }
  }, [user]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await GET('/admin/dashboard');
        if (response.status === 200) {
          setData(response.data);
          console.log(response.data);
        } else {
          handleFetchError();
        }
      } catch (error) {
        handleFetchError(error);
      }
    };

    fetchBlogs();
  }, []);

  const handleFetchError = (error = null) => {
    if (error) {
      console.error('While Fetching Dashboard', error);
    }
    toast({
      title: 'Error',
      description: 'Something went wrong while fetching the Dashboard.',
      status: 'error',
      duration: 2000,
      position: 'top-right',
      isClosable: true,
    });
  };
  const Data = [
    {
      id: 1,
      year: 'Mon',
      userGain: 80000,
      userLost: 823,
    },
    {
      id: 2,
      year: 'Tues',
      userGain: 45677,
      userLost: 345,
    },
    {
      id: 3,
      year: 'Wed',
      userGain: 78888,
      userLost: 555,
    },
    {
      id: 4,
      year: 'Thurs',
      userGain: 90000,
      userLost: 4555,
    },
    {
      id: 5,
      year: 'Fri',
      userGain: 4300,
      userLost: 234,
    },
    {
      id: 5,
      year: 'Sat',
      userGain: 4300,
      userLost: 234,
    },
    {
      id: 5,
      year: 'Sun',
      userGain: 4300,
      userLost: 234,
    },
  ];
  // const [chartData, setChartData] = useState({
  //   labels: Data.map(data => data.year),
  //   title: 'Sales Analytics',
  //   datasets: [
  //     {
  //       label: 'Completed',
  //       data: [65, 59, 80, 81, 56, 55, 40],
  //       backgroundColor: '#208C74',
  //       borderColor: '#208C74',
  //       borderWidth: 0,
  //       borderRadius: 7,
  //     },
  //     {
  //       label: 'Cancel',
  //       data: [45, 39, 60, 71, 26, 25, 20],
  //       backgroundColor: '#FCC945',
  //       borderColor: '#FCC945',
  //       borderWidth: 0,
  //       borderRadius: 7,
  //     },
  //   ],
  // });
  if (!data) {
    return (
      <Stack w="100%" h="100vh" justifyContent={'center'} alignItems={'center'}>
        <Spinner />
      </Stack>
    );
  }
  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Dashboard
        </Heading>
        {/* <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text> */}
      </Stack>
      {/*Heading  */}
      {/* Stats */}
      <Stack mt={'10px'}>
        <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
          {type === 'patient' ? (
            <GridItem rowSpan={2} colSpan={2}>
              <Box
                p={5}
                shadow="md"
                borderWidth="1px"
                display={'flex'}
                height={'300px'}
                flexDirection={'column'}
                gap={'15px'}
                borderRadius="16px"
              >
                <Text fontSize={'16px'} fontWeight={500}>
                  What type of service you’re looking for?
                </Text>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  flexWrap={'wrap'}
                  gap={4}
                >
                  <Button
                    w="45%"
                    m="0 !important"
                    bgGradient={
                      selectedService === 'onGoings'
                        ? 'linear(to-r, #295377, #208C74)'
                        : 'linear(to-b, #C2D4FF, #fff)'
                    }
                    border={'1px solid #208C74'}
                    fontSize={{ base: '12px', xl: '16px' }}
                    fontWeight={500}
                    color={
                      selectedService === 'onGoings'
                        ? '#fff'
                        : 'primaryBlack.100'
                    }
                    borderRadius={'6px'}
                    h={{ base: '64px', xl: '64px' }}
                    onClick={() => setSelectedService('onGoings')}
                    _hover={{
                      bgGradient: 'linear(to-r, #295377, #208C74)',
                      color: '#fff',
                    }}
                    as={Link}
                    to={`/dashboard/health-practitioner?type=inperson`}
                  >
                    Clinic Appointment
                  </Button>
                  <Button
                    w="45%"
                    m="0 !important"
                    bgGradient={
                      selectedService === 'virtual'
                        ? 'linear(to-r, #295377, #208C74)'
                        : 'linear(to-b, #C2D4FF, #fff)'
                    }
                    border={'1px solid #208C74'}
                    fontSize={{ base: '12px', lg: '12px', xl: '16px' }}
                    fontWeight={500}
                    color={
                      selectedService === 'virtual'
                        ? '#fff'
                        : 'primaryBlack.100'
                    }
                    borderRadius={'6px'}
                    h={{ base: '64px', xl: '64px' }}
                    onClick={() => setSelectedService('virtual')}
                    _hover={{
                      bgGradient: 'linear(to-r, #295377, #208C74)',
                      color: '#fff',
                    }}
                    as={Link}
                    to={`/dashboard/health-practitioner?type=virtual`}
                  >
                    Virtual Clinic Appointment
                  </Button>
                  <Button
                    w="45%"
                    m="0 !important"
                    bgGradient={
                      selectedService === 'bedBooking'
                        ? 'linear(to-r, #295377, #208C74)'
                        : 'linear(to-b, #C2D4FF, #fff)'
                    }
                    fontSize={{ base: '12px', xl: '16px' }}
                    fontWeight={500}
                    border={'1px solid #208C74'}
                    color={
                      selectedService === 'bedBooking'
                        ? '#fff'
                        : 'primaryBlack.100'
                    }
                    borderRadius={'6px'}
                    h={{ base: '64px', xl: '64px' }}
                    onClick={() => setSelectedService('bedBooking')}
                    _hover={{
                      bgGradient: 'linear(to-r, #295377, #208C74)',
                      color: '#fff',
                    }}
                    as={Link}
                    to={`/dashboard/bed-booking`}
                  >
                    Book an Inpatient Bed
                  </Button>
                  <Button
                    as={Link}
                    to={`/dashboard/travel`}
                    w="45%"
                    m="0 !important"
                    bgGradient={
                      selectedService === 'travel'
                        ? 'linear(to-r, #295377, #208C74)'
                        : 'linear(to-b, #C2D4FF, #fff)'
                    }
                    border={'1px solid #208C74'}
                    fontSize={{ base: '12px', xl: '16px' }}
                    fontWeight={500}
                    color={
                      selectedService === 'travel' ? '#fff' : 'primaryBlack.100'
                    }
                    borderRadius={'6px'}
                    h={{ base: '64px', xl: '64px' }}
                    // onClick={() => setSelectedService('travel')}
                    _hover={{
                      bgGradient: 'linear(to-r, #295377, #208C74)',
                      color: '#fff',
                    }}
                  >
                    Travel Services
                  </Button>
                  <Button
                    w="45%"
                    m="0 !important"
                    bgGradient={
                      selectedService === 'nearByDoctors'
                        ? 'linear(to-r, #295377, #208C74)'
                        : 'linear(to-b, #C2D4FF, #fff)'
                    }
                    border={'1px solid #208C74'}
                    fontSize={{ base: '12px', xl: '16px' }}
                    fontWeight={500}
                    color={
                      selectedService === 'nearByDoctors'
                        ? '#fff'
                        : 'primaryBlack.100'
                    }
                    borderRadius={'6px'}
                    h={{ base: '64px', xl: '64px' }}
                    onClick={() => setSelectedService('nearByDoctors')}
                    _hover={{
                      bgGradient: 'linear(to-r, #295377, #208C74)',
                      color: '#fff',
                    }}
                    as={Link}
                    to={`/dashboard/health-practitioner?type=nearByDoctors`}
                  >
                    Nearest Doctor Search
                  </Button>
                </Stack>
              </Box>
            </GridItem>
          ) : (
            data?.topHeader?.map((item, index) => (
              <Box
                key={index}
                p={5}
                shadow="md"
                borderWidth="1px"
                display={'flex'}
                height={'219px'}
                flexDirection={'column'}
                gap={'15px'}
                borderRadius="16px"
                bgGradient={
                  index !== 0
                    ? 'linear(to-r, #F2F2F2, #F2F2F2)'
                    : 'linear(to-r, #295377, #208C74)'
                }
                color={index === 0 ? '#fff' : 'initial'}
              >
                <HStack>
                  <IconButton
                    isRound={true}
                    variant="solid"
                    colorScheme="#F2F2F2"
                    bgGradient={
                      index === 0
                        ? 'linear(to-r, #F2F2F2, #F2F2F2)'
                        : 'linear(to-r, #295377, #208C74)'
                    }
                    aria-label="Done"
                    fontSize="20px"
                    icon={
                      item?.name === 'Total Doctors' ||
                      item?.name === 'Total Patients' ? (
                        <LiaHospitalAltSolid
                          color={index === 0 ? '#208C74' : '#f2f2f2'}
                        />
                      ) : item?.name === 'Total Hospitals' ? (
                        <TbBuildingHospital
                          color={index === 0 ? '#208C74' : '#f2f2f2'}
                        />
                      ) : item?.name === 'Total Revenue' ? (
                        <MdOutlineAttachMoney
                          color={index === 0 ? '#208C74' : '#f2f2f2'}
                        />
                      ) : (
                        <LuCalendarClock
                          color={index === 0 ? '#208C74' : '#F2F2F2'}
                        />
                      )
                    }
                  />
                  <Text
                    fontSize={'16px'}
                    fontWeight={'500'}
                    color={index === 0 ? '#fff' : '#75767A'}
                  >
                    {item?.name}
                  </Text>
                </HStack>
                <Stack>
                  <Heading
                    as="h1"
                    fontWeight={'500'}
                    fontSize={'40px'}
                    color={index === 0 ? '#fff' : 'initial'}
                  >
                    {item?.count}
                  </Heading>
                  {item?.virtual ? (
                    <Stack flexDirection={'column'}>
                      <HStack>
                        <Box
                          w="32px"
                          h={'32px'}
                          bg={'#6B8FC6'}
                          borderRadius={'100px'}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <CalendarIcon color={'#F2F2F2'} />
                        </Box>
                        <Text
                          fontSize={'15px'}
                          fontWeight={'500'}
                          color={index === 0 ? '#fff' : '#75767A'}
                        >
                          {item?.virtual?.name}
                          {` (${item?.virtual?.count})`}
                        </Text>
                      </HStack>
                      <HStack>
                        <Box
                          w="32px"
                          h={'32px'}
                          bg={'#6BC67A'}
                          borderRadius={'100px'}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <CalendarIcon color={'#F2F2F2'} />
                        </Box>
                        <Text
                          fontSize={'14px'}
                          fontWeight={'500'}
                          color={index === 0 ? '#fff' : '#75767A'}
                        >
                          {item?.bedBooking?.name}
                          {` (${item?.bedBooking?.count})`}
                        </Text>
                      </HStack>
                    </Stack>
                  ) : (
                    <Text
                      fontSize={'15px'}
                      color={index === 0 ? '#fff' : '#75767A'}
                      fontWeight={'500'}
                    >
                      {item?.description}
                    </Text>
                  )}
                </Stack>
              </Box>
            ))
          )}

          <GridItem rowSpan={2} colSpan={2}>
            <Stack
              display={
                type === 'practitioner' || type === 'patient'
                  ? 'initial'
                  : 'none'
              }
            >
              {data?.latestAppointment?.map((item, index) => (
                <Box
                  px="20px"
                  py="10px"
                  shadow="lg"
                  borderRadius="16px"
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'space-around'}
                  gap={'10px'}
                >
                  <HStack justifyContent={'space-between'}>
                    <HStack>
                      <WrapItem>
                        <Avatar
                          size="lg"
                          name="Prosper Otemuyiwa"
                          src={`${imgUrl}/${item?.patientPicture}`}
                        />{' '}
                      </WrapItem>
                      <Stack>
                        <Heading fontWeight={'400'} fontSize={'22px'}>
                          {item?.patientName}
                        </Heading>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          #d44vdsQ
                        </Text>
                      </Stack>
                    </HStack>
                    <Button
                      bgGradient="linear(to-r, #295377, #208C74)"
                      color="#E9E9E9"
                      fontWeight={'500'}
                      fontSize={'16px'}
                      borderRadius={'21px'}
                      as={Link}
                      border={'2px solid transparent'}
                      _hover={{
                        bgGradient: 'none',
                        borderColor: 'primaryGreen.200',
                        color: 'primaryGreen.200',
                      }}
                      to={`/dashboard/appointments/appointment-details/${item._id}`}
                    >
                      View Details
                    </Button>
                  </HStack>
                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Stack>
                      <Stack>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          Appointment Date
                        </Text>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          {moment(item?.startTime).format('MM/DD/YYYY')}
                        </Text>
                      </Stack>
                      <Stack>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          $Price
                        </Text>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          ${item?.assignedDoctorCharges}
                        </Text>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Stack>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          Patient Type
                        </Text>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          {item?.type}
                        </Text>
                      </Stack>
                      <Stack>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          Time
                        </Text>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          {moment(item.startTime).format('h - mm A') ||
                            ' 3 - 30 PM'}
                        </Text>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              ))}
            </Stack>
          </GridItem>
        </Grid>

        {type !== 'practitioner' && type !== 'patient' ? (
          <Grid templateColumns="repeat(4, 1fr)" gap={6}>
            <GridItem
              colSpan={type === 'superadmin' ? 4 : 3}
              className="chart-container"
              position={'relative'}
            >
              <Heading fontWeight={'500'} fontSize={'25px'}>
                Patient Appointments
              </Heading>
              <Bar
                options={{
                  plugins: {
                    legend: {
                      display: true,
                      position: 'top',
                      labels: {
                        font: {
                          size: 14,
                          weight: 'italic',
                          padding: 10,
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
                height={'100%'}
                data={{
                  labels: ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'],
                  title: 'Sales Analytics',
                  datasets: data?.['analytics'][0]?.['datasets'] || [],
                }}
              />
            </GridItem>

            <Stack
              display={
                type === 'practitioner' ||
                type === 'superadmin' ||
                type === 'patient'
                  ? 'none'
                  : 'initial'
              }
            >
              <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={'15px'}
              >
                <Heading fontWeight={'500'} fontSize={'25px'}>
                  Appointments
                </Heading>
                <Text
                  cursor={'pointer'}
                  fontSize={'16px'}
                  fontWeight={'400'}
                  color={'#75767A'}
                  as={Link}
                  to={'/dashboard/appointments'}
                >
                  View All
                </Text>
              </Stack>
              {data?.latestAppointment?.map((item, index) => {
                return (
                  <Box
                    px="20px"
                    shadow="lg"
                    h="275px"
                    mt="20px"
                    borderRadius="16px"
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'space-around'}
                    gap={'10px'}
                  >
                    <HStack>
                      <WrapItem>
                        <Avatar
                          size="lg"
                          name="Prosper Otemuyiwa"
                          src={`${imgUrl}/${item?.patientPicture}`}
                        />{' '}
                      </WrapItem>
                      <Stack>
                        <Heading fontWeight={'400'} fontSize={'22px'}>
                          {item?.patientName}
                        </Heading>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          #d44vdsQ
                        </Text>
                      </Stack>
                    </HStack>
                    <Stack>
                      <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <Stack>
                          <Stack>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'400'}
                              color={'#75767A'}
                            >
                              Appointment Date
                            </Text>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'500'}
                              color={'#1B1C1D'}
                            >
                              {moment(item?.startTime).format('MM/DD/YYYY')}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'400'}
                              color={'#75767A'}
                            >
                              $Price
                            </Text>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'500'}
                              color={'#1B1C1D'}
                            >
                              ${item?.assignedDoctorCharges}
                            </Text>
                          </Stack>
                        </Stack>
                        <Stack>
                          <Stack>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'400'}
                              color={'#75767A'}
                            >
                              Patient Type
                            </Text>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'500'}
                              color={'#1B1C1D'}
                            >
                              {item?.type}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'400'}
                              color={'#75767A'}
                            >
                              Time
                            </Text>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'500'}
                              color={'#1B1C1D'}
                            >
                              {moment(item.startTime).format('h - mm A') ||
                                ' 3 - 30 PM'}
                            </Text>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Button
                      bgGradient="linear(to-r, #295377, #208C74)"
                      color="#E9E9E9"
                      fontWeight={'500'}
                      as={Link}
                      to={`/dashboard/appointments/appointment-details/${item._id}`}
                      fontSize={'16px'}
                      borderRadius={'21px'}
                    >
                      View Details
                    </Button>
                  </Box>
                );
              })}
            </Stack>
          </Grid>
        ) : null}
      </Stack>
      {/* Table */}
      <Stack>
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          my={'15px'}
        >
          <Heading fontWeight={'500'} fontSize={'25px'}>
            {type === 'superadmin' ? 'Hospital' : 'Appointment'} Requests
          </Heading>
          {type === 'superadmin' && (
            <Text
              as={Link}
              to={'/dashboard/hospital-requests'}
              cursor={'pointer'}
              fontSize={'16px'}
              fontWeight={'400'}
              color={'#75767A'}
            >
              View All
            </Text>
          )}
        </Stack>
        {type === 'superadmin' ? (
          <Stack display={'flex'} flexDirection={'column'} gap={2}>
            <HStack display={'flex'} w="100%" px={4}>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                NO.
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                ID
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Hospital Name
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Registration Date
              </Box>
              <Box
                fontSize="15px"
                fontWeight={'400'}
                color="#75767A"
                flex={1}
                justifyContent={'center'}
              >
                Hospital Certificate
              </Box>

              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Status
              </Box>
              {/* <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Action
              </Box> */}
            </HStack>
            {data?.hospitals?.map((hospital, index) => (
              <HStack
                display={'flex'}
                w="100%"
                h="70px"
                bg={'#fff'}
                shadow={'0px 16px 38px 0px #0000000F'}
                px={4}
                borderRadius={'16px'}
              >
                <Box
                  fontSize="15px"
                  fontWeight={'500'}
                  color="#1B1C1D"
                  flex={1}
                >
                  {index + 1}
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'500'}
                  color="#1B1C1D"
                  flex={1}
                >
                  #{hospital?.ibanNo}
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'500'}
                  color="#1B1C1D"
                  flex={1}
                >
                  <VStack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    gap={2}
                  >
                    <Avatar
                      size="sm"
                      name="Kent Dodds"
                      src="https://bit.ly/kent-c-dodds"
                    />
                    <Text>{hospital?.name}</Text>
                  </VStack>
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'500'}
                  color="#1B1C1D"
                  flex={1}
                >
                  {moment(hospital?.createdAt).format('MM/DD/YYYY') ||
                    '2/27/2024'}
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'500'}
                  color="#1B1C1D"
                  flex={1}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Button
                    bgGradient="linear(to-r, #295377, #208C74)"
                    color="#E9E9E9"
                    fontWeight={'500'}
                    fontSize={'15px'}
                    borderRadius={'21px'}
                    type="submit"
                    border={'2px solid transparent'}
                    _hover={{
                      bgGradient: 'none',
                      borderColor: 'primaryGreen.200',
                      color: 'primaryGreen.200',
                    }}
                  >
                    View Certificate
                  </Button>{' '}
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'500'}
                  color="#1B1C1D"
                  flex={1}
                >
                  <Badge
                    w="81px"
                    height="28px"
                    display={'flex'}
                    alignItems={'center'}
                    borderRadius={'50px'}
                    justifyContent={'center'}
                    bg="#F3D893"
                    color={'#C79209'}
                  >
                    Pending
                  </Badge>
                </Box>
                {/* <Box
                  fontSize="15px"
                  fontWeight={'500'}
                  color="#1B1C1D"
                  flex={1}
                >
                  <HStack>
                    {' '}
                    <IconButton
                      isRound={true}
                      variant="solid"
                      colorScheme="#D9D9D9"
                      w={'40px'}
                      h={'40px'}
                      fontSize="16px"
                      // bgGradient="linear(to-r, #295377, #208C74)"
                      bg={'#D9D9D9'}
                      aria-label="Done"
                      icon={<CloseIcon fontSize="16px" color="#1B1C1D" />}
                    />
                    <IconButton
                      isRound={true}
                      variant="solid"
                      colorScheme="#F2F2F2"
                      w={'40px'}
                      h={'40px'}
                      fontSize="16px"
                      bgGradient="linear(to-r, #295377, #208C74)"
                      aria-label="Done"
                      icon={<CheckIcon fontSize="16px" color="#F2F2F2" />}
                    />
                  </HStack>
                </Box> */}
              </HStack>
            ))}
          </Stack>
        ) : type === 'patient' ? (
          <Stack display={'flex'} flexDirection={'column'} gap={2}>
            <HStack display={'flex'} w="100%" px={4}>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                NO.
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                ID
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Patient Type
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Appointment Date
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Price
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Status
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Action
              </Box>
            </HStack>
            {data?.appointmentRequest?.slice(-5)?.map((item, index) => {
              return (
                <HStack
                  display={'flex'}
                  w="100%"
                  h="70px"
                  bg={'#fff'}
                  shadow={'0px 16px 38px 0px #0000000F'}
                  px={4}
                  borderRadius={'16px'}
                >
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    {index + 1}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    #d44vdsQ
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    {item?.type}
                  </Box>

                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    {moment(item?.startTime).format('MM/DD/YYYY') ||
                      '2/27/2024'}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    $ {item?.assignedDoctorCharges || '20'}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    <Badge
                      w="81px"
                      height="28px"
                      display={'flex'}
                      alignItems={'center'}
                      borderRadius={'50px'}
                      justifyContent={'center'}
                      bg="#F3D893"
                      color={'#C79209'}
                    >
                      {item?.status}
                    </Badge>
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    <HStack>
                      {' '}
                      <IconButton
                        isRound={true}
                        variant="solid"
                        colorScheme="#F2F2F2"
                        w={'40px'}
                        h={'40px'}
                        fontSize="16px"
                        bgGradient="linear(to-r, #295377, #208C74)"
                        aria-label="Done"
                        icon={<ViewIcon fontSize="16px" color="#F2F2F2" />}
                        as={Link}
                        to={`/dashboard/appointments/appointment-details/${item._id}`}
                      />
                    </HStack>
                  </Box>
                </HStack>
              );
            })}
          </Stack>
        ) : (
          <Stack display={'flex'} flexDirection={'column'} gap={2}>
            <HStack display={'flex'} w="100%" px={4}>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                NO.
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                ID
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={2}>
                Patient Name
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Age
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={2}>
                Appointment Date
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                patient Type
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Status
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Action
              </Box>
            </HStack>
            {data?.appointmentRequest?.slice(-5)?.map((item, index) => {
              return (
                <HStack
                  display={'flex'}
                  w="100%"
                  h="70px"
                  bg={'#fff'}
                  shadow={'0px 16px 38px 0px #0000000F'}
                  px={4}
                  borderRadius={'16px'}
                >
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    {index + 1}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    #d44vdsQ
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={2}
                  >
                    <VStack
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'flex-start'}
                      gap={2}
                    >
                      <Avatar
                        size="sm"
                        name="Kent Dodds"
                        src={`${imgUrl}/${item?.patientPicture}`}
                      />
                      <Text>{item?.patientName}</Text>
                    </VStack>
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    {item?.patientage}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={2}
                  >
                    {moment(item?.startTime).format('MM/DD/YYYY') ||
                      '2/27/2024'}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    {item?.type}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    <Badge
                      w="81px"
                      height="28px"
                      display={'flex'}
                      alignItems={'center'}
                      borderRadius={'50px'}
                      justifyContent={'center'}
                      bg="#F3D893"
                      color={'#C79209'}
                    >
                      {item?.status}
                    </Badge>
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    <HStack>
                      {' '}
                      {/* <IconButton
                        isRound={true}
                        variant="solid"
                        colorScheme="#D9D9D9"
                        w={'40px'}
                        h={'40px'}
                        fontSize="16px"
                        // bgGradient="linear(to-r, #295377, #208C74)"
                        bg={'#D9D9D9'}
                        aria-label="Done"
                        icon={<EditIcon fontSize="16px" color="#1B1C1D" />}
                      /> */}
                      <IconButton
                        isRound={true}
                        variant="solid"
                        colorScheme="#F2F2F2"
                        w={'40px'}
                        h={'40px'}
                        fontSize="16px"
                        bgGradient="linear(to-r, #295377, #208C74)"
                        aria-label="Done"
                        icon={<ViewIcon fontSize="16px" color="#F2F2F2" />}
                        as={Link}
                        to={`/dashboard/appointments/appointment-details/${item._id}`}
                      />
                    </HStack>
                  </Box>
                </HStack>
              );
            })}
          </Stack>
        )}
      </Stack>
      {/* Table */}
    </Stack>
  );
}
