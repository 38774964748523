import React, { useEffect, useState } from 'react';

import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
  Spinner,
  Image,
  Icon,
} from '@chakra-ui/react';
// import Dentist from '../../../assets/images/dashboard/dentist.png';
import { FaStar } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa6';
import { CiLocationOn } from 'react-icons/ci';
import { GET } from '../../../../utilities/ApiProvider';
import { imgUrl } from '../../../../utilities/config';
import { Link } from 'react-router-dom';
import AvailableHospitals from '../../Hospital/AvailableHospitals';

function PatientBedBooking() {
  const [hospitals, setHospitals] = useState(null);
  // useEffect(() => {
  //   async function fetchHospitals() {
  //     try {
  //       const response = await GET('/web/patient/hospital');
  //       console.log(response);
  //       if (response.status === 200) setHospitals(response?.data?.serviceData);
  //     } catch (error) {
  //       console.log('Error while fetching hospitals', error);
  //     }
  //   }
  //   fetchHospitals();
  // }, []);
  // if (!hospitals) {
  //   return (
  //     <Stack w="100%" h="100vh" justifyContent={'center'} alignItems={'center'}>
  //       <Spinner />
  //     </Stack>
  //   );
  // }
  return (
    <Stack>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Stack>
          <Heading
            display={'flex'}
            alignItems={'end'}
            fontSize={'36px'}
            fontWeight={'500'}
          >
            Bed Booking /{' '}
            <Text color={'#208C74'} fontSize={'20px'}>
              Select Hospital
            </Text>
          </Heading>
          {/* <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all services...Check Now!
          </Text> */}
        </Stack>
      </HStack>

      <AvailableHospitals />
    </Stack>
  );
}

export default PatientBedBooking;
