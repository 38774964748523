import React from 'react';
import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Stack,
  WrapItem,
  Text,
  HStack,
  Avatar,
  useToast,
  Spinner,
  Grid,
} from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';

function Appointments() {
  const { status } = useParams();
  return (
    <Stack>
      <Stack pb={6}>
        <Heading
          textTransform={'capitalize'}
          fontSize={'36px'}
          fontWeight={'500'}
        >
          {status} / <span>Appointments</span>
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text>
      </Stack>
      <Grid gridTemplateColumns="repeat(2, 1fr)" gap={6}>
        <Box
          shadow={'lg'}
          w="100%"
          borderRadius={'16px'}
          bg={'#fff'}
          p={5}
          // height={type === 'practitioner' ? '245px' : '300px'}
        >
          <HStack justifyContent={'space-between'}>
            <HStack alignItems={'center'}>
              <WrapItem>
                <Avatar
                  size="lg"
                  name="Prosper Otemuyiwa"
                  src={'https://bit.ly/prosper-baba'}
                />{' '}
              </WrapItem>
              <Stack justifyContent={'center'}>
                <Heading fontWeight={'400'} fontSize={'22px'}>
                  {'Adam Knight'}
                </Heading>
                <Text
                  lineHeight={0}
                  fontSize={'16px'}
                  fontWeight={'400'}
                  color={'#75767A'}
                >
                  #d44vdsQ
                </Text>
              </Stack>
            </HStack>
          </HStack>
          <Stack gap={4} mt={4}>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Stack flex={1} margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  Appointment Date
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  {'2/27/2024'}
                </Text>
              </Stack>

              <Stack flex={1} margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  Patient Type
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  {'Consultation'}
                </Text>
              </Stack>
              <Stack flex={1}>
                <Text fontSize={'16px'} fontWeight={'500'} color={'#75767A'}>
                  Assigned Doctor
                </Text>

                <HStack marginTop={'2px'}>
                  <Avatar
                    size="sm"
                    name="Kent Dodds"
                    src={'https://bit.ly/kent-c-dodds'}
                  />{' '}
                  <Text lineHeight={'5px'} fontWeight={'400'} color={'#1B1C1D'}>
                    {'Adam Knight'}
                  </Text>
                </HStack>
              </Stack>
            </Stack>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Stack flex={1} margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  $Price
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  ${'120.00'}
                </Text>
              </Stack>

              <Stack flex={1} margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  Time
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  {' 3 - 30 PM'}
                </Text>
              </Stack>

              <Stack flex={1} margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  Patient Age
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  {'27'}
                </Text>
              </Stack>
            </Stack>
            <Stack>
              <Button
                as={Link}
                to={
                  '/dashboard/appointments/appointment-details/664496f4a34cb499359d5037'
                }
                w="100%"
                bgGradient="linear(to-r, #295377, #208C74)"
                color="#E9E9E9"
                fontWeight={'500'}
                fontSize={'15px'}
                borderRadius={'21px'}
              >
                View Appointment
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Box
          shadow={'lg'}
          w="100%"
          borderRadius={'16px'}
          bg={'#fff'}
          p={5}
          // height={type === 'practitioner' ? '245px' : '300px'}
        >
          <HStack justifyContent={'space-between'}>
            <HStack alignItems={'center'}>
              <WrapItem>
                <Avatar
                  size="lg"
                  name="Prosper Otemuyiwa"
                  src={'https://bit.ly/prosper-baba'}
                />{' '}
              </WrapItem>
              <Stack justifyContent={'center'}>
                <Heading fontWeight={'400'} fontSize={'22px'}>
                  {'Adam Knight'}
                </Heading>
                <Text
                  lineHeight={0}
                  fontSize={'16px'}
                  fontWeight={'400'}
                  color={'#75767A'}
                >
                  #d44vdsQ
                </Text>
              </Stack>
            </HStack>
          </HStack>
          <Stack gap={4} mt={4}>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Stack flex={1} margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  Appointment Date
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  {'2/27/2024'}
                </Text>
              </Stack>

              <Stack flex={1} margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  Patient Type
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  {'Consultation'}
                </Text>
              </Stack>
              <Stack flex={1}>
                <Text fontSize={'16px'} fontWeight={'500'} color={'#75767A'}>
                  Assigned Doctor
                </Text>

                <HStack marginTop={'2px'}>
                  <Avatar
                    size="sm"
                    name="Kent Dodds"
                    src={'https://bit.ly/kent-c-dodds'}
                  />{' '}
                  <Text lineHeight={'5px'} fontWeight={'400'} color={'#1B1C1D'}>
                    {'Adam Knight'}
                  </Text>
                </HStack>
              </Stack>
            </Stack>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Stack flex={1} margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  $Price
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  ${'120.00'}
                </Text>
              </Stack>

              <Stack flex={1} margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  Time
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  {' 3 - 30 PM'}
                </Text>
              </Stack>

              <Stack flex={1} margin={'0 !important'}>
                <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                  Patient Age
                </Text>
                <Text
                  lineHeight={'10px'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'#1B1C1D'}
                >
                  {'27'}
                </Text>
              </Stack>
            </Stack>
            <Stack>
              <Button
                as={Link}
                to={
                  '/dashboard/appointments/appointment-details/664496f4a34cb499359d5037'
                }
                w="100%"
                bgGradient="linear(to-r, #295377, #208C74)"
                color="#E9E9E9"
                fontWeight={'500'}
                cls
                fontSize={'15px'}
                borderRadius={'21px'}
              >
                View Appointment
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Stack>
  );
}

export default Appointments;
