import React from 'react';
import { Outlet } from 'react-router-dom';
import MainDashboard from '../components/DashNav/MainDashboard';
// import MainDashboard from '../../../components/DashNav/MainDashboard';
import DashboardHeader from '../components/Header/DashboardHeader';
import {Stack} from '@chakra-ui/react';

function Layout() {
  return (
    <Stack overflowY="hidden">
      <MainDashboard>
        <DashboardHeader />
        <Stack
          overflowY="auto"
          margin={'0 !important'}
          gap={8}
          borderRadius={'30px 0 0 0'}
          py={10}
          px={6}
          bg={'#FBFBFB'}
          height={'100%'}
          bgPos={'-32px -100px'}
        >
          <Outlet />
        </Stack>
      </MainDashboard>
    </Stack>
  );
}

export default Layout;
