import React, { useEffect, useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import { Link, useParams } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { GrDocumentText } from 'react-icons/gr';

function FormRequest() {
  const [services, setServices] = useState(null);
  const user = useSelector(state => state.user.value);
  const { id } = useParams();
  async function fetchServices() {
    try {
      const response = await GET('/hospital/form-requests');
      if (response.status === 200) {
        setServices(response.data);
        // console.log('services:', JSON.parse(response?.data[2]?.completeData));
      }
    } catch (error) {
      console.error('Error fetching form services:', error);
    }
  }
  useEffect(() => {
    fetchServices();
  }, []);
  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Form Requests
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all services...Check Now!
        </Text>
        <Text fontSize={'20px'} fontWeight={'500'}>
          You've 5 services
        </Text>
      </Stack>
      {/*Heading  */}
      {!services ? (
        <Spinner />
      ) : (
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          {services.map((item, ind) => (
            <Box
              // border={'2px solid #75767A'}
              shadow={'0px 16.32px 38.75px 0px #0000000F'}
              borderRadius={'17px'}
              p={{ base: '15px 20px', xl: '25px 40px' }}
              display={'flex'}
              key={item._id}
              // flexDir={'column'}
              // alignItems={'center'}
              // justifyContent={'center'}
              role="group"
              transition={'0.3s'}
              _hover={{
                borderColor: 'primaryGreen.200',
              }}
            >
              <Stack flex={2}>
                <Box
                  w={'54px'}
                  h={'54px'}
                  bgColor={'#E2E2E2'}
                  display={'flex'}
                  alignItems={'center'}
                  transition={'0.3s'}
                  borderRadius={'12px'}
                  mb="5px"
                  justifyContent={'center'}
                  _groupHover={{
                    bgColor: 'primaryGreen.200',
                    color: '#fff',
                  }}
                >
                  <Icon
                    _groupHover={{
                      color: '#fff',
                    }}
                    transition={'0.3s'}
                    fontSize={'30px'}
                    // fontSize={{ base: '26px', xl: '52px' }}
                    //   as={`${imgUrl}/${val.icon}`}
                    as={GrDocumentText}
                  />
                </Box>
                <Heading w={'80%'} fontSize={'18px'} fontWeight={'500'}>
                  {item.name}
                </Heading>
              </Stack>
              <Stack flex={1} alignItems={'end'} justifyContent={'center'}>
                {user?.type === 'superadmin' ? (
                  <Button
                    as={Link}
                    to={`/dashboard/form-request/hospitals/${item._id}`}
                    bgGradient={'linear(to-r, #295377, #208C74)'}
                    fontSize={{ base: '12px', xl: '14px' }}
                    fontWeight={400}
                    color={'#fff'}
                    borderRadius={'25px'}
                    w="122px"
                    h="34px"
                    border={'2px solid transparent'}
                    _hover={{
                      bgGradient: 'none',
                      borderColor: 'primaryGreen.200',
                      color: 'primaryGreen.200',
                    }}
                  >
                    View Details
                  </Button>
                ) : user?.type === 'hospital' ? (
                  <Button
                    as={Link}
                    to={
                      !id
                        ? `${item.url}/${item._id}`
                        : `/dashboard/form-request/hospitals/form/${item._id}/${user?.hospital}`
                    }
                    bgGradient={'linear(to-r, #295377, #208C74)'}
                    fontSize={{ base: '12px', xl: '14px' }}
                    fontWeight={400}
                    color={'#fff'}
                    borderRadius={'25px'}
                    w="122px"
                    h="34px"
                    border={'2px solid transparent'}
                    _hover={{
                      bgGradient: 'none',
                      borderColor: 'primaryGreen.200',
                      color: 'primaryGreen.200',
                    }}
                  >
                    View Details
                  </Button>
                ) : (
                  <Button
                    as={Link}
                    to={`${item.url}/${item._id}`}
                    bgGradient={'linear(to-r, #295377, #208C74)'}
                    fontSize={{ base: '12px', xl: '14px' }}
                    fontWeight={400}
                    color={'#fff'}
                    borderRadius={'25px'}
                    w="122px"
                    h="34px"
                    border={'2px solid transparent'}
                    _hover={{
                      bgGradient: 'none',
                      borderColor: 'primaryGreen.200',
                      color: 'primaryGreen.200',
                    }}
                  >
                    Form Request
                  </Button>
                )}
              </Stack>
            </Box>
          ))}
        </Grid>
      )}
    </Stack>
  );
}

export default FormRequest;
