import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  VStack,
  Input,
  FormLabel,
  useToast,
} from '@chakra-ui/react';

function RequestElectronicDetails({ formValue }) {
  return (
    <Stack>
      <form className="applyForm">
        <Text fontWeight={'500'} fontSize={'20px'}>
          Beneficiary’s Details:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              name="beneficiaryName"
              type="text"
              value={formValue.beneficiaryName}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Mobile No</FormLabel>
            <Input
              required
              name="beneficiaryNumber"
              type="text"
              value={formValue.beneficiaryNumber}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              name="beneficiaryEmail"
              type="text"
              value={formValue.beneficiaryEmail}
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Requested Scientific Material:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Title</FormLabel>
            <Input
              required
              name="scientificTitle"
              type="text"
              value={formValue.scientificTitle}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Key Words</FormLabel>
            <Input
              required
              name="scientificKeywords"
              value={formValue.scientificKeywords}
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Search from Year to Year</FormLabel>
            <Input
              required
              name="scientificYear"
              type="text"
              value={formValue.scientificYear}
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Articles
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Name of Magazine</FormLabel>
            <Input
              required
              name="articleName"
              type="text"
              value={formValue.articleName}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Title of Article</FormLabel>
            <Input
              required
              name="articleTitle"
              type="text"
              value={formValue.articleTitle}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Year</FormLabel>
            <Input
              required
              name="articleYear"
              type="text"
              value={formValue.articleYear}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Issue No.</FormLabel>
            <Input
              required
              name="articleIssue"
              type="text"
              value={formValue.articleIssue}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Author</FormLabel>
            <Input
              required
              name="articleAuthor"
              type="text"
              value={formValue.articleAuthor}
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Electronic Book
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Title</FormLabel>
            <Input
              required
              name="electronicTitle"
              type="text"
              value={formValue.electronicTitle}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Author</FormLabel>
            <Input
              required
              name="electronicAuthor"
              type="text"
              value={formValue.electronicAuthor}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Publisher</FormLabel>
            <Input
              required
              name="electronicPublisher"
              type="text"
              value={formValue.electronicPublisher}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Year of Publication</FormLabel>
            <Input
              required
              name="electronicYear"
              type="text"
              value={formValue.electronicYear}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Print No.</FormLabel>
            <Input
              required
              name="electronicPrintNo"
              type="text"
              value={formValue.electronicPrintNo}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>ISBN</FormLabel>
            <Input
              required
              name="electronicISBN"
              type="text"
              value={formValue.electronicISBN}
              placeholder="Enter Name"
            />
          </Box>
        </Stack>

        <Text fontWeight={'500'} fontSize={'20px'}>
          Medical Magazine
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              name="medicalName"
              type="text"
              value={formValue.medicalName}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Issue No.</FormLabel>
            <Input
              required
              name="medicalIssueNo"
              type="text"
              value={formValue.medicalIssueNo}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Issue Date</FormLabel>
            <Input
              required
              name="medicalIssueDate"
              type="text"
              value={formValue.medicalIssueDate}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Date of Published</FormLabel>
            <Input
              required
              name="medicalDatePublished"
              type="text"
              value={formValue.medicalDatePublished}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Year Published</FormLabel>
            <Input
              required
              name="medicalYearPublished"
              type="text"
              value={formValue.medicalYearPublished}
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
      </form>
    </Stack>
  );
}

export default RequestElectronicDetails;
