import React from 'react';
import Header from '../../components/Website/Header';
import Footer from '../../components/Website/Footer';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import ExtendedFaq from '../../components/Website/faq/ExtendedFaq';

const FAQ = () => {
  return (
    <>
      <Header />
      <MiniBanner
        subHeading="Got Questions? We have answers"
        mainHeading="FAQ's"
        // content="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      />
      <ExtendedFaq />

      <Footer />
    </>
  );
};

export default FAQ;
