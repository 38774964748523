import React, { useEffect, useState } from 'react';
import {
  Heading,
  Stack,
  Text,
  Box,
  FormLabel,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react';
import { POST } from '../../../utilities/ApiProvider';

function AddTraining() {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const form = new FormData(e.target);
      const response = await POST('/admin/study-programs', form);
      if (response.status == 200 || response.status == 'success') {
        toast({
          title: 'Training Program Added Successfully',
          position: 'top-right',
          isClosable: true,
          duration: 3000,
          status: 'success',
        });
      } else {
        toast({
          title: 'Training Program Not Added',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
          status: 'error',
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Add Medical Education Training Program
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text>
      </Stack>
      <form className="applyForm" onSubmit={handleSubmit}>
        <Box w="100%">
          <FormLabel>Program Name</FormLabel>
          <Input name="name" type="text" placeholder="Enter Program Name" />
        </Box>
        <Box w="100%">
          <FormLabel>Program Type</FormLabel>
          <Input name="type" type="text" placeholder="Enter Program Type" />
        </Box>
        <Box w="100%">
          <FormLabel>Program Duration</FormLabel>
          <Input
            name="duration"
            type="text"
            placeholder="Enter Program Duration"
          />
        </Box>
        <Box w="100%">
          <FormLabel>Program Location</FormLabel>
          <Input
            name="location"
            type="text"
            placeholder="Enter Program Location"
          />
        </Box>
        <Stack mt={10}>
          <Button
            mt={5}
            isLoading={loading}
            width={'200px'}
            bgGradient="linear(to-r, #295377, #208C74)"
            color="#E9E9E9"
            fontWeight={'500'}
            type="submit"
            fontSize={'15px'}
            borderRadius={'21px'}
            border={'2px solid transparent'}
            _hover={{
              bgGradient: 'none',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
          >
            Add Training Program
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

export default AddTraining;
