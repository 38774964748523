import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  useToast,
  Spinner,
  Grid,
  Avatar,
  VStack,
} from '@chakra-ui/react';
import Hospital from '../../../assets/images/website/hospital.png';
import { register } from 'swiper/element';
import { StarIcon } from '@chakra-ui/icons';
import { GET } from '../../../utilities/ApiProvider';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { imgUrl } from '../../../utilities/config';
import { GrDocumentText } from 'react-icons/gr';
function FormRequestHospitalList() {
  const [data, setData] = useState(null);
  const toast = useToast();
  const { formId, id } = useParams();
  console.log('formId', formId, id);

  useEffect(() => {
    const fetchHospitalsFormList = async () => {
      try {
        const response = await GET(
          `/admin/form-requests/${formId}?hospital=${id}`
        );
        console.log('hospitals', response);
        if (response.status === 200) {
          console.log('response', response.data);
          setData(response.data);
        } else {
          handleFetchError();
        }
      } catch (error) {
        handleFetchError(error);
      }
    };

    fetchHospitalsFormList();
  }, []);

  const handleFetchError = (error = null) => {
    if (error) {
      console.error('While Fetching Dashboard', error);
    }
    toast({
      title: 'Error',
      description: 'Something went wrong while fetching the Dashboard.',
      status: 'error',
      duration: 2000,
      position: 'top-right',
      isClosable: true,
    });
  };
  if (!data) {
    return (
      <Stack h={'100vh'} justifyContent={'center'} alignItems={'center'}>
        <Spinner />
      </Stack>
    );
  }
  return (
    <Stack>
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'center'}
          fontSize={'28px'}
          fontWeight={'500'}
        >
          {data?.service?.name} {''}/
          <Text
            mt={'8px'}
            ml={'2px'}
            color={'#208C74'}
            fontSize={'16px'}
            fontWeight={'500'}
          >
            Hospitals
          </Text>
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text>
      </Stack>

      <Stack pt={4}>
        {data?.requests?.length === 0 ? (
          <Stack>
            <Heading fontSize={'24px'}>No Hospitals Requests Found</Heading>
          </Stack>
        ) : null}
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          {data?.requests?.map((item, index) => (
            <Box
              shadow={'0px 16.32px 38.75px 0px #0000000F'}
              borderRadius={'17px'}
              p={{ base: '15px 20px', xl: '25px 40px' }}
              display={'flex'}
              key={index}
              alignItems={'center'}
              role="group"
              transition={'0.3s'}
              _hover={{
                borderColor: 'primaryGreen.200',
              }}
            >
              <Stack flex={2} gap={2}>
                <Stack
                  direction={'row'}
                  flexWrap={'wrap'}
                  alignItems={'center'}
                >
                  <Box
                    w={'54px'}
                    h={'54px'}
                    bgColor={'#E2E2E2'}
                    display={'flex'}
                    alignItems={'center'}
                    transition={'0.3s'}
                    borderRadius={'12px'}
                    mb="5px"
                    justifyContent={'center'}
                    _groupHover={{
                      bgColor: 'primaryGreen.200',
                    }}
                  >
                    <Icon
                      _groupHover={{
                        color: '#fff',
                      }}
                      transition={'0.3s'}
                      fontSize={'30px'}
                      as={GrDocumentText}
                    />
                  </Box>
                  <Box>
                    <Heading fontSize={'22px'} fontWeight={'500'}>
                      {item.name}
                    </Heading>
                  </Box>
                </Stack>
                <Button
                  as={Link}
                  to={`/dashboard/form-request/detail/${item._id}`}
                  bgGradient={'linear(to-r, #295377, #208C74)'}
                  fontSize={{ base: '12px', xl: '14px' }}
                  fontWeight={400}
                  color={'#fff'}
                  borderRadius={'25px'}
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                >
                  View Details
                </Button>
              </Stack>
            </Box>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}

export default FormRequestHospitalList;
