import {
  Box,
  Button,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  Link,
  useToast,
} from '@chakra-ui/react';
import Logo from '../../assets/images/website/eMedst Logo.svg';
import authBg from '../../assets/images/website/authBg.png';
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { Link as ReactLink, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { POST, GET } from '../../utilities/ApiProvider';
import { useDispatch } from 'react-redux';
import { loadUser } from '../../reducers/useReducers';

export default function Login() {
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      const queryParams = new URLSearchParams(location.search);
      const tokenValue = queryParams.get('token');
      if (tokenValue) {
        await verifyPatient(tokenValue);
      }
    };

    verifyToken();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const form = new FormData(e.target);

      const response = await POST('/authentication/login', form);
      if (response.status === 200) {
        localStorage.setItem('emediiUser', JSON.stringify(response?.data));
        console.log(response?.data, 'arham');
        dispatch(loadUser(response?.data));
        if (response?.data?.type === 'doctor_web') {
          navigate('/dashboard/form-request');
        } else {
          navigate('/dashboard');
        }
        toast({
          description:
            response.status === 200 ? 'Login successful!' : response?.message,
          status: response.status === 200 ? 'success' : 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
      } else {
        toast({
          description: response?.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
      }
    } catch (err) {
      toast({
        description: err?.message,
        status: 'error',
        isClosable: true,
        position: 'top-right',
        duration: 3000,
      });
    }
    setIsLoading(false);
  };
  const verifyPatient = async _token => {
    try {
      const response = await GET(
        '/authentication/verify-patient?token=' + _token
      );
      console.log(response);
      if (response.status === 200) {
        toast({
          description: 'Patient verified successfully',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        navigate('/login');
      } else {
        toast({
          description: 'Patient not verified',
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Stack position={'relative'} height={'100vh'} p="0">
      <Box
        maxW={{ base: '100%', lg: '490px', xl: '600px' }}
        p={{
          base: '120px 25px',
          lg: '60px 0 60px 40px',
          xl: '60px 0 60px 120px',
        }}
      >
        <Link as={ReactLink} to="/">
          <Button
            bgColor={'transparent'}
            _hover={{ bgColor: 'transparent' }}
            _active={{ bgColor: 'transparent' }}
            mb="60px"
            fontWeight={'500'}
          >
            <Icon as={FaArrowLeft} mr="10px" /> Back
          </Button>
        </Link>
        <Image mb="40px" w="150px" src={Logo} />
        <Heading
          fontWeight={'500'}
          color={'primaryBlack.100'}
          fontSize={'32px'}
          pb="20px"
        >
          Login to your account
        </Heading>
        <Text
          fontWeight={'500'}
          color={'primaryGray.100'}
          fontSize={'14px'}
          pb="20px"
        >
          Enter your email and password to login
        </Text>
        <form className="applyForm" onSubmit={handleSubmit}>
          <Box mb="10px">
            <FormLabel>Email Address*</FormLabel>
            <Input name="email" placeholder="Enter here" required />
          </Box>
          <Box>
            <FormLabel>Password*</FormLabel>
            <Box position={'relative'}>
              <Input
                required
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter here"
              />
              <Button
                w={'40px !important'}
                fontSize={'18px !important'}
                border={'none !important'}
                zIndex={1}
                bgColor={'transparent'}
                _hover={{ bgColor: 'transparent' }}
                _active={{ bgColor: 'transparent' }}
                onClick={() => setShowPassword(!showPassword)}
                position={'absolute'}
                right={'0'}
              >
                {!showPassword ? (
                  <Icon as={FaRegEyeSlash} />
                ) : (
                  <Icon
                    fontSize={'18px'}
                    color={'primaryGray.100'}
                    as={FaRegEye}
                  />
                )}
              </Button>
            </Box>
          </Box>
          <Box display={'flex'} w="100%" justifyContent={'flex-end'}>
            <Link
              as={ReactLink}
              to="/forgot-password"
              color={'primaryGreen.200'}
              borderBottom={'1px solid'}
              borderColor={'primaryGreen.200'}
              fontSize={'14px'}
            >
              Forgot Password?
            </Link>
          </Box>
          <Button
            isLoading={isLoading}
            my="20px"
            bgGradient={'linear(to-r, #295377, #208C74)'}
            fontSize={'14px'}
            fontWeight={500}
            color={'#fff'}
            borderRadius={'25px'}
            w="100%"
            h="45px"
            textTransform={'uppercase'}
            border={'1px solid primaryGreen.200'}
            _hover={{
              bgGradient: 'none',
              border: '2px solid',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
            type="submit"
          >
            Log in
          </Button>
        </form>

        <Text textAlign={'center'} color={'primaryGray.100'} fontSize={'14px'}>
          Don't have an account?
          <Link
            as={ReactLink}
            to="/register"
            color={'primaryGreen.200'}
            borderBottom={'1px solid'}
            borderColor={'primaryGreen.200'}
            fontSize={'14px'}
            ml="5px"
          >
            Sign Up
          </Link>
        </Text>
      </Box>
      <Image
        display={{ base: 'none', lg: 'block' }}
        src={authBg}
        w={{ lg: '505px', xl: '605px' }}
        h="100%"
        position={'absolute'}
        top="0"
        right="0"
        mt="0 !important"
      />
    </Stack>
  );
}
