import React, { useEffect, useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import { PhoneInput } from 'react-international-phone';
import {
  Stack,
  Heading,
  Text,
  Box,
  Button,
  FormLabel,
  Input,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  Textarea,
  Image,
  useToast,
} from '@chakra-ui/react';
import DashboardHeader from '../../../components/Header/DashboardHeader';

import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { FaRegEye, FaRegEyeSlash, FaRegUser } from 'react-icons/fa';
import { BiCamera } from 'react-icons/bi';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { AiOutlineLock } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { POST, PUT } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import { loadUser } from '../../../reducers/useReducers';

export default function Profile() {
  const user = useSelector(state => state.user.value);
  const [type, setType] = useState(user?.type);
  const [selectedFile, setSelectedFile] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  useEffect(() => {
    setType(user?.type);
  }, [user]);
  console.log(user);
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      if (selectedFile !== null) {
        form.append('picture', selectedFile);
      }
      const formDataEntries = Array.from(form.entries());
      console.log(formDataEntries.map(([key, value]) => ({ [key]: value })));
      const response = await PUT('/web/profile', form);
      if (response.status === 200) {
        setLoading(false);
        setSelectedFile(null);
        console.log(response.data);
        localStorage.setItem('emediiUser', JSON.stringify(response.data));
        dispatch(loadUser(response.data));

        toast({
          description: 'Profile Updated SucessFully!!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (err) {
      setLoading(false);
      console.log('Error while changing the profile information', err);
    }
  };
  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleButtonClick = () => {
    document.getElementById('file-input').click();
  };

  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          My Profile
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is your profile info, Check Now!
        </Text>
      </Stack>
      <Box position={'relative'} w="fit-content">
        <Box
          bgColor={'#208C744D'}
          borderRadius={'100%'}
          overflow={'hidden'}
          w="150px"
          h="150px"
        >
          {/* <Icon
                borderRadius={'100%'}
                w={'150px'}
                h={'150px'}
                p="25px"
                as={FaRegUser}
                color={'primaryGreen.100'}
              /> */}
          <Image w={'150px'} h={'150px'} src={`${imgUrl}/${user?.picture}`} />
        </Box>
        <Input
          name="photo"
          type="file"
          id="file-input"
          required
          display="none"
          onChange={handleFileChange}
        />
        <Icon
          cursor={'pointer'}
          position={'absolute'}
          borderRadius={'100%'}
          w={'35px'}
          h={'35px'}
          p="5px"
          as={BiCamera}
          color={'#fff'}
          bgGradient={'linear(to-r, #295377, #208C74)'}
          right="0"
          bottom="15px"
          onClick={handleButtonClick}
        />
      </Box>
      {/* <Heading fontSize={'24px'} color={'primaryBlack.100'}>
        Dr. Joe West /{' '}
        <Text
          as="span"
          fontWeight={'500'}
          color={'primaryGreen.200'}
          fontSize={'14px'}
        >
          Dental Specialist
        </Text>
      </Heading> */}
      <form onSubmit={handleSubmit} className="applyForm" id="registerForm">
        <Stack
          direction={'row'}
          spacing={0}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <Box w={{ base: '100%', xl: '48%' }}>
            <FormLabel>First Name</FormLabel>
            <Input
              name="firstName"
              defaultValue={user?.firstName}
              placeholder="Dr. Joel"
            />
          </Box>
          <Box w={{ base: '100%', xl: '48%' }}>
            <FormLabel>Last Name</FormLabel>
            <Input
              name="lastName"
              defaultValue={user?.lastName}
              placeholder="West"
            />
          </Box>
          <Box w={{ base: '100%', xl: '48%' }}>
            <FormLabel>Phone No.*</FormLabel>
            <PhoneInput
              name="number"
              value={user?.number}
              containerStyle={{ background: 'none', border: 'none' }}
              inputStyle={{ background: 'none', border: 'none' }}
              dropdownStyle={{ background: 'none', border: 'none' }}
              id="phone-input"
              defaultCountry="sa"
              inputClass="custom-phone-input"
            />
          </Box>
          <Box w={{ base: '100%', xl: '48%' }}>
            <FormLabel>Email Address</FormLabel>
            <Input
              name="email"
              disabled={true}
              defaultValue={user?.email}
              placeholder="joel.west42@gmail.com"
            />
          </Box>
          <Box w={{ base: '100%', xl: '48%' }}>
            <FormLabel>Date of Birth</FormLabel>
            <Input
              name="dob"
              defaultValue={user?.dob?.split('T')[0]}
              placeholder="West"
              type="date"
            />
          </Box>
          <Box w={{ base: '100%', xl: '48%' }}>
            <FormLabel>Address</FormLabel>
            <Input
              name="address"
              defaultValue={user?.address}
              placeholder="King Fahad Rd, Al Bustan"
            />
          </Box>
        </Stack>
        {type === 'practitioner' ? (
          <Stack
            direction={'row'}
            spacing={0}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            <Box w={{ base: '100%', xl: '100%' }}>
              <FormLabel>About me</FormLabel>
              <Textarea
                name="about"
                resize={'none'}
                placeholder="Enter here"
              ></Textarea>
            </Box>
            <Heading
              w="100%"
              fontWeight={'500'}
              color={'primaryBlack.100'}
              fontSize={'26px'}
              pb="10px"
            >
              Academic Qualifications
            </Heading>
            <Box w="48%">
              <FormLabel>Institution*</FormLabel>
              <Input
                name="graduation"
                defaultValue={user?.doctor?.graduation}
                placeholder="Enter here"
              />
            </Box>
            <Box w="48%">
              <FormLabel>Degree*</FormLabel>
              <Input
                name="academicQualification"
                defaultValue={user?.doctor?.academicQualification}
                placeholder="Enter here"
              />
            </Box>
            <Box w="48%">
              <FormLabel>Specialization*</FormLabel>
              <Select placeholder="Enter here">
                <option>Special 1</option>
                <option>Special 2</option>
                <option>Special 3</option>
              </Select>
            </Box>
            <Box w="48%">
              <FormLabel>Year of Graduation*</FormLabel>
              <Input
                name="yearsOfExperience"
                type="number"
                defaultValue={user?.doctor?.yearsOfExperience}
                placeholder="Enter here"
              />
            </Box>
          </Stack>
        ) : null}
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Button
            bgGradient={'linear(to-r, #295377, #208C74)'}
            fontSize={'14px'}
            fontWeight={500}
            color={'#fff'}
            borderRadius={'25px'}
            px="40px"
            type="submit"
            isLoading={loading}
            h="45px"
            border={'2px solid transparent'}
            _hover={{
              bgGradient: 'none',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
          >
            Save Changes
          </Button>
          <Button
            onClick={onOpen}
            bgGradient={'linear(to-r, #295377, #208C74)'}
            fontSize={'14px'}
            fontWeight={500}
            color={'#fff'}
            borderRadius={'25px'}
            px="40px"
            h="45px"
            border={'2px solid transparent'}
            _hover={{
              bgGradient: 'none',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
          >
            Change Password
          </Button>
        </Stack>
      </form>

      <ChangePassModal user={user} isOpen={isOpen} onClose={onClose} />
    </Stack>
  );
}

export function ChangePassModal({ user, isOpen, onClose }) {
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();

  const handleSubmit = async e => {
    console.log(e);
    e.preventDefault();
    try {
      const form = new FormData(e.target);
      form.append('value', user.email);
      const response = await POST('/authentication/change-password', form, {
        Authorization: `Bearer ${user.verificationToken}`,
      });
      console.log(response);

      if (response.status === 200) {
        toast({
          description: 'Pasword changed successfully!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        onClose();
      }
    } catch (error) {
      console.log('Error while changing password', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent p="20px 40px">
        <ModalHeader fontWeight="600" fontSize={'24px'} textAlign="center">
          Change Password
        </ModalHeader>
        <form onSubmit={handleSubmit} className="applyForm">
          <ModalBody>
            <Box>
              <FormLabel mt="20px !important" fontWeight="600">
                New Password
              </FormLabel>
              <Box position={'relative'}>
                <Input
                  required
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter here"
                />
                <Button
                  w={'40px !important'}
                  fontSize={'18px !important'}
                  border={'none !important'}
                  zIndex={1}
                  bgColor={'transparent'}
                  _hover={{ bgColor: 'transparent' }}
                  _active={{ bgColor: 'transparent' }}
                  onClick={() => setShowPassword(!showPassword)}
                  position={'absolute'}
                  right={'0'}
                >
                  {!showPassword ? (
                    <Icon as={FaRegEyeSlash} />
                  ) : (
                    <Icon
                      fontSize={'18px'}
                      color={'primaryGray.100'}
                      as={FaRegEye}
                    />
                  )}
                </Button>
              </Box>
            </Box>
            <Box>
              <FormLabel mt="20px !important" fontWeight="600">
                Confirm Password
              </FormLabel>
              <Box position={'relative'}>
                <Input
                  required
                  name="confirmPassword"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter here"
                />
                <Button
                  w={'40px !important'}
                  fontSize={'18px !important'}
                  border={'none !important'}
                  zIndex={1}
                  bgColor={'transparent'}
                  _hover={{ bgColor: 'transparent' }}
                  _active={{ bgColor: 'transparent' }}
                  onClick={() => setShowPassword(!showPassword)}
                  position={'absolute'}
                  right={'0'}
                >
                  {!showPassword ? (
                    <Icon as={FaRegEyeSlash} />
                  ) : (
                    <Icon
                      fontSize={'18px'}
                      color={'primaryGray.100'}
                      as={FaRegEye}
                    />
                  )}
                </Button>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              bgGradient={'linear(to-r, #295377, #208C74)'}
              fontSize={'14px'}
              fontWeight={500}
              color={'#fff'}
              borderRadius={'25px'}
              px="40px"
              h="45px"
              border={'2px solid transparent'}
              _hover={{
                bgGradient: 'none',
                borderColor: 'primaryGreen.200',
                color: 'primaryGreen.200',
              }}
              type="submit"
            >
              Save & Continue
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
