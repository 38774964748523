import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Blog from '../../../assets/images/website/blogs/blog1.jpeg';
import { CiCalendar } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import moment from 'moment';
import { imgUrl } from '../../../utilities/config';

export default function Blogs() {
  const toast = useToast();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await GET('/admin/get-all-blogs');
        if (response.status === 200) {
          setData(response.data);
          console.log(response.data);
        } else {
          handleFetchError();
        }
      } catch (error) {
        handleFetchError(error);
      }
    };

    fetchBlogs();
  }, []);

  const handleFetchError = (error = null) => {
    if (error) {
      console.error("While Fetching Blogs", error);
    }
    toast({
      title: 'Error',
      description: 'Something went wrong while fetching the blogs.',
      status: 'error',
      duration: 2000,
      position: 'top-right',
      isClosable: true,
    });
  };
  return (
    <>
      <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Blog
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all blogs...Check Now!
          </Text>
        </Stack>
        <Button as={Link} to={'/dashboard/addblog'} bg={'var(--Gradient-1, linear-gradient(109deg, #295377 -22.91%, #208C74 93.87%))'} color={'#fff'} px={8} py={6} borderRadius={25}>Add New</Button>
      </Stack>
      <SimpleGrid columns={4} spacing={10}>
        {!data ? <Spinner /> : data.length > 0 ? (
         data.map((blog) => (
          <Stack boxShadow="xl" borderRadius={8} overflow={'hidden'}>
          <Image w='365px' h='260px' src={`${imgUrl}/${blog?.thumbnail}`} />
          <Stack py={6} px={4} gap={3} mt={'0 !important'}>
            <Text fontSize={'14px'}>
              <Icon
                verticalAlign={'middle'}
                fontSize={'16px'}
                as={CiCalendar}
              />{' '}
              {moment(blog?.createdAt).format('DD MMMM YYYY')}
              {/* 07 December 2023 */}
            </Text>
            <Heading color={'#1B1C1D'} fontSize={'17px'} fontWeight={600}>
              {blog?.title}
            </Heading>
            <Button as={Link} to={`/dashboard/blogdetails/${blog?._id}`} bg={'linear-gradient(180deg, #C2D4FF 37.5%, #F0F4FF 100%)'} borderRadius={'25px'} width={'max-content'} padding={'15px 25px'}>
              read more
            </Button>
          </Stack>
        </Stack>
         ))

        ) : <Heading color={'#1B1C1D'} fontSize={'17px'} fontWeight={600}>
          No Blogs Found </Heading> }
      </SimpleGrid>
    </>
  );
}
