import React, { useState } from 'react';
import MainDashboard from '../../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Select,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../../components/Header/DashboardHeader';
import Modal from '../../../../components/Dashboard/Modal';

function HeathPractitioner() {
  return (
    <Stack overflowY="hidden">
      <MainDashboard>
        <DashboardHeader />
        <Stack
          overflowY="auto"
          margin={'0 !important'}
          gap={3}
          borderRadius={'30px 0 0 0'}
          py={10}
          px={6}
          bg={'#FBFBFB'}
          height={'100%'}
          bgPos={'-32px -100px'}
        >
          <Stack>
            <Heading fontSize={'36px'} fontWeight={'500'}>
            Request Health Practitioner
            </Heading>
            <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
              Here is the all Health Practitioner...Check Now!
            </Text>
          </Stack>
          <form className='applyForm'>
            <Box w='50%'>
                <FormLabel>Practitioner Type</FormLabel>
                <Input name='practitionerType' placeholder='Enter here' />
            </Box>
            <Box w='50%'>
                <FormLabel>Qualifications</FormLabel>
                <Input name='practitionerType' placeholder='Bachelor of Surgery (MBBS)' />
            </Box>
            <Box w='50%'>
                <FormLabel>Requirements</FormLabel>
                <Textarea h='120px !important' size={'lg'} name='requirements' resize={'none'}  placeholder='Enter here' />
            </Box>
            <Button
                w="50%"
                mt={'15px'}
                bgGradient="linear(to-r, #295377, #208C74)"
                color="#E9E9E9"
                fontWeight={'500'}
                fontSize={'15px'}
                borderRadius={'21px'}
              >
                Submit
              </Button>
          </form>
        </Stack>
      </MainDashboard>
    </Stack>
  );
}

export default HeathPractitioner;
