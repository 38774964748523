import React, { useEffect, useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Select,
  Spinner,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import moment from 'moment';
import { imgUrl } from '../../../utilities/config';

function HospitalStaff() {
  const [hospitalStaff, setHospitalStaff] = useState(null);
  useEffect(() => {
    const getHospitalStaff = async () => {
      try {
        const response = await GET('/hospital/employee');
        if (response.status === 200) {
          setHospitalStaff(response.data.record);
        } else {
          setHospitalStaff([]);
        }
        console.log(response.data.record);
      } catch (error) {
        console.log(error);
      }
    };
    getHospitalStaff();
  }, []);

  return (
    <Stack>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Hospital Staff
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the list of staff in the hospital
          </Text>
        </Stack>
        <Stack>
          <Button
            as={Link}
            to={'/dashboard/add-hospital-staff'}
            w="100%"
            bgGradient="linear(to-r, #295377, #208C74)"
            color="#E9E9E9"
            fontWeight={'500'}
            fontSize={'15px'}
            borderRadius={'21px'}
            border={'2px solid transparent'}
            _hover={{
              bgGradient: 'none',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
          >
            Add New Staff
          </Button>
        </Stack>
      </HStack>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {!hospitalStaff ? (
          <Spinner />
        ) : (
          hospitalStaff?.map(staff => (
            <Box
              shadow={'0px 16px 38px 0px #0000000F'}
              bg={'#fff'}
              borderRadius={'16px'}
              p={4}
              display={'flex'}
              flexDirection={'column'}
              gap={4}
            >
              <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                px={4}
              >
                <HStack alignItems={'center'} lineHeight={'0.3rem'} gap={2}>
                  <Avatar
                    size={'lg'}
                    name={staff?.fullName || 'Alex John'}
                    src={
                      `${imgUrl}/${staff?.picture}` ||
                      'https://bit.ly/dan-abramov'
                    }
                  />
                  <Stack>
                    <Heading fontSize={'22px'} fontWeight={'500'}>
                      {staff?.fullName || 'Alex John'}
                    </Heading>
                    <Text
                      fontSize={'15px'}
                      color={'#75767A'}
                      fontWeight={'400'}
                    >
                      #d44vdsQ
                    </Text>
                  </Stack>
                </HStack>
              </Stack>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                px={4}
              >
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Date Added
                  </Text>
                  <Text fontSize={'16px'} fontWeight={'500'}>
                    {/* 2/27/2024 */}
                    {moment(staff?.createdAt).format('MM / DD / YYYY')}
                  </Text>
                </Stack>
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Role Type
                  </Text>
                  <Text
                    textTransform={'capitalize'}
                    fontSize={'16px'}
                    fontWeight={'500'}
                  >
                    {/* {hospital?.totalDoctors} */}
                    {/* Supervisor */}
                    {staff?.role?.name || 'Supervisor'}
                  </Text>
                </Stack>
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Age
                  </Text>
                  <Text fontSize={'16px'} fontWeight={'500'}>
                    {/* {hospital?.hospitalType} */}
                    27
                  </Text>
                </Stack>
              </Stack>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                py={2}
              >
                <Button
                  as={Link}
                  to={`/dashboard/staff-detail/${staff?._id}`}
                  //   as={Link}
                  //   to={`/dashboard/hospital-profile/${hospital?._id}`}
                  bgGradient={'linear(to-r, #295377, #208C74)'}
                  fontSize={'16px'}
                  fontWeight={500}
                  color={'#fff'}
                  borderRadius={'25px'}
                  h="45px"
                  w="100%"
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                >
                  View Details
                </Button>
                <Button
                  //   as={Link}
                  //   to={`/dashboard/hospital-profile/${hospital?._id}`}
                  //   bgGradient={'linear(to-r, #295377, #208C74)'}
                  border={'1px solid #1B1C1D'}
                  fontSize={'16px'}
                  fontWeight={500}
                  color={'#1B1C1D'}
                  borderRadius={'25px'}
                  h="45px"
                  w="100%"
                  //   _hover={{
                  //     bgGradient: 'none',
                  //     borderColor: 'primaryGreen.200',
                  //     color: 'primaryGreen.200',
                  //   }}
                >
                  Block User
                </Button>
              </Stack>
            </Box>
          ))
        )}
      </Grid>
    </Stack>
  );
}

export default HospitalStaff;
