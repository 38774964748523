import Header from '../../components/Website/Header';
import Footer from '../../components/Website/Footer';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import CareerList from '../../components/Website/expert/careerList';
import SingleHospital from '../../components/Website/expert/SingleHospital';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GET } from '../../utilities/ApiProvider';

export default function SingleCareer() {
  const params = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GET(`/web/hospitals/${params.id}/jobs`);
        if (response.status === 200) setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <Header />
      <MiniBanner subHeading="Openings" mainHeading="Careers" />
      <SingleHospital data={data} />
      <Footer />
    </>
  );
}
