import React, { useEffect, useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { CloseIcon, CheckIcon, ViewIcon } from '@chakra-ui/icons';
import { GET } from '../../../utilities/ApiProvider';
import moment from 'moment/moment';

function HospitalRequests() {
  const [data, setData] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const response = await GET('/admin/hospitals');
        if (response.status === 200) {
          setData(response.data);
          console.log(response.data);
        } else {
          handleFetchError();
        }
      } catch (error) {
        handleFetchError(error);
      }
    };

    fetchHospitals();
  }, []);

  const handleFetchError = (error = null) => {
    if (error) {
      console.error('While Fetching Dashboard', error);
    }
    toast({
      title: 'Error',
      description: 'Something went wrong while fetching the Dashboard.',
      status: 'error',
      duration: 2000,
      position: 'top-right',
      isClosable: true,
    });
  };
  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Hospital Requests /{' '}
          <span fontSize={'15px !important'} fontWeight={'500'}>
            Home
          </span>
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text>
      </Stack>
      <Stack display={'flex'} flexDirection={'column'} gap={2}>
        <HStack display={'flex'} w="100%" px={4}>
          <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
            NO.
          </Box>
          <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
            ID
          </Box>
          <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={2}>
            Hospital Name
          </Box>
          <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
            Registration Date
          </Box>
          <Box
            fontSize="15px"
            fontWeight={'400'}
            color="#75767A"
            flex={1}
            justifyContent={'center'}
          >
            Hospital Certificate
          </Box>

          <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
            Status
          </Box>
          {/* <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
            Action
          </Box> */}
        </HStack>

        {data?.map((hospital, index) => (
          <HStack
            display={'flex'}
            w="100%"
            h="70px"
            bg={'#fff'}
            shadow={'0px 16px 38px 0px #0000000F'}
            px={4}
            borderRadius={'16px'}
          >
            <Box fontSize="15px" fontWeight={'500'} color="#1B1C1D" flex={1}>
              {index + 1}
            </Box>
            <Box fontSize="15px" fontWeight={'500'} color="#1B1C1D" flex={1}>
              #{hospital?.ibanNo}
            </Box>
            <Box fontSize="15px" fontWeight={'500'} color="#1B1C1D" flex={2}>
              <VStack
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                gap={2}
              >
                <Avatar
                  size="sm"
                  name="Kent Dodds"
                  src="https://bit.ly/kent-c-dodds"
                />
                <Text>{hospital?.name}</Text>
              </VStack>
            </Box>
            <Box fontSize="15px" fontWeight={'500'} color="#1B1C1D" flex={1}>
              {moment(hospital?.createdAt).format('MM/DD/YYYY')}
            </Box>
            <Box
              fontSize="15px"
              fontWeight={'500'}
              color="#1B1C1D"
              flex={1}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Button
                bgGradient="linear(to-r, #295377, #208C74)"
                color="#E9E9E9"
                fontWeight={'500'}
                fontSize={'15px'}
                borderRadius={'21px'}
                type="submit"
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
              >
                View Certificate
              </Button>{' '}
            </Box>
            <Box fontSize="15px" fontWeight={'500'} color="#1B1C1D" flex={1}>
              <Badge
                w="81px"
                height="28px"
                display={'flex'}
                alignItems={'center'}
                borderRadius={'50px'}
                justifyContent={'center'}
                bg="#F3D893"
                color={'#C79209'}
              >
                Pending
              </Badge>
            </Box>
            {/* <Box fontSize="15px" fontWeight={'500'} color="#1B1C1D" flex={1}>
              <HStack>
                {' '}
                <IconButton
                  isRound={true}
                  variant="solid"
                  colorScheme="#D9D9D9"
                  w={'40px'}
                  h={'40px'}
                  fontSize="16px"
                  // bgGradient="linear(to-r, #295377, #208C74)"
                  bg={'#D9D9D9'}
                  aria-label="Done"
                  icon={<CloseIcon fontSize="16px" color="#1B1C1D" />}
                />
                <IconButton
                  isRound={true}
                  variant="solid"
                  colorScheme="#F2F2F2"
                  w={'40px'}
                  h={'40px'}
                  fontSize="16px"
                  bgGradient="linear(to-r, #295377, #208C74)"
                  aria-label="Done"
                  icon={<CheckIcon fontSize="16px" color="#F2F2F2" />}
                />
              </HStack>
            </Box> */}
          </HStack>
        ))}
      </Stack>
    </Stack>
  );
}

export default HospitalRequests;
