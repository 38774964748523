import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Heading,
  Icon,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { PhoneInput } from 'react-international-phone';
import { FaRegTrashAlt } from 'react-icons/fa';
import { AttachmentIcon } from '@chakra-ui/icons';
import { GET, POST } from '../../../utilities/ApiProvider';

export default function AddDoctorInfo() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openingHours, setOpeningHours] = useState([
    {
      on: true,
      day: 'Monday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Tuesday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Wednesday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Thursday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Friday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Saturday',
      startTime: '09:00',
      endTime: '22:00',
    },
    {
      on: true,
      day: 'Sunday',
      startTime: '09:00',
      endTime: '22:00',
    },
  ]);
  const toast = useToast();
  const [departments, setDepartments] = useState(null);
  async function fetchDepartments() {
    try {
      const response = await GET('/hospital/department');
      console.log(response);
      if (response.status === 200) setDepartments(response.data);
    } catch (error) {
      console.log('Error while fetching departments', error);
    }
  }

  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleButtonClick = () => {
    document.getElementById('file-input').click();
  };

  const handleFileChange2 = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleButtonClick2 = () => {
    document.getElementById('file-input').click();
  };
  async function fetchCategories() {
    try {
      const response = await GET('/hospital/categories');
      console.log(response);
      if (response.status === 200) setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories data:', error);
    }
  }
  useEffect(() => {
    fetchCategories();
    fetchDepartments();
  }, []);
  function changeOnStatus(itemIndex, value) {
    let modifiedArray = openingHours.map((val, ind) => {
      if (ind === itemIndex) {
        return {
          ...val,
          on: value,
        };
      } else return val;
    });
    setOpeningHours(modifiedArray);
  }

  function changeStartingTime(itemIndex, value) {
    let modifiedArray = openingHours.map((val, ind) => {
      if (ind === itemIndex) {
        return {
          ...val,
          startTime: value,
        };
      } else return val;
    });
    setOpeningHours(modifiedArray);
  }

  function changeEndingTime(itemIndex, value) {
    let modifiedArray = openingHours.map((val, ind) => {
      if (ind === itemIndex) {
        return {
          ...val,
          endTime: value,
        };
      } else {
        return val;
      }
    });
    setOpeningHours(modifiedArray);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      form.append(
        'opening_hours',
        JSON.stringify(openingHours.filter(item => item.on))
      );
      form.append('photo', selectedFile);
      if (form.get('department') === '' || form.get('category') === '') {
        toast({
          description: 'Please select department and category',
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        setLoading(false);
        return;
      }
      if (!selectedFile) {
        toast({
          description: 'Please select Photo to proceed',
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        setLoading(false);
        return;
      }
      const formDataEntries = Array.from(form.entries());
      const response = await POST('/hospital/doctor-onboarding', form);

      if (response.status === 200) {
        setLoading(false);
        toast({
          description: 'Health Practitioner created Sucessfully!!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        setLoading(false);
        e.target.reset();
        setSelectedFile(null);
      } else {
        toast({
          description: response?.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log('Erorr while submitting form ', error);
    }
  };

  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Add New Health Practitioners
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Enter the doctor details to add
        </Text>
      </Stack>
      <form onSubmit={handleSubmit} className="applyForm" id="registerForm">
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Box
            w="50%"
            display={'flex'}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            {/* <Box w='100%' mb="10px">
                                    <FormLabel>Hospital Name*</FormLabel>
                                    <Select name='name' placeholder='Enter here' >
                                        <option>Professional 1</option>
                                        <option>Professional 2</option>
                                        <option>Professional 3</option>
                                    </Select>
                                </Box> */}
            <Box w="100%" mb="10px">
              <FormLabel>Practitioner Category*</FormLabel>
              <Select name="category" placeholder="Enter here">
                {categories?.map(category => (
                  <option value={category._id}>{category.name}</option>
                ))}
                {/* <option>Professional 1</option>
                                        <option>Professional 2</option>
                                        <option>Professional 3</option> */}
              </Select>
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>Category*</FormLabel>
              <Select name="doctorCategory" placeholder="Choose here">
                <option value="physician">Physician</option>
                <option value="dentist">Dentist</option>
                <option value="pharmacist">Pharmacist</option>
                <option value="nurse">Nurse</option>
                <option value="health specialist">Health Specialist</option>
                <option value="paramedic">Paramedic</option>
                <option value="health assistant">Health Assistant</option>
                <option value="health college student">
                  Health College Student
                </option>
              </Select>
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>Department*</FormLabel>
              <Select name="department" placeholder="Enter here">
                {departments?.map(item => (
                  <option value={item._id}>{item.name}</option>
                ))}
                {/* <option>Professional 1</option>
                                        <option>Professional 2</option>
                                        <option>Professional 3</option> */}
              </Select>
            </Box>

            <Heading
              w="100%"
              fontSize={'20px'}
              fontWeight={'500'}
              color={'primaryBlack.100'}
              my="10px"
            >
              Health Practitioner Particulars
            </Heading>
            <Box w="48%" mb="10px">
              <FormLabel>Name*</FormLabel>
              <Input required name="name" placeholder="Enter here" />
            </Box>
            <Box w="48%" mb="10px">
              <FormLabel>Employer</FormLabel>
              <Input required name="employer" placeholder="Enter here" />
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>Employment Address</FormLabel>
              <Input
                required
                name="employmentAddress"
                placeholder="Enter here"
              />
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                required
                name="email"
                placeholder="Enter here"
              />
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>Phone No.*</FormLabel>
              <PhoneInput
                name="number"
                required
                containerStyle={{ background: 'none', border: 'none' }}
                inputStyle={{ background: 'none', border: 'none' }}
                dropdownStyle={{ background: 'none', border: 'none' }}
                id="phone-input"
                defaultCountry="sa"
                inputClass="custom-phone-input"
              />
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>Doctor Type*</FormLabel>
              <Select name="doctorType" placeholder="Enter here">
                <option value={'inperson'}>In-Person</option>
                <option value={'virtual'}>Virtual</option>
              </Select>
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>Gender*</FormLabel>
              <Select name="gender" placeholder="Choose here">
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Select>
            </Box>
            <Heading
              w="100%"
              fontSize={'20px'}
              fontWeight={'500'}
              color={'primaryBlack.100'}
              my="10px"
            >
              Professional Data of the Health Practitioner
            </Heading>
            <Box w="48%" mb="10px">
              <FormLabel>General Specialty</FormLabel>
              <Select name="generalSpeciality" placeholder="Select here">
                <option value="medicine">Medicine</option>
                <option value="general-surgery">General Surgery</option>
                <option value="pediatric">Pediatric</option>
                <option value="obstetrics-gynecology">
                  Obstetrics and Gynecology
                </option>
                <option value="ophthalmology">Ophthalmology</option>
                <option value="ent">Ear Nose and Throat (ENT)</option>
                <option value="dermatology">Dermatology</option>
                <option value="dental">Dental</option>
                <option value="cardiology">Cardiology</option>
                <option value="pulmonologist">Pulmonologist</option>
                <option value="endocrinology">Endocrinology</option>
                <option value="diabetics">Diabetics</option>
                <option value="thoracic-surgery">Thoracic Surgery</option>
                <option value="infertility">Infertility</option>
                <option value="orthopedic">Orthopedic</option>
                <option value="urology">Urology</option>
                <option value="pediatric-surgery">Pediatric Surgery</option>
                <option value="vascular-surgery">Vascular Surgery</option>
                <option value="rheumatology">Rheumatology</option>
                <option value="hematology">Hematology</option>
                <option value="neurology">Neurology</option>
                <option value="neurosurgery">Neurosurgery</option>
                <option value="plastic-surgery">Plastic Surgery</option>
              </Select>
              {/* <Input name="generalSpeciality" placeholder="Enter here" /> */}
            </Box>
            <Box w="48%" mb="10px">
              <FormLabel>Subspecialty</FormLabel>
              <Input required name="subspeciality" placeholder="Enter here" />
            </Box>
            <Box w="48%" mb="10px">
              <FormLabel>Academic Qualification</FormLabel>
              <Input name="academicQualification" placeholder="Enter here" />
            </Box>
            <Box w="48%" mb="10px">
              <FormLabel>Years of experience</FormLabel>
              <Input
                required
                name="yearsOfExperience"
                placeholder="Enter here"
              />
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>Medical Treatment Services</FormLabel>
              <Input
                required
                name="medicalTreatmentService"
                placeholder="Enter here"
              />
            </Box>
            <Box w="100%" mb="10px">
              <FormLabel>Health Practitioner Price</FormLabel>
              <Input
                required
                type="number"
                name="charges"
                placeholder="Enter here"
              />
            </Box>
            <Box mt="20px">
              <Button
                bgGradient={'linear(to-r, #295377, #208C74)'}
                fontSize={'16px'}
                fontWeight={500}
                color={'#fff'}
                borderRadius={'25px'}
                h="45px"
                px="80px"
                type="submit"
                isLoading={loading}
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Box w={'36%'}>
            <Box w="100%">
              <FormLabel>Photo</FormLabel>
              <Input
                type="file"
                id="file-input"
                display="none"
                onChange={handleFileChange}
              />
              <Text fontSize="14px" color="primaryGray.100" textAlign={'right'}>
                <Button
                  cursor={'pointer'}
                  as="span"
                  border="1px solid #75767A"
                  mb="10px"
                  bgColor="transparent"
                  fontSize="14px"
                  w="100%"
                  color="primaryGray.100"
                  transition=".3s"
                  _hover={{
                    bgColor: 'primaryGreen.200',
                    color: '#fff',
                    borderColor: 'primaryGreen.200',
                  }}
                  mr="10px"
                  onClick={handleButtonClick}
                >
                  <AttachmentIcon mr="10px" /> Attach File
                </Button>
                {selectedFile ? selectedFile.name : 'No File chosen'}
              </Text>
            </Box>
            <Heading
              fontSize={'20px'}
              fontWeight={'500'}
              color={'primaryBlack.100'}
              my="20px"
            >
              Health Practitioner Availability
            </Heading>
            {openingHours.map((val, ind) => (
              <Stack
                mb="5px !important"
                border={'2px solid'}
                borderRadius={'12px'}
                borderColor={!val.on ? '#75767A' : 'primaryGreen.200'}
                p="5px 5px 5px 15px"
                key={val.day}
                direction={'row'}
                alignItems={'center'}
              >
                <Text flex={'1'}>
                  <Checkbox
                    colorScheme="teal"
                    defaultChecked={val?.on}
                    onChange={e => changeOnStatus(ind, e.target.checked)}
                    mr="5px"
                    mt="3px"
                    transform="translateY(2px)"
                  ></Checkbox>
                  {val.day}:
                </Text>
                <Text
                  bgColor={!val.on ? '#75767A' : 'primaryGreen.200'}
                  borderRadius={'12px'}
                  color={'#fff'}
                >
                  <Input
                    isDisabled={!val.on}
                    height={'42px !important'}
                    borderColor={'primaryGreen.200 !important'}
                    onChange={e => changeStartingTime(ind, e.target.value)}
                    type="time"
                    defaultValue={val.startTime}
                    value={val.startTime}
                  />
                </Text>
                <Text
                  bgColor={!val.on ? '#75767A' : 'primaryGreen.200'}
                  borderRadius={'12px'}
                  color={'#fff'}
                >
                  <Input
                    isDisabled={!val.on}
                    height={'42px !important'}
                    borderColor={'primaryGreen.200 !important'}
                    onChange={e => changeEndingTime(ind, e.target.value)}
                    type="time"
                    defaultValue={val.endTime}
                    value={val.endTime}
                  />
                </Text>
              </Stack>
            ))}
            {/* <Box w="100%">
                                    <FormLabel>CV</FormLabel>
                                    <Input
                                        name="document"
                                        type="file"
                                        id="file-input"
                                        required
                                        display="none"
                                        onChange={handleFileChange2}
                                    />
                                    <Text fontSize="14px" color="primaryGray.100" textAlign={'right'}>
                                        <Button
                                            cursor={'pointer'}
                                            as="span"
                                            border="1px solid #75767A"
                                            bgColor="transparent"
                                            fontSize="14px"
                                            color="primaryGray.100"
                                            w="100%"
                                            mb="10px"
                                            transition=".3s"
                                            _hover={{
                                                bgColor: 'primaryGreen.200',
                                                color: '#fff',
                                                borderColor: 'primaryGreen.200',
                                            }}
                                            mr="10px"
                                            onClick={handleButtonClick2}
                                        >
                                            <AttachmentIcon mr="10px" /> Attach File
                                        </Button>
                                        {selectedFile ? selectedFile.name : 'No File chosen'}
                                    </Text>
                                </Box> */}
          </Box>
        </Stack>
      </form>
    </Stack>
  );
}
