import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import Blog from '../../../assets/images/website/blogs/blogdetails.jpg';
import { MdOutlineDelete } from 'react-icons/md';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GET, DELETE } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';

export default function ServiceDetails() {
  const { id } = useParams();
  const toast = useToast();
  const [service, setService] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchService = async () => {
      try {
        const response = await GET(`/admin/get-single-service/${id}`);
        if (response.status === 200) {
          setService(response.data[0]);
          console.log(response.data);
        } else {
          handleFetchError();
        }
      } catch (error) {
        handleFetchError(error);
      }
    };
    fetchService();
  }, []);

  const handleFetchError = (error = null) => {
    if (error) {
      console.error('While Fetching Blogs', error);
    }
    toast({
      title: 'Error',
      description: 'Something went wrong while fetching the blog.',
      status: 'error',
      duration: 2000,
      position: 'top-right',
      isClosable: true,
    });
  };

  const handleDelete = async id => {
    try {
      const response = await DELETE(`/admin/delete-service/${id}`);
      if (response.status === 200) {
        navigate('/dashboard/hospital-services');
        toast({
          description: 'Service deleted successfully.',
          status: 'success',
          duration: 2000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        handleFetchError();
      }
    } catch (error) {
      handleFetchError(error);
    }
  };

  return (
    <>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
      >
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Service Details
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all service details
          </Text>
        </Stack>
        <Flex gap={2}>
          <Button
            bg={
              'var(--Gradient-1, linear-gradient(109deg, #295377 -22.91%, #208C74 93.87%))'
            }
            color={'#fff'}
            px={8}
            py={6}
            borderRadius={25}
            onClick={() =>
              navigate('/dashboard/hospital-addservicesdetails', {
                state: service,
              })
            }
          >
            Edit Service
          </Button>
          <Button
            onClick={() => handleDelete(id)}
            border={'2px solid #348874'}
            width={'45px'}
            height={'45px'}
            borderRadius={'50%'}
            bg={'transparent'}
          >
            <Icon color={'#348874'} fontSize={'20px'} as={MdOutlineDelete} />
          </Button>
        </Flex>
      </Stack>
      <Stack gap={3}>
        <Image
          width={'100%'}
          h="420px"
          borderRadius={12}
          src={`${imgUrl}/${service?.thumbnail}`}
        />
        <Heading color={'#1B1C1D'} fontSize={'25px'} fontWeight={600}>
          {service?.title}
        </Heading>
        <Text
          fontSize={'16px'}
          color={'#75767A'}
          fontWeight={'400'}
          dangerouslySetInnerHTML={{ __html: service?.description }}
        ></Text>
      </Stack>
    </>
  );
}
