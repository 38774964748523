import Header from '../../components/Website/Header';
import Footer from '../../components/Website/Footer';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import CareerList from '../../components/Website/expert/careerList';

export default function Career() {
  return (
    <>
      <Header />
      <MiniBanner
        subHeading="Openings"
        mainHeading="Recruitment of Health Practitioners"
        // content="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      />
      <CareerList />
      <Footer />
    </>
  );
}
