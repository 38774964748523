import Header from '../../components/Website/Header';
import Footer from '../../components/Website/Footer';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import headingIcon from '../../assets/images/website/headingIcon.svg';
import { useToast } from '@chakra-ui/react';
import {
  Box,
  Button,
  Container,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { FaEnvelope, FaMapMarker, FaPhone } from 'react-icons/fa';
import { AttachmentIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { POST } from '../../utilities/ApiProvider';
import Chatbot from '../../components/Website/chatbot/Chatbot';
import { FaWhatsapp } from 'react-icons/fa';

export default function Contact() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleButtonClick = () => {
    document.getElementById('file-input').click();
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const form = new FormData(e.target);

      const response = await POST('/web/inquiry', form);

      toast({
        description:
          response.status === 200
            ? 'Thank you for contacting us!'
            : response?.message,
        status: response.status === 200 ? 'success' : 'error',
        isClosable: true,
        position: 'top-right',
        duration: 3000,
      });
      e.target.reset();
    } catch (err) {
      toast({
        description: err?.message,
        status: 'error',
        isClosable: true,
        position: 'top-right',
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Header />
      <MiniBanner
        // subHeading="24/7 Available"
        mainHeading="Contact Us"
        // content="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      />
      <Container maxW={'6xl'} py="60px">
        <Stack
          direction={'row'}
          gap="10px"
          alignItems={'center'}
          flexDir={{ base: 'column', xl: 'row' }}
          justifyContent={'space-between'}
        >
          <Box
            w={{ base: '100%', xl: '31%' }}
            flex={1}
            bgColor={'#ededed'}
            padding={'25px'}
            borderRadius={'12px'}
            _hover={{
              bgGradient: 'linear(to-r, primaryGreen.100, primaryGreen.200)',
            }}
            role="group"
          >
            <Stack direction={'row'} mb="15px" alignItems={'center'}>
              <Text
                _groupHover={{ borderColor: '#fff' }}
                border={'1px solid'}
                borderColor={'primaryGreen.200'}
                borderRadius={'100%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                w="37px"
                h="37px"
              >
                <Icon
                  _groupHover={{ color: '#fff' }}
                  transition={'.3s'}
                  as={FaEnvelope}
                  color={'primaryBlack.100'}
                />
              </Text>
              <Heading
                transition={'.3s'}
                fontSize={'18px'}
                _groupHover={{ color: '#fff' }}
                fontWeight={'600'}
              >
                Email Address
              </Heading>
            </Stack>
            <Text
              transition={'.3s'}
              fontSize={'14px'}
              color={'primaryGray.100'}
              my="5px"
              _groupHover={{ color: '#fff' }}
            >
              info@emedii.com
            </Text>
            <Text
              transition={'.3s'}
              fontSize={'14px'}
              color={'primaryGray.100'}
              _groupHover={{ color: '#fff' }}
            >
              info@emedii.com
            </Text>
          </Box>
          <Box
            w={{ base: '100%', xl: '31%' }}
            flex={1}
            bgColor={'#ededed'}
            padding={'25px'}
            borderRadius={'12px'}
            _hover={{
              bgGradient: 'linear(to-r, primaryGreen.100, primaryGreen.200)',
            }}
            role="group"
          >
            <Stack direction={'row'} mb="15px" alignItems={'center'}>
              <Text
                _groupHover={{ borderColor: '#fff' }}
                border={'1px solid'}
                borderColor={'primaryGreen.200'}
                borderRadius={'100%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                w="37px"
                h="37px"
              >
                <Icon
                  _groupHover={{ color: '#fff' }}
                  transition={'.3s'}
                  as={FaPhone}
                  color={'primaryBlack.100'}
                />
              </Text>
              <Heading
                transition={'.3s'}
                fontSize={'18px'}
                _groupHover={{ color: '#fff' }}
                fontWeight={'600'}
              >
                Phone
              </Heading>
            </Stack>
            <Text
              transition={'.3s'}
              fontSize={'14px'}
              color={'primaryGray.100'}
              my="5px"
              _groupHover={{ color: '#fff' }}
            >
              +966 233 5126 123
            </Text>
            <Text
              transition={'.3s'}
              fontSize={'14px'}
              color={'primaryGray.100'}
              _groupHover={{ color: '#fff' }}
            >
              +966 233 5126 123
            </Text>
          </Box>
          <Box
            flex={1}
            bgColor={'#ededed'}
            w={{ base: '100%', xl: '31%' }}
            padding={'25px'}
            borderRadius={'12px'}
            _hover={{
              bgGradient: 'linear(to-r, primaryGreen.100, primaryGreen.200)',
            }}
            role="group"
            onClick={() => {
              const url = 'https://wa.me/9662335126123';
              window.open(url, '_blank');
            }}
          >
            <Stack direction={'row'} mb="15px" alignItems={'center'}>
              <Text
                _groupHover={{ borderColor: '#fff' }}
                border={'1px solid'}
                borderColor={'primaryGreen.200'}
                borderRadius={'100%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                w="37px"
                h="37px"
              >
                <Icon
                  fontSize={'19px'}
                  fontWeight={'900'}
                  _groupHover={{ color: '#fff' }}
                  transition={'.3s'}
                  as={FaWhatsapp}
                  color={'primaryBlack.100'}
                />
              </Text>
              <Heading
                transition={'.3s'}
                fontSize={'18px'}
                _groupHover={{ color: '#fff' }}
                fontWeight={'600'}
              >
                WhatsApp Business
              </Heading>
            </Stack>
            <Text
              transition={'.3s'}
              fontSize={'14px'}
              color={'primaryGray.100'}
              my="5px"
              _groupHover={{ color: '#fff' }}
            >
              +966 233 5126 123
            </Text>
            <Text
              transition={'.3s'}
              fontSize={'14px'}
              color={'primaryGray.100'}
              my="5px"
              _groupHover={{ color: '#fff' }}
            >
              redirect to whatsapp
            </Text>
          </Box>
        </Stack>
        {/* <Box flex={1} my="40px" textAlign={'center'}>
          <Heading
            mb="15px"
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            color={'primaryGreen.200'}
            as={'h6'}
            fontWeight={'600'}
          >
            <Image src={headingIcon} />
            <Text
              ml="10px"
              fontSize={'16px'}
              textTransform={'uppercase'}
              as="span"
            >
              Careers
            </Text>
          </Heading>
          <Heading
            fontSize={'38px'}
            fontWeight={'600'}
            color={'primaryBlack.100'}
            mb="35px"
          >
            Get In Touch With Us
          </Heading>
        </Box>
        <form onSubmit={handleSubmit} className="applyForm">
          <Stack
            spacing={0}
            direction={'row'}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            <Box w="48%">
              <FormLabel>First Name</FormLabel>
              <Input placeholder="Enter here" name="fullName" />
            </Box>
            <Box w="48%">
              <FormLabel>Last Name</FormLabel>
              <Input placeholder="Enter here" name="lastName" />
            </Box>
            <Box w={{ base: '100%', xl: '48%' }}>
              <FormLabel>Email Address</FormLabel>
              <Input placeholder="Enter here" name="email" />
            </Box>
            <Box w={{ base: '100%', xl: '48%' }}>
              <FormLabel>Phone No.</FormLabel>
              <Input placeholder="Enter here" name="number" />
            </Box>
            <Box w="100%">
              <FormLabel>Address</FormLabel>
              <Textarea
                placeholder="Enter here"
                resize={'none'}
                cols={16}
                name="address"
              ></Textarea>
            </Box>
            <Box w="100%">
              <FormLabel>
                You can attach a copy of your academic certificates and
                professional experience, if any
              </FormLabel>
              <Input
                name="document"
                type="file"
                id="file-input"
                display="none"
                onChange={handleFileChange}
              />
              <Text fontSize="14px" color="primaryGray.100">
                <Button
                  cursor={'pointer'}
                  as="span"
                  border="1px solid #75767A"
                  bgColor="transparent"
                  fontSize="14px"
                  color="primaryGray.100"
                  transition=".3s"
                  _hover={{
                    bgColor: 'primaryGreen.200',
                    color: '#fff',
                    borderColor: 'primaryGreen.200',
                  }}
                  mr="10px"
                  onClick={handleButtonClick}
                >
                  <AttachmentIcon mr="10px" /> Attach File
                </Button>
                {selectedFile ? selectedFile.name : 'No File chosen'}
              </Text>
            </Box>

            <Button
              isLoading={isLoading}
              type='submit'
              m="20px 0 !important"
              bgGradient={'linear(to-r, #295377, #208C74)'}
              fontSize={"14px"}
              fontWeight={500}
              color={'#fff'}
              borderRadius={"25px"}
              h="45px"
              px="60px"
              textTransform={'uppercase'}
              border={"2px solid transparent"}
              _hover={{
                bgGradient: "none",
                borderColor: 'primaryGreen.200',
                color: 'primaryGreen.200'
              }}
            >
              Submit
            </Button>
          </Stack>
        </form> */}
        <Stack
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Chatbot />
        </Stack>
      </Container>
      <Footer />
    </>
  );
}
