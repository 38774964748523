import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Heading,
  Grid,
  HStack,
  Stack,
  Text,
  FormLabel,
  Input,
  Select,
  Spinner,
} from '@chakra-ui/react';
import { GET } from '../../../utilities/ApiProvider';
import moment from 'moment/moment';
import { Link, useNavigate } from 'react-router-dom';

function TrainingProgram() {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [dropdowns, setDropdowns] = useState({});
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem('emediiUser'));
  useEffect(() => {
    fetchProgram();
  }, []);
  const fetchProgram = async (filter = '') => {
    try {
      setLoading(true);
      const response = await GET(`/admin/study-programs?${filter}`);
      if (response.status === 200) {
        setLoading(false);

        console.log(response.data);
        setData(response.data);
        if (filter === '') {
          const uniqueAttributes = extractUniqueAttributes(response.data);
          setDropdowns(uniqueAttributes);
        }
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  function extractUniqueAttributes(_data) {
    const result = {
      type: [],
      location: [],
      duration: [],
    };

    _data?.forEach(item => {
      if (item.type && !result.type.includes(item.type)) {
        result.type.push(item.type);
      }

      if (item.location && !result.location.includes(item.location)) {
        result.location.push(item.location);
      }

      if (item.duration && !result.duration.includes(item.duration)) {
        result.duration.push(item.duration);
      }
    });

    return result;
  }
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      let filter = '';
      const form = new FormData(e.target);

      if (form.get('type')) {
        filter += `&type=${form.get('type')}`;
      }

      if (form.get('location')) {
        filter += `&location=${form.get('location')}`;
      }

      if (form.get('duration')) {
        filter += `&duration=${form.get('duration')}`;
      }

      console.log(filter);

      await fetchProgram(filter);
    } catch (error) {
      console.log(error);
    }
  };
  const clearFilter = e => {
    fetchProgram();
    document.getElementById('filers').reset();
  };

  return (
    <Stack>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Medical Education Training Program
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the latest update...Check Now!
          </Text>
        </Stack>
        {user?.type !== 'superadmin' && (
          <Button
            onClick={() => navigate('/dashboard/requested-training-program')}
            bgGradient={'linear(to-r, #295377, #208C74)'}
            fontSize={'16px'}
            fontWeight={500}
            color={'#fff'}
            borderRadius={'25px'}
            h="45px"
            border={'2px solid transparent'}
            _hover={{
              bgGradient: 'none',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
          >
            View Applied Program
          </Button>
        )}
      </HStack>

      <Stack my={6}>
        <form id="filers" className="applyForms" onSubmit={handleSubmit}>
          <Stack
            className="applyForms"
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            py={8}
            gap={4}
          >
            <Box width={'100%'}>
              <FormLabel>Program Type</FormLabel>
              <Select name="type" placeholder="Select Type">
                {dropdowns?.type?.map(item => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </Box>
            <Box width={'100%'}>
              <FormLabel>Program Location</FormLabel>
              <Select name="location" placeholder="Select Location">
                {dropdowns?.location?.map(item => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </Box>
            <Box width={'100%'}>
              <FormLabel>Program Duration</FormLabel>
              <Select name="duration" placeholder="Select Duration">
                {dropdowns?.duration?.map(item => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </Box>

            <Box>
              <Button
                bgGradient={'linear(to-r, #295377, #208C74)'}
                bg={'none'}
                fontSize={{ base: '12px', xl: '16px' }}
                fontWeight={500}
                border={'1px solid'}
                color={'#fff'}
                isLoading={loading}
                borderRadius={'25px'}
                padding={'20px 35px'}
                h={{ base: '35px', xl: '45px' }}
                textTransform={'uppercase'}
                _hover={{
                  bgGradient: 'linear(to-r, #295377, #208C74)',
                  color: '#fff',
                }}
                type="submit"
              >
                Search
              </Button>
            </Box>
            <Box>
              <Button
                bgGradient={'linear(to-r, #295377, #208C74)'}
                bg={'none'}
                fontSize={{ base: '12px', xl: '16px' }}
                fontWeight={500}
                border={'1px solid'}
                color={'#fff'}
                borderRadius={'25px'}
                padding={'20px 35px'}
                h={{ base: '35px', xl: '45px' }}
                textTransform={'uppercase'}
                _hover={{
                  bgGradient: 'linear(to-r, #295377, #208C74)',
                  color: '#fff',
                }}
                onClick={e => clearFilter(e)}
              >
                Clear filter
              </Button>
            </Box>
          </Stack>
        </form>
      </Stack>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {data?.length > 0 ? (
          data?.map(item => (
            <Box
              shadow={'0px 16px 38px 0px #0000000F'}
              bg={'#fff'}
              borderRadius={'16px'}
              p={4}
              display={'flex'}
              flexDirection={'column'}
              gap={4}
            >
              <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                px={4}
              >
                <HStack alignItems={'center'} lineHeight={'0.3rem'} gap={2}>
                  <Stack>
                    <Heading fontSize={'22px'} fontWeight={'500'}>
                      {item?.name}
                    </Heading>
                    <Text
                      fontSize={'15px'}
                      color={'#75767A'}
                      fontWeight={'400'}
                    >
                      #{item?._id?.slice(0, 6)}
                    </Text>
                  </Stack>
                </HStack>
              </Stack>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                px={4}
              >
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Date Added
                  </Text>
                  <Text fontSize={'16px'} fontWeight={'500'}>
                    {moment(item?.createdAt).format('MM / DD / YYYY') ||
                      moment(new Date()).format('MM / DD / YYYY')}
                  </Text>
                </Stack>
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Program Type
                  </Text>
                  <Text
                    textTransform={'capitalize'}
                    fontSize={'16px'}
                    fontWeight={'500'}
                  >
                    {item?.type}
                  </Text>
                </Stack>
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Duration
                  </Text>
                  <Text fontSize={'16px'} fontWeight={'500'}>
                    {item?.duration}
                  </Text>
                </Stack>
              </Stack>

              {user?.type !== 'superadmin' && (
                <Button
                  onClick={() =>
                    navigate(`/dashboard/training-program-apply`, {
                      state: item,
                    })
                  }
                  bgGradient={'linear(to-r, #295377, #208C74)'}
                  fontSize={'16px'}
                  fontWeight={500}
                  color={'#fff'}
                  borderRadius={'25px'}
                  h="45px"
                  w="100%"
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                >
                  Apply Now
                </Button>
              )}
            </Box>
          ))
        ) : (
          <Heading fontSize={'22px'} fontWeight={'500'}>
            No Data Found
          </Heading>
        )}
      </Grid>
    </Stack>
  );
}

export default TrainingProgram;
