import React, { useEffect, useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Select,
  Spinner,
} from '@chakra-ui/react';
import { FaEnvelope, FaMapMarker, FaPhoneAlt } from 'react-icons/fa';
import { TbActivityHeartbeat } from 'react-icons/tb';
import { Link, useParams } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import moment from 'moment';

function StaffDetail() {
  const { id } = useParams();
  const [staff, setStaff] = useState(null);
  useEffect(() => {
    const getStaff = async () => {
      try {
        const response = await GET('/hospital/employee/' + id);
        if (response.status === 200) {
          setStaff(response.data);
          console.log(response.data);
        } else {
          setStaff({});
        }
      } catch (error) {
        console.log(error);
      }
    };
    getStaff();
  }, [id]);
  return (
    <Stack flexDirection={'column'} gap={2}>
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'center'}
          fontSize={'36px'}
          fontWeight={'500'}
        >
          Staff Details {''}/
          <Text
            mt={'8px'}
            ml={'2px'}
            color={'#208C74'}
            fontSize={'16px'}
            fontWeight={'500'}
          >
            Hospital Staff
          </Text>
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text>
      </Stack>
      {!staff ? (
        <Stack>
          <Spinner />
        </Stack>
      ) : (
        <>
          <Stack>
            <Avatar
              size="2xl"
              name="Prosper Otemuyiwa"
              src={
                `${imgUrl}/${staff?.employee?.picture}` ||
                'https://bit.ly/dan-abramov'
              }
            />{' '}
          </Stack>
          <Stack>
            <Heading
              display={'flex'}
              alignItems={'center'}
              fontSize={'26px'}
              fontWeight={'500'}
            >
              {staff?.employee?.fullName} {''}/
              <Text
                mt={'8px'}
                ml={'2px'}
                color={'#208C74'}
                fontSize={'16px'}
                fontWeight={'500'}
                textTransform={'capitalize'}
              >
                {staff?.employee?.role?.name}
              </Text>
            </Heading>
            <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
              #d44vdsQ
            </Text>
          </Stack>
          <Stack my={4}>
            <Stack direction={'row'} alignItems={'center'} gap="30px">
              <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
                <Icon mr="5px" as={FaMapMarker} />{' '}
                {staff?.employee?.address || 'King Fahad Rd, Al Bustan'}
              </Text>
              <Text
                mb="10px"
                mt={'0 !important'}
                color={'primaryGray.100'}
                fontSize={'14px'}
              >
                <Icon mr="5px" as={FaPhoneAlt} />{' '}
                {staff?.employee?.number || '+966 765 272 46'}
              </Text>
            </Stack>
            <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
              <Icon mr="5px" as={FaEnvelope} />{' '}
              {staff?.employee?.email || 'adam.knight@gmail.com'}
            </Text>
          </Stack>

          <Heading fontSize={'20px'} fontWeight={'500'}>
            Activity Logs:{' '}
          </Heading>
          {staff?.logs?.data?.map((log, index) => (
            <Box
              w={'100%'}
              h={'100px'}
              bg={'#F2F2F2'}
              borderRadius={'13px'}
              display={'flex'}
              alignItems={'center'}
            >
              <Stack flexDirection={'row'} alignItems={'center'} gap={4} px={4}>
                <Stack flexDirection={'row'} alignItems={'center'} gap={6}>
                  <Box
                    w="57px"
                    h="57px"
                    bgGradient={'linear(to-r, #295377, #208C74)'}
                    borderRadius={'50%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Icon
                      as={TbActivityHeartbeat}
                      color={'#fff'}
                      w={10}
                      h={10}
                    />
                  </Box>
                  <VStack
                    alignItems={'flex-start'}
                    w={'250px'}
                    borderRight={'1.5px solid #464646'}
                  >
                    <Text
                      fontSize={'14px'}
                      fontWeight={'500'}
                      color={'#75767A'}
                    >
                      Login Type:
                    </Text>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'500'}
                      mt={'0 !important'}
                      color={'#1B1C1D'}
                    >
                      {log?.type || 'Login'}
                    </Text>
                  </VStack>
                </Stack>
                <HStack w={'600px'} gap={6} alignItems={'center'} px={6}>
                  <Stack flex={4}>
                    <Text
                      fontSize={'14px'}
                      fontWeight={'500'}
                      color={'#75767A'}
                    >
                      Date:
                    </Text>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'500'}
                      mt={'0 !important'}
                      color={'#1B1C1D'}
                    >
                      {moment(log?.createdAt).format('DD/MM/YYYY')}
                    </Text>
                  </Stack>
                  <Stack flex={4}>
                    <Text
                      fontSize={'14px'}
                      fontWeight={'500'}
                      color={'#75767A'}
                    >
                      Time:
                    </Text>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'500'}
                      mt={'0 !important'}
                      color={'#1B1C1D'}
                    >
                      {moment(log?.createdAt).format('hh:mm A')}
                    </Text>
                  </Stack>
                  {log?.path !== '' && (
                    <Stack flex={4} ml={'0 !important'}>
                      <Text
                        fontSize={'14px'}
                        fontWeight={'500'}
                        color={'#75767A'}
                        px={'15px'}
                      >
                        Details:
                      </Text>
                      {/* <Text
                      fontSize={'16px'}
                      fontWeight={'500'}
                      mt={'0 !important'}
                      color={'#1B1C1D'}
                    >
                      Cardiology
                    </Text> */}
                      <Button
                        as={Link}
                        to={`${log?.path}`}
                        bgGradient={'linear(to-r, #295377, #208C74)'}
                        fontSize={'14px'}
                        fontWeight={500}
                        color={'#fff'}
                        borderRadius={'25px'}
                        h="40px"
                        // px="80px"
                        w={'125px'}
                        border={'2px solid transparent'}
                        _hover={{
                          bgGradient: 'none',
                          borderColor: 'primaryGreen.200',
                          color: 'primaryGreen.200',
                        }}
                      >
                        View Details
                      </Button>
                    </Stack>
                  )}
                </HStack>
              </Stack>
            </Box>
          ))}
        </>
      )}
    </Stack>
  );
}

export default StaffDetail;
