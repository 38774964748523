import React, { useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Select,
  useToast,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import { POST } from '../../../utilities/ApiProvider';

function AddWard() {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      form.append('numberOfBeds', 0);
      const response = await POST('/hospital/ward', form);

      if (response.status === 200) {
        setLoading(false);

        toast({
          description: 'Ward Created Successfully!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        e.target.reset();
      } else {
        setLoading(false);

        toast({
          description: response.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
      }
    } catch (error) {
      console.log('Error while creating wards', error);
    }
  };

  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Create New Ward
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          If an query you can send it to the platform admin...!
        </Text>
      </Stack>
      <form onSubmit={handleSubmit} className="applyForm">
        <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="50%">
            <FormLabel>Ward Name</FormLabel>
            <Input name="wardName" placeholder="Enter here" />
          </Box>
          <Box w="50%">
            <FormLabel>Ward Price</FormLabel>
            <Input name="price" placeholder="Enter here" />
          </Box>
          {/* <Box w="50%">
                <FormLabel>No. of Beds</FormLabel>
                <Input name="numberOfBeds" placeholder="Enter here" />
              </Box> */}
        </Stack>

        <Button
          w="15%"
          mt={'15px'}
          isLoading={loading}
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          type="submit"
        >
          Continue
        </Button>
      </form>
    </Stack>
  );
}

export default AddWard;
