import { Box, Button, FormLabel, Heading, Icon, Image, Input, Stack, Text, Link, Textarea, Select, Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, useDisclosure } from "@chakra-ui/react";
import Logo from '../../../assets/images/website/logo.svg'
import authBg from '../../../assets/images/website/authBg.png'
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import LogoIcon from '../../../assets/images/website/logoIcon.jpg'

export default function CompleteProfile() {

    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [registrationLvl, setRegistrationLvl] = useState(0);

    const continueCompleteProfile = async () => {
        if (registrationLvl === 0) {
            setRegistrationLvl(1)
        } else {
            onOpen()
        }
    }

    return (
        <Stack position={'relative'} height={'100vh'} p="0">
            <Box
                maxW={'720px'}
                p={'60px 0 60px 120px'}
            >
                <Button
                    onClick={() => {
                        registrationLvl === 0 ? navigate('/')
                            : setRegistrationLvl(registrationLvl - 1)
                    }}
                    bgColor={'transparent'}
                    _hover={{ bgColor: 'transparent' }}
                    _active={{ bgColor: 'transparent' }}
                    mb="40px"
                    fontWeight={'500'}
                >
                    <Icon as={FaArrowLeft} mr="10px" /> Back
                </Button>
                <Image py="40px" src={Logo} />
                <Heading
                    fontWeight={'500'}
                    color={'primaryBlack.100'}
                    fontSize={'32px'}
                >Complete your profile</Heading>
                <Text
                    fontWeight={'500'}
                    color={'primaryGray.100'}
                    fontSize={'14px'}
                    py="20px"
                >Enter your remaining details to complete your profile</Text>
                <form className='applyForm' id="registerForm">
                    <FirstLevelForm registrationLvl={registrationLvl} />
                    <SecondLevelForm registrationLvl={registrationLvl} />
                </form>
                <Button
                    onClick={continueCompleteProfile}
                    my="20px"
                    bgGradient={'linear(to-r, #295377, #208C74)'}
                    fontSize={'14px'}
                    fontWeight={500}
                    color={'#fff'}
                    borderRadius={'25px'}
                    w="100%"
                    h="45px"
                    textTransform={'uppercase'}
                    border={'2px solid transparent'}
                    _hover={{
                        bgGradient: 'none',
                        borderColor: 'primaryGreen.200',
                        color: 'primaryGreen.200',
                    }}
                    type="submit"
                >
                    {registrationLvl === 0 ? 'Continue' : 'Submit'}
                </Button>
            </Box>
            <Image src={authBg} w="605px" h="100%" position={'fixed'} top="0" right="0" mt="0 !important" />
            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent
                    p="20px 40px">
                    <ModalBody textAlign={'center'}>
                        <Image src={LogoIcon} w={'fit-content'} marginX={'auto'} mb="30px" />
                        <Heading
                            fontWeight={'500'}
                            color={'primaryBlack.100'}
                            fontSize={'32px'}
                        >Congrats!</Heading>
                        <Text
                            fontWeight={'500'}
                            color={'primaryGray.100'}
                            fontSize={'14px'}
                            py="10px"
                        >Your profile is complete</Text>
                    </ModalBody>
                    <ModalFooter justifyContent="center">
                        <Button
                            w="100%"
                            onClick={() => navigate('/dashboard')}
                            bgGradient={'linear(to-r, #295377, #208C74)'}
                            fontSize={'14px'}
                            fontWeight={500}
                            color={'#fff'}
                            borderRadius={'25px'}
                            px="40px"
                            h="45px"
                            border={'2px solid transparent'}
                            _hover={{
                                bgGradient: 'none',
                                borderColor: 'primaryGreen.200',
                                color: 'primaryGreen.200',
                            }}
                        >
                            Go to Dashboard
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Stack>
    )
}



export const FirstLevelForm = ({ registrationLvl }) => {
    const logoRef = useRef(null);
    const [imgData, setImgData] = useState({
        logo: { name: 'Upload File' },
    });
    return (
        <Stack display={registrationLvl === 0 ? 'block' : 'none'}>
            <Box w="33%">
                <FormLabel>Upload Profile Picture*</FormLabel>
                <Input name='logo' ref={logoRef} onChange={e => setImgData({ ...imgData, logo: e.target.files[0] })} placeholder='Enter here' type='file' display={'none'} />
                <Button onClick={() => logoRef.current.click()}>{imgData.logo.name}</Button>
            </Box>
            <Box>
                <FormLabel>Date of Birth*</FormLabel>
                <Input name='name' type="date" placeholder='Enter here' />
            </Box>
            <Box w="100%">
                <FormLabel>About Hospital</FormLabel>
                <Textarea name='about' resize={'none'} placeholder='Enter here'></Textarea>
            </Box>
            <Box>
                <FormLabel>Location*</FormLabel>
                <Input name='address' placeholder='Enter here' />
            </Box>
            <Box>
                <FormLabel>Total Years of Experience*</FormLabel>
                <Input name='email' placeholder='Enter here' />
            </Box>
        </Stack>
    )
}

export const SecondLevelForm = ({ registrationLvl }) => {

    const bannerRef = useRef(null);
    const docRef = useRef(null);

    const [imgData, setImgData] = useState({
        logo: { name: 'Upload File' },
        banner: { name: 'Upload File' },
        doc: { name: 'Upload File' }
    });

    return (
        <Stack direction={'row'} gap="3%" flexWrap={'wrap'} spacing={'0'} display={registrationLvl === 1 ? 'flex' : 'none'}>
            <Heading
                w="100%"
                fontWeight={'500'}
                color={'primaryBlack.100'}
                fontSize={'26px'}
                pb="10px"
            >Academic  Qualifications</Heading>
            <Box w="48%">
                <FormLabel>Institution*</FormLabel>
                <Input name='ibanNo' placeholder='Enter here' />
            </Box>
            <Box w="48%">
                <FormLabel>Degree*</FormLabel>
                <Input name='facebook' placeholder='Enter here' />
            </Box>
            <Box w="48%">
                <FormLabel>Specialization*</FormLabel>
                <Select placeholder="Enter here">
                    <option>Special 1</option>
                    <option>Special 2</option>
                    <option>Special 3</option>
                </Select>
            </Box>
            <Box w="48%">
                <FormLabel>Year of Graduation*</FormLabel>
                <Input name='twitter' type="number" placeholder='Enter here' />
            </Box>
            <Box w="100%">
                <FormLabel>You can attach JPG, PNG, PDF</FormLabel>
                <Input name='banner' ref={bannerRef} onChange={e => setImgData({ ...imgData, banner: e.target.files[0] })} placeholder='Enter here' type='file' display={'none'} />
                <Button onClick={() => bannerRef.current.click()}>{imgData.banner.name}</Button>
            </Box>
        </Stack>
    )
}