import React, { useEffect, useState } from 'react';

import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
  Spinner,
  Image,
  Icon,
} from '@chakra-ui/react';
import Dentist from '../../../assets/images/dashboard/dentist.png';
import { FaStar } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa6';
import { FaRegHeart } from 'react-icons/fa';

import { Link } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import AvailableHospitals from '../Hospital/AvailableHospitals';

function VirtualAppointment() {
  // const data = [
  //   'Dentistry',
  //   'Cardiology',
  //   'Pulmonologist',
  //   'General',
  //   'Neurology',
  //   'Gastroenteritis',
  //   'Laboratory',
  //   'Vaccination',
  // ];
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchInpersonAppointment = async () => {
      try {
        const response = await GET('/web/patient/home');
        if (response.status === 200) {
          console.log(response.data);
          setData(response.data);
        } else {
          setData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchInpersonAppointment();
  }, []);
  if (!data) {
    return (
      <Stack w="100%" h="100vh" justifyContent={'center'} alignItems={'center'}>
        <Spinner />
      </Stack>
    );
  }
  return (
    <Stack>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Stack>
          <Heading
            display={'flex'}
            alignItems={'end'}
            fontSize={'36px'}
            fontWeight={'500'}
          >
            Virtual Appointment /{' '}
            <Text color={'#208C74'} fontSize={'20px'}>
              Dashboard
            </Text>
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all services...Check Now!
          </Text>
        </Stack>
      </HStack>
      <Stack my={'20px !important'}>
        <Text fontSize={'22px'} fontWeight={500}>
          Desired Doctor Type?
        </Text>
      </Stack>
      <Grid gridTemplateColumns="repeat(2, 1fr)" gap={6}>
        {data?.doctorType?.map((val, index) => (
          <Box
            h="83px"
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            shadow={'0px 16px 38px 0px #0000000F'}
            bg={'#fff'}
            borderRadius={'10px'}
            p={'0 20px !important'}
            key={index}
          >
            <Stack>
              <HStack>
                <Stack>
                  <Stack
                    w="53px"
                    h="48px"
                    bgGradient="linear(to-r, #295377, #208C74)"
                    borderRadius={'6px'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    //   shadow={'0px 16px 38px 0px #0000000F'}
                    //   bg={'#fff'}
                  >
                    <Image w="27px" h="34px" src={`${imgUrl}/${val?.image}`} />
                  </Stack>
                </Stack>
                <Text fontSize={'18px'} fontWeight={'500'}>
                  {val?.name}
                </Text>
              </HStack>
            </Stack>
            <Stack>
              <Button
                w="125px"
                bgGradient="linear(to-r, #295377, #208C74)"
                color="#E9E9E9"
                fontWeight={'500'}
                fontSize={'15px'}
                borderRadius={'21px'}
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
                as={Link}
                to={`/dashboard/health-practitioner?type=virtual`}
              >
                View Profile
              </Button>
            </Stack>
          </Box>
        ))}
      </Grid>

      <Text fontSize={'22px'} fontWeight={500} mt={'20px !important'}>
        Available Hospitals
      </Text>
      <AvailableHospitals />
    </Stack>
  );
}

export default VirtualAppointment;
