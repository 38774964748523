import React, { useEffect, useState } from 'react';

import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
  Spinner,
  Image,
  Icon,
  Flex,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react';
// import Dentist from '../../../assets/images/dashboard/dentist.png';
import { FaStar } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa6';
import { FaRegHeart } from 'react-icons/fa';
import { GET, POST } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import RcSelect from 'react-select';
import { Country, City } from 'country-state-city';

function Recruitment() {
  const [selectedService, setSelectedService] = useState('all');
  const [data, setData] = useState(null);
  const location = useLocation();
  const [generalSpeciality, setGeneralSpeciality] = useState([]);
  const [searchParams] = useSearchParams();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [cities, setCities] = useState([]);
  const type = searchParams.get('type');
  const patientType = searchParams.get('patientType');
  const [loading, setLoading] = useState(false);

  const colourOptions = [
    { value: 'medicine', label: 'Medicine' },
    { value: 'general-surgery', label: 'General Surgery' },
    { value: 'pediatric', label: 'Pediatric' },
    {
      value: 'obstetrics-gynecology',
      label: 'Obstetrics and Gynecology',
      // isFixed: true,
    },
    { value: 'ophthalmology', label: 'Ophthalmology' },
    { value: 'ent', label: 'Ear Nose and Throat (ENT)' },
    { value: 'dermatology', label: 'Dermatology' },
    { value: 'dental', label: 'Dental' },
    { value: 'cardiology', label: 'Cardiology' },
    { value: 'pulmonologist', label: 'Pulmonologist' },
    { value: 'endocrinology', label: 'Endocrinology' },
    { value: 'diabetics', label: 'Diabetics' },
    { value: 'thoracic-surgery', label: 'Thoracic Surgery' },
    { value: 'infertility', label: 'Infertility' },
    { value: 'orthopedic', label: 'Orthopedic' },
    { value: 'urology', label: 'Urology' },
    { value: 'pediatric-surgery', label: 'Pediatric Surgery' },
    { value: 'vascular-surgery', label: 'Vascular Surgery' },
    { value: 'rheumatology', label: 'Rheumatology' },
    { value: 'hematology', label: 'Hematology' },
    { value: 'neurology', label: 'Neurology' },
    { value: 'neurosurgery', label: 'Neurosurgery' },
    { value: 'plastic-surgery', label: 'Plastic Surgery' },
  ];

  useEffect(() => {
    const countryData = Country.getAllCountries().map(country => ({
      value: country.isoCode,
      displayValue: `${country.name} - ${country.isoCode}`,
    }));
    setCountries(countryData);
    fetchHealthPractitioner();
  }, []);
  
  const handleCountryChange = e => {
    const countryCode = e.target.value?.split('-')[1].trim();

    const fetchedCities = City.getCitiesOfCountry(countryCode);
    console.log(fetchedCities, countryCode);
    setCities(fetchedCities);
  };
  const fetchHealthPractitioner = async (filter = '') => {
    try {
      const response = await GET(`/web/patient/doctor?${filter}`);
      if (response.status === 200) {
        console.log(response.data.doctor);
        setData(response.data?.doctor);

        setSelectedService(response.data?.doctor[0]?.category);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      let filter = '';
      const form = new FormData(e.target);

      if (generalSpeciality.length > 0) {
        filter += `&generalSpeciality=${generalSpeciality
          ?.map(item => item.value)
          ?.join(',')}`;
      }

      if (form.get('experience')) {
        filter += `&experience=${form.get('experience')}`;
      }

      if (form.get('country')) {
        filter += `&country=${form.get('country')}`;
      }

      if (form.get('gender')) {
        filter += `&gender=${form.get('gender')}`;
      }
      if (form.get('category')) {
        filter += `&category=${form.get('category')}`;
      }

      console.log(filter);

      await fetchHealthPractitioner(filter);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const clearFilter = e => {
    setData(null);
    setGeneralSpeciality([]);
    fetchHealthPractitioner();
    document.getElementById('filers').reset();
  };

  return (
    <Stack>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Stack>
          <Heading
            display={'flex'}
            alignItems={'end'}
            fontSize={'36px'}
            fontWeight={'500'}
            textTransform={'capitalize'}
          >
            Recruitment/
            <Text
              textTransform={'capitalize'}
              color={'#208C74'}
              fontSize={'20px'}
            >
              All Health Practitioners
            </Text>
          </Heading>
          {/* <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all services...Check Now!
          </Text> */}
        </Stack>
      </HStack>
      <form id="filers" onSubmit={handleSubmit}>
        <Stack
          className="applyForms"
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          py={8}
          gap={4}
        >
          <Box className="multis" width={'100%'}>
            <FormLabel>General Specialist</FormLabel>
            <RcSelect
              // defaultValue={}
              value={generalSpeciality}
              isMulti
              onChange={e => setGeneralSpeciality(e)}
              options={colourOptions}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Box>
          <Box width={'100%'}>
            <FormLabel>Experience</FormLabel>
            <Input name="experience" placeholder="Enter Experience" />
          </Box>
          <Box width={'100%'}>
            <FormLabel>Nationality</FormLabel>
            <Select
              name="country"
              onChange={handleCountryChange}
              placeholder="Select option"
            >
              {countries.map(country => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.displayValue}
                </option>
              ))}
            </Select>
          </Box>
          <Box width={'100%'}>
            <FormLabel>Gender</FormLabel>
            <Select name="gender" placeholder="Select option">
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </Select>
          </Box>
          <Box width={'100%'}>
            <FormLabel>Category</FormLabel>
            <Select name="category" placeholder="Select option">
              <option value="inperson">In Person</option>
              <option value="virtual">Virtual</option>
            </Select>
          </Box>
        </Stack>
        <Stack w="100%" direction={'row'} justifyContent={'end'}>
          {' '}
          <Box>
            <Button
              bgGradient={'linear(to-r, #295377, #208C74)'}
              bg={'none'}
              fontSize={{ base: '12px', xl: '16px' }}
              fontWeight={500}
              border={'1px solid'}
              isLoading={loading}
              color={'#fff'}
              borderRadius={'25px'}
              padding={'20px 35px'}
              h={{ base: '35px', xl: '45px' }}
              textTransform={'uppercase'}
              _hover={{
                bgGradient: 'linear(to-r, #295377, #208C74)',
                color: '#fff',
              }}
              type="submit"
            >
              Search
            </Button>
          </Box>
          <Box>
            <Button
              bgGradient={'linear(to-r, #295377, #208C74)'}
              bg={'none'}
              fontSize={{ base: '12px', xl: '16px' }}
              fontWeight={500}
              border={'1px solid'}
              color={'#fff'}
              borderRadius={'25px'}
              padding={'20px 35px'}
              h={{ base: '35px', xl: '45px' }}
              textTransform={'uppercase'}
              _hover={{
                bgGradient: 'linear(to-r, #295377, #208C74)',
                color: '#fff',
              }}
              onClick={e => clearFilter(e)}
            >
              Clear filter
            </Button>
          </Box>
        </Stack>
      </form>

      {!data && <Spinner />}
      <Grid
        mt={'25px !important'}
        gridTemplateColumns={'repeat(2, 1fr)'}
        gap={6}
      >
        {data && data?.length > 0 ? (
          data?.map((val, index) => (
            <Box
              shadow={'0px 16px 38px 0px #0000000F'}
              bg={'#fff'}
              borderRadius={'16px'}
              p={4}
              display={'flex'}
              flexDirection={'column'}
              gap={4}
              key={index}
            >
              <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                px={4}
              >
                <HStack alignItems={'center'} lineHeight={'0.3rem'} gap={2}>
                  <Avatar
                    size={'lg'}
                    name="Dan Abrahmov"
                    // src={`${imgUrl}/${
                    //   selectedService === 'patient' ? item.picture : item.photo
                    // }`}
                    src={
                      `${imgUrl}/${val?.photo}` || 'https://bit.ly/dan-abramov'
                    }
                  />
                  <Stack>
                    <Heading fontSize={'22px'} fontWeight={'500'}>
                      {val?.name}
                    </Heading>
                    <Box w="100%">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Text fontSize="15px" color="#75767A" fontWeight="400">
                          {val?.subspeciality}
                        </Text>
                        <Icon
                          p={'0 !important'}
                          mt={'0 !important'}
                          as={FaStar}
                          color="#FEB052"
                        />
                        <Text fontSize="15px" color="#75767A" fontWeight="400">
                          {`|  ${val?.reviews} Reviews`}
                        </Text>
                      </Stack>
                    </Box>
                  </Stack>
                </HStack>
                {/* <Stack
                  onClick={() => handleFavourite(val?._id)}
                  cursor={'pointer'}
                >
                  <Icon
                    as={val?.isFavuorite ? FaHeart : FaRegHeart}
                    w="27px"
                    h="25px"
                    color="#208C74"
                  />
                </Stack> */}
              </Stack>
              <Stack pt={4}>
                <Button
                  w="100%"
                  bgGradient="linear(to-r, #295377, #208C74)"
                  color="#E9E9E9"
                  fontWeight={'500'}
                  fontSize={'15px'}
                  borderRadius={'21px'}
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                  as={Link}
                  to={`/dashboard/recruitment/doctor-profile/${val?._id}`}
                  //   to={`/dashboard/doctor-profile/${val?._id}?type=${type}`}
                >
                  View Profile
                </Button>
              </Stack>
            </Box>
          ))
        ) : (
          <Text fontSize={'18px'} fontWeight={'bold'} color={'#75767A'}>
            No doctors found
          </Text>
        )}
      </Grid>
    </Stack>
  );
}

export default Recruitment;
