import React, { useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  Checkbox,
  VStack,
  Input,
  FormLabel,
  useToast,
  Textarea,
  CheckboxGroup,
} from '@chakra-ui/react';
import { POST } from '../../../utilities/ApiProvider';
import { Navigate, useNavigate } from 'react-router-dom';

function DigitalLibrary() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [checkedValues, setCheckedValues] = useState({
    medicalDatabase: true,
    articles: false,
    researches: false,
    periodicals: false,
    digitalMedicalReferences: false,
  });
  const toast = useToast();
  const user = JSON.parse(localStorage.getItem('emediiUser'));
  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    console.log(name, checked);

    setCheckedValues(() => {
      return {
        ...checkedValues,
        [name]: checked,
      };
    });
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      form.append('requestedSubscription', JSON.stringify(checkedValues));
      form.append('hospital', user?.hospital);

      const formDataObject = Array.from(form.entries()).reduce(
        (acc, [key, value]) => {
          if (value !== '') {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      const response = await POST(
        '/admin/digital-program/application',
        formDataObject
      );
      if (response.status === 200) {
        setLoading(false);

        toast({
          description:
            'Your subscription request has been submitted successfully.',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        e.target.reset();
        setCheckedValues({
          medicalDatabase: true,
          articles: false,
          researches: false,
          periodicals: false,
          digitalMedicalReferences: false,
        });
      } else {
        setLoading(false);

        toast({
          description: 'Your subscription request has not submitted.',
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };
  return (
    <Stack>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Digital Medical Library Services Request for a Hospital
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all services...Check Now!
          </Text>
        </Stack>
        <Button
          w="200px"
          mt={'25px'}
          onClick={() => navigate('/dashboard/requested-digital-library')}
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          type="submit"
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
        >
          View Requested Services
        </Button>
      </HStack>

      <form onSubmit={handleSubmit} className="applyForm">
        <Text fontWeight={'500'} fontSize={'20px'}>
          Hospital Particulars:
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              name="hospitalName"
              value={user?.fullName}
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Address</FormLabel>
            <Input
              required
              name="address"
              type="text"
              value={user?.address}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              name="hospitalEmail"
              value={user?.email}
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Requesting Staff Particulars:
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Name of Requesting Staff</FormLabel>
            <Input
              required
              name="requestingStaffName"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Mobile No.</FormLabel>
            <Input
              required
              name="requestingStaffMobileNo"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Requested Subscription:
        </Text>
        <Stack pos={'relative'}>
          <Stack
            pos={'absolute'}
            m="0 !important"
            flexDirection={'row'}
            width={'100%'}
            top={-6}
            justifyContent={'end'}
            alignItems={'end'}
          >
            <Checkbox colorScheme="teal">All the above</Checkbox>
          </Stack>
        </Stack>
        <Box name="requestedSubscription" w="100%" className="checkboxFamily">
          <Stack
            spacing={0}
            gap={4}
            direction={'row'}
            flexDir={{ base: 'column', xl: 'row' }}
            flexWrap={'wrap'}
            alignItems={'center'}
          >
            <Box w="32.33%">
              <Checkbox
                colorScheme="teal"
                name="medicalDatabase"
                isChecked={checkedValues.medicalDatabase}
                defaultChecked={checkedValues.medicalDatabase}
                onChange={handleCheckboxChange}
              >
                Medical Databases
              </Checkbox>
            </Box>
            <Box w="32.33%">
              <Checkbox
                colorScheme="teal"
                name="articles"
                isChecked={checkedValues.articles}
                defaultChecked={checkedValues.articles}
                onChange={handleCheckboxChange}
              >
                Articles
              </Checkbox>
            </Box>
            <Box w="32.33%">
              <Checkbox
                colorScheme="teal"
                name="researches"
                isChecked={checkedValues.researches}
                defaultChecked={checkedValues.researches}
                onChange={handleCheckboxChange}
              >
                Researches
              </Checkbox>
            </Box>
            <Box w="32.33%">
              <Checkbox
                colorScheme="teal"
                name="periodicals"
                isChecked={checkedValues.periodicals}
                defaultChecked={checkedValues.periodicals}
                onChange={handleCheckboxChange}
              >
                Periodicals
              </Checkbox>
            </Box>
            <Box w="32.33%">
              <Checkbox
                colorScheme="teal"
                name="digitalMedicalReferences"
                isChecked={checkedValues.digitalMedicalReferences}
                defaultChecked={checkedValues.digitalMedicalReferences}
                onChange={handleCheckboxChange}
              >
                Digital Medical References
              </Checkbox>
            </Box>
          </Stack>
        </Box>
        <Button
          w="200px"
          mt={'25px'}
          isLoading={loading}
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          type="submit"
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
        >
          Apply
        </Button>
      </form>
    </Stack>
  );
}

export default DigitalLibrary;
