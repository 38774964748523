import Header from "../../components/Website/Header";
import Footer from "../../components/Website/Footer";
import Testimonials from "../../components/Website/testimonials";
import MiniBanner from "../../components/Website/banner/MiniBanner";
import ServicesList from "../../components/Website/services/ServicesList.js";

export default function Services() {

    return (
        <>
            <Header />
          
            <ServicesList />
           
            <Footer />
        </>
    );
}