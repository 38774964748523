import React, { useEffect, useState } from 'react';

import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
  Spinner,
  Image,
  Icon,
} from '@chakra-ui/react';
import { GET } from '../../../utilities/ApiProvider';
import { Link } from 'react-router-dom';
import { FaHeart, FaRegHeart, FaStar } from 'react-icons/fa';
import { imgUrl } from '../../../utilities/config';
import moment from 'moment';

function NearByDoctors() {
  const checking = [
    {
      location: {
        type: 'Point',
        coordinates: [0, 0],
      },
      _id: '668bcefa2cea0759698b64f2',
      category: '66333e887fb6262a8d42e62e',
      doctorCategory: '',
      tagline: '',
      name: 'sadads',
      employer: 'asdads',
      employmentAddress: 'sdaasd',
      email: 'dasdsa',
      generalSpeciality: 'asdasd',
      subspeciality: 'dsasad',
      academicQualification: 'asdassd',
      yearsOfExperience: 'sads',
      medicalTreatmentService: 'saddsa',
      charges: 232,
      currency: '0',
      opening_hours: [
        {
          day: 'Monday',
          startTime: '09:00',
          endTime: '22:00',
          on: true,
          _id: '668bcefa2cea0759698b64f3',
        },
        {
          day: 'Tuesday',
          startTime: '09:00',
          endTime: '22:00',
          on: true,
          _id: '668bcefa2cea0759698b64f4',
        },
        {
          day: 'Wednesday',
          startTime: '09:00',
          endTime: '22:00',
          on: true,
          _id: '668bcefa2cea0759698b64f5',
        },
        {
          day: 'Thursday',
          startTime: '09:00',
          endTime: '22:00',
          on: true,
          _id: '668bcefa2cea0759698b64f6',
        },
        {
          day: 'Friday',
          startTime: '09:00',
          endTime: '22:00',
          on: true,
          _id: '668bcefa2cea0759698b64f7',
        },
        {
          day: 'Saturday',
          startTime: '09:00',
          endTime: '22:00',
          on: true,
          _id: '668bcefa2cea0759698b64f8',
        },
        {
          day: 'Sunday',
          startTime: '09:00',
          endTime: '22:00',
          on: true,
          _id: '668bcefa2cea0759698b64f9',
        },
      ],
      hospital: {
        _id: '664211a1b37650cc158c2be7',
        name: 'King Faisal',
        email: 'kingfaisal@gmail.com',
        createdAt: '2024-05-21T10:02:02.386Z',
      },
      department: '6683aa31eb2ca4eb2b66d15f',
      rating: 0,
      photo: 'doctors/pictures/1720438522176_check.png',
      cv: '',
      graduation: '',
      createdAt: '2024-07-08T11:35:22.187Z',
      updatedAt: '2024-07-08T11:35:22.187Z',
      isFavourite: false,
    },
  ];
  const appointment = {};
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchNearByDoctors = async () => {
      try {
        const response = await GET('/web/doctor/nearby');
        if (response.status === 200) {
          console.log(response.data);
          setData(response.data);
        } else {
          setData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchNearByDoctors();
  }, []);
  //   if (!data) {
  //     return (
  //       <Stack w="100%" h="100vh" justifyContent={'center'} alignItems={'center'}>
  //         <Spinner />
  //       </Stack>
  //     );
  //   }
  return (
    <Stack>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Stack>
          <Heading
            display={'flex'}
            alignItems={'end'}
            fontSize={'36px'}
            fontWeight={'500'}
          >
            Near By Doctors /{' '}
            <Text color={'#208C74'} fontSize={'20px'}>
              Dashboard
            </Text>
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all services...Check Now!
          </Text>
        </Stack>
      </HStack>
      <Stack my={'20px !important'}>
        <Text fontSize={'22px'} fontWeight={500}>
          Near By Doctors?
        </Text>
      </Stack>
      <Grid gridTemplateColumns="repeat(2, 1fr)" gap={6}>
        {checking?.map((val, index) => (
          <Box
            key={index}
            shadow={'lg'}
            w="100%"
            borderRadius={'16px'}
            bg={'#fff'}
            p={5}
          >
            <HStack justifyContent={'space-between'}>
              <HStack alignItems={'center'}>
                <WrapItem>
                  <Avatar
                    size="lg"
                    name="Prosper Otemuyiwa"
                    src={
                      `${imgUrl}/${val?.photo}` || 'https://bit.ly/dan-abramov'
                    }
                  />{' '}
                </WrapItem>
                <Stack justifyContent={'center'}>
                  <Heading fontWeight={'400'} fontSize={'22px'}>
                    {val?.name || 'John Doe'}
                  </Heading>
                  <Text
                    lineHeight={0}
                    fontSize={'16px'}
                    fontWeight={'400'}
                    color={'#75767A'}
                  >
                    #d44vdsQ
                  </Text>
                </Stack>
              </HStack>
            </HStack>
            <Stack gap={4} mt={4}>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Stack flex={2} margin={'0 !important'}>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Hospital
                  </Text>
                  <Text
                    lineHeight={'10px'}
                    fontSize={'16px'}
                    fontWeight={'500'}
                    color={'#1B1C1D'}
                  >
                    {val.hospital?.name || 'King Faisal'}
                  </Text>
                </Stack>
                <Stack flex={2} margin={'0 !important'}>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Academic Qualification
                  </Text>
                  <Text
                    lineHeight={'10px'}
                    fontSize={'16px'}
                    fontWeight={'500'}
                    color={'#1B1C1D'}
                  >
                    {val?.academicQualification}
                  </Text>
                </Stack>
                <Stack flex={2} margin={'0 !important'}>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    General Speciality
                  </Text>
                  <Text
                    lineHeight={'10px'}
                    fontSize={'16px'}
                    fontWeight={'500'}
                    color={'#1B1C1D'}
                  >
                    {val?.generalSpeciality || 'Consultation'}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        ))}
      </Grid>
    </Stack>
  );
}

export default NearByDoctors;
