import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Heading,
  Grid,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { GET } from '../../../utilities/ApiProvider';
import moment from 'moment/moment';
import { Link, useNavigate } from 'react-router-dom';

function RequestedTrainingProgram() {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState('pending');
  const user = JSON.parse(localStorage.getItem('emediiUser'));
  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const url =
          user?.type === 'superadmin'
            ? `/admin/study-program/application`
            : `/admin/study-program/application?hospital=${user?.hospital}`;

        const response = await GET(url);
        if (response.status === 200) {
          console.log(response.data);
          setData(response.data);
        } else {
          //   setData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchProgram();
  }, []);
  return (
    <Stack>
      {user?.type === 'superadmin' ? (
        <HStack alignItems={'center'} justifyContent={'space-between'}>
          <Stack>
            <Heading fontSize={'36px'} fontWeight={'500'}>
              Applied Medical Education Training Program
            </Heading>
            <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
              Here is the latest update...Check Now!
            </Text>
          </Stack>
          <HStack>
            <Button
              onClick={() => navigate('/dashboard/training-program')}
              bgGradient={'linear(to-r, #295377, #208C74)'}
              fontSize={'16px'}
              fontWeight={500}
              color={'#fff'}
              borderRadius={'25px'}
              h="45px"
              border={'2px solid transparent'}
              _hover={{
                bgGradient: 'none',
                borderColor: 'primaryGreen.200',
                color: 'primaryGreen.200',
              }}
            >
              View Training Program
            </Button>
            <Button
              onClick={() => navigate('/dashboard/add-training-program')}
              bgGradient={'linear(to-r, #295377, #208C74)'}
              fontSize={'16px'}
              fontWeight={500}
              color={'#fff'}
              borderRadius={'25px'}
              h="45px"
              border={'2px solid transparent'}
              _hover={{
                bgGradient: 'none',
                borderColor: 'primaryGreen.200',
                color: 'primaryGreen.200',
              }}
            >
              Add Training Program
            </Button>
          </HStack>
        </HStack>
      ) : (
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Applied Medical Education Training Program
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the latest update...Check Now!
          </Text>
        </Stack>
      )}

      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={{ base: '5px', xl: '20px' }}
        mb={'20px !important'}
      >
        <Button
          bgGradient={
            selectedService === 'pending'
              ? 'linear(to-r, #295377, #208C74)'
              : 'linear(to-b, #C2D4FF, #fff)'
          }
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight={500}
          color={selectedService === 'pending' ? '#fff' : 'primaryBlack.100'}
          borderRadius={'25px'}
          h={{ base: '35px', xl: '35px' }}
          onClick={() => setSelectedService('pending')}
          _hover={{
            bgGradient: 'linear(to-r, #295377, #208C74)',
            color: '#fff',
          }}
        >
          Requested
        </Button>
        <Button
          bgGradient={
            selectedService === 'accepted'
              ? 'linear(to-r, #295377, #208C74)'
              : 'linear(to-b, #C2D4FF, #fff)'
          }
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight={500}
          color={selectedService === 'accepted' ? '#fff' : 'primaryBlack.100'}
          borderRadius={'25px'}
          h={{ base: '35px', xl: '35px' }}
          onClick={() => setSelectedService('accepted')}
          _hover={{
            bgGradient: 'linear(to-r, #295377, #208C74)',
            color: '#fff',
          }}
        >
          Accepted
        </Button>
        <Button
          bgGradient={
            selectedService === 'rejected'
              ? 'linear(to-r, #295377, #208C74)'
              : 'linear(to-b, #C2D4FF, #fff)'
          }
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight={500}
          color={selectedService === 'rejected' ? '#fff' : 'primaryBlack.100'}
          borderRadius={'25px'}
          h={{ base: '35px', xl: '35px' }}
          onClick={() => setSelectedService('rejected')}
          _hover={{
            bgGradient: 'linear(to-r, #295377, #208C74)',
            color: '#fff',
          }}
        >
          Rejected
        </Button>
      </Stack>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {data?.[selectedService]?.length > 0 ? (
          data?.[selectedService]?.map(item => (
            <Box
              shadow={'0px 16px 38px 0px #0000000F'}
              bg={'#fff'}
              borderRadius={'16px'}
              p={4}
              display={'flex'}
              flexDirection={'column'}
              gap={4}
            >
              <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                px={4}
              >
                <HStack alignItems={'center'} lineHeight={'0.3rem'} gap={2}>
                  <Stack>
                    <Heading fontSize={'22px'} fontWeight={'500'}>
                      {item?.programName}
                    </Heading>
                    <Text
                      fontSize={'15px'}
                      color={'#75767A'}
                      fontWeight={'400'}
                    >
                      #{item?._id?.slice(0, 6)}
                    </Text>
                  </Stack>
                </HStack>
              </Stack>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                px={4}
              >
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Date Added
                  </Text>
                  <Text fontSize={'16px'} fontWeight={'500'}>
                    {moment(item?.studyProgram?.createdAt).format(
                      'MM / DD / YYYY'
                    ) || moment(new Date()).format('MM / DD / YYYY')}
                  </Text>
                </Stack>
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Program Type
                  </Text>
                  <Text
                    textTransform={'capitalize'}
                    fontSize={'16px'}
                    fontWeight={'500'}
                  >
                    {item?.studyProgram?.type || 'Study-Program'}
                  </Text>
                </Stack>
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Duration
                  </Text>
                  <Text fontSize={'16px'} fontWeight={'500'}>
                    {item?.studyProgram?.duration || '1 week'}
                  </Text>
                </Stack>
              </Stack>

              <Button
                onClick={() =>
                  navigate(`/dashboard/training-program-apply`, { state: item })
                }
                bgGradient={'linear(to-r, #295377, #208C74)'}
                fontSize={'16px'}
                fontWeight={500}
                color={'#fff'}
                borderRadius={'25px'}
                h="45px"
                w="100%"
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
              >
                View Application
              </Button>
            </Box>
          ))
        ) : (
          <Heading fontSize={'22px'} fontWeight={'500'}>
            No Data Found
          </Heading>
        )}
      </Grid>
    </Stack>
  );
}

export default RequestedTrainingProgram;
