import { Image, Stack, Box } from '@chakra-ui/react';

import authBg from '../../assets/images/website/authBg.png';

import { useEffect, useState } from 'react';
import Forget from './Forget';
import Verification from './Verification';
import NewPassword from './newPassword';

export default function Main() {
  const [state, setState] = useState(false);
  const [otpVerfied, setOtp] = useState(false);
  const email = localStorage.getItem('emedieeForgetEmail');
  const otp = localStorage.getItem('emedieeForgetOtp');

  return (
    <Stack position={'relative'} height={'100vh'} p="0">
      {email && otp ? (
        <NewPassword />
      ) : email && !otp ? (
        <Verification setOtp={setOtp} />
      ) : (
        <Forget setState={setState} />
      )}

      <Image
        display={{ base: 'none', lg: 'block' }}
        src={authBg}
        w={{lg: '505px',xl: '605px'}}
        h="100%"
        position={'absolute'}
        top="0"
        right="0"
        mt="0 !important"
      />
    </Stack>
  );
}
