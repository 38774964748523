import {
  Heading,
  Stack,
  Text,
  Box,
  Grid,
  Icon,
  Button,
  useToast,
  Image,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { RiHospitalLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { MdOutlineDelete } from 'react-icons/md';
import { GET, DELETE } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';

export default function WebServices() {
  const toast = useToast();
  const [data, setData] = useState(null);
  const [selectedService, setSelectedService] = useState('patients');

  useEffect(() => {
    fetchBlogs();
  }, []);
  const fetchBlogs = async () => {
    try {
      const response = await GET('/admin/get-all-services');
      if (response.status === 200) {
        setData(response.data);
        console.log(response.data);
      } else {
        handleFetchError();
      }
    } catch (error) {
      handleFetchError(error);
    }
  };

  const handleFetchError = (error = null) => {
    if (error) {
      console.error('While Fetching Blogs', error);
    }
    toast({
      title: 'Error',
      description: 'Something went wrong while fetching the blogs.',
      status: 'error',
      duration: 2000,
      position: 'top-right',
      isClosable: true,
    });
  };
  const handleDelete = async id => {
    try {
      const response = await DELETE(`/admin/delete-service/${id}`);
      if (response.status === 200) {
        fetchBlogs();
        toast({
          description: 'Service deleted successfully.',
          status: 'success',
          duration: 2000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        handleFetchError();
      }
    } catch (error) {
      handleFetchError(error);
    }
  };
  useEffect(() => {
    console.log(
      data?.find(item => item.serviceName === selectedService)?.service
    );
  }, [selectedService]);
  return (
    <Stack gap={4}>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
      >
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Services
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all Services...Check Now!
          </Text>
        </Stack>
        <Button
          as={Link}
          to={'/dashboard/hospital-addservicesdetails'}
          bg={
            'var(--Gradient-1, linear-gradient(109deg, #295377 -22.91%, #208C74 93.87%))'
          }
          color={'#fff'}
          // px={8}
          w={'max-content'}
          // py={6}
          borderRadius={25}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
        >
          Add New
        </Button>
      </Stack>
      <Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'20px'}>
          <Button
            bgGradient={
              selectedService === 'patients' && 'linear(to-r, #295377, #208C74)'
            }
            bg={'none'}
            fontSize={{ base: '12px', xl: '16px' }}
            fontWeight={500}
            color={selectedService === 'patients' ? '#fff' : 'primaryBlack.100'}
            borderRadius={'25px'}
            h={{ base: '35px', xl: '45px' }}
            textTransform={'uppercase'}
            onClick={() => setSelectedService('patients')}
            _hover={{
              bgGradient: 'linear(to-r, #295377, #208C74)',
              color: '#fff',
            }}
          >
            Patient
          </Button>
          <Button
            bgGradient={
              selectedService === 'Practitioners' &&
              'linear(to-r, #295377, #208C74)'
            }
            bg={'none'}
            fontSize={{ base: '12px', xl: '16px' }}
            fontWeight={500}
            color={
              selectedService === 'Practitioners' ? '#fff' : 'primaryBlack.100'
            }
            borderRadius={'25px'}
            h={{ base: '35px', xl: '45px' }}
            textTransform={'uppercase'}
            onClick={() => setSelectedService('Practitioners')}
            _hover={{
              bgGradient: 'linear(to-r, #295377, #208C74)',
              color: '#fff',
            }}
          >
            Practitioners
          </Button>
          <Button
            bgGradient={
              selectedService === 'hospital' && 'linear(to-r, #295377, #208C74)'
            }
            bg={'none'}
            fontSize={{ base: '12px', xl: '16px' }}
            fontWeight={500}
            color={selectedService === 'hospital' ? '#fff' : 'primaryBlack.100'}
            borderRadius={'25px'}
            h={{ base: '35px', xl: '45px' }}
            textTransform={'uppercase'}
            onClick={() => setSelectedService('hospital')}
            _hover={{
              bgGradient: 'linear(to-r, #295377, #208C74)',
              color: '#fff',
            }}
          >
            Hospital
          </Button>
        </Stack>
        <Text fontSize={'20px'} fontWeight={'500'}>
          You've 7 services
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          {data &&
            data
              ?.find(item => item.serviceName === selectedService)
              ?.service?.map((item, index) => {
                return (
                  <Box
                    // border={'2px solid #75767A'}
                    shadow={'0px 16.32px 38.75px 0px #0000000F'}
                    borderRadius={'17px'}
                    p={{ base: '15px 20px', xl: '25px 40px' }}
                    display={'flex'}
                    // flexDir={'column'}
                    key={index}
                    alignItems={'center'}
                    // justifyContent={'center'}
                    role="group"
                    transition={'0.3s'}
                    _hover={{
                      borderColor: 'primaryGreen.200',
                    }}
                  >
                    <Stack flex={2}>
                      <Box
                        w={'54px'}
                        h={'54px'}
                        bgColor={'#E2E2E2'}
                        display={'flex'}
                        alignItems={'center'}
                        transition={'0.3s'}
                        borderRadius={'12px'}
                        mb="5px"
                        justifyContent={'center'}
                        _groupHover={{
                          bgColor: 'primaryGreen.200',
                        }}
                      >
                        {/* <Icon
                          _groupHover={{
                            color: '#fff',
                          }}
                          transition={'0.3s'}
                          fontSize={'30px'}
                          // fontSize={{ base: '26px', xl: '52px' }}
                          as={`${imgUrl}/${item.icon}`}
                          // as={RiHospitalLine}
                        /> */}
                        <Image
                          src={`${imgUrl}/${item.icon}`}
                          boxSize={'30px'}
                          alt="icon"
                        />
                      </Box>
                      <Heading w={'60%'} fontSize={'18px'} fontWeight={'500'}>
                        {item.title}
                      </Heading>
                    </Stack>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      flexWrap={'wrap'}
                    >
                      <Button
                        as={Link}
                        to={`/dashboard/hospital-service/${item._id}`}
                        // as={Link}
                        // to={item.url}
                        bgGradient={'linear(to-r, #295377, #208C74)'}
                        fontSize={{ base: '12px', xl: '14px' }}
                        fontWeight={400}
                        color={'#fff'}
                        borderRadius={'25px'}
                        w="122px"
                        h="34px"
                        border={'2px solid transparent'}
                        _hover={{
                          bgGradient: 'none',
                          borderColor: 'primaryGreen.200',
                          color: 'primaryGreen.200',
                        }}
                      >
                        View Service
                      </Button>
                      <Button
                        // as={Link}
                        // to={'/'}
                        onClick={() => handleDelete(item._id)}
                        border={'2px solid #348874'}
                        width={'45px'}
                        height={'45px'}
                        borderRadius={'50%'}
                        bg={'transparent'}
                      >
                        <Icon
                          color={'#348874'}
                          fontSize={'20px'}
                          as={MdOutlineDelete}
                        />
                      </Button>
                    </Stack>
                  </Box>
                );
              })}
        </Grid>
      </Stack>
    </Stack>
  );
}
