import React, { useEffect, useState } from 'react';

import {
  Stack,
  Heading,
  Text,
  Box,
  Button,
  FormLabel,
  Input,
  useToast,
  Checkbox,
  Textarea,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { POST } from '../../../utilities/ApiProvider';

function Offer() {
  const data = JSON.parse(localStorage.getItem('emediiUser') ?? '{}');
  const [checkedValues, setCheckedValues] = useState('');
  const { id } = useParams();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = event => {
    setCheckedValues(event.target.name);
  };
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      const form = new FormData(e.target);
      form.append('contractType', checkedValues);
      form.append('doctor', id);
      form.append('hospital', data?.hospital);
      //   const formDataEntries = Array.from(form.entries()).map(
      //     ([key, value]) => ({
      //       [key]: value,
      //     })
      //   );

      const response = await POST('/hospital/doctor-job-offer', form);
      if (response.status === 200) {
        setLoading(false);
        toast({
          title: 'Job Offer Sent Successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        e.target.reset();
      }
    } catch (error) {
      setLoading(false);

      console.log('Erorr while submitting form ', error);
    }
  };
  return (
    <Stack>
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'end'}
          fontSize={'36px'}
          fontWeight={'500'}
          textTransform={'capitalize'}
        >
          Job Offer Form For Health Practitioner
        </Heading>
      </Stack>
      <form onSubmit={handleSubmit} className="applyForm">
        <Text fontWeight={'500'} fontSize={'20px'}>
          Hospital Particulars:
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              name="name"
              type="text"
              defaultValue={data?.fullName}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Address</FormLabel>
            <Input
              required
              name="address"
              defaultValue={data?.address}
              type="text"
              placeholder="Enter Address"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              name="email"
              defaultValue={data?.email}
              type="text"
              placeholder="Enter Email"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Name of the Staff in cahrge of the offer</FormLabel>
            <Input
              required
              name="requestingStaffName"
              type="text"
              placeholder="Enter Name of the Staffe"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Mobile No.</FormLabel>
            <Input
              required
              name="number"
              defaultValue={data?.number}
              type="text"
              placeholder="Enter Mobile No."
            />
          </Box>
        </Stack>

        <Text fontWeight={'500'} fontSize={'20px'}>
          Information of the Job Offer:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Job Title</FormLabel>
            <Input required name="title" type="text" placeholder="Enter Job Title" />
          </Box>
          <Box w="100%">
            <FormLabel>Basic Salary</FormLabel>
            <Input
              required
              name="salary"
              type="text"
              placeholder="Enter Basic Salary"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Duration of the Contract</FormLabel>
            <Input
              required
              name="durationOfContract"
              type="text"
              placeholder="Enter Duration"
            />
          </Box>
        </Stack>
        <Box
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <FormLabel>Allowances</FormLabel>
          <Textarea
            h="100px !important"
            name="allowances"
            placeholder='Enter Allowances'
            resize={'none'}
          ></Textarea>
        </Box>
        <Box
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <FormLabel>Other Benefits </FormLabel>
          <Textarea
            h="100px !important"
            name="otherBenefits"
            placeholder='Enter Other Benefits'
            resize={'none'}
          ></Textarea>
        </Box>
        <Box w="100%">
          <FormLabel>Number of Working Hours per Month</FormLabel>
          <Input
            required
            name="workingHours"
            type="text"
            placeholder="Enter No of Hours"
          />
        </Box>
        <Text fontWeight={'500'} my={6} fontSize={'20px'}>
          Type of Contract:
        </Text>
        <Box w="100%" className="checkboxFamily">
          <Stack
            spacing={0}
            gap={4}
            direction={'row'}
            flexDir={{ base: 'column', xl: 'row' }}
            justifyContent={'space-between'}
          >
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="annual"
                isChecked={checkedValues === 'annual'}
                onChange={handleCheckboxChange}
              >
                Annual
              </Checkbox>
            </Box>
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="partTime"
                isChecked={checkedValues === 'partTime'}
                onChange={handleCheckboxChange}
              >
                Part-time
              </Checkbox>
            </Box>
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="temporary"
                isChecked={checkedValues === 'temporary'}
                onChange={handleCheckboxChange}
              >
                Temporary
              </Checkbox>
            </Box>
          </Stack>
        </Box>

        <Button
          w="200px"
          mt={'15px'}
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          type="submit"
          isLoading={loading}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
        >
          Send
        </Button>
      </form>
    </Stack>
  );
}

export default Offer;
