import React, { useEffect, useState, useRef } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  IconButton,
} from '@chakra-ui/react';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import Doctor from '../../../assets/images/dashboard/doctor.png';
import { IoIosAttach, IoMdSend } from 'react-icons/io';
import { GET } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import { io } from 'socket.io-client';
import ScrollToBottom from 'react-scroll-to-bottom';

import moment from 'moment';
import { useParams } from 'react-router-dom';
// const socket = io('e-medical.thewebtestlink.xyz:5401');
const socket = io('wss://e-medical.thewebtestlink.xyz', {
  path: '/socket.io',
  transports: ['websocket'],
  EIO: 4,
});
console.log('socket', socket);
export default function Chat() {
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [roomId, setRoomId] = useState(null);
  const [appointments, setAppointments] = useState(null);
  const messageEl = useRef(null);
  let user = JSON.parse(localStorage.getItem('emediiUser') ?? '{}');
  const { id } = useParams();
  async function fetchAppointments() {
    try {
      const uniquePatients = [];
      const patientIdSet = new Set();

      const response = await GET('/web/appointments');
      console.log('response', response.data);
      if (response.status === 200) {
        if (user?.type === 'patient') {
          response.data.ongoing.forEach(doctor => {
            if (!patientIdSet.has(doctor.doctorId)) {
              uniquePatients.push(doctor);
              patientIdSet.add(doctor.doctorId);
              // console.log('doctor', doctor);
            }
          });
          setAppointments(uniquePatients);
        } else {
          response.data.ongoing.forEach(patient => {
            if (!patientIdSet.has(patient.patientId)) {
              uniquePatients.push(patient);
              patientIdSet.add(patient.patientId);
              console.log('patient', uniquePatients);
            }
          });
          setAppointments(uniquePatients);
        }
      } else setAppointments([]);
    } catch (error) {
      console.log('Error : ', error);
    }
  }
  async function fetchAppointmentsById() {
    try {
      const response = await GET(`/admin/get-all-appointments/${id}`);
      if (response.status === 200) {
        console.log('response', response.data);
        setAppointments(response.data);
      }
    } catch (error) {
      console.log('Error : ', error);
    }
  }
  useEffect(() => {
    if (id) {
      fetchAppointmentsById();
    } else {
      fetchAppointments();
    }
  }, []);

  useEffect(() => {
    if (messageEl) {
      console.log('messageEl', messageEl);
      // messageEl.current.addEventListener('DOMNodeInserted', event => {
      //   const { currentTarget: target } = event;
      //   target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      // });
    }
  }, []);
  useEffect(() => {
    // console.log('Socket', socket);
    socket.on('connected', () => {
      console.log('Connected to server', socket);
    });

    socket.emit('getDrList', {
      userId: user?._id,
    });
    socket.on('getDrList', data => {
      console.log('getDrList', data);
      // setDrList(data);
    });
    socket.on('chat', value => {
      const { data, roomId } = value;
      setRoomId(roomId);
      if (data || data !== null) {
        console.log('data', data);
        setMessages(data?.[0]?.messages);
      }
    });

    socket.on('getMessage', data => {
      // let temp = [...messages];
      // temp.push(data);
      // console.log('temp', temp);
      // setMessages(temp);
      // setMessages([...messages, temp?.[0]]);
      setMessages(prevMessages => [...prevMessages, data]);
    });
  }, [socket]);

  const handleSubmit = e => {
    e.preventDefault();
    const message = e.target.message.value;
    if (message) {
      // setMessages(prevMessages => [
      //   ...prevMessages,
      //   { sender: user?.doctor?._id, content: message },
      // ]);
      // setMessages([
      //   ...messages,
      //   { sender: user?.doctor?._id, content: message },
      // ]);
      socket.emit('sendMessage', {
        roomId: roomId,
        sender: user?.doctor?._id || user?.doctor,
        message: message,
      });

      e.target.message.value = '';
    }
  };

  const handleSelectedChat = val => {
    if (user?.type === 'patient') {
      socket.emit('getChat', {
        reciever: val?.doctorId,
        sender: user?.doctor?._id || user?.doctor,
        appointment: val?._id,
        hospital: val?.hospital,
      });
      setSelectedChat(val);
    } else {
      socket.emit('getChat', {
        reciever: val?.patientId,
        sender: user?.doctor?._id || user?.doctor,
        appointment: val?._id,
        hospital: val?.hospital,
      });
      setSelectedChat(val);
    }
  };

  // useEffect(() => {
  //   console.log(messages);
  // }, [messages]);

  return (
    <Stack>
      <Grid display={'flex'} e w="100%">
        <GridItem width={'25%'}>
          <Stack mb="20px">
            <Heading fontSize={'36px'} fontWeight={'500'}>
              Chat
            </Heading>
            {/* <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
              Here is all the chat...Check Now!
            </Text> */}
          </Stack>
          <Stack
            className="chatUsersList"
            overflowY={'auto'}
            height={'70vh'}
            pr="10px"
          >
            {appointments?.map((val, ind) => {
              console.log('val', val);
              if (user?.type === 'patient') {
                return (
                  <Button
                    onClick={() => handleSelectedChat(val)}
                    p="35px 0"
                    key={ind}
                    display={'flex'}
                    alignItems={'center'}
                    borderRadius={'12px'}
                    transition={'.3s'}
                    gap="10px"
                    bgColor="transparent"
                    bgGradient={
                      selectedChat === ind
                        ? 'linear(to-r, primaryGreen.100, primaryGreen.200)'
                        : 'none'
                    }
                    color={selectedChat === ind ? '#fff' : 'primaryBlack.100'}
                    border={'1px solid #888888'}
                    _hover={{
                      bgGradient:
                        'linear(to-r, primaryGreen.100, primaryGreen.200)',
                      color: '#fff',
                    }}
                    _focusVisible={{ background: 'transparent' }}
                  >
                    <Image
                      src={`${imgUrl}/${val.assignedDoctorPhoto}`}
                      w="45px"
                      h="45px"
                      borderRadius={'100%'}
                    />
                    <Box textAlign={'left'}>
                      <Heading fontWeight={'600'} fontSize={'18px'}>
                        {val.assignedDoctor}
                      </Heading>
                      <Text
                        fontSize={'14px'}
                        fontWeight={'500'}
                        maxW={'200px'}
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                      >
                        Hey man, are you there? I need your suggestion regarding
                        my reserach.
                      </Text>
                    </Box>
                  </Button>
                );
              } else {
                return (
                  <Button
                    onClick={() => handleSelectedChat(val)}
                    p="35px 0"
                    key={ind}
                    display={'flex'}
                    alignItems={'center'}
                    borderRadius={'12px'}
                    transition={'.3s'}
                    gap="10px"
                    bgColor="transparent"
                    bgGradient={
                      selectedChat === ind
                        ? 'linear(to-r, primaryGreen.100, primaryGreen.200)'
                        : 'none'
                    }
                    color={selectedChat === ind ? '#fff' : 'primaryBlack.100'}
                    border={'1px solid #888888'}
                    _hover={{
                      bgGradient:
                        'linear(to-r, primaryGreen.100, primaryGreen.200)',
                      color: '#fff',
                    }}
                    _focusVisible={{ background: 'transparent' }}
                  >
                    <Image
                      src={`${imgUrl}/${val.patientPicture}`}
                      w="45px"
                      h="45px"
                      borderRadius={'100%'}
                    />
                    <Box textAlign={'left'}>
                      <Heading fontWeight={'600'} fontSize={'18px'}>
                        {val.patientName}
                      </Heading>
                      <Text
                        fontSize={'14px'}
                        fontWeight={'500'}
                        maxW={'200px'}
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                      >
                        Hey man, are you there? I need your suggestion regarding
                        my reserach.
                      </Text>
                    </Box>
                  </Button>
                );
              }
            })}
          </Stack>
        </GridItem>
        <GridItem width={'75%'}>
          {selectedChat !== null ? (
            <Stack borderLeft={'1px solid #888888'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                gap="10px"
                mb="15px"
                pb={'15px'}
                borderBottom={'1px solid #888'}
              >
                <Image
                  src={
                    user?.type === 'patient'
                      ? `${imgUrl}/${selectedChat?.assignedDoctorPhoto}`
                      : `${imgUrl}/${selectedChat?.patientPicture}`
                  }
                  w="75px"
                  h="75px"
                  borderRadius={'100%'}
                />
                <Box textAlign={'left'}>
                  <Heading fontWeight={'600'} fontSize={'18px'}>
                    {user?.type === 'patient'
                      ? selectedChat?.assignedDoctor
                      : selectedChat?.patientName}
                  </Heading>
                  <Text
                    fontSize={'14px'}
                    fontWeight={'500'}
                    maxW={'200px'}
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                    whiteSpace={'nowrap'}
                  >
                    Online
                  </Text>
                </Box>
              </Box>
              <Stack
                className="chatUsersList"
                overflowY={'auto'}
                height={'63vh'}
              >
                <ScrollToBottom className="chat-container">
                  <Stack px="20px">
                    {messages?.map((val, ind) => {
                      if (
                        val.sender !== (user?.doctor?._id || user?.doctor || id)
                      ) {
                        return (
                          <Box
                            w="100%"
                            display={'flex'}
                            alignItems={'flex-end'}
                            gap={'5px'}
                          >
                            <Image src={Doctor} w="25px" h="25px" />
                            <Text
                              maxW={'50%'}
                              bgGradient={'linear(to-r, #D9D9D9, #D9D9D9)'}
                              borderRadius={'12px'}
                              borderBottomLeftRadius={'0px'}
                              p="10px"
                            >
                              {val?.content}
                            </Text>
                            <Text
                              fontSize={'12px'}
                              textAlign={'right'}
                              color={'primaryGray.100'}
                            >
                              {moment(val.sentAt).format('h:mm A')}
                            </Text>
                          </Box>
                        );
                      } else {
                        return (
                          <Box
                            w="100%"
                            display={'flex'}
                            alignItems={'flex-end'}
                            gap={'5px'}
                            justifyContent={'end'}
                            flexDir={'row-reverse'}
                          >
                            <Image src={Doctor} w="25px" h="25px" />
                            <Text
                              maxW={'50%'}
                              bgGradient={
                                'linear(to-r, primaryGreen.100, primaryGreen.200)'
                              }
                              color={'#fff'}
                              borderRadius={'12px'}
                              borderBottomRightRadius={'0px'}
                              p="10px"
                            >
                              {val?.content}
                            </Text>
                            <Text
                              fontSize={'12px'}
                              textAlign={'right'}
                              color={'primaryGray.100'}
                            >
                              {moment(val.sentAt).format('h:mm A')}
                            </Text>
                          </Box>
                        );
                      }
                    })}
                  </Stack>
                </ScrollToBottom>
              </Stack>
              {(user?.type === 'practitioner' || user?.type === 'patient') && (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="flex"
                    alignItems="center"
                    borderWidth="1px"
                    borderRadius="md"
                    padding="2"
                    mx={'10px !important'}
                  >
                    <IconButton
                      aria-label="Attach file"
                      icon={<IoIosAttach />}
                      variant="ghost"
                      size="md"
                    />
                    <Input
                      placeholder="Write here..."
                      name="message"
                      variant="unstyled"
                      marginLeft="2"
                      flex="1"
                    />
                    <IconButton
                      type="submit"
                      aria-label="Send"
                      icon={<IoMdSend />}
                      colorScheme="teal"
                      size="md"
                    />
                  </Box>
                </form>
              )}
            </Stack>
          ) : null}
        </GridItem>
      </Grid>
    </Stack>
  );
}
