import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Heading,
  Icon,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { AttachmentIcon } from '@chakra-ui/icons';
import { GET, POST } from '../../../utilities/ApiProvider';
import { useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import { useNavigate } from 'react-router-dom';

function AddStaff() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const getStaffType = async () => {
      try {
        const response = await GET('/hospital/employee/roles');
        if (response.status === 200) {
          setRole(response.data);
        } else {
          setRole([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getStaffType();
  }, []);
  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleButtonClick = () => {
    document.getElementById('file-input').click();
  };
  const handleSubmit = async e => {
    try {
      setLoading(true);
      e.preventDefault();
      const form = new FormData(e.target);
      form.append('picture', selectedFile);
      form.append(
        'hospital',
        JSON.parse(localStorage.getItem('emediiUser'))?.hospital
      );
      const formDataEntries = Array.from(form.entries());
      if (
        formDataEntries?.some(
          ([key, value]) =>
            value === '' || value === null || value === undefined
        )
      ) {
        toast({
          description: 'Please fill all the required fields',
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        setLoading(false);
        return;
      }

      const response = await POST('/hospital/employee', form);
      if (response.status === 200) {
        setLoading(false);
        toast({
          description: 'Hospital Staff created Sucessfully!!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        setLoading(false);
        e.target.reset();
        setSelectedFile(null);
        navigate('/dashboard/hospital-staff');
      } else {
        toast({
          description: response?.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        setLoading(false);
      }
      console.log(response);
    } catch (error) {
      setLoading(false);
      console.log('Erorr while submitting form ', error);
    }
  };

  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Add New Hospital Staff
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Enter the doctor details to add
        </Text>
      </Stack>
      <form className="applyForm" id="registerForm" onSubmit={handleSubmit}>
        <Box
          w="100%"
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          gap={1}
        >
          <Box w="50%" mb="10px">
            <FormLabel>Staff Type</FormLabel>
            <Select name="role" placeholder="Choose here">
              {role?.map(role => (
                <option key={role?._id} value={role?._id}>
                  {role?.name}
                </option>
              ))}
              {/* <option value="male">Male</option>
              <option value="female">Female</option> */}
            </Select>
          </Box>
          <Box w="50%" mb="10px">
            <FormLabel>Full Name</FormLabel>
            <Input name="fullName" placeholder="Enter here" />
          </Box>
          <Box w="50%" mb="10px">
            <FormLabel>Email</FormLabel>
            <Input name="email" placeholder="Enter here" />
          </Box>
          <Box w="50%" mb="10px">
            <FormLabel>Phone No.</FormLabel>
            <PhoneInput
              name="number"
              required
              containerStyle={{ background: 'none', border: 'none' }}
              inputStyle={{ background: 'none', border: 'none' }}
              dropdownStyle={{ background: 'none', border: 'none' }}
              id="phone-input"
              defaultCountry="sa"
              inputClass="custom-phone-input"
            />
          </Box>

          <Box w="50%" mb="10px">
            <FormLabel>Staff Address</FormLabel>
            <Input name="address" type="text" placeholder="Enter here" />
          </Box>

          <Box w="50%">
            <FormLabel>Photo</FormLabel>
            <Input
              type="file"
              id="file-input"
              display="none"
              onChange={handleFileChange}
            />
            <Text fontSize="14px" color="primaryGray.100" textAlign={'right'}>
              <Button
                cursor={'pointer'}
                as="span"
                border="1px solid #75767A"
                mb="10px"
                bgColor="transparent"
                fontSize="14px"
                w="100%"
                color="primaryGray.100"
                transition=".3s"
                _hover={{
                  bgColor: 'primaryGreen.200',
                  color: '#fff',
                  borderColor: 'primaryGreen.200',
                }}
                mr="10px"
                onClick={handleButtonClick}
              >
                <AttachmentIcon mr="10px" /> Attach File
              </Button>
              {selectedFile ? selectedFile.name : 'No File chosen'}
            </Text>
          </Box>
          <Box w="50%">
            <Button
              bgGradient={'linear(to-r, #295377, #208C74)'}
              fontSize={'16px'}
              fontWeight={500}
              color={'#fff'}
              borderRadius={'25px'}
              h="45px"
              px="80px"
              w={'100%'}
              type="submit"
              isLoading={loading}
              border={'2px solid transparent'}
              _hover={{
                bgGradient: 'none',
                borderColor: 'primaryGreen.200',
                color: 'primaryGreen.200',
              }}
            >
              Add Staff
            </Button>
          </Box>
        </Box>
      </form>
    </Stack>
  );
}

export default AddStaff;
