import { Stack, Text } from '@chakra-ui/react';
import Header from '../../components/Website/Header';
import Footer from '../../components/Website/Footer';
import Banner from '../../components/Website/banner';
import About from '../../components/Website/about';
import Services from '../../components/Website/services';
import AccTypeSec from '../../components/Website/accTypeSec';
import Cta from '../../components/Website/cta';
import Choose from '../../components/Website/choose';
import Counter from '../../components/Website/counter';
import Expert from '../../components/Website/expert';
import Work from '../../components/Website/work';
import Faq from '../../components/Website/faq';
import Testimonials from '../../components/Website/testimonials';
import Blog from '../../components/Website/blog';
import { useEffect } from 'react';

export default function Home() {
  return (
    <>
      <Header />
      <Banner />
      <About />
      {/* <Services /> */}
      {/* <Work /> */}
      {/* <AccTypeSec /> */}
      {/* <Cta /> */}
      <Choose />
      <Counter />
      <Expert />
      <Faq />
      <Testimonials />
      <Blog />
      <Footer />
    </>
  );
}
