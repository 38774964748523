import { Button, Heading, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function DummyTrainingTExt() {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack
        bg="#fff"
        borderRadius={'16px'}
        p={4}
        boxShadow={'0px 16px 38px 0px #0000000F'}
        direction={'column'}
        gap={4}
      >
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Medical Training Education Program
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all services...Check Now!
          </Text>
        </Stack>
        <Text fontSize={'16px'} color={'#75767A'} fontWeight={'400'}>
          Booking virtual clinic appointments in referral and specialized
          hospitals worldwide is also essential for medical trainees seeking to
          verify diagnoses and treatment plans as part of their education. Many
          trainees look to receive recommendations for alternative treatments if
          the initial approach is not suitable, enhancing their clinical skills
          and knowledge. For this purpose, emedst.com enables hospitals to offer
          this service to medical trainees at no cost, along with a one-time
          free advertisement. Trainees can book these sessions, paying directly
          to the hospital’s account at the time of booking, thus facilitating
          practical learning in real-world scenarios.
        </Text>
        <Button
          onClick={() => navigate('/dashboard/training-program')}
          bgGradient={'linear(to-r, #295377, #208C74)'}
          fontSize={'16px'}
          fontWeight={500}
          color={'#fff'}
          borderRadius={'25px'}
          h="45px"
          px="80px"
          type="submit"
          border={'1px solid priamryGreen.200'}
          _hover={{
            border: '2px solid',
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
}

export default DummyTrainingTExt;
