import {
    Box,
    Button,
    Flex,
    Heading,
    Icon,
    IconButton,
    Input,
    SimpleGrid,
    Stack,
    Text,
     
  } from '@chakra-ui/react';
  import React from 'react';

  import { BsFileEarmarkCheck } from 'react-icons/bs';
  import { LuCornerLeftDown } from 'react-icons/lu';
  

export default function ViewInquiries() {
  return (
    <Stack gap={6} px={4} pb={6}>
    <Stack>
      <Flex alignItems={'end'} gap={3}>
      <Heading fontSize={'30px'} fontWeight={'700'}>
        Inquiry Details
      </Heading>
      <Text color={'#208C74'} fontWeight={700}>/ Help & Support Inquiries</Text>
      </Flex>
      <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
        Here is the all user details...Check Now!
      </Text>
    </Stack>
    <SimpleGrid columns={2} spacing={10}>
      <Stack gap={4}>
        <Stack>
          <Heading fontSize={'16px'}>Name</Heading>
          <Box
            borderRadius={6}
            py={4}
            px={3}
            border={'1px solid #e5e4e4'}
            bg={'#F8F8F8'}
          >
            <Text>John</Text>
          </Box>
        </Stack>
        <Stack>
          <Heading fontSize={'16px'}>Email Address</Heading>
          <Box
            borderRadius={6}
            py={4}
            px={3}
            border={'1px solid #e5e4e4'}
            bg={'#F8F8F8'}
          >
            <Text>johndoe534@gmail.com</Text>
          </Box>
        </Stack>
        <Stack>
          <Heading fontSize={'16px'}>Phone No.</Heading>
          <Box
            borderRadius={6}
            py={4}
            px={3}
            border={'1px solid #e5e4e4'}
            bg={'#F8F8F8'}
          >
            <Text>+(966) 763 282 46</Text>
          </Box>
        </Stack>
        <Stack>
          <Heading fontSize={'16px'}>Message</Heading>
          <Box
            borderRadius={6}
            py={4}
            px={3}
            border={'1px solid #e5e4e4'}
            bg={'#F8F8F8'}
          >
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{' '}
            </Text>
          </Box>
        </Stack>
      </Stack>
      <Stack>
        <Stack>
          <Heading fontSize={'16px'}>Attachment</Heading>
          <Box
            borderRadius={6}
            py={8}
            px={3}
            border={'1px solid #e5e4e4'}
            bg={'#F8F8F8'}
            textAlign={'center'}
          >
            <Icon fontSize={'50px'} as={BsFileEarmarkCheck} />
          </Box>
          <Button
            bg={'transparent'}
            borderRadius={'0'}
            width={'max-content'}
            color={'#75767A'}
            borderBottom={'1px solid #75767A'}
          >
            <Icon fontSize={'15px'} mr={2} as={LuCornerLeftDown} /> Download
            File
          </Button>
        </Stack>
      </Stack>
    </SimpleGrid>
    
  </Stack>
  )
}
