import Header from '../../components/Website/Header';
import Footer from '../../components/Website/Footer';
import About from '../../components/Website/about';
import Testimonials from '../../components/Website/testimonials';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import AboutVision from '../../components/Website/about/AboutVision.js';
import ServiceInnerData from '../../components/Website/services/ServiceInnerData.js';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GET } from '../../utilities/ApiProvider.js';
import { Spinner, Stack } from '@chakra-ui/react';

export default function ServiceInner() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await GET(`/web/service/${id}`);
        if (response.status === 200) {
          setData(response.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />
      {loading ? (
        <Stack h={'50vh'} w={'100%'} align={'center'} justify={'center'}>
          <Spinner color={'primaryGreen'} />
        </Stack>
      ) : (
        <>
          <MiniBanner
            mainHeading={data?.title || 'Orthopedics'}
            name={'service_inner'}
          />
          <ServiceInnerData data={data} />
          <Testimonials />
        </>
      )}

      <Footer />
    </>
  );
}
