import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  useToast,
  useDisclosure,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

function Offer() {
  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Offers
        </Heading>
      </Stack>
      <Stack>
        <Text fontSize={'20px'} color={'#75767A'} fontWeight={'400'}>
          No Offers Found
        </Text>
      </Stack>
    </Stack>
  );
}

export default Offer;
