import Header from '../../components/Website/Header';
import Footer from '../../components/Website/Footer';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import BlogList from '../../components/Website/blog/BlogList';
import ApplyForm from '../../components/Website/expert/ApplyForm';
import {
  Box,
  Container,
  Heading,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

export default function Policy() {
  return (
    <>
      {/* <Header /> */}
      {/* <MiniBanner subHeading="Policy" mainHeading="Privacy Policy" /> */}
      <Container maxW={'6xl'}>
        <Box p={8} mx="auto">
          <Heading as="h2" size="xl" mb={6}>
            1. Introduction:
          </Heading>

          <Text mb={4} color="primaryGray.100">
            We welcome you to eMedst.com online. We have designed this document
            to help you understand how we obtain the personal information we
            receive from you and how to use and protect it. This document will
            constantly be updated in line with new technologies to meet the
            needs of our customers.
          </Text>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            2. Implied Consent by eMedst.com:
          </Heading>
          <UnorderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              Your use of eMedst.com automatically means that you agree to
              collect and use personal information in accordance with the
              requirements of this document. Accordingly, we reserve the right
              to make any change to this document and the subsequent change in
              its procedure without us having to notify you of the same.
            </ListItem>
          </UnorderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            3. Collect, Use and Store Customer Information:
          </Heading>
          <Text mb={4} color="primaryGray.100">
            When you visit eMedst.com, you provide us with the following:
          </Text>
          <OrderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              <b>Personal Information: </b> This is the information you need to
              disclose and is collected individually. eMedst.com uses this
              information to provide you with the services you have requested
              and may be used for other purposes, including but not limited to
              understanding customers’ medical needs and updating your data with
              us whether by us or by providers of services through us.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              <b>Private Information: </b> This information is collected by
              using eMedst.com on a collective basis through you and others who
              are browsing eMedst.com. This information enables us to track how
              the visitor navigates through the sections of the site in which
              you are interested. We collect information such as the number of
              visits to eMedst.com and page visits. The duration of user
              sessions is similar to a movement report and does not track the
              tendencies of individuals and members and their behavior in
              general, nor does it determine the behavior of a specific
              individual. This information we collect from you helps us know the
              services that our users seek.
            </ListItem>
          </OrderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            4. Information Sent to eMedst.com:
          </Heading>

          <OrderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              These are all the information and materials sent to eMedst.com or
              provided to them by eMedst.com, provided that such information and
              materials do not violate or contain what is considered a violation
              of applicable laws and regulations.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              eMedst.com is not responsible for the confidentiality of this
              information or materials except in certain cases, including the
              existence of an agreement with eMedst.com not to disclose
              information or if this is required by applicable laws and
              regulations, by a judicial order or a pertinent governmental body.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              Notwithstanding the provisions stated in clause 2.4 hereinabove,
              eMedst.com has the right to use all information, such as ideas,
              experiences and techniques, for any purposes and by all means.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              eMedst.com confirms that to this day there is no such thing as
              complete protection/complete security for using the Internet, and,
              therefore, the user bears all risks and is fully responsible for
              sending any information or materials provided to eMedst.com via
              the Internet.
            </ListItem>
          </OrderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            5. Disclosure of Information:
          </Heading>
          <OrderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              eMedst.com may send information related to customers and services,
              in addition to traffic models on eMedst.com and other information
              related to it, and to the extent permitted by the applicable laws
              and legislation, to its partners or to other known parties.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The personal information and data that eMedst.com sends to its
              partners does not include personal identifying data unless
              otherwise stated in this privacy document.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              eMedst.com may be forced to disclose any information if this is
              imposed on it by law and in accordance with the regulations,
              meaning that the law requires disclosure and/or information if
              such disclosure would protect its financial rights.
            </ListItem>
          </OrderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            6. Other Websites Linked to eMedst.com
          </Heading>
          <OrderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              eMedst.com confirms that it is not responsible for the practices
              carried out by any other sites linked to its site or platform, or
              for the information that includes these practices.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              The connection between eMedst.com and other sites is often no more
              than an indicator, i.e. the information that is related to topics
              that may be of interest to users of its site and platform.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              When the customer moves from eMedst.com to another site, he is not
              subject to or is outside the scope of the protection provided by
              this document.
            </ListItem>
            <ListItem color={'primaryGray.100'}>
              When you move from eMedst.com to other sites and platforms, you
              will be subject to the terms of such sites.
            </ListItem>
          </OrderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            7. Limits of Liability
          </Heading>
          <UnorderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              <Text mb={4} color={'primaryGray.100'}>
                Your review of this document and access to eMedst.com means your
                implied agreement to release eMedst.com and its subsidiaries,
                all directors of those companies, and all employees and agents,
                and not to hold any of them responsible for any claims resulting
                from claims, compensation, or any legal fees arising therefrom.
                Violation or misuse of this document or misuse of the site also
                includes any violation or misuse committed by any of your
                employees or agents, if any.EMedst.com website is not intended
                for children, and it does not knowingly include any information
                for children or data intended for use by children.
              </Text>
            </ListItem>
          </UnorderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            8. Illegal Use:
          </Heading>
          <UnorderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              <Text mb={4} color={'primaryGray.100'}>
                Customers are prohibited from using eMedst.com for any illegal,
                harmful, defamatory, obscene or threatening purposes.
              </Text>
            </ListItem>
          </UnorderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            9. Additional Conditions:
          </Heading>
          <UnorderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              <Text mb={4} color={'primaryGray.100'}>
                All terms and conditions contained in any service on eMedst.com
                or any of its publications form an integral part of this
                document.
              </Text>
            </ListItem>
          </UnorderedList>

          <Heading as="h2" size="lg" mt={6} mb={4}>
            11. Judicial Jurisdiction and Dispute Settlement:
          </Heading>
          <UnorderedList mb={4}>
            <ListItem color={'primaryGray.100'}>
              <Text mb={4} color={'primaryGray.100'}>
                This document shall be governed by and construed in accordance
                with the laws and regulations in force in the Kingdom of Saudi
                Arabia and any dispute that arises between the two parties, if
                it is not possible to resolve it amicably - between them, it
                will be decided by the pertinent courts in the Kingdom of Saudi
                Arabia.
              </Text>
            </ListItem>
          </UnorderedList>
          <Text textAlign={'center'} my={6} color={'primaryGray.100'}>
            ❖✅ We agree to the above terms, conditions and privacy policy."
          </Text>
        </Box>
      </Container>
      {/* <Footer /> */}
    </>
  );
}
