import {
  Box,
  Button,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { BsFileEarmarkCheck } from 'react-icons/bs';
import { LuCornerLeftDown } from 'react-icons/lu';
import { useLocation } from 'react-router-dom';
import { imgUrl } from '../../../utilities/config';

export default function InquiriesDetails() {
  const location = useLocation();
  const { state } = location;
  console.log(state);

  // const handleClick = url => {
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.download = 'document.jpg';
  //   // link.download = `document.${url.split('.').pop().split(/\#|\?/)[0]}`;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  const handleClick = url => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = `document.pdf`;
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Stack gap={6} px={4} pb={6}>
      <Stack>
        <Heading fontSize={'30px'} fontWeight={'700'}>
          Inquiry Details
        </Heading>
        <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
          Here is the all user details...Check Now!
        </Text>
      </Stack>
      <SimpleGrid columns={2} spacing={10}>
        <Stack gap={4}>
          <Stack>
            <Heading fontSize={'16px'}>Name</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={'1px solid #e5e4e4'}
              bg={'#F8F8F8'}
            >
              <Text>{state.fullName || 'John'}</Text>
            </Box>
          </Stack>
          <Stack>
            <Heading fontSize={'16px'}>Email Address</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={'1px solid #e5e4e4'}
              bg={'#F8F8F8'}
            >
              <Text>{state.email || 'johndoe534@gmail.com'}</Text>
            </Box>
          </Stack>
          <Stack>
            <Heading fontSize={'16px'}>Phone No.</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={'1px solid #e5e4e4'}
              bg={'#F8F8F8'}
            >
              <Text>{state.number || '+(966) 763 282 46'}</Text>
            </Box>
          </Stack>
          <Stack>
            <Heading fontSize={'16px'}>Address</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={'1px solid #e5e4e4'}
              bg={'#F8F8F8'}
            >
              <Text>
                {state.address ||
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}{' '}
              </Text>
            </Box>
          </Stack>
        </Stack>

        <Stack>
          {state.document && state.document !== '/' && (
            <Stack>
              <Heading fontSize={'16px'}>Attachment</Heading>
              <Box
                borderRadius={6}
                py={8}
                px={3}
                border={'1px solid #e5e4e4'}
                bg={'#F8F8F8'}
                textAlign={'center'}
              >
                <Icon fontSize={'50px'} as={BsFileEarmarkCheck} />
              </Box>
              <Button
                bg={'transparent'}
                borderRadius={'0'}
                type="button"
                onClick={() => handleClick(`${imgUrl}${state.document}`)}
                width={'max-content'}
                color={'#75767A'}
                borderBottom={'1px solid #75767A'}
              >
                <Icon fontSize={'15px'} mr={2} as={LuCornerLeftDown} /> Download
                File
              </Button>
            </Stack>
          )}
        </Stack>
      </SimpleGrid>
    </Stack>
  );
}
