import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridItem,
  Box,
  HStack,
  Stack,
  Avatar,
  Heading,
  Text,
  WrapItem,
  Button,
  Divider,
  Badge,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { io } from 'socket.io-client';
import { imgUrl } from '../../../utilities/config';
import moment from 'moment';
// const socket = io('e-medical.thewebtestlink.xyz:5401');
const socket = io('wss://e-medical.thewebtestlink.xyz', {
  path: '/socket.io',
  transports: ['websocket'],
  EIO: 4,
});

function Dotors() {
  const [doctorsList, setDoctorsList] = useState([]);
  let user = JSON.parse(localStorage.getItem('emediiUser') ?? '{}');
  useEffect(() => {
    console.log('Socket', socket);
    socket.on('connected', () => {
      console.log('Connected to server', socket);
    });
    socket.emit('myDoctors', { hospitalId: user?.hospital });

    socket.on('myDoctorsList', data => {
      console.log(data);
      setDoctorsList(data);
    });
  }, [socket]);
  return (
    <>
      <Stack mb="20px">
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Doctors
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is all the chat...Check Now!
        </Text>
      </Stack>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {!doctorsList ? (
          <Spinner />
        ) : doctorsList.length > 0 ? (
          doctorsList?.map((val, ind) => (
            <Box
              key={ind}
              shadow={'lg'}
              w="100%"
              borderRadius={'16px'}
              bg={'#fff'}
              p={5}
            >
              <HStack alignItems={'center'}>
                <WrapItem>
                  <Avatar
                    size="lg"
                    name="Prosper Otemuyiwa"
                    src={`${imgUrl}/${val?.sender?.photo}`}
                  />{' '}
                </WrapItem>
                <Stack justifyContent={'center'}>
                  <Heading fontWeight={'400'} fontSize={'22px'}>
                    {val?.sender?.name}
                  </Heading>
                  <Text
                    lineHeight={0}
                    fontSize={'16px'}
                    fontWeight={'400'}
                    color={'#75767A'}
                  >
                    #{val?._id.slice(-5)}
                  </Text>
                </Stack>
              </HStack>

              <Button
                as={Link}
                to={`/dashboard/chat/${val?.sender?._id}`}
                mt="30px"
                bgGradient={'linear(to-r, #295377, #208C74)'}
                fontSize={'16px'}
                fontWeight={500}
                color={'#fff'}
                w="100%"
                borderRadius={'25px'}
                h="45px"
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
              >
                View Chats
              </Button>
            </Box>
          ))
        ) : (
          <Heading>No Health practitioners</Heading>
        )}
      </Grid>
    </>
  );
}

export default Dotors;
