import React, { useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  VStack,
  Input,
  FormLabel,
  useToast,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import { POST } from '../../../utilities/ApiProvider';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ElectronicRequest() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const user = useSelector(state => state.user.value);
  const toast = useToast();
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      form.append('service', id);
      form.append('hospital', user.hospital);
      // const formDataEntries = Array.from(form.entries());
      //   console.log(formDataEntries.map(([key, value]) => ({ [key]: value })));
      const response = await POST('/hospital/service', form);
      if (response.status === 200) {
        setLoading(false);
        toast({
          description: 'Form Submitted SucessFully!!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
      }
    } catch (error) {
      console.log('Erorr while submitting form ', error);
    } finally {
      setLoading(false);
      e.target.reset();
    }
  };

  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Request Electronic Medical Article, Research, Magazine or Book
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all services...Check Now!
        </Text>
        {/* <Text fontSize={'20px'} fontWeight={'500'}>
              You've 5 services
            </Text> */}
      </Stack>
      {/*Heading  */}
      <form onSubmit={handleSubmit} className="applyForm">
        <Text fontWeight={'500'} fontSize={'20px'}>
          Beneficiary’s Details:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              name="beneficiaryName"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Mobile No</FormLabel>
            <Input
              required
              name="beneficiaryNumber"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              name="beneficiaryEmail"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Requested Scientific Material:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Title</FormLabel>
            <Input
              required
              name="scientificTitle"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Key Words</FormLabel>
            <Input
              required
              name="scientificKeywords"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Search from Year to Year</FormLabel>
            <Input
              required
              name="scientificYear"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Articles
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Name of Magazine</FormLabel>
            <Input
              required
              name="articleName"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Title of Article</FormLabel>
            <Input
              required
              name="articleTitle"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Year</FormLabel>
            <Input
              required
              name="articleYear"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Issue No.</FormLabel>
            <Input
              required
              name="articleIssue"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Author</FormLabel>
            <Input
              required
              name="articleAuthor"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
        <Text fontWeight={'500'} fontSize={'20px'}>
          Electronic Book
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Title</FormLabel>
            <Input
              required
              name="electronicTitle"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Author</FormLabel>
            <Input
              required
              name="electronicAuthor"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Publisher</FormLabel>
            <Input
              required
              name="electronicPublisher"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Year of Publication</FormLabel>
            <Input
              required
              name="electronicYear"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Print No.</FormLabel>
            <Input
              required
              name="electronicPrintNo"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>ISBN</FormLabel>
            <Input
              required
              name="electronicISBN"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack>

        <Text fontWeight={'500'} fontSize={'20px'}>
          Medical Magazine
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              name="medicalName"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Issue No.</FormLabel>
            <Input
              required
              name="medicalIssueNo"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Issue Date</FormLabel>
            <Input
              required
              name="medicalIssueDate"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Date of Published</FormLabel>
            <Input
              required
              name="medicalDatePublished"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Year Published</FormLabel>
            <Input
              required
              name="medicalYearPublished"
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack>

        <Button
          w="30.33%"
          mt={'25px'}
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          type="submit"
          isLoading={loading}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
        >
          <Stack
            w="100%"
            direction={'row'}
            alignItems={'center'}
            px={4}
            justifyContent={'space-between'}
          >
            <Text fontSize={'16px'} fontWeight={'500'}>
              $80.00
            </Text>
            <Text fontSize={'16px'} fontWeight={'500'}>
              Continue
            </Text>
          </Stack>
        </Button>
      </form>
    </Stack>
  );
}

export default ElectronicRequest;
