import React, { useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  VStack,
  Image,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import { Link } from 'react-router-dom';
import FirstIcon from '../../../assets/images/icons/first.png';
import { LuCalendarClock } from 'react-icons/lu';
import { BsCameraVideo } from 'react-icons/bs';
import { TbEmergencyBed, TbLibrary } from 'react-icons/tb';
import { LiaHospitalAltSolid } from 'react-icons/lia';
import { MdOutlineDocumentScanner } from 'react-icons/md';

function Services() {
  const data = [
    {
      id: 1,
      icon: LuCalendarClock,
      name: 'Booking an in-person clinic appointment service',
      url: '/dashboard/patients?type=inperson',
    },
    {
      id: 2,
      icon: BsCameraVideo,
      name: 'Booking virtual clinic appointment service',
      url: '/dashboard/patients?type=virtual',
    },
    {
      id: 3,
      icon: TbEmergencyBed,
      name: 'Booking an inpatient bed service',
      url: '/dashboard/services/bed-booking',
    },
    {
      id: 4,
      icon: MdOutlineDocumentScanner,
      name: 'Electronic Advertising service',
      url: '/dashboard/form-request/electronic-hospital-request/663a253be43a378f373a8fef',
    },
    {
      id: 5,
      icon: LiaHospitalAltSolid,
      name: 'Recruitment service',
      // Old Flow of Job Posting
      // url: '/dashboard/form-request/hospital-request/663a2527e43a378f373a8fed',
      // New Flow of Job Recruitment
      url: '/dashboard/recruitment',
    },
    {
      id: 6,
      icon: TbLibrary,
      name: 'Medical Training Education Program',
      url: '/dashboard/medical-training-program',
    },
    {
      id: 6,
      icon: TbLibrary,
      name: 'Digital Medical Library',
      url: '/dashboard/digital-library',
    },
  ];
  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Services
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all services...Check Now!
        </Text>
        <Text fontSize={'20px'} fontWeight={'500'}>
          You've 7 services
        </Text>
      </Stack>
      {/*Heading  */}
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <Box
              // border={'2px solid #75767A'}
              shadow={'0px 16.32px 38.75px 0px #0000000F'}
              borderRadius={'17px'}
              p={{ base: '15px 20px', xl: '25px 40px' }}
              display={'flex'}
              // flexDir={'column'}
              key={index}
              // alignItems={'center'}
              // justifyContent={'center'}
              role="group"
              transition={'0.3s'}
              _hover={{
                borderColor: 'primaryGreen.200',
              }}
            >
              <Stack flex={2}>
                <Box
                  w={'54px'}
                  h={'54px'}
                  bgColor={'#E2E2E2'}
                  display={'flex'}
                  alignItems={'center'}
                  color={'#000'}
                  transition={'0.3s'}
                  borderRadius={'12px'}
                  mb="5px"
                  justifyContent={'center'}
                  _groupHover={{
                    bgColor: 'primaryGreen.200',
                    color: '#fff',
                  }}
                >
                  {item?.src ? (
                    <Image src={item.src} alt="icon" boxSize="30px" />
                  ) : (
                    <Icon
                      // _hover={{ color: '#ffff' }}
                      as={item?.icon || RiHospitalLine}
                      boxSize="30px"
                    />
                  )}
                </Box>
                <Heading w={'60%'} fontSize={'18px'} fontWeight={'500'}>
                  {item.name}
                </Heading>
              </Stack>
              <Stack flex={1} alignItems={'end'} justifyContent={'center'}>
                <Button
                  as={Link}
                  to={item.url}
                  bgGradient={'linear(to-r, #295377, #208C74)'}
                  fontSize={{ base: '12px', xl: '14px' }}
                  fontWeight={400}
                  color={'#fff'}
                  borderRadius={'25px'}
                  w="122px"
                  h="34px"
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                >
                  View Service
                </Button>
              </Stack>
            </Box>
          ))}
      </Grid>
    </Stack>
  );
}

export default Services;
