import Header from '../../components/Website/Header';
import Footer from '../../components/Website/Footer';
import About from '../../components/Website/about';
import Testimonials from '../../components/Website/testimonials';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import AboutVision from '../../components/Website/about/AboutVision.js';
import AboutService from '../../components/Website/about/AboutService.js';

export default function AboutUs() {
  return (
    <>
      <Header />
      <MiniBanner
        subHeading="Who We Are"
        mainHeading="About Us"
        // content="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      />

      <AboutVision />

      {/* <About /> */}
      <AboutService />
      <Testimonials />
      <Footer />
    </>
  );
}
