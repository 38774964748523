import React, { useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
} from '@chakra-ui/react';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';

import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import DocInfoBox from './DocInfoBox';
import { Link } from 'react-router-dom';

function DashboardDoctors() {
  const [selectedService, setSelectedService] = useState('pending');

  return (
    <Stack>
      {/*Heading  */}
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack>
          <Heading fontSize={'36px'} fontWeight={'500'}>
            Health practitioners
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the latest update...Check Now!
          </Text>
        </Stack>
        {/*Heading  */}
        <Button
          as={Link}
          to={'/dashboard/edit-doctor'}
          bgGradient={'linear(to-r, #295377, #208C74)'}
          fontSize={'16px'}
          fontWeight={500}
          color={'#fff'}
          borderRadius={'25px'}
          h="45px"
          px="30px"
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
        >
          Add New Health Practitioners
        </Button>
      </Stack>
      <DocInfoBox selectedService={selectedService} />
    </Stack>
  );
}

export default DashboardDoctors;
