import React, { useEffect, useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  Checkbox,
  VStack,
  Input,
  FormLabel,
  useToast,
  Textarea,
  CheckboxGroup,
} from '@chakra-ui/react';
import { GET } from '../../../utilities/ApiProvider';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { imgUrl } from '../../../utilities/config';

function RequestedDigitalLibrary() {
  const user = JSON.parse(localStorage.getItem('emediiUser'));
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      try {
        const url =
          user.type === 'superadmin'
            ? `/admin/digital-program/application`
            : `/admin/digital-program/application?hospital=${user?.hospital}`;
        const response = await GET(url);
        if (response.status === 200) {
          console.log(response?.data?.pending);
          setData(response?.data?.pending);
        } else {
          setData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);
  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Requested Digital Medical Library Services
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all services...Check Now!
        </Text>
      </Stack>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {data?.length > 0 ? (
          data?.map(item => (
            <Box
              shadow={'0px 16px 38px 0px #0000000F'}
              bg={'#fff'}
              borderRadius={'16px'}
              p={4}
              display={'flex'}
              flexDirection={'column'}
              gap={4}
            >
              <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                px={4}
              >
                <HStack alignItems={'center'} lineHeight={'0.3rem'} gap={2}>
                  <Avatar
                    size={'lg'}
                    src={
                      `${imgUrl}/${item?.hospital?.logo}` ||
                      'https://bit.ly/dan-abramov'
                    }
                  />
                  <Stack>
                    <Heading fontSize={'22px'} fontWeight={'500'}>
                      {item?.hospitalName}
                    </Heading>
                    <Text
                      fontSize={'15px'}
                      color={'#75767A'}
                      fontWeight={'400'}
                    >
                      #{item?._id?.slice(0, 6)}
                    </Text>
                  </Stack>
                </HStack>
                <Stack alignItems={'start'}>
                  <Heading fontSize={'22px'} fontWeight={'500'}>
                    Reg No: #{item?.registrationNumber || '566ce1'}
                  </Heading>
                </Stack>
              </Stack>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                px={4}
              >
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Date Added
                  </Text>
                  <Text fontSize={'16px'} fontWeight={'500'}>
                    {moment(item?.createdAt).format('MM / DD / YYYY') ||
                      moment(new Date()).format('MM / DD / YYYY')}
                  </Text>
                </Stack>
                <Stack m={'0 !important'}>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Email
                  </Text>
                  <Text
                    textTransform={'capitalize'}
                    fontSize={'16px'}
                    fontWeight={'500'}
                  >
                    {item?.hospitalEmail}
                  </Text>
                </Stack>
                <Stack m={'0 !important'}>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Requesting Staff
                  </Text>
                  <Text
                    textTransform={'capitalize'}
                    fontSize={'16px'}
                    fontWeight={'500'}
                  >
                    {item?.requestingStaffName}
                  </Text>
                </Stack>
              </Stack>

              <Button
                onClick={() =>
                  navigate(
                    `/dashboard/view-digital-library-request/${item?._id}`
                  )
                }
                bgGradient={'linear(to-r, #295377, #208C74)'}
                fontSize={'16px'}
                fontWeight={500}
                color={'#fff'}
                borderRadius={'25px'}
                h="45px"
                w="100%"
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
              >
                View Details
              </Button>
            </Box>
          ))
        ) : (
          <Heading fontSize={'22px'} fontWeight={'500'}>
            No Data Found
          </Heading>
        )}
      </Grid>
    </Stack>
  );
}

export default RequestedDigitalLibrary;
