import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  useToast,
  Spinner,
  Grid,
  Avatar,
  VStack,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { imgUrl } from '../../../utilities/config';
import Doctor from '../../../assets/images/dashboard/doctor.png';
import { PhoneInput } from 'react-international-phone';
import { useParams } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';

function DoctorDetail() {
  const { id } = useParams();
  const [doctor, setDoctor] = useState(null);
  useEffect(() => {
    const fetchDoctor = async () => {
      try {
        const response = await GET(`/admin/doctors/${id}?doctor_type=internal`);
        console.log(response);
        if (response.status === 200) setDoctor(response?.data?.doctor);
      } catch (error) {
        console.error('Error fetching doctor detail data:', error);
      }
    };

    fetchDoctor();
  }, [id]);
  return (
    <Stack>
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'center'}
          fontSize={'36px'}
          fontWeight={'500'}
        >
          Health Practitioner Details {''}/
          <Text
            mt={'8px'}
            ml={'2px'}
            color={'#208C74'}
            fontSize={'16px'}
            fontWeight={'500'}
          >
            External Health Practitioners
          </Text>
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text>
      </Stack>
      {!doctor && (
        <Stack>
          <Spinner />
        </Stack>
      )}
      {doctor && doctor?.doctor && (
        <>
          <Stack
            direction={'row'}
            justify={'space-between'}
            alignItems={'center'}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <Box w="151px" h="151px">
                <Image
                  borderRadius="full"
                  boxSize="130px"
                  src={`${imgUrl}/${doctor?.doctor?.photo}` || Doctor}
                  alt="Dan Abramov"
                />
              </Box>
              <Stack>
                <Heading
                  display={'flex'}
                  alignItems={'center'}
                  fontSize={'26px'}
                  fontWeight={'500'}
                >
                  {doctor?.fullName}
                </Heading>
                <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
                  #{doctor?.doctor?._id?.slice(0, 6)}
                </Text>
              </Stack>
            </Stack>
            <Button
              w="max-content"
              h="38px"
              letterSpacing={'0.5px'}
              shadow={'0px 16px 38px 0px #0000000F'}
              border={
                doctor?.status === 'active'
                  ? '1px solid transparent'
                  : '1px solid #75767A'
              }
              fontWeight={'500'}
              fontSize={'15px'}
              color={doctor?.status === 'active' ? '#E9E9E9' : '#75767A'}
              bgGradient={
                doctor?.status === 'active'
                  ? 'linear(to-r, #295377, #208C74)'
                  : null
              }
              borderRadius={'21px'}
              textTransform={'capitalize'}
            >
              {doctor?.status}
            </Button>
          </Stack>
          <form className="applyForm">
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Box
                w="100%"
                display={'flex'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
              >
                <Heading
                  w="100%"
                  fontSize={'20px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                  my="10px"
                >
                  Health Practitioner Particulars
                </Heading>
                <Box w="48%" mb="10px">
                  <FormLabel>Name*</FormLabel>
                  <Input
                    name="name"
                    value={doctor.fullName}
                    placeholder="Enter here"
                  />
                </Box>
                <Box w="48%" mb="10px">
                  <FormLabel>Employer</FormLabel>
                  <Input
                    name="employer"
                    value={doctor?.doctor?.employer}
                    placeholder="Enter here"
                  />
                </Box>
                <Box w="48%" mb="10px">
                  <FormLabel>Employment Address</FormLabel>
                  <Input
                    value={doctor?.doctor?.employmentAddress}
                    name="employmentAddress"
                    placeholder="Enter here"
                  />
                </Box>
                <Box w="48%" mb="10px">
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    value={doctor?.doctor?.email}
                    placeholder="Enter here"
                  />
                </Box>
                <Box w="48%" mb="10px">
                  <FormLabel>Phone No.*</FormLabel>
                  <PhoneInput
                    name="number"
                    value={doctor?.number}
                    required
                    containerStyle={{ background: 'none', border: 'none' }}
                    inputStyle={{ background: 'none', border: 'none' }}
                    dropdownStyle={{ background: 'none', border: 'none' }}
                    id="phone-input"
                    defaultCountry="sa"
                    inputClass="custom-phone-input"
                  />
                </Box>
                <Heading
                  w="100%"
                  fontSize={'20px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                  my="10px"
                >
                  Professional Data of the Health Practitioner
                </Heading>
                <Box w="48%" mb="10px">
                  <FormLabel>General Specialty</FormLabel>
                  <Input
                    value={doctor?.doctor?.generalSpeciality}
                    name="generalSpeciality"
                    placeholder="Enter here"
                  />
                </Box>
                <Box w="48%" mb="10px">
                  <FormLabel>Subspecialty</FormLabel>
                  <Input
                    value={doctor?.doctor?.subspeciality}
                    name="subspeciality"
                    placeholder="Enter here"
                  />
                </Box>
                <Box w="48%" mb="10px">
                  <FormLabel>Academic Qualification</FormLabel>
                  <Input
                    value={doctor?.doctor?.academicQualification}
                    name="academicQualification"
                    placeholder="Enter here"
                  />
                </Box>
                <Box w="48%" mb="10px">
                  <FormLabel>Years of experience</FormLabel>
                  <Input
                    value={doctor?.doctor?.yearsOfExperience}
                    name="yearsOfExperience"
                    placeholder="Enter here"
                  />
                </Box>
                <Box w="48%" mb="10px">
                  <FormLabel>Medical Treatment Services</FormLabel>
                  <Input
                    value={doctor?.doctor?.medicalTreatmentService}
                    name="medicalTreatmentService"
                    placeholder="Enter here"
                  />
                </Box>
                <Box w="48%" mb="10px">
                  <FormLabel>Health Practitioner Price</FormLabel>
                  <Input
                    value={doctor?.doctor?.charges}
                    type="number"
                    name="charges"
                    placeholder="Enter here"
                  />
                </Box>
              </Box>
            </Stack>
          </form>
        </>
      )}
    </Stack>
  );
}

export default DoctorDetail;
