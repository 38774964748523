import React, { useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Select,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import BlockUserCard from './BlockUserCard';
import { GET } from '../../../utilities/ApiProvider';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function BlockUsers() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedService, setSelectedService] = useState('doctor');
  const user = useSelector(state => state.user.value);
  const [data, setData] = useState({});
  const [type, setType] = useState(user?.type);
  useEffect(() => {
    if (user) {
      setType(user.type);
    }
  }, [user]);

  async function fetchBlockUsers() {
    try {
      let url =
        type === 'superadmin' ? '/admin/block' : '/hospital/blockedUsers';
      const response = await GET(url);
      console.log('response', response);
      if (response.status === 200) setData(response.data);
      else setData({});
    } catch (err) {
      console.log('Error while fetching Block Users', err);
    }
  }
  useEffect(() => {
    fetchBlockUsers();
  }, []);

  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Block Users
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all block users...Check Now!
        </Text>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} gap={'20px'}>
        {type === 'superadmin' && (
          <Button
            bgGradient={
              selectedService === 'hospital' && 'linear(to-r, #295377, #208C74)'
            }
            bg={'none'}
            fontSize={{ base: '12px', xl: '16px' }}
            fontWeight={500}
            color={selectedService === 'hospital' ? '#fff' : 'primaryBlack.100'}
            borderRadius={'25px'}
            h={{ base: '35px', xl: '45px' }}
            textTransform={'uppercase'}
            onClick={() => setSelectedService('hospital')}
            _hover={{
              bgGradient: 'linear(to-r, #295377, #208C74)',
              color: '#fff',
            }}
          >
            Hospital
          </Button>
        )}
        <Button
          bgGradient={
            selectedService === 'doctor' && 'linear(to-r, #295377, #208C74)'
          }
          bg={'none'}
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight={500}
          color={selectedService === 'doctor' ? '#fff' : 'primaryBlack.100'}
          borderRadius={'25px'}
          h={{ base: '35px', xl: '45px' }}
          textTransform={'uppercase'}
          onClick={() => setSelectedService('doctor')}
          _hover={{
            bgGradient: 'linear(to-r, #295377, #208C74)',
            color: '#fff',
          }}
        >
          Doctors
        </Button>

        {/* <Button
          bgGradient={
            selectedService === 'patient' && 'linear(to-r, #295377, #208C74)'
          }
          bg={'none'}
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight={500}
          color={selectedService === 'patient' ? '#fff' : 'primaryBlack.100'}
          borderRadius={'25px'}
          h={{ base: '35px', xl: '45px' }}
          textTransform={'uppercase'}
          onClick={() => setSelectedService('patient')}
          _hover={{
            bgGradient: 'linear(to-r, #295377, #208C74)',
            color: '#fff',
          }}
        >
          Patients
        </Button> */}
      </Stack>
      <Text fontSize={'20px'} fontWeight={'500'}>
        You’ve {data[selectedService]?.length} blocked {selectedService}
      </Text>
      <BlockUserCard
        data={data[selectedService]}
        fetchBlockUsers={fetchBlockUsers}
        selectedService={selectedService}
      />
    </Stack>
  );
}

export default BlockUsers;
