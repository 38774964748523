import {
  Box,
  Button,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  Link,
} from '@chakra-ui/react';
import Logo from '../../assets/images/website/eMedst Logo.svg';
import authBg from '../../assets/images/website/authBg.png';
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ChooseAccount from '../../components/auth/Registration/ChooseAccount';
import RegistrationForm from '../../components/auth/Registration/RegistrationForm';
import AddDoctor from '../../components/auth/Registration/AddDoctor';
import AddPatient from '../Dashboard/Patients/AddPatient';

export default function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get('type');
  const [registrationLvl, setRegistrationLvl] = useState(0);
  useEffect(() => {
    // Check url params from url and get type params
    if (location.search) {
      console.log('Type', type);
      if (type === 'practitioner') {
        setRegistrationLvl(-1);
      } else if (type === 'patient') {
        setRegistrationLvl(-2);
      } else if (type === 'hospital') {
        setRegistrationLvl(1);
      }
    }
  }, [location, type]);

  return (
    <Stack position={'relative'} height={'100vh'} p="0">
      <Box
        maxW={'720px'}
        p={{ base: '20px 20px 20px 30px', md: '60px 0 60px 120px' }}
      >
        <Button
          onClick={() => {
            console.log(registrationLvl);
            if (registrationLvl === 0) {
              navigate('/');
            } else if (type) {
              setRegistrationLvl(0);
              navigate('/register');
            } else if (registrationLvl === -1) {
              navigate('/register');
              setRegistrationLvl(0);
            } else if (registrationLvl === -2) {
              setRegistrationLvl(0);
            } else {
              setRegistrationLvl(registrationLvl - 1);
            }
          }}
          // onClick={() => {
          //   console.log(registrationLvl);
          //   registrationLvl === 0
          //     ? navigate('/')
          //     : registrationLvl === -1
          //     ? (navigate('/register')
          //     setRegistrationLvl(0)
          //   )
          //     : registrationLvl === -2
          //     ? setRegistrationLvl(0)
          //     : setRegistrationLvl(registrationLvl - 1);
          // }}
          bgColor={'transparent'}
          _hover={{ bgColor: 'transparent' }}
          _active={{ bgColor: 'transparent' }}
          mb="40px"
          fontWeight={'500'}
        >
          <Icon as={FaArrowLeft} mr="10px" /> Back
        </Button>
        <Stack
          mb="40px"
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Image src={Logo} display={{ base: 'none', md: 'block' }} />
          {registrationLvl >= 1 ? (
            <Stack direction={'row'} alignItems={'center'} gap={'15px'}>
              <Box
                transition={'.3s'}
                bgColor={registrationLvl >= 1 ? 'primaryGreen.200' : '#fff'}
                color={registrationLvl >= 1 ? '#fff' : 'primaryBlack,100'}
                position={'relative'}
                _before={{
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  right: '-25px',
                  w: '25px',
                  h: '2px',
                  bgColor: 'primaryBlack.100',
                  transform: 'translate(0%, -50%)',
                }}
                border={'1px solid'}
                borderColor={
                  registrationLvl >= 1 ? 'primaryGreen.200' : 'primaryBlack.100'
                }
                borderRadius={'100%'}
                w="40px"
                h="40px"
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                1
              </Box>
              <Box
                transition={'.3s'}
                bgColor={registrationLvl >= 2 ? 'primaryGreen.200' : '#fff'}
                color={registrationLvl >= 2 ? '#fff' : 'primaryBlack,100'}
                position={'relative'}
                _before={{
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  right: '-25px',
                  w: '25px',
                  h: '2px',
                  bgColor: 'primaryBlack.100',
                  transform: 'translate(0%, -50%)',
                }}
                border={'1px solid'}
                borderColor={
                  registrationLvl >= 2 ? 'primaryGreen.200' : 'primaryBlack.100'
                }
                borderRadius={'100%'}
                w="40px"
                h="40px"
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                2
              </Box>
              <Box
                transition={'.3s'}
                bgColor={registrationLvl >= 3 ? 'primaryGreen.200' : '#fff'}
                color={registrationLvl >= 3 ? '#fff' : 'primaryBlack,100'}
                position={'relative'}
                _before={{
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  right: '-25px',
                  w: '25px',
                  h: '2px',
                  bgColor: 'primaryBlack.100',
                  transform: 'translate(0%, -50%)',
                }}
                border={'1px solid'}
                borderColor={
                  registrationLvl >= 3 ? 'primaryGreen.200' : 'primaryBlack.100'
                }
                borderRadius={'100%'}
                w="40px"
                h="40px"
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                3
              </Box>
              <Box
                transition={'.3s'}
                bgColor={registrationLvl >= 4 ? 'primaryGreen.200' : '#fff'}
                color={registrationLvl >= 4 ? '#fff' : 'primaryBlack,100'}
                border={'1px solid'}
                borderColor={
                  registrationLvl >= 4 ? 'primaryGreen.100' : 'primaryBlack.100'
                }
                borderRadius={'100%'}
                w="40px"
                h="40px"
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                4
              </Box>
            </Stack>
          ) : null}
        </Stack>
        {registrationLvl === 0 ? (
          <ChooseAccount setRegistrationLvl={setRegistrationLvl} />
        ) : registrationLvl === -1 ? (
          <AddDoctor />
        ) : registrationLvl === -2 ? (
          <AddPatient />
        ) : (
          <RegistrationForm
            registrationLvl={registrationLvl}
            setRegistrationLvl={setRegistrationLvl}
          />
        )}
      </Box>
      <Image
        src={authBg}
        width={{ base: '605px', sm: '0%', md: '25%' }}
        h="100%"
        position={{ base: 'fixed', md: 'fixed' }}
        top="0"
        right="0"
        mt="0 !important"
        display={{ base: 'none', md: 'block' }}
      />
    </Stack>
  );
}
