import {
  Avatar,
  Box,
  Button,
  Grid,
  Heading,
  HStack,
  Spinner,
  Stack,
  Text,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';

function JobDetails() {
  const { id } = useParams();
  const [selectedService, setSelectedService] = useState('requested');
  const [jobDetails, setJobDetails] = useState(null);
  useEffect(() => {
    async function fetchDetails() {
      try {
        setJobDetails(null);
        const response = await GET(`/admin/jobs/${id}?type=${selectedService}`);
        if (response.status === 200) {
          console.log('response', response);
          setJobDetails(response.data);
        } else {
          console.log('error', response);
          setJobDetails([]);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
    fetchDetails();
  }, [selectedService, id]);

  return (
    <>
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'center'}
          fontSize={'36px'}
          fontWeight={'500'}
        >
          Job Details {''}/
          <Text
            mt={'8px'}
            ml={'2px'}
            color={'#208C74'}
            fontSize={'16px'}
            fontWeight={'500'}
          >
            Jobs
          </Text>
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text>
      </Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={{ base: '5px', xl: '20px' }}
      >
        <Button
          bgGradient={
            selectedService === 'requested'
              ? 'linear(to-r, #295377, #208C74)'
              : 'linear(to-b, #C2D4FF, #fff)'
          }
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight={500}
          color={selectedService === 'requested' ? '#fff' : 'primaryBlack.100'}
          borderRadius={'25px'}
          h={{ base: '35px', xl: '35px' }}
          onClick={() => setSelectedService('requested')}
          _hover={{
            bgGradient: 'linear(to-r, #295377, #208C74)',
            color: '#fff',
          }}
        >
          Requested
        </Button>
        <Button
          bgGradient={
            selectedService === 'accepted'
              ? 'linear(to-r, #295377, #208C74)'
              : 'linear(to-b, #C2D4FF, #fff)'
          }
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight={500}
          color={selectedService === 'accepted' ? '#fff' : 'primaryBlack.100'}
          borderRadius={'25px'}
          h={{ base: '35px', xl: '35px' }}
          onClick={() => setSelectedService('accepted')}
          _hover={{
            bgGradient: 'linear(to-r, #295377, #208C74)',
            color: '#fff',
          }}
        >
          Accepted
        </Button>
      </Stack>
      {!jobDetails && (
        <Stack alignItems={'center'} justifyContent={'center'} height={'400px'}>
          <Spinner />
        </Stack>
      )}
      {jobDetails?.count === 0 && (
        <Stack height={'400px'}>
          <Heading fontSize={'34px'} fontWeight={'500'}>
            No Jobs Found
          </Heading>
        </Stack>
      )}
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {jobDetails?.data?.map((val, ind) => (
          <Box shadow={'lg'} w="100%" borderRadius={'16px'} bg={'#fff'} p={5}>
            <HStack alignItems={'center'}>
              <WrapItem>
                <Avatar
                  size="lg"
                  name="Prosper Otemuyiwa"
                  src={'https://bit.ly/dan-abramov'}
                />{' '}
              </WrapItem>
              <Stack justifyContent={'center'} gap={1}>
                <Heading fontWeight={'500'} fontSize={'22px'}>
                  {val?.title || 'Neurologist Specialist'}
                </Heading>
                <Text
                  lineHeight={0}
                  fontSize={'16px'}
                  fontWeight={'400'}
                  color={'#75767A'}
                >
                  # {val?._id?.slice(0, 6) || 'd44vdsQ'}
                </Text>
              </Stack>
            </HStack>

            <HStack alignItems={'baseline'}>
              <Button
                as={Link}
                to={`/dashboard/job-details/${val?._id}`}
                mt="30px"
                bgGradient={'linear(to-r, #295377, #208C74)'}
                fontSize={'16px'}
                fontWeight={500}
                color={'#fff'}
                w="100%"
                borderRadius={'25px'}
                h="45px"
                border={'2px solid transparent'}
                _hover={{
                  bgGradient: 'none',
                  borderColor: 'primaryGreen.200',
                  color: 'primaryGreen.200',
                }}
              >
                View Job
              </Button>
              {selectedService === 'requested' && (
                <Button
                  as={Link}
                  to={`/dashboard/job-details/edit/${val?._id}`}
                  mt="30px"
                  bgGradient={'linear(to-r, #295377, #208C74)'}
                  fontSize={'16px'}
                  fontWeight={500}
                  color={'#fff'}
                  w="100%"
                  borderRadius={'25px'}
                  h="45px"
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                >
                  Edit Job
                </Button>
              )}
            </HStack>
          </Box>
        ))}
      </Grid>
    </>
  );
}

export default JobDetails;
