import React, { useEffect, useState } from 'react';

import {
  Stack,
  Heading,
  Text,
  Box,
  Button,
  FormLabel,
  Input,
  useToast,
  Checkbox,
  Textarea,
  HStack,
} from '@chakra-ui/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { POST } from '../../../utilities/ApiProvider';

function ViewJobOffer() {
  const location = useLocation();
  const data = location.state;
  const type = JSON.parse(localStorage.getItem('emediiUser') ?? '{}')?.type;
  console.log(type, data);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const handleJobOffer = async _status => {
    try {
      setLoading(true);
      const response = await POST(`/hospital/doctor-job-offer/${id}`, {
        is_hired: _status,
      });
      if (response.status === 200 || response.status === 'success') {
        setLoading(false);
        toast({
          title: `Job Offer ${_status ? 'Accepted' : 'Rejected'} Successfully`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        navigate(`/dashboard/job-offers`);
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };
  return (
    <Stack>
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'end'}
          fontSize={'36px'}
          fontWeight={'500'}
          textTransform={'capitalize'}
        >
          Job Offer Form For Health Practitioner
        </Heading>
      </Stack>
      <form className="applyForm">
        <Text fontWeight={'500'} fontSize={'20px'}>
          Hospital Particulars:
        </Text>
        <Stack
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          gap={4}
          flexWrap={'wrap'}
        >
          <Box w="32.33%">
            <FormLabel>Name</FormLabel>
            <Input
              required
              name="name"
              type="text"
              defaultValue={data?.name}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Address</FormLabel>
            <Input
              required
              name="address"
              value={data?.address}
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Email Address</FormLabel>
            <Input
              required
              name="email"
              value={data?.email}
              type="text"
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Name of the Staff in cahrge of the offer</FormLabel>
            <Input
              required
              name="requestingStaffName"
              type="text"
              value={data?.requestingStaffName}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="32.33%">
            <FormLabel>Mobile No.</FormLabel>
            <Input
              required
              name="number"
              value={data?.number}
              type="text"
              placeholder="Enter Name"
            />
          </Box>
        </Stack>

        <Text fontWeight={'500'} fontSize={'20px'}>
          Information of the Job Offer:
        </Text>
        <Stack w="100%" flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="100%">
            <FormLabel>Job Title</FormLabel>
            <Input
              required
              name="title"
              type="text"
              value={data?.title}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Basic Salary</FormLabel>
            <Input
              required
              name="salary"
              type="text"
              value={data?.salary}
              placeholder="Enter Name"
            />
          </Box>
          <Box w="100%">
            <FormLabel>Duration of the Contract</FormLabel>
            <Input
              required
              name="durationOfContract"
              type="text"
              value={data?.durationOfContract}
              placeholder="Enter Name"
            />
          </Box>
        </Stack>
        <Box
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <FormLabel>Allowances</FormLabel>
          <Textarea
            h="100px !important"
            name="allowances"
            value={data?.allowances}
            resize={'none'}
          ></Textarea>
        </Box>
        <Box
          w="100%"
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <FormLabel>Other Benefits </FormLabel>
          <Textarea
            h="100px !important"
            name="otherBenefits"
            value={data?.otherBenefits}
            resize={'none'}
          ></Textarea>
        </Box>
        <Box w="100%">
          <FormLabel>Number of Working Hours per Month</FormLabel>
          <Input
            required
            name="workingHours"
            type="text"
            value={data?.workingHours}
            placeholder="Enter Name"
          />
        </Box>
        <Text fontWeight={'500'} my={6} fontSize={'20px'}>
          Type of Contract:
        </Text>
        <Box w="100%" className="checkboxFamily">
          <Stack
            spacing={0}
            gap={4}
            direction={'row'}
            flexDir={{ base: 'column', xl: 'row' }}
            justifyContent={'space-between'}
          >
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="annual"
                isChecked={data?.contractType === 'annual'}
                // isChecked={checkedValues === 'annual'}
                // onChange={handleCheckboxChange}
              >
                Annual
              </Checkbox>
            </Box>
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="partTime"
                isChecked={data?.contractType === 'partTime'}
                // isChecked={checkedValues === 'partTime'}
                // onChange={handleCheckboxChange}
              >
                Part-time
              </Checkbox>
            </Box>
            <Box w="100%">
              <Checkbox
                colorScheme="teal"
                name="temporary"
                isChecked={data?.contractType === 'temporary'}
                // isChecked={checkedValues === 'temporary'}
                // onChange={handleCheckboxChange}
              >
                Temporary
              </Checkbox>
            </Box>
          </Stack>
        </Box>
      </form>

      {type !== 'hospital' && data?.is_hired == false && (
        <HStack
          w="50%"
          alignItems={'center'}
          justifyContent={'center'}
          gap={5}
          mt={4}
        >
          <Button
            m="0 !important"
            bgGradient={'linear(to-r, #295377, #208C74)'}
            fontSize={'16px'}
            fontWeight={500}
            color={'#fff'}
            w="100%"
            borderRadius={'25px'}
            cursor={'pointer'}
            isLoading={loading}
            onClick={() => handleJobOffer(true)}
            h="45px"
            border={'2px solid transparent'}
            _hover={{
              bgGradient: 'none',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
          >
            Accept Job
          </Button>
          <Button
            m="0 !important"
            bgGradient={'linear(to-r, #295377, #208C74)'}
            fontSize={'16px'}
            fontWeight={500}
            color={'#fff'}
            cursor={'pointer'}
            isLoading={loading}
            w="100%"
            onClick={() => handleJobOffer(false)}
            borderRadius={'25px'}
            h="45px"
            border={'2px solid transparent'}
            _hover={{
              bgGradient: 'none',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
          >
            Reject Job
          </Button>
        </HStack>
      )}
    </Stack>
  );
}

export default ViewJobOffer;
