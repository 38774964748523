import React, { useEffect, useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Checkbox,
  AvatarGroup,
  Button,
  Textarea,
  Icon,
  FormLabel,
  Input,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Select,
  useToast,
} from '@chakra-ui/react';
import { RiHospitalLine } from 'react-icons/ri';
import { BiBed } from 'react-icons/bi';
import { GiHealthPotion } from 'react-icons/gi';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import { GET, POST } from '../../../utilities/ApiProvider';

function AddDepartment() {
  const [wards, setWards] = useState([]);
  const [checkedWard, setCheckWards] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  useEffect(() => {
    fetchWards();
  }, []);
  async function fetchWards() {
    try {
      const response = await GET('/hospital/ward');
      if (response.status === 200) setWards(response.data);
      else setWards([]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (e, ward) => {
    const temp = [...wards];
    const index = temp.findIndex(val => val._id === ward._id);
    temp[index].check = !temp[index].check;
    let filterArray = temp.filter(item => item.check == true);
    setCheckWards(filterArray);
  };
  const handleInputChange = (e, ward) => {
    const temp = [...wards];
    const index = temp.findIndex(val => val._id === ward._id);
    temp[index].numberOfBeds = e.target.value;
    setWards(temp);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      const dataToSend = checkedWard.map(item => {
        return {
          ward: item._id,
          beds: Number(item.numberOfBeds),
        };
      });
      const response = await POST('/hospital/department', {
        name: form.get('name'),
        foundingDate: form.get('foundingDate'),
        ward: dataToSend,
      });
      if (response.status === 200) {
        setLoading(false);

        toast({
          description: 'Department Created SuccessFully!!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
          duration: 3000,
        });
        e.target.reset();
        const temp = [...wards];
        const arrayToSet = temp.map(item => {
          return {
            ...item,
            check: false,
          };
        });
        console.log(arrayToSet);
        setWards(arrayToSet);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };
  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Create New Department
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          If an query you can send it to the platform admin...!
        </Text>
      </Stack>
      <form onSubmit={handleSubmit} className="applyForm">
        <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
          <Box w="50%">
            <FormLabel>Department Name</FormLabel>
            <Input name="name" placeholder="Enter here" />
          </Box>
          <Box w="50%">
            <FormLabel>Department Founding Date</FormLabel>
            <Input type="date" name="foundingDate" placeholder="Enter here" />
          </Box>
        </Stack>
        <Box w="100%" className="checkboxFamily">
          <FormLabel>Choose ward type</FormLabel>
          <Stack
            direction={'row'}
            spacing={0}
            gap="10px"
            flexDir={{ base: 'column', xl: 'row' }}
            // justifyContent={'space-between'}
            flexWrap={'wrap'}
          >
            {wards?.map((ward, index) => {
              return (
                <Box
                  key={index}
                  display={'flex'}

                  // justifyContent={'space-between'}
                  // w={{ base: '100%', xl: '31%' }}
                >
                  <Checkbox
                    colorScheme="teal"
                    defaultChecked={ward?.check}
                    onChange={e => handleChange(e, ward)}
                  >
                    {ward.wardName}
                  </Checkbox>
                </Box>
              );
            })}
          </Stack>

          {wards?.map((ward, index) => {
            if (ward.check) {
              return (
                <Stack>
                  <Input
                    onChange={e => handleInputChange(e, ward)}
                    w="30%"
                    placeholder="No.of beds"
                  />
                </Stack>
              );
            }
          })}
        </Box>
        <Button
          w="10%"
          mt={'15px'}
          isLoading={loading}
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          borderRadius={'21px'}
          type="submit"
        >
          Continue
        </Button>
      </form>
    </Stack>
  );
}

export default AddDepartment;
