import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  useToast,
  Spinner,
  Grid,
  Avatar,
  VStack,
} from '@chakra-ui/react';
import Hospital from '../../../assets/images/website/hospital.png';
import { register } from 'swiper/element';
import { StarIcon } from '@chakra-ui/icons';
import { GET } from '../../../utilities/ApiProvider';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { imgUrl } from '../../../utilities/config';
function Hospitals() {
  // const data = [
  //   {
  //     id: 1,
  //     name: 'Bascom Palmer Anne',
  //     rating: 4.5,
  //     hospitalId: 'd44vdsQ',
  //     img: Hospital,
  //     registered: '2/10/2022',
  //     doctors: '21',
  //     type: 'Cardiologist',
  //   },
  //   {
  //     id: 2,
  //     name: 'Bascom Palmer Anne',
  //     rating: 4.5,
  //     hospitalId: 'd44vdsQ',
  //     img: Hospital,
  //     registered: '2/10/2022',
  //     doctors: '21',
  //     type: 'Cardiologist',
  //   },
  //   {
  //     id: 3,
  //     name: 'Bascom Palmer Anne',
  //     rating: 4.5,
  //     hospitalId: 'd44vdsQ',
  //     img: Hospital,
  //     registered: '2/10/2022',
  //     doctors: '21',
  //     type: 'Cardiologist',
  //   },
  // ];
  const [data, setData] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const response = await GET('/admin/hospitals');
        console.log('hospitals', response.data);
        if (response.status === 200) {
          setData(response.data);
        } else {
          handleFetchError();
        }
      } catch (error) {
        handleFetchError(error);
      }
    };

    fetchHospitals();
  }, []);

  const handleFetchError = (error = null) => {
    if (error) {
      console.error('While Fetching Dashboard', error);
    }
    toast({
      title: 'Error',
      description: 'Something went wrong while fetching the Dashboard.',
      status: 'error',
      duration: 2000,
      position: 'top-right',
      isClosable: true,
    });
  };
  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Hospitals
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text>
      </Stack>

      <Stack pt={4}>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          {data?.map((hospital, index) => (
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              shadow={'0px 16px 38px 0px #0000000F'}
              borderRadius={'16px'}
              p={5}
            >
              <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Stack
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  gap={4}
                >
                  <Stack>
                    <Avatar
                      width={'63px'}
                      height={'63px'}
                      borderColor={'#F9FAFE'}
                      src={`${imgUrl}${hospital?.logo}`}
                    ></Avatar>
                  </Stack>
                  <Stack>
                    <Text
                      fontSize={'22px'}
                      fontWeight={'500'}
                      lineHeight={'0.5rem'}
                    >
                      {hospital?.name}
                    </Text>
                    <Text
                      fontSize={'16px'}
                      color={'#75767A'}
                      fontWeight={'400'}
                    >
                      #{hospital?.ibanNo}
                    </Text>
                  </Stack>
                </Stack>
                <Stack
                  display={'flex'}
                  flexDirection={'row'}
                  gap={2}
                  alignItems={'center'}
                >
                  <StarIcon size={'20px'} color={'#E0BC3E'} />
                  <Text
                    m="0 !important"
                    color="#788094"
                    fontSize={'16px'}
                    fontWeight={'400'}
                  >
                    {hospital?.rating}
                  </Text>
                </Stack>
              </Stack>
              <Text fontSize={'16px'} fontWeight={'500'}>
                Hospital Info:
              </Text>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Registration Date
                  </Text>
                  <Text fontSize={'16px'} fontWeight={'500'}>
                    {moment(hospital?.createdAt).format('MM / DD / YYYY')}
                  </Text>
                </Stack>
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Total Doctors
                  </Text>
                  <Text fontSize={'16px'} fontWeight={'500'}>
                    {hospital?.totalDoctors}
                  </Text>
                </Stack>
                <Stack>
                  <Text fontSize={'16px'} fontWeight={'400'} color={'#75767A'}>
                    Hospital Type
                  </Text>
                  <Text fontSize={'16px'} fontWeight={'500'}>
                    {hospital?.hospitalType}
                  </Text>
                </Stack>
              </Stack>
              <Stack pt={4}>
                <Button
                  as={Link}
                  to={`/dashboard/hospital-profile/${hospital?._id}`}
                  bgGradient={'linear(to-r, #295377, #208C74)'}
                  fontSize={'16px'}
                  fontWeight={500}
                  color={'#fff'}
                  borderRadius={'25px'}
                  h="45px"
                  w="100%"
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                >
                  View Profile
                </Button>
              </Stack>
            </Box>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}

export default Hospitals;
