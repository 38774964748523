import {
  Box,
  Button,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  Link,
  useToast,
} from '@chakra-ui/react';
import Logo from '../../assets/images/website/logo.jpg';
import authBg from '../../assets/images/website/authBg.png';
import { FaArrowLeft } from 'react-icons/fa';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { PinInput, PinInputField, HStack } from '@chakra-ui/react';
import { POST } from '../../utilities/ApiProvider';

export default function Verification({ setOtp }) {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    const form = new FormData(e.target);
    const email = localStorage.getItem('emedieeForgetEmail');
    const otp = ['pin', 'pin1', 'pin2', 'pin3']
      .map(item => form.get(item) || '')
      .join('');

    try {
      const response = await POST('/authentication/verify-otp', {
        value: email,
        otp,
      });
      const isSuccess = response.status === 200;

      if (isSuccess) {
        localStorage.setItem('emedieeForgetOtp', otp);
        setOtp(true);
      }

      toast({
        description: response?.message,
        status: isSuccess ? 'success' : 'error',
        isClosable: true,
        position: 'top-right',
        duration: 3000,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      toast({
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top-right',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
      e.target.reset();
    }
  };

  return (
    <Stack position={'relative'} height={'100vh'} p="0">
      <Box
        maxW={{ base: '100%', lg: '490px', xl: '600px' }}
        p={{
          base: '120px 25px',
          lg: '60px 0 60px 40px',
          xl: '60px 0 60px 120px',
        }}
      >
        <Button
          as={ReactLink}
          to={'/login'}
          bgColor={'transparent'}
          _hover={{ bgColor: 'transparent' }}
          _active={{ bgColor: 'transparent' }}
          mb="60px"
          fontWeight={'500'}
          // onClick={()=>setState({...value,state:false})}
        >
          <Icon as={FaArrowLeft} mr="10px" /> Back
        </Button>
        <Image mb="40px" src={Logo} />
        <Heading
          fontWeight={'500'}
          color={'primaryBlack.100'}
          fontSize={'32px'}
          pb="20px"
        >
          Verification
        </Heading>
        <Text
          fontWeight={'500'}
          color={'primaryGray.100'}
          fontSize={'14px'}
          pb="20px"
        >
          Enter your code that we sent you on your email
        </Text>

        <form className="applyForm" onSubmit={handleSubmit}>
          <FormLabel
            fontSize={'15px'}
            fontWeight={'400'}
            color="#208C74 !important"
          >
            Code
          </FormLabel>

          <HStack gap={'10px'}>
            <PinInput>
              <PinInputField
                name="pin"
                w={{ base: '100%', xl: '69px' }}
                h={'58px !important'}
                borderRadius={'14px'}
              />
              <PinInputField
                name="pin1"
                w={{ base: '100%', xl: '69px' }}
                h={'58px !important'}
                borderRadius={'14px'}
              />
              <PinInputField
                name="pin2"
                w={{ base: '100%', xl: '69px' }}
                h={'58px !important'}
                borderRadius={'14px'}
              />
              <PinInputField
                name="pin3"
                w={{ base: '100%', xl: '69px' }}
                h={'58px !important'}
                borderRadius={'14px'}
              />
            </PinInput>
          </HStack>
          <Box
            display={'flex'}
            w="100%"
            justifyContent={'flex-start'}
            mt={'15px'}
          >
            <Link
              as={ReactLink}
              to="/forgot-password"
              color={'primaryGreen.200'}
              borderBottom={'1px solid'}
              borderColor={'primaryGreen.200'}
              fontSize={'14px'}
            >
              Resend code
            </Link>
          </Box>
          <Button
            isLoading={isLoading}
            my="20px"
            bgGradient={'linear(to-r, #295377, #208C74)'}
            fontSize={'14px'}
            fontWeight={500}
            color={'#fff'}
            borderRadius={'25px'}
            w="100%"
            h="45px"
            textTransform={'uppercase'}
            border={'2px solid transparent'}
            _hover={{
              bgGradient: 'none',
              borderColor: 'primaryGreen.200',
              color: 'primaryGreen.200',
            }}
            type="submit"
          >
            Verify
          </Button>
        </form>
      </Box>
      <Image
        display={{ base: 'none', xl: 'block' }}
        src={authBg}
        w={{ lg: '505px', xl: '605px' }}
        h="100%"
        position={'absolute'}
        top="0"
        right="0"
        mt="0 !important"
      />
    </Stack>
  );
}
