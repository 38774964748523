import React, { useState, useEffect } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Divider,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  Image,
  RadioGroup,
  VStack,
  FormLabel,
  Select,
  Textarea,
  Checkbox,
  Input,
  useDisclosure,
  useToast,
  Radio,
  Flex,
} from '@chakra-ui/react';
import { CalendarIcon, AttachmentIcon } from '@chakra-ui/icons';
import ModalWrapper from '../../../components/Dashboard/Modal/index';
import Tick from '../../../assets/images/dashboard/tick.png';
import { GET, POST } from '../../../utilities/ApiProvider';
import moment from 'moment';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
function ConfirmAppointment({
  onOpen,
  onClose,
  selectedSlot,
  doctor,
  selectedTime,
}) {
  const { day, date } = selectedSlot;
  const { startTime, endTime } = selectedTime;
  const user = JSON.parse(localStorage.getItem('emediiUser'));
  return (
    <Stack flexDirection={'column'} gap={2} lineHeight={'0.6rem'} mt={3}>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Booking No:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          03
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          MemberShip No:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {user?.memId || 'MID0001666'}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Booking Date:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {moment(new Date()).format('DD-MM-YYYY')}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Doctor Name:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          Dr. {doctor?.name || 'Adam Knight'}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Hospital Name:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {doctor?.hospital?.name}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Specialty:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {doctor?.generalSpeciality}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Appointment Day:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {day}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Appointment Date:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {moment(new Date(date)).format('DD-MM-YYYY')}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Appointment Time:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          {startTime} to {endTime}
        </Text>
      </Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'18px'} color={'#208C74'} fontWeight={'500'}>
          Examination Charge:
        </Text>
        <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
          ${doctor?.charges}
        </Text>
      </Stack>
      <Stack m={'10px 0px !important'}>
        <Button
          w="100%"
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'16px'}
          borderRadius={'21px'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          onClick={() => {
            onClose();
            onOpen();
          }}
        >
          Confirm Appointment
        </Button>
      </Stack>
    </Stack>
  );
}
function PaymentDetails({ onOpen, onClose, handleSubmit, isLoading }) {
  return (
    <form className="applyForm">
      <Box>
        <FormLabel>Enter Card Number</FormLabel>
        <Input type="text" placeholder="Enter here" />
      </Box>
      <Box>
        <FormLabel>Enter Card Holder Name</FormLabel>
        <Input type="text" placeholder="Enter here" />
      </Box>
      <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
        <Box w="48%">
          <FormLabel>Expiry Date</FormLabel>
          <Input type="date" placeholder="Enter here" />
        </Box>
        <Box w="48%">
          <FormLabel>CVC</FormLabel>
          <Input type="text" placeholder="Enter here" />
        </Box>
      </Stack>
      <Box>
        <Checkbox colorScheme="teal" fontSize={'15px'} fontWeight={400}>
          Save this card for future payment?{' '}
        </Checkbox>
      </Box>
      <Box w="100%">
        <Button
          w="100%"
          mt={'15px'}
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          isLoading={isLoading}
          onClick={() => {
            handleSubmit(onClose, onOpen);
          }}
        >
          Continue & Pay
        </Button>
      </Box>
    </form>
  );
}
function CongratsModal({ doctor }) {
  const navigate = useNavigate();
  console.log(doctor);

  return (
    <Stack
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      gap={2}
      w={'100%'}
    >
      <Stack
        w="97px"
        h="97px"
        bgGradient="linear(to-r, #295377, #208C74)"
        borderRadius="50%"
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Image w="53px" h="53px" src={Tick} alt="tick" />
      </Stack>
      <Stack flexDirection={'column'} align={'center'}>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Congratulations!
        </Heading>
        <Text
          textAlign={'center'}
          fontSize={'15px'}
          fontWeight={'400'}
          color={'#75767A'}
        >
          Your appointment with Dr.{doctor?.name || 'John Doe'} . Once the
          hospital approved your appointment you will be notify.
        </Text>
      </Stack>
      <Stack w={'100%'} mb={'15px !important'}>
        <Button
          w="100%"
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          Done
        </Button>
      </Stack>
    </Stack>
  );
}
function HoursAvailableSlots({ timeSlots, setSelectedTime }) {
  const [selectedDayIndex, setSelectedDayIndex] = useState(null);
  const handleDaySelection = index => {
    setSelectedDayIndex(index);
  };

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
      {timeSlots.map((val, index) => (
        <Stack
          border={'2px solid'}
          borderRadius={'12px'}
          borderColor={'primaryGreen.200'}
          p="4px 2px"
          w="100%"
          direction={'row'}
          alignItems={'center'}
          mb="0px !important"
        >
          <Text>
            <Checkbox
              colorScheme="teal"
              defaultChecked={val?.on}
              isChecked={selectedDayIndex === index}
              onChange={() => {
                handleDaySelection(index);
                setSelectedTime(val);
              }}
              // onChange={e => changeOnStatus(ind, e.target.checked)}
              mr="5px"
              mt="3px"
              transform="translateY(2px)"
            ></Checkbox>
          </Text>
          <Text
            bgColor={'primaryGreen.200'}
            borderRadius={'12px'}
            color={'#fff'}
          >
            <Input
              height={'42px !important'}
              borderColor={'primaryGreen.200 !important'}
              // onChange={e => changeStartingTime(ind, e.target.value)}
              type="text"
              defaultValue={val.startTime}
              value={val.startTime}
            />
          </Text>
          <Text
            bgColor={'primaryGreen.200'}
            borderRadius={'12px'}
            color={'#fff'}
          >
            <Input
              height={'42px !important'}
              borderColor={'primaryGreen.200 !important'}
              // onChange={e => changeEndingTime(ind, e.target.value)}
              type="text"
              defaultValue={val.endTime}
              value={val.endTime}
            />
          </Text>
        </Stack>
      ))}
    </Grid>
  );
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function BookAppointment() {
  const [selectedService, setSelectedService] = useState('');
  const { id } = useParams();
  const toast = useToast();
  const [daysSlots, setDaysSlots] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [travel, setTravel] = useState('');
  const [selectedTime, setSelectedTime] = useState({});
  const [notes, setNotes] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const query = useQuery();
  const hospital = query.get('hospital');
  const checkType = query.get('type');
  const patientType = query.get('patientType');
  const appointmentId = query.get('appointmentId');
  console.log(patientType, 'patientType');

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();
  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4,
  } = useDisclosure();
  const {
    isOpen: isOpen5,
    onOpen: onOpen5,
    onClose: onClose5,
  } = useDisclosure();
  const handleSlotClick = val => {
    setSelectedSlot(val);
    onOpen4();
  };
  useEffect(() => {
    const fetchDaysSlots = async () => {
      try {
        const response = await GET(`/web/patient/doctor/${id}/availability`);
        if (response.status === 200) {
          console.log(response.data);
          setSelectedService(response.data[0]?._id);
          setDaysSlots(response.data);
        } else {
          setDaysSlots([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const fetchSlots = async () => {
      try {
        const response = await POST(
          `/web/patient/doctor/${id}/getAllAppointments`,
          {}
        );

        if (response.status === 200) {
          console.log(response.data);
          setTimeSlots(response.data);
        } else {
          setDaysSlots([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    async function fetchDoctor() {
      try {
        const response = await GET(`/web/patient/doctor/${id}`);
        console.log(response);
        if (response.status === 200) setDoctor(response.data);
      } catch (error) {
        console.error('Error fetching doctor detail data:', error);
      }
    }
    fetchDoctor();
    fetchSlots();
    fetchDaysSlots();
  }, []);
  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleButtonClick = () => {
    document.getElementById('file-input').click();
  };
  const validateFields = () => {
    let missingFields = [];

    // Retrieve patientType from query params (assuming you have access to query parameters)
    const patientType = query.get('patientType');

    // Basic required fields validation
    if (!patientType) missingFields.push('Patient Type');
    if (!selectedSlot) missingFields.push('Available Slots');
    if (!selectedTime) missingFields.push('Selected Time');
    if (!id) missingFields.push('Doctor ID');
    if (!hospital) missingFields.push('Hospital');
    if (!notes) missingFields.push('Notes');

    // Validate selectedFile based on patientType
    if (
      patientType === 'virtual' &&
      !selectedFile &&
      checkType !== 'reschedule'
    ) {
      missingFields.push('Upload Reports');
    }

    // In-person appointments do not require a file
    if (patientType === 'in-person') {
      // No file required, so clear any 'Upload Reports' from missingFields if present
      missingFields = missingFields.filter(field => field !== 'Upload Reports');
    }

    // Show validation error if there are missing fields
    if (missingFields.length > 0) {
      const missingFieldsText = missingFields.join(', ');

      console.log('Please fill in all the required fields.');

      // Show toast notification for missing fields
      toast({
        title: 'Validation Error',
        description: `Please fill following fields: ${missingFieldsText}.`,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });

      return false; // Return false if validation fails
    }

    return true; // Return true if all fields are valid
  };

  const handleSubmit = async (onClose, onOpen) => {
    // Provide default values if selectedSlot is null or undefined
    const { day = '', date = '' } = selectedSlot || {};

    if (!checkType) {
      setIsLoading(true);
      try {
        const form = new FormData();
        form.append('type', patientType);
        form.append('day', day);
        form.append('startTime', new Date(`${date} ${selectedTime.startTime}`));
        form.append('endTime', new Date(`${date} ${selectedTime.endTime}`));
        form.append('doctor', id);
        form.append('hospital', hospital);
        form.append('notes', notes);
        form.append('reports', selectedFile);
        form.append('status', 'pending');
        form.append('doctorName', doctor?.name);
        form.append('charges', doctor?.charges);
        // form.append('hospitalDetails', hospital)
        // console.log({doctor: doctor?.charges})

        form.append('travel', travel === 'yes' ? true : false);
        // Log form entries for debugging

        console.log(
          Array.from(form.entries()).map(([key, value]) => ({ [key]: value }))
        );

        const response = await POST(
          `/web/patient/doctor/${id}/book-appointment/${hospital}`,
          form
        );
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);

          onClose();
          onOpen();
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);

        console.log('Error while submitting form', error);
      }
    } else if (checkType === 'reschedule' || checkType === 'rebook') {
      try {
        const data = {
          reason: notes,
          availability: {
            day: day,
            time: `${selectedTime.startTime} - ${selectedTime.endTime}`,
            startTime: new Date(`${date} ${selectedTime.startTime}`),
            endTime: new Date(`${date} ${selectedTime.endTime}`),
          },
        };
        console.log(data);

        const endpoint =
          checkType === 'reschedule'
            ? `/web/patient/appointments/${appointmentId}/reschedule`
            : `/web/patient/appointments/${appointmentId}/rebook`;

        const response = await POST(endpoint, data);
        console.log(response);
        if (response.status === 200) {
          onClose();
          onOpen();
        }
      } catch (error) {
        console.log('Error while submitting form', error);
      }
    }
  };

  return (
    <Stack>
      {/*Heading  */}
      <ModalWrapper
        isOpen={isOpen1}
        onClose={onClose1}
        title={'Confirm Appointment'}
        subTitle={'In-person Clinic Appointment Booking'}
        children={
          <ConfirmAppointment
            onOpen={onOpen5}
            onClose={onClose1}
            selectedSlot={selectedSlot}
            doctor={doctor}
            selectedTime={selectedTime}
            // handleSubmit={handleSubmit}
            // isLoading={isLoading}
          />
        }
      />
      {/* <ModalWrapper
        isOpen={isOpen2}
        onClose={onClose2}
        title={'Payment Details'}
        subTitle={'Enter the payment details'}
        children={<PaymentDetails onOpen={onOpen3} onClose={onClose2} />}
      /> */}
      <ModalWrapper
        isOpen={isOpen3}
        onClose={onClose3}
        children={<CongratsModal doctor={doctor} />}
      />
      <ModalWrapper
        isOpen={isOpen5}
        onClose={onClose5}
        children={
          <PaymentDetails
            onOpen={onOpen3}
            onClose={onClose5}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        }
      />
      <ModalWrapper
        isOpen={isOpen4}
        onClose={onClose4}
        children={
          <HoursAvailableSlots
            timeSlots={timeSlots}
            setSelectedTime={setSelectedTime}
          />
        }
      />
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'center'}
          fontSize={'36px'}
          fontWeight={'500'}
        >
          {checkType === 'reschedule'
            ? 'Re-schedule Appointment'
            : checkType === 'rebook'
            ? 'Re-book Appointment'
            : 'Book Appointment'}{' '}
          /{' '}
          <Text
            mt={'10px'}
            ml={'8px'}
            color={'#208C74'}
            fontSize={'20px'}
            fontWeight={'500'}
          >
            Health Practitioner Profile
          </Text>
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the details of health practitioner
        </Text>
      </Stack>
      {/*Heading  */}
      <Stack>
        <Text fontSize={'18px'} fontWeight={400}>
          Choose Time
        </Text>

        <Stack direction={'row'} gap={4}>
          {daysSlots?.map((val, ind) => (
            <Button
              w="100%"
              m="0 !important"
              bgGradient={
                selectedService === val?._id
                  ? 'linear(to-r, #295377, #208C74)'
                  : 'linear(to-b, #C2D4FF, #fff)'
              }
              fontSize={{ base: '12px', xl: '16px' }}
              fontWeight={500}
              color={selectedService === val?._id ? '#fff' : 'primaryBlack.100'}
              borderRadius={'6px'}
              h={{ base: '81px', xl: '81px' }}
              onClick={() => setSelectedService(val?._id)}
              _hover={{
                bgGradient: 'linear(to-r, #295377, #208C74)',
                color: '#fff',
              }}
            >
              <VStack alignItems={'start'} justifyContent={'start'}>
                <Text
                  fontSize={'21px'}
                  fontWeight={600}
                  color={
                    selectedService === val?._id ? '#fff' : 'primaryBlack.100'
                  }
                >
                  {val?.day}
                </Text>
                <Text
                  fontSize={'18px'}
                  fontWeight={500}
                  color={
                    selectedService === val?._id ? '#fff' : 'primaryBlack.100'
                  }
                >
                  {val?.startTime} to {val?.endTime} PM
                </Text>
              </VStack>
            </Button>
          ))}
        </Stack>
      </Stack>
      <Stack my={'20px !important'}>
        <Text fontSize={'18px'} fontWeight={400}>
          Available Slots
        </Text>
      </Stack>
      <Stack direction={'row'} gap={4} alignItems={'start'} flexWrap={'wrap'}>
        {daysSlots
          ?.filter(item => item._id === selectedService)?.[0]
          ?.['availableSlots']?.map((val, index) => (
            <Box
              w={'48%'}
              h="66px"
              border={'1px solid '}
              borderRadius={'11px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              m={'0 !important'}
              onClick={() => handleSlotClick(val)}
            >
              <Stack
                w="100%"
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={3}
                margin={'0 10px'}
              >
                <Stack direction={'row'} gap={3}>
                  <Box
                    w="45px"
                    h="50px"
                    borderRadius={'6px'}
                    bgGradient="linear(to-r, #295377, #208C74)"
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <CalendarIcon color={'#fff'} w={'20px'} h={'20px'} />
                  </Box>
                  <Stack>
                    <Text fontSize={'16px'} fontWeight={600}>
                      {val?.date}
                    </Text>
                    <Text fontSize={'14px'} m={'0 !important'} fontWeight={400}>
                      {val?.day}
                    </Text>
                  </Stack>
                </Stack>
                <Stack>
                  <Radio
                    isChecked={selectedSlot === val}
                    colorScheme="green"
                    value="2"
                    size={'lg'}
                  ></Radio>
                </Stack>
              </Stack>
            </Box>
          ))}
      </Stack>

      <Stack my={'10px !important'}>
        <Text fontSize={'18px'} fontWeight={400}>
          {checkType === 'reschedule'
            ? 'Reason for reschedule'
            : checkType === 'rebook'
            ? 'Reason for rebook'
            : 'Notes'}
        </Text>
        {checkType !== 'reschedule' ||
          (checkType !== 'rebook' && (
            <Text color={'#75767A'} fontSize={'15px'} fontWeight={400}>
              Leave Notes for Health Practitioner
            </Text>
          ))}
        <Textarea
          placeholder="Enter Notes..."
          height={'117px'}
          borderRadius={'14px'}
          name="description"
          resize={'none'}
          onChange={e => setNotes(e.target.value)}
          border={'1px solid #75767A !important'}
          required
        ></Textarea>
      </Stack>
      {checkType !== 'reschedule' && checkType !== 'rebook' && (
        <Stack mb={'10px !important'}>
          <Box w="100%">
            <FormLabel color={'#208C74'}>Upload Reports</FormLabel>

            <Text fontSize="14px" color="primaryGray.100">
              <Input
                name="attachment"
                type="file"
                id="file-input"
                display="none"
                onChange={handleFileChange}
              />
              <Button
                cursor={'pointer'}
                as="span"
                border="1px solid #75767A"
                bgColor="transparent"
                fontSize="14px"
                color="primaryGray.100"
                transition=".3s"
                _hover={{
                  bgColor: 'primaryGreen.200',
                  color: '#fff',
                  borderColor: 'primaryGreen.200',
                }}
                mr="10px"
                onClick={handleButtonClick}
              >
                <AttachmentIcon mr="10px" /> Attach File
              </Button>
              {selectedFile ? selectedFile.name : 'No File chosen'}
            </Text>
          </Box>
        </Stack>
      )}

      {patientType === 'inperson' && (
        <Stack mb={'10px !important'}>
          <Text fontSize="16px" fontWeight={'400'} color="#208C74">
            Would you like to avail a travel service for your appointment?"
          </Text>
          <RadioGroup onChange={setTravel} value={travel}>
            <Stack direction="row" alignItems={'center'} gap={4}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Stack>
          </RadioGroup>
        </Stack>
      )}

      <Stack>
        <Button
          w="220px"
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'16px'}
          borderRadius={'21px'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          onClick={() => {
            if (validateFields()) {
              onOpen1(); // Open modal only if all fields are valid
            } else {
              // Optionally, show a toast or error message
              console.log('Form validation failed.');
            }
          }}
        >
          {checkType === 'reschedule'
            ? 'Re-schedule Appointment'
            : checkType === 'rebook'
            ? 'Re-book Appointment'
            : 'Confirm Appointment'}
        </Button>
      </Stack>
    </Stack>
  );
}

export default BookAppointment;
