import React, { useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Icon,
  Badge,
  WrapItem,
  VStack,
  useDisclosure,
  Checkbox,
  Input,
  Select,
  FormLabel,
  useToast,
} from '@chakra-ui/react';
import Modal from '../../../../components/Dashboard/Modal';
import moment from 'moment';
import { POST } from '../../../../utilities/ApiProvider';

function OccupiedBed({ itemToOccupied, onClose }) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(e.target);
      const data = {
        department: itemToOccupied?.department,
        ward: itemToOccupied?.ward,
        occupiedDays: form.get('occupiedDays'),
      };
      const response = await POST(
        `/web/bedbooking/${itemToOccupied?._id}/occupy`,
        data
      );

      if (response.status === 200) {
        setLoading(false);
        toast({
          description: 'Occupied Bed SuccessFully!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
      }
    } catch (error) {
      console.log('Error while occupying the bed', error);
    }
  };
  return (
    <Stack>
      <form onSubmit={handleSubmit} className="applyForm">
        <Box>
          <FormLabel>Occupied Days</FormLabel>
          <Input name="occupiedDays" placeholder="Enter here" type="number" />
        </Box>

        <Button
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'15px'}
          borderRadius={'21px'}
          w="100%"
          mb={4}
          mt={2}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          isLoading={loading}
          type="submit"
        >
          Save & continue
        </Button>
      </form>
    </Stack>
  );
}

function BookingCard({ selectedService, data }) {
  // const [bedType, setType] = useState('general');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [itemToOccupied, setOccupied] = useState(null);
  return (
    <div>
      {/* {selectedService === 'patientBooking' && (
        <Stack direction={'row'} alignItems={'center'} mb={4} gap={'10px'}>
          <Button
            bgGradient={
              bedType === 'general' && 'linear(to-r, #295377, #208C74)'
            }
            bg={'none'}
            fontSize={{ base: '12px', xl: '16px' }}
            fontWeight={500}
            color={bedType === 'general' ? '#fff' : 'primaryBlack.100'}
            borderRadius={'25px'}
            h={{ base: '35px', xl: '45px' }}
            textTransform={'capitalize'}
            onClick={() => setType('general')}
            _hover={{
              bgGradient: 'linear(to-r, #295377, #208C74)',
              color: '#fff',
            }}
          >
            General
          </Button>
          <Button
            bgGradient={
              bedType === 'private' && 'linear(to-r, #295377, #208C74)'
            }
            bg={'none'}
            fontSize={{ base: '12px', xl: '16px' }}
            fontWeight={500}
            color={bedType === 'private' ? '#fff' : 'primaryBlack.100'}
            borderRadius={'25px'}
            h={{ base: '35px', xl: '45px' }}
            textTransform={'capitalize'}
            onClick={() => setType('private')}
            _hover={{
              bgGradient: 'linear(to-r, #295377, #208C74)',
              color: '#fff',
            }}
          >
            Private
          </Button>
          <Button
            bgGradient={bedType === 'vip' && 'linear(to-r, #295377, #208C74)'}
            bg={'none'}
            fontSize={{ base: '12px', xl: '16px' }}
            fontWeight={500}
            color={bedType === 'vip' ? '#fff' : 'primaryBlack.100'}
            borderRadius={'25px'}
            h={{ base: '35px', xl: '45px' }}
            textTransform={'capitalize'}
            onClick={() => setType('vip')}
            _hover={{
              bgGradient: 'linear(to-r, #295377, #208C74)',
              color: '#fff',
            }}
          >
            VIP
          </Button>
        </Stack>
      )} */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={'Occupied Days'}
        subTitle={'Enter the details to add new bed service'}
        children={
          <OccupiedBed itemToOccupied={itemToOccupied} onClose={onClose} />
        }
        isCentered={true}
      />
      <Stack
        my={4}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        {/* <Text
          fontSize={selectedService === 'bedBooking' ? '20px' : '18px'}
          fontWeight={'500'}
        >
          {selectedService === 'bedBooking'
            ? 'You’ve 1 bed booking request'
            : 'In “General” you’ve 15 bed'}
        </Text> */}
        {/* {selectedService !== 'bedBooking' && (
          <Button
            shadow={'0px 16px 38px 0px #0000000F'}
            border={'1px solid #75767A'}
            color="#75767A"
            fontWeight={'500'}
            fontSize={'16px'}
            borderRadius={'21px'}
            onClick={onOpen}
          >
            Add New Bed
          </Button>
        )} */}
      </Stack>

      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {data && data.length > 0 ? (
          data.map(item => (
            <Box
              borderRadius={'16px'}
              p={4}
              // shadow={'0px 16.32px 38.75px 0px #0000000F'}
              shadow={'lg'}
            >
              <Stack
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Heading fontSize={'20px'} fontWeight={'500'}>
                  {item['ward']?.['wardName'] || 'General'} Bed Booking
                </Heading>
                <Heading fontSize={'20px'} color={'#208C74'} fontWeight={'500'}>
                  ${item['ward']?.['price'] || item?.price}
                </Heading>
                {/* {selectedService === 'bedBooking' ? (
                 <>
                   <Heading fontSize={'20px'} fontWeight={'500'}>
                     General Bed Booking
                   </Heading>
                   <Heading
                     fontSize={'20px'}
                     color={'#208C74'}
                     fontWeight={'500'}
                   >
                     $100.00
                   </Heading>
                 </>
               ) : (
                 <>
                   <Stack lineHeight={'0.3em'}>
                     <Heading fontSize={'20px'} fontWeight={'500'}>
                       General Bed Booking
                     </Heading>
                     <Heading
                       fontSize={'20px'}
                       color={'#208C74'}
                       fontWeight={'500'}
                     >
                       $100.00
                     </Heading>
                   </Stack>
                   <Stack>
                     <Button
                       bgGradient="linear(to-r, #295377, #208C74)"
                       color="#E9E9E9"
                       fontWeight={'500'}
                       fontSize={'15px'}
                       borderRadius={'21px'}
                       disabled={item.occupied ? true : false}
                     >
                       Edit
                     </Button>
                   </Stack>
                 </>
               )} */}
              </Stack>

              <Stack flexDirection={'column'} lineHeight={'0.6rem'} mt={3}>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text fontSize={'18px'} color={'#75767A'} fontWeight={'500'}>
                    Patient Name
                  </Text>
                  <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
                    {item?.name}
                  </Text>
                </Stack>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text fontSize={'18px'} color={'#75767A'} fontWeight={'500'}>
                    Age
                  </Text>
                  <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
                    {item?.age}
                  </Text>
                </Stack>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text fontSize={'18px'} color={'#75767A'} fontWeight={'500'}>
                    Sex
                  </Text>
                  <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
                    {item?.gender}
                  </Text>
                </Stack>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text fontSize={'18px'} color={'#75767A'} fontWeight={'500'}>
                    Patient Location:
                  </Text>
                  <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
                    {item?.location}
                  </Text>
                </Stack>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text fontSize={'18px'} color={'#75767A'} fontWeight={'500'}>
                    Desired Inpatient Department:
                  </Text>
                  <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
                    {moment(item.createdAt).format('D/M/YYYY') || '29/3/2024'}
                  </Text>
                </Stack>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text fontSize={'18px'} color={'#75767A'} fontWeight={'500'}>
                    Type of Bed:
                  </Text>
                  <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
                    {item?.typeOfBed}
                  </Text>
                </Stack>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text fontSize={'18px'} color={'#75767A'} fontWeight={'500'}>
                    Bed Name:
                  </Text>
                  <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
                    {item?.bed?.['name']}
                  </Text>
                </Stack>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text fontSize={'18px'} color={'#75767A'} fontWeight={'500'}>
                    Desired Hospitalist:
                  </Text>
                  <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
                    {item?.hospitalist}
                  </Text>
                </Stack>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text fontSize={'18px'} color={'#75767A'} fontWeight={'500'}>
                    Treatment Cost to Be Paid by:
                  </Text>
                  <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
                    {item?.costtobepaid.toUpperCase() || 'Insurance'}
                  </Text>
                </Stack>
              </Stack>

              {/* <HStack mt={'25px'} mb={2}>
                <Button
                  w="40%"
                  bgGradient="linear(to-r, #295377, #208C74)"
                  color="#E9E9E9"
                  fontWeight={'500'}
                  fontSize={'15px'}
                  borderRadius={'21px'}
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                >
                  Available
                </Button>
                <Button
                  w="60%"
                  shadow={'0px 16px 38px 0px #0000000F'}
                  border={'1px solid #75767A'}
                  color="#75767A"
                  fontWeight={'500'}
                  fontSize={'15px'}
                  borderRadius={'21px'}
                  onClick={() => {
                    setOccupied(item);
                    onOpen();
                  }}
                >
                  Occupied
                </Button>
              </HStack> */}
              {/* <HStack mt={'25px'} mb={4}>
               <Button
                 w="50%"
                 bgGradient="linear(to-r, #295377, #208C74)"
                 color="#E9E9E9"
                 fontWeight={'500'}
                 fontSize={'15px'}
                 borderRadius={'21px'}
               >
                 {selectedService === 'bedBooking' ? 'Accepted' : 'Available'}
               </Button>
               <Button
                 w="50%"
                 shadow={'0px 16px 38px 0px #0000000F'}
                 border={'1px solid #75767A'}
                 color="#75767A"
                 fontWeight={'500'}
                 fontSize={'15px'}
                 borderRadius={'21px'}
               >
                 {selectedService === 'bedBooking' ? (
                   'Rejected'
                 ) : selectedService === 'patientBooking' ? (
                   `${
                     item.occupied
                       ? `Occupied for ${item.occupiedDays} days`
                       : 'Occupied'
                   } `
                 ) : item.occupied &&
                   selectedService === 'inpatientBooking' ? (
                   <Stack
                     w="100%"
                     flexDirection={'row'}
                     justifyContent={'space-between'}
                     alignItems={'center'}
                   >
                     <Text>Occupied</Text>
                     <Button
                       bgGradient="linear(to-r, #295377, #208C74)"
                       color="#E9E9E9"
                       w="83px"
                       h="30px"
                       fontWeight={'500'}
                       marginTop={'0 !important'}
                       fontSize={'15px'}
                       borderRadius={'21px'}
                     >
                       Edit
                     </Button>
                   </Stack>
                 ) : (
                   'Occupied'
                 )}
               </Button>
             </HStack> */}
            </Box>
          ))
        ) : (
          <Heading>No Data Found!</Heading>
        )}
      </Grid>
    </div>
  );
}

export default BookingCard;
