import React, { useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
  Spinner,
  SimpleGrid,
  useDisclosure,
  Icon,
  Textarea,
  Toast,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import ModalWrapper from '../../../../components/Dashboard/Modal';
import { FaRegStar, FaStar } from 'react-icons/fa';

function AddReview({ onCLose }) {
  const [travelrating, setTravelRating] = useState([
    { id: 1, isCheck: false },
    { id: 2, isCheck: false },
    { id: 3, isCheck: false },
    { id: 4, isCheck: false },
    { id: 5, isCheck: false },
  ]);
  const toast = useToast();
  const [travelFeedback, setTravelFeedback] = useState('');
  return (
    <Stack direction={'column'} gap={2}>
      <Text mt={4} fontSize={'18px'} fontWeight={500}>
        Bed Service Feedback:
      </Text>
      <Text mt={4} color={'#75767A'} fontSize={'15px'} fontWeight={400}>
        Rate the to the recent service
      </Text>

      <Stack
        display={'flex'}
        flexDirection={'row'}
        gap={6}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {travelrating?.map((item, index) => (
          <Icon
            as={item?.isCheck ? FaStar : FaRegStar}
            m="0 !important"
            p="0 !important"
            w="55px"
            h="55px"
            onClick={() =>
              setTravelRating(
                travelrating.map(val =>
                  val.id === index + 1 ? { ...val, isCheck: !val.isCheck } : val
                )
              )
            }
            color="#FEB052"
          />
        ))}
      </Stack>
      <Textarea
        placeholder="Detail Feedback..."
        height={'117px'}
        borderRadius={'14px'}
        onChange={e => setTravelFeedback(e.target.value)}
        name="description"
        resize={'none'}
        border={'1px solid #75767A'}
        required
      ></Textarea>

      <Stack my={'15px !important'}>
        <Button
          w="100%"
          bgGradient="linear(to-r, #295377, #208C74)"
          color="#E9E9E9"
          fontWeight={'500'}
          fontSize={'16px'}
          borderRadius={'21px'}
          border={'2px solid transparent'}
          _hover={{
            bgGradient: 'none',
            borderColor: 'primaryGreen.200',
            color: 'primaryGreen.200',
          }}
          onClick={() => {
            onCLose();
            toast({
              description: 'Review Added Sucessfully!!',
              status: 'success',
              isClosable: true,
              position: 'top-right',
              duration: 3000,
            });
          }}
        >
          Send
        </Button>
      </Stack>
    </Stack>
  );
}

export default function PatBooking({ data }) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <ModalWrapper
        title={'Add Review'}
        subTitle={'Enter the details to add review'}
        isOpen={isOpen}
        onClose={onClose}
        children={<AddReview onCLose={onClose} />}
      />
      <SimpleGrid
        columns={{ base: '1', md: '2', xl: '2', '2xl': '3' }}
        spacing={10}
      >
        {data?.length > 0 &&
          data?.map((v, k) => {
            return (
              <Box key={k} borderRadius={'16px'} p={4} shadow={'lg'}>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Heading fontSize={'20px'} fontWeight={'500'}>
                    Bed Booking Details
                  </Heading>
                  <Heading
                    fontSize={'20px'}
                    color={'#208C74'}
                    fontWeight={'500'}
                  >
                    {v?.bed?.name}
                  </Heading>
                </Stack>

                <Stack
                  flexDirection={'column'}
                  lineHeight={'0.6rem'}
                  mt={3}
                  gap={2}
                >
                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Patient Name
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.name}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Age
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.age}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Sex
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.gender}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Patient Location
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.location}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Desired Inpatient Department
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.department?.name}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Type of Bed
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.typeOfBed}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Bed Name
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.bed?.name}
                    </Text>
                  </Stack>
                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Start Date
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {moment(v?.startDate).format('DD/MM/YYYY')}
                    </Text>
                  </Stack>
                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      End Date
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {moment(v?.endDate).format('DD/MM/YYYY')}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Desired Hospitalist
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.hospitalist}
                    </Text>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontSize={'18px'}
                      color={'#75767A'}
                      fontWeight={'500'}
                    >
                      Treatment Cost to Be Paid by
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#1B1C1D'}
                      fontWeight={'500'}
                    >
                      {v?.costtobepaid}
                    </Text>
                  </Stack>

                  <Button
                    w="100%"
                    shadow={'0px 16px 38px 0px #0000000F'}
                    border={'1px solid #75767A'}
                    color="#75767A"
                    fontWeight={'500'}
                    fontSize={'16px'}
                    borderRadius={'21px'}
                    onClick={onOpen}
                  >
                    Add Review
                  </Button>
                </Stack>
              </Box>
            );
          })}
      </SimpleGrid>
    </>
  );
}
