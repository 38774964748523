import React, { useEffect, useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  VStack,
  Spinner,
  Icon,
} from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa6';
import { FaRegHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { GET, POST } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';

function Favourites() {
  const [selectedService, setSelectedService] = useState('doctor');
  const [favourites, setFavourites] = useState(null);
  useEffect(() => {
    fetchFavourites();
  }, []);
  const fetchFavourites = async () => {
    try {
      const response = await GET(`/web/patient/getFavourite`);
      console.log(response);
      if (response.status === 200) setFavourites(response.data);
    } catch (error) {
      console.error('Error fetching favourites data:', error);
    }
  };
  const handleFavourite = async (type, id) => {
    try {
      let check = type === 'doctor' ? 'doctor' : 'hospital';

      const response = await POST('/web/patient/favourite', {
        type: type,
        [check]: id,
      });
      if (response.status === 200) {
        fetchFavourites();
      }
    } catch (error) {
      console.log('Error while fetching hospitals', error);
    }
  };
  return (
    <Stack>
      <Stack>
        <Heading fontSize={'36px'} fontWeight={'500'}>
          Favourites
        </Heading>
        {/* <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text> */}
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={'20px'}>
        <Button
          bgGradient={
            selectedService === 'doctor' && 'linear(to-r, #295377, #208C74)'
          }
          bg={'none'}
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight={500}
          color={selectedService === 'doctor' ? '#fff' : 'primaryBlack.100'}
          borderRadius={'25px'}
          h={{ base: '35px', xl: '45px' }}
          textTransform={'uppercase'}
          onClick={() => setSelectedService('doctor')}
          _hover={{
            bgGradient: 'linear(to-r, #295377, #208C74)',
            color: '#fff',
          }}
        >
          Health Practitioner
        </Button>
        <Button
          bgGradient={
            selectedService === 'hospital' && 'linear(to-r, #295377, #208C74)'
          }
          bg={'none'}
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight={500}
          color={selectedService === 'hospital' ? '#fff' : 'primaryBlack.100'}
          borderRadius={'25px'}
          h={{ base: '35px', xl: '45px' }}
          textTransform={'uppercase'}
          onClick={() => setSelectedService('hospital')}
          _hover={{
            bgGradient: 'linear(to-r, #295377, #208C74)',
            color: '#fff',
          }}
        >
          Hospitals
        </Button>
      </Stack>
      <Grid
        mt={'25px !important'}
        gridTemplateColumns={'repeat(2, 1fr)'}
        gap={6}
      >
        {!favourites ? (
          <Stack w="100%" h="100vh">
            <Spinner />
          </Stack>
        ) : favourites[selectedService].length > 0 ? (
          favourites[selectedService]?.map((item, index) => {
            if (selectedService === 'hospital') {
              return (
                <Box
                  shadow={'0px 16px 38px 0px #0000000F'}
                  bg={'#fff'}
                  borderRadius={'16px'}
                  p={4}
                  display={'flex'}
                  flexDirection={'column'}
                  gap={4}
                >
                  <Stack
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    px={4}
                  >
                    <HStack alignItems={'center'} lineHeight={'0.3rem'} gap={2}>
                      <Avatar
                        size={'lg'}
                        name="Dan Abrahmov"
                        // src={`${imgUrl}/${
                        //   selectedService === 'patient' ? item.picture : item.photo
                        // }`}
                        src={
                          `${imgUrl}/${item?.hospitalPicture}` ||
                          'https://bit.ly/dan-abramov'
                        }
                      />
                      <Stack>
                        <Heading fontSize={'22px'} fontWeight={'500'}>
                          {item?.hospitalName}
                        </Heading>
                        <Text
                          fontSize={'15px'}
                          color={'#75767A'}
                          fontWeight={'400'}
                        >
                          {item?.hospitalLocation}
                        </Text>
                        <Box w="100%">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Text
                              fontSize="15px"
                              color="#75767A"
                              fontWeight="400"
                            >
                              {item?.hospitalRating}
                            </Text>

                            <Icon
                              p={'0 !important'}
                              mt={'0 !important'}
                              as={FaStar}
                              color="#FEB052"
                            />
                            <Icon
                              p={'0 !important'}
                              mt={'0 !important'}
                              as={FaStar}
                              color="#FEB052"
                            />
                            <Icon
                              p={'0 !important'}
                              mt={'0 !important'}
                              as={FaStar}
                              color="#FEB052"
                            />
                            <Icon
                              p={'0 !important'}
                              mt={'0 !important'}
                              as={FaStar}
                              color="#FEB052"
                            />
                            <Icon
                              p={'0 !important'}
                              mt={'0 !important'}
                              as={FaStar}
                              color="#FEB052"
                            />
                            <Text
                              fontSize="15px"
                              color="#75767A"
                              fontWeight="400"
                            >
                              {`(${item?.hospitalReview} reviews)`}
                            </Text>
                          </Stack>
                        </Box>
                      </Stack>
                    </HStack>
                    <Stack
                      onClick={() =>
                        handleFavourite('hospital', item?.hospitalId)
                      }
                      cursor={'pointer'}
                    >
                      <Icon as={FaHeart} w="27px" h="25px" color="#208C74" />
                    </Stack>
                  </Stack>
                  <Stack pt={4}>
                    <Button
                      w="100%"
                      bgGradient="linear(to-r, #295377, #208C74)"
                      color="#E9E9E9"
                      fontWeight={'500'}
                      fontSize={'15px'}
                      borderRadius={'21px'}
                      border={'2px solid transparent'}
                      _hover={{
                        bgGradient: 'none',
                        borderColor: 'primaryGreen.200',
                        color: 'primaryGreen.200',
                      }}
                      as={Link}
                      to={`/dashboard/hospital-profile/${item?.hospitalId}`}
                    >
                      Continue
                    </Button>
                  </Stack>
                </Box>
              );
            } else {
              return (
                <Box
                  shadow={'0px 16px 38px 0px #0000000F'}
                  bg={'#fff'}
                  borderRadius={'16px'}
                  p={4}
                  display={'flex'}
                  flexDirection={'column'}
                  gap={4}
                >
                  <Stack
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    px={4}
                  >
                    <HStack alignItems={'center'} lineHeight={'0.3rem'} gap={2}>
                      <Avatar
                        size={'lg'}
                        name="Dan Abrahmov"
                        // src={`${imgUrl}/${
                        //   selectedService === 'patient' ? item.picture : item.photo
                        // }`}
                        src={
                          `${imgUrl}/${item?.picture}` ||
                          'https://bit.ly/dan-abramov'
                        }
                      />
                      <Stack>
                        <Heading fontSize={'22px'} fontWeight={'500'}>
                          {item?.name}
                        </Heading>
                        <Box w="100%">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Text
                              fontSize="15px"
                              color="#75767A"
                              fontWeight="400"
                            >
                              {item?.speciality}
                            </Text>
                            <Icon
                              p={'0 !important'}
                              mt={'0 !important'}
                              as={FaStar}
                              color="#FEB052"
                            />
                            <Text
                              fontSize="15px"
                              color="#75767A"
                              fontWeight="400"
                            >
                              {`|  ${item?.reviews} Reviews`}
                            </Text>
                          </Stack>
                        </Box>
                      </Stack>
                    </HStack>
                    <Stack
                      cursor={'pointer'}
                      onClick={() => handleFavourite('doctor', item?.doctorId)}
                    >
                      <Icon as={FaHeart} w="27px" h="25px" color="#208C74" />
                    </Stack>
                  </Stack>
                  <Stack pt={4}>
                    <Button
                      w="100%"
                      bgGradient="linear(to-r, #295377, #208C74)"
                      color="#E9E9E9"
                      fontWeight={'500'}
                      fontSize={'15px'}
                      borderRadius={'21px'}
                      border={'2px solid transparent'}
                      _hover={{
                        bgGradient: 'none',
                        borderColor: 'primaryGreen.200',
                        color: 'primaryGreen.200',
                      }}
                      as={Link}
                      to={`/dashboard/doctor-profile/${item?.doctorId}`}
                    >
                      View Profile
                    </Button>
                  </Stack>
                </Box>
              );
            }
          })
        ) : (
          <Heading fontSize={'36px'} fontWeight={'500'}>
            No Favourites Found
          </Heading>
        )}
      </Grid>
    </Stack>
  );
}

export default Favourites;
