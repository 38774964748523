import React, { useEffect, useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Divider,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  Image,
  VStack,
  Icon,
  Spinner,
} from '@chakra-ui/react';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import Doctor from '../../../assets/images/dashboard/doctor.png';

import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AppointmentBox from '../Appointments/AppointmentBox';
import { FaEnvelope, FaMapMarker, FaPhoneAlt } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import moment from 'moment/moment';

export default function PatientProfile() {
  const { id } = useParams();
  const [patient, setPatient] = useState({});

  async function fetchPatient() {
    try {
      const response = await GET(`/hospital/patients/${id}`);
      console.log(response, 'patient profile');
      if (response.status === 200) setPatient(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {
    fetchPatient();
  }, [id]);
  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'center'}
          fontSize={'36px'}
          fontWeight={'500'}
        >
          Patients Details
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the details of patient
        </Text>
      </Stack>
      {/*Heading  */}

      <Grid gridTemplateColumns={'2fr 1fr'} gap={'80px'}>
        <Box display={'flex'} flexDirection={'column'} gap={5}>
          <Stack>
            <Box w="151px" h="151px" borderRadius={'50%'}>
              {/* <Image
                borderRadius={'100%'}
                src={
                  `${imgUrl}/${patient.picture}` ||
                  'https://bit.ly/prosper-baba'
                }
              /> */}
              <Image
                borderRadius="full"
                boxSize="130px"
                src={
                  `${imgUrl}/${patient.picture}` ||
                  'https://bit.ly/prosper-baba'
                }
                alt="Dan Abramov"
              />
            </Box>
            <Heading
              display={'flex'}
              alignItems={'center'}
              fontSize={'26px'}
              fontWeight={'500'}
            >
              {patient.fullName || 'Dexter Watts'} /{' '}
              <Text
                mt={'8px'}
                ml={'2px'}
                color={'#208C74'}
                fontSize={'16px'}
                fontWeight={'500'}
              >
                {patient.gender || 'Male'}
              </Text>
            </Heading>
            <Text fontSize={'20px'} color={'#75767A'} fontWeight={'400'}>
              #d44vdsQ
            </Text>
          </Stack>
          <Box>
            <Stack
              mb="10px !important"
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Heading
                fontSize={'20px'}
                fontWeight={'500'}
                color={'primaryBlack.100'}
                my="10px"
              >
                About
              </Heading>
              <Heading
                fontSize={'20px'}
                fontWeight={'500'}
                color={'primaryBlack.100'}
                my="10px"
              >
                3+ Years of Experience
              </Heading>
            </Stack>
            <Text
              mb="10px"
              fontSize={'14px'}
              fontWeight={'400'}
              color={'primaryGray.100'}
            >
              {patient.about ||
                `Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.`}
            </Text>
            <Stack direction={'row'} alignItems={'center'} gap="30px">
              <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
                <Icon mr="5px" as={FaMapMarker} />{' '}
                {patient.address || 'King Fahad Rd, Al Bustan'}
              </Text>
              <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
                <Icon mr="5px" as={FaPhoneAlt} />
                {patient.number || '+966 765 272 46'}
              </Text>
            </Stack>
            <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
              <Icon mr="5px" as={FaEnvelope} />
              {patient.email || ' adam.knight@gmail.com'}
            </Text>
            <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
              Registration Date:
              {moment(patient.createdAt).format('DD MMM YYYY') ||
                ' 12 Feb 2024'}
            </Text>
            <Text
              alignItems={'center'}
              display={'flex'}
              gap="10px"
              color={'primaryGray.100'}
              fontSize={'14px'}
            >
              {' '}
              Patient Status:
              <Badge
                w="81px"
                height="28px"
                display={'flex'}
                alignItems={'center'}
                borderRadius={'50px'}
                justifyContent={'center'}
                fontWeight={'400'}
                fontSize={'14px'}
                bgGradient={'linear(to-r, primaryGreen.100, primaryGreen.200)'}
                color={'#fff'}
                textTransform={'capitalize'}
              >
                {patient.status}
              </Badge>
            </Text>
          </Box>
        </Box>
        <Box>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Heading
              fontSize={'20px'}
              fontWeight={'500'}
              color={'primaryBlack.100'}
              my="10px"
            >
              Appointments
            </Heading>
          </Stack>
          {!patient?.appointments ? (
            <Spinner />
          ) : patient?.appointments?.length > 0 ? (
            patient.appointments?.map((item, index) => (
              <Box
                mb="20px"
                shadow={'lg'}
                w="100%"
                borderRadius={'16px'}
                bg={'#fff'}
                p={5}
              >
                <Text color={'primaryGray.100'} fontSize={'16px'} mb="10px">
                  Tomorrow
                </Text>
                <HStack justifyContent={'space-between'}>
                  <HStack alignItems={'center'}>
                    <WrapItem>
                      <Avatar
                        size="lg"
                        name="Prosper Otemuyiwa"
                        src={
                          `${imgUrl}/${item.assignedDoctorPhoto}` ||
                          'https://bit.ly/kent-c-dodds'
                        }
                      />{' '}
                    </WrapItem>
                    <Stack justifyContent={'center'}>
                      <Heading fontWeight={'400'} fontSize={'22px'}>
                        {item.assignedDoctor || 'Adam Knight'}
                      </Heading>
                      <Text
                        lineHeight={0}
                        fontSize={'16px'}
                        fontWeight={'400'}
                        color={'#75767A'}
                      >
                        #d44vdsQ
                      </Text>
                    </Stack>
                  </HStack>
                </HStack>
                <Stack gap={4} mt={4}>
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Stack flex={1} margin={'0 !important'}>
                      <Text
                        fontSize={'16px'}
                        fontWeight={'400'}
                        color={'#75767A'}
                      >
                        Appointment Date
                      </Text>
                      <Text
                        lineHeight={'10px'}
                        fontSize={'16px'}
                        fontWeight={'500'}
                        color={'#1B1C1D'}
                      >
                        {moment(item.startTime).format('M/D/YYYY') ||
                          '2/27/2024'}
                      </Text>
                    </Stack>

                    <Stack flex={1} margin={'0 !important'}>
                      <Text
                        fontSize={'16px'}
                        fontWeight={'400'}
                        color={'#75767A'}
                      >
                        Patient Type
                      </Text>
                      <Text
                        lineHeight={'10px'}
                        fontSize={'16px'}
                        fontWeight={'500'}
                        color={'#1B1C1D'}
                      >
                        {item.type || 'Consultation'}
                      </Text>
                    </Stack>
                  </Stack>
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Stack flex={1} margin={'0 !important'}>
                      <Text
                        fontSize={'16px'}
                        fontWeight={'400'}
                        color={'#75767A'}
                      >
                        $Price
                      </Text>
                      <Text
                        lineHeight={'10px'}
                        fontSize={'16px'}
                        fontWeight={'500'}
                        color={'#1B1C1D'}
                      >
                        ${item.assignedDoctorCharges || '120.00'}
                      </Text>
                    </Stack>

                    <Stack flex={1} margin={'0 !important'}>
                      <Text
                        fontSize={'16px'}
                        fontWeight={'400'}
                        color={'#75767A'}
                      >
                        Time
                      </Text>
                      <Text
                        lineHeight={'10px'}
                        fontSize={'16px'}
                        fontWeight={'500'}
                        color={'#1B1C1D'}
                      >
                        {moment(item.startTime).format('h - mm A') ||
                          ' 3 - 30 PM'}
                      </Text>
                    </Stack>
                  </Stack>

                  <Stack>
                    <Button
                      w="100%"
                      as={Link}
                      to={`/dashboard/appointments/appointment-details/${item?._id}`}
                      bgGradient="linear(to-r, #295377, #208C74)"
                      color="#E9E9E9"
                      fontWeight={'500'}
                      fontSize={'15px'}
                      borderRadius={'21px'}
                      border={'2px solid transparent'}
                      _hover={{
                        bgGradient: 'none',
                        borderColor: 'primaryGreen.200',
                        color: 'primaryGreen.200',
                      }}
                    >
                      View Appointment Details
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            ))
          ) : (
            <Text color={'primaryGray.100'} fontSize={'16px'} mb="10px">
              No Appointment For Today
            </Text>
          )}
        </Box>
      </Grid>
      <Divider mt={'25px !important'} color={'#75767A'} />
    </Stack>
  );
}
