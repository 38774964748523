import React, { useState } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
// import HomeWrap from '../../../components/Dashboard/Home/HomeWrap';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  Divider,
  AvatarGroup,
  Button,
  Badge,
  WrapItem,
  Image,
  VStack,
  Icon,
  Spinner,
} from '@chakra-ui/react';
import { CalendarIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import DashboardHeader from '../../../components/Header/DashboardHeader';
import Doctor from '../../../assets/images/dashboard/doctor.png';
import { FaStar } from 'react-icons/fa';

import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AppointmentBox from '../Appointments/AppointmentBox';
import { FaEnvelope, FaMapMarker, FaPhoneAlt, FaRegStar } from 'react-icons/fa';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { useEffect } from 'react';
import { imgUrl } from '../../../utilities/config';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
function DoctorProfile() {
  const user = useSelector(state => state.user.value);
  const { id } = useParams();
  const [reviews, setReviews] = useState([]);
  const [doctor, setDoctor] = useState(null);
  const [searchParams] = useSearchParams();
  const [type, setType] = useState(user?.type);
  const typeUrl = searchParams.get('type');

  useEffect(() => {
    setType(user?.type);
  }, [user]);

  async function DoctorDetails() {
    try {
      const response = await GET(`/hospital/practitioners/${id}`);
      console.log(response);
      if (response.status === 200) setDoctor(response.data);
    } catch (error) {
      console.error('Error fetching doctor detail data:', error);
    }
  }

  async function fetchReviews() {
    try {
      const response = await GET(`/web/patient/doctor/${id}/reviews`);
      console.log(response);
      if (response.status === 200) setReviews(response.data);
    } catch (error) {
      console.error('Error fetching doctor review data:', error);
    }
  }
  useEffect(() => {
    DoctorDetails();
    fetchReviews();
    // if (type === 'patient') fetchReviews();
  }, []);
  return (
    <Stack>
      {/*Heading  */}
      <Stack>
        <Heading
          display={'flex'}
          alignItems={'center'}
          fontSize={'36px'}
          fontWeight={'500'}
        >
          Doctor Details
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the details of doctor
        </Text>
      </Stack>
      {/*Heading  */}

      <Grid gridTemplateColumns={'2fr 1fr'} gap={'80px'}>
        {!doctor ? (
          <Spinner />
        ) : (
          <Box display={'flex'} flexDirection={'column'} gap={5}>
            <Stack>
              <Box w="151px" h="151px">
                {/* <Image
                      borderRadius={'50%'}
                      src={`${imgUrl}/${doctor?.photo}` || Doctor}
                    /> */}
                <Image
                  borderRadius="full"
                  boxSize="130px"
                  src={`${imgUrl}/${doctor?.photo}` || Doctor}
                  alt="Dan Abramov"
                />
              </Box>
              <Heading
                display={'flex'}
                alignItems={'center'}
                fontSize={'26px'}
                fontWeight={'500'}
              >
                Dr {doctor?.name || ' Dr. Alexa John'} /{' '}
                <Text
                  mt={'8px'}
                  ml={'2px'}
                  color={'#208C74'}
                  fontSize={'16px'}
                  fontWeight={'500'}
                >
                  {doctor?.generalSpeciality + '  ' + 'Specialist' ||
                    'Cadiology Specialist'}
                </Text>
              </Heading>
              <Text fontSize={'20px'} color={'#75767A'} fontWeight={'400'}>
                #d44vdsQ
              </Text>
            </Stack>

            <Stack
              direction={'row'}
              alignItems={'center'}
              gap="30px"
              flexWrap={'wrap'}
            >
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Hospital Name
                </Heading>
                <Text
                  fontSize={'16px'}
                  fontWeight={'400'}
                  color={'primaryGray.100'}
                >
                  {doctor?.hospitalName}
                </Text>
              </Stack>
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Subspecialty
                </Heading>
                <Text
                  fontSize={'14px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.subspeciality}
                </Text>
              </Stack>
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Academic Qualification
                </Heading>
                <Text
                  fontSize={'14px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.academicQualification}
                </Text>
              </Stack>
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Treatment Services
                </Heading>
                <Text
                  fontSize={'14px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.medicalTreatmentService}
                </Text>
              </Stack>
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Examination Charges
                </Heading>
                <Text
                  fontSize={'14px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.charges}
                </Text>
              </Stack>
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Heading
                fontSize={'20px'}
                fontWeight={'500'}
                color={'primaryBlack.100'}
                my="10px"
              >
                About :
              </Heading>
              <Heading
                fontSize={'20px'}
                fontWeight={'500'}
                color={'primaryBlack.100'}
                my="10px"
              >
                {doctor?.yearsOfExperience}+ Years of Experience
              </Heading>
            </Stack>
            <Text
              fontSize={'14px'}
              fontWeight={'400'}
              color={'primaryGray.100'}
            >
              {doctor?.about ||
                `  Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.`}
            </Text>
            {type === 'patient' ? (
              <Stack direction={'row'} alignItems={'center'} gap="30px">
                {doctor?.opening_hours?.map((val, ind) => (
                  <Text key={ind}>
                    <Text>{val.day}</Text>
                    <Text>
                      {val.startTime} - {val.endTime}
                    </Text>
                  </Text>
                ))}
              </Stack>
            ) : (
              <Stack>
                <Stack direction={'row'} alignItems={'center'} gap="30px">
                  <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
                    <Icon mr="5px" as={FaMapMarker} />{' '}
                    {doctor?.employmentAddress || 'King Fahad Rd, Al Bustan'}
                  </Text>
                  <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
                    <Icon mr="5px" as={FaPhoneAlt} />{' '}
                    {doctor?.number || '+966 765 272 46'}
                  </Text>
                </Stack>
                <Text mb="10px" color={'primaryGray.100'} fontSize={'14px'}>
                  <Icon mr="5px" as={FaEnvelope} />{' '}
                  {doctor?.email || 'adam.knight@gmail.com'}
                </Text>
              </Stack>
            )}

            <Heading
              fontSize={'20px'}
              fontWeight={'500'}
              color={'primaryBlack.100'}
              my="10px"
            >
              Contact :
            </Heading>
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap="30px"
              flexWrap={'wrap'}
            >
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Address
                </Heading>
                <Text
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.address || 'King Fahd Rd, Al Bustan'}
                </Text>
              </Stack>
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Email
                </Heading>
                <Text
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.email || 'King Fahd Rd, Al Bustan'}
                </Text>
              </Stack>
              <Stack flexDirection={'column'}>
                <Heading
                  fontSize={'18px'}
                  fontWeight={'500'}
                  color={'primaryBlack.100'}
                >
                  Phone No
                </Heading>
                <Text
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'primaryGray.100'}
                >
                  {doctor?.number || '0310876351'}
                </Text>
              </Stack>
            </Stack>
            <iframe
              style={{ width: '100%', height: '200px' }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.144404792699!2d46.614954999999995!3d24.824734799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1b5ab2e599c1%3A0x58ab1ee4e94e9c89!2sKing%20Fahd%20Rd%2C%20Riyadh%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1714130640115!5m2!1sen!2s"
            />
            {type === 'patient' && (
              <Stack mt={'10px !important'}>
                <Button
                  w={'217px'}
                  bgGradient={'linear(to-r, #295377, #208C74)'}
                  fontSize={{ base: '12px', xl: '14px' }}
                  fontWeight={500}
                  color={'#fff'}
                  borderRadius={'25px'}
                  h={{ base: '35px', xl: '45px' }}
                  textTransform={'uppercase'}
                  border={'2px solid transparent'}
                  _hover={{
                    bgGradient: 'none',
                    borderColor: 'primaryGreen.200',
                    color: 'primaryGreen.200',
                  }}
                  as={Link}
                  to={`/dashboard/book-appointment/${doctor?._id}?hospital=${doctor?.hospital}&patientType=${typeUrl}`}
                >
                  Book Appointment
                </Button>
              </Stack>
            )}
          </Box>
        )}
        {type === 'patient' ? (
          <Box
            shadow={'0px 16px 38px 0px #0000000F'}
            bg={'#fff'}
            borderRadius={'16px'}
            p={4}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              mb={'20px !important'}
            >
              <Heading fontSize={'25px'} fontWeight={500}>
                {reviews?.length || '0'} Reviews
              </Heading>
              {reviews?.length > 0 && (
                <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
                  View All
                </Text>
              )}
            </Stack>
            {reviews?.length > 0 ? (
              reviews?.map((val, ind) => (
                <Stack mb={'20px !important'}>
                  <HStack gap={2}>
                    <Stack>
                      <Avatar
                        size="lg"
                        name="Dan Abrahmov"
                        src={`${imgUrl}/${val?.patient?.picture}` || Doctor}
                      />
                    </Stack>
                    <Stack direction={'column'} lineHeight={'0'}>
                      <Heading
                        display={'flex'}
                        alignItems={'center'}
                        fontSize={'22px'}
                        fontWeight={'500'}
                      >
                        {val?.patient?.fullName}{' '}
                      </Heading>
                      <Stack direction={'row'} alignItems={'center'}>
                        <Text>{val?.doctorRating}.0</Text>
                        <HStack>
                          {Array.from({ length: 5 }, (_, i) => (
                            <Icon
                              as={i < val?.doctorRating ? FaStar : FaRegStar}
                              color={'#FEB052'}
                              key={i}
                            />
                          ))}
                        </HStack>
                      </Stack>
                    </Stack>
                  </HStack>
                  <Stack my={'20px !important'}>
                    <Text
                      p={'0 20px !important'}
                      fontSize={'16px'}
                      color={'#75767A'}
                      fontWeight={'400'}
                    >
                      {val?.doctorFeedback}
                    </Text>
                  </Stack>
                  <Stack>
                    <Divider m={'0 auto !important'} borderColor={'#1B1C1D'} />
                  </Stack>
                </Stack>
              ))
            ) : (
              <Text>No Reviews</Text>
            )}
          </Box>
        ) : (
          <Box>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Heading
                fontSize={'20px'}
                fontWeight={'500'}
                color={'primaryBlack.100'}
                my="10px"
              >
                Appointments
              </Heading>
              <Heading
                fontWeight={'500'}
                fontSize={'16px'}
                color={'primaryGray.100'}
                my="10px"
                cursor={'pointer'}
                as={Link}
                to={'/dashboard/appointments'}
              >
                View All
              </Heading>
            </Stack>
            {!doctor?.appointments ? (
              <Spinner />
            ) : doctor?.appointments?.length > 0 ? (
              doctor?.appointments?.map((val, ind) => (
                <Box
                  mb="20px"
                  shadow={'lg'}
                  w="100%"
                  borderRadius={'16px'}
                  bg={'#fff'}
                  p={5}
                  key={ind}
                >
                  <Text color={'primaryGray.100'} fontSize={'16px'} mb="10px">
                    Today
                  </Text>
                  <HStack justifyContent={'space-between'}>
                    <HStack alignItems={'center'}>
                      <WrapItem>
                        <Avatar
                          size="lg"
                          name="Prosper Otemuyiwa"
                          src={
                            `${imgUrl}/${val?.patientPicture}` ||
                            'https://bit.ly/prosper-baba'
                          }
                        />{' '}
                      </WrapItem>
                      <Stack justifyContent={'center'}>
                        <Heading fontWeight={'400'} fontSize={'22px'}>
                          {val?.patientName || 'Adam Knight'}
                        </Heading>
                        <Text
                          lineHeight={0}
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          #d44vdsQ
                        </Text>
                      </Stack>
                    </HStack>
                  </HStack>
                  <Stack gap={4} mt={4}>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Stack flex={1} margin={'0 !important'}>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          Appointment Date
                        </Text>
                        <Text
                          lineHeight={'10px'}
                          fontSize={'16px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          {moment(val.startTime).format('M/D/YYYY') ||
                            '2/27/2024'}
                        </Text>
                      </Stack>

                      <Stack flex={1} margin={'0 !important'}>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          Patient Type
                        </Text>
                        <Text
                          lineHeight={'10px'}
                          fontSize={'16px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          {val?.type || 'Consultation'}
                        </Text>
                      </Stack>
                    </Stack>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Stack flex={1} margin={'0 !important'}>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          $Price
                        </Text>
                        <Text
                          lineHeight={'10px'}
                          fontSize={'16px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          ${val?.assignedDoctorCharges || '120.00'}
                        </Text>
                      </Stack>

                      <Stack flex={1} margin={'0 !important'}>
                        <Text
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                        >
                          Time
                        </Text>
                        <Text
                          lineHeight={'10px'}
                          fontSize={'16px'}
                          fontWeight={'500'}
                          color={'#1B1C1D'}
                        >
                          {moment(val.startTime).format('h - mm A') ||
                            ' 3 - 30 PM'}
                        </Text>
                      </Stack>
                    </Stack>

                    <Stack>
                      <Button
                        w="100%"
                        as={Link}
                        to={`/dashboard/appointments/appointment-details/${val?._id}`}
                        bgGradient="linear(to-r, #295377, #208C74)"
                        color="#E9E9E9"
                        fontWeight={'500'}
                        fontSize={'15px'}
                        borderRadius={'21px'}
                        border={'2px solid transparent'}
                        _hover={{
                          bgGradient: 'none',
                          borderColor: 'primaryGreen.200',
                          color: 'primaryGreen.200',
                        }}
                      >
                        View Appointment Details
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              ))
            ) : (
              <Text color={'primaryGray.100'} fontSize={'16px'} mb="10px">
                No Appointment For Today
              </Text>
            )}
          </Box>
        )}
      </Grid>
      <Divider mt={'25px !important'} color={'#75767A'} />
    </Stack>
  );
}

export default DoctorProfile;
